import { useFormat } from 'hooks';
import {
    CheckoutMessages,
    checkoutMessagesValues,
} from 'lang/messages/checkout';
import { CommonMessages, commonMessagesValues } from 'lang/messages/common';

interface Props {
    currentZip?: string;
}

export const useBillingTranslations = ({ currentZip }: Props) => {
    const billingText = useFormat(
        CheckoutMessages,
        checkoutMessagesValues({ zip: currentZip })
    );

    const commonMessages = useFormat(CommonMessages, commonMessagesValues({}));

    return { ...billingText, ...commonMessages };
};
