import { Button } from 'components';
import { TEST_ACCOUNTS } from 'screens/PrinterUpgrade/LandingUpgrade/constants';

type TestAccount = (typeof TEST_ACCOUNTS)[0];

interface Props {
    data: TestAccount;
    onRedirect: (data: TestAccount) => void;
}

function AccountButton({ data, onRedirect }: Props) {
    return (
        <Button key={data.account_identifier} onClick={() => onRedirect(data)}>
            {data.account_identifier} [
            {new Date(data.eligible_for_printer_upgrade) < new Date()
                ? 'Eligible'
                : 'Not eligible'}
            ]
        </Button>
    );
}
export default AccountButton;
