import { useParams } from 'react-router-dom-v5-compat';
import { Button, Modal } from 'components';
import { Paths } from 'components/RoutedContent/paths';
import { TrackAction, TrackLocation } from 'constants/analytics';
import { ModalType } from 'constants/modals';
import { useCheckoutModals } from 'contexts';
import { AvailablePrinters } from 'contexts/PrinterChoice/constants';
import {
    useBreakpoint,
    useFormat,
    useRedirect,
    useTrackLink,
    useVariation,
} from 'hooks';
import {
    CheckoutMessages,
    checkoutMessagesValues,
} from 'lang/messages/checkout';

import { getBuyLink, getPrinterImage } from './utils';

import styles from './PrinterNotAvailableModal.module.scss';

function PrinterNotAvailableModal() {
    const {
        modals: { [ModalType.SayanNotAvailable]: show },
        toggleModals,
    } = useCheckoutModals();
    const track = useTrackLink();
    const { planId } = useParams();

    const {
        SAYAN_MODAL_BUTTON_BUY,
        SAYAN_MODAL_CONTINUE_BUY,
        SAYAN_MODAL_CONTINUE_SUBSCRIPTION,
        SAYAN_MODAL_TITLE,
        SAYAN_MODAL_DISCLAIMER,
        SAYAN_MODAL_CONTINUE_BUTTON,
    } = useFormat(
        CheckoutMessages,
        checkoutMessagesValues({
            modelNumber:
                planId === AvailablePrinters.HighVolume ? '7605' : '5105',
        })
    );
    const brkp = useBreakpoint();
    const { push } = useRedirect();
    const { variation } = useVariation();

    const handleRedirectToPurchase = () => {
        track(
            TrackAction.SayanNotAvailableModalBuyNowButton,
            TrackLocation.Checkout
        );
        window.open(getBuyLink(variation, planId), '_blank');
        toggleModals(ModalType.SayanNotAvailable);
    };

    const handleRedirectPrinterSelection = () => {
        track(
            TrackAction.SayanNotAvailableModalPrinterSelection,
            TrackLocation.Checkout
        );
        toggleModals(ModalType.SayanNotAvailable);
        push(Paths.PrinterSelection);
    };

    const printerImage = getPrinterImage(brkp, planId);

    return (
        <Modal
            show={show}
            onClose={() => {
                toggleModals(ModalType.SayanNotAvailable);
            }}
            hideHeader
        >
            <div className={styles.container}>
                <img src={printerImage} alt="" className={styles.img} />
                <div className={styles.contentContainer}>
                    <h1 className={styles.title}>{SAYAN_MODAL_TITLE}</h1>
                    <div className={styles.optionContainer}>
                        <div className={styles.option}>
                            <p>{SAYAN_MODAL_CONTINUE_SUBSCRIPTION}</p>
                            <Button
                                className={styles.button}
                                onClick={handleRedirectPrinterSelection}
                            >
                                {SAYAN_MODAL_CONTINUE_BUTTON}
                            </Button>
                        </div>
                        <div className={styles.option}>
                            <p>{SAYAN_MODAL_CONTINUE_BUY}</p>
                            <Button
                                className={styles.button}
                                onClick={handleRedirectToPurchase}
                            >
                                {SAYAN_MODAL_BUTTON_BUY}
                            </Button>
                        </div>
                    </div>
                    <div className={styles.footerText}>
                        {SAYAN_MODAL_DISCLAIMER}
                    </div>
                </div>
            </div>
        </Modal>
    );
}
export default PrinterNotAvailableModal;
