import {
    NewInkPlan,
    Printer,
    PrinterSelectionResponse,
} from 'contexts/PrinterChoice/constants';
import { Params } from 'screens/Checkout/constants';
import { Nullable } from 'types/globals';

export type DataProviderState = {
    loaded: boolean;
    loading: boolean;
    planData?: PrinterSelectionResponse;
    utmParams?: Record<string, string>;
    showSixMonthFreeBanner: boolean;
    showFree2DayShipping: boolean;
    show30DayRiskFree: boolean;
    showActivation: boolean;
};

export type DataContextType = {
    loaded: boolean;
    planData?: PrinterSelectionResponse;
    utmParams?: Partial<Record<string, Nullable<string>>>;
    setExistingPlan: (plan: string) => void;
    getCurrentInkPlanAndPlanId: (params: Params) => {
        inkPlan: Nullable<NewInkPlan>;
        plan: Nullable<Printer>;
    };
    showSixMonthFreeBanner: boolean;
    showFree2DayShipping: boolean;
    show30DayRiskFree: boolean;
    showActivation: boolean;
    saveData: (arg0: PrinterSelectionResponse) => void;
};

export type ProductSpecification = {
    label: string;
    data: string;
};

export type ExistingCustomer = {
    id: string;
    inkPlan: string;
    printerAge: number;
    printerModel: string;
};

export const NEXT_ERROR_ID = 500;
export const INITIAL_CONTEXT = {
    show30DayRiskFree: false,
    showFree2DayShipping: false,
    showSixMonthFreeBanner: false,
    loaded: false,
    showActivation: false,
    planData: {
        existingInkPlans: [],
        gaApiKey: '',
        newForExistingInkPlans: [],
        newInkPlans: [],
        printers: [],
        stripeApiKey: '',
        comfeBase: undefined,
        comfeStack: undefined,
        existingCustomer: undefined,
        tokenBase: undefined,
        excludedZipPrefixes: [],
        oobeFlag: 'false',
    },
    setExistingPlan: () => {},
    saveData: () => {},
    getCurrentInkPlanAndPlanId: () => ({
        plan: null,
        inkPlan: null,
    }),
};
