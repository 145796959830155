import { ReactNode } from 'react';
import CustomModal from 'components/CustomModal/CustomModal';

import styles from './InformativeModal.module.scss';

interface Props {
    show: boolean;
    onClick: () => void;
    image: string;
    children: ReactNode;
}

function InformativeModal({ show, onClick, image, children }: Props) {
    return (
        <CustomModal show={show} onClose={onClick} hideHeader>
            <main className={styles.modalContainer}>
                <img className={styles.leftContainer} src={image} alt="Modal" />
                <div className={styles.rightContainer}>{children}</div>
            </main>
        </CustomModal>
    );
}
export default InformativeModal;
