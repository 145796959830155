import { useMemo } from 'react';
import { useCookies } from 'react-cookie';
import { CookieVariation } from 'constants/cookiesEnums';
import {
    AvailablePrinters,
    LOCALSTORAGE_PRINTER,
    UserChoice,
} from 'contexts/PrinterChoice/constants';
import useLocalStorage from 'hooks/useLocalStorage';

import { getNamedVariation } from './useVariation.utils';

function useVariation() {
    const [{ variation }] = useCookies(['variation']);
    const [localStorage] = useLocalStorage<UserChoice>(LOCALSTORAGE_PRINTER);

    const namedVariation = useMemo(
        () => getNamedVariation(variation),
        [variation]
    );

    return {
        variation: variation as CookieVariation,
        namedVariation,
        isHighVolumeDisabled:
            localStorage?.disabledPrinterIds?.includes(
                AvailablePrinters.HighVolume
            ) || false,
        isLebiDisabled:
            localStorage?.disabledPrinterIds?.includes(
                AvailablePrinters.LEBI
            ) || false,
        isControl: variation === CookieVariation.Control || !variation,
        isPlusSayan: variation === CookieVariation.PlusSayan,
        isSayanReplacing: variation === CookieVariation.SayanReplacing,
        isLEBI: variation === CookieVariation.LEBI,
    };
}

export default useVariation;
