import { FieldValues, RegisterOptions } from 'react-hook-form';
import { ShippingInfo } from 'contexts/UserInfo/constants';

export interface FormArray<T extends FieldValues> {
    id: number;
    register: keyof T;
    options?: RegisterOptions<T>;
    label: string;
    info?: string;
    type?: 'text' | 'number';
}

export interface InputFields {
    firstName: string;
    lastName: string;
    company?: string;
    address1: string;
    address2?: string;
    city: string;
    state: string;
    zip: string;
    phone: string;
    printerSerialNumber?: string;
    email: string;
    recieveMobileAlerts: boolean;
}

export const DEFAULT_VALUES: ShippingInfo = {
    firstName: '',
    lastName: '',
    company: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    phone: '',
    email: '',
    printerSerialNumber: '',
    recieveMobileAlerts: true,
};

export const FORM: (
    message: Record<string, string>
) => { id: number; data: FormArray<InputFields>[] }[] = (message) => [
    {
        id: 0,
        data: [
            {
                id: 0,
                register: 'firstName',
                options: {
                    required: {
                        value: true,
                        message: message.CARD_SHIPPING_FORM_FIRST_NAME_ERROR,
                    },
                },
                label: message.CARD_SHIPPING_FORM_FIRST_NAME,
            },
            {
                id: 1,
                register: 'lastName',
                options: {
                    required: {
                        value: true,
                        message: message.CARD_SHIPPING_FORM_LAST_NAME_ERROR,
                    },
                },
                label: message.CARD_SHIPPING_FORM_LAST_NAME,
            },
            {
                id: 2,
                register: 'company',
                label: message.CARD_SHIPPING_FORM_COMPANY,
                info: message.CARD_COMMON_OPTIONAL,
            },
            {
                id: 3,
                register: 'email',
                options: {
                    required: {
                        value: true,
                        message: message.CARD_SHIPPING_FORM_EMAIL_ERROR,
                    },
                    pattern: {
                        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                        message: message.CARD_SHIPPING_FORM_EMAIL_ERROR_FORMAT,
                    },
                },
                label: message.CARD_SHIPPING_FORM_EMAIL,
            },
            {
                id: 4,
                register: 'phone',
                options: {
                    valueAsNumber: true,
                    min: {
                        value: 0,
                        message:
                            message.CARD_SHIPPING_FORM_MOBILE_ERROR_PHONE_NUMBER_BELOW_ZERO,
                    },
                    required: {
                        value: true,
                        message: message.CARD_SHIPPING_FORM_MOBILE_PHONE_ERROR,
                    },
                },
                label: message.CARD_SHIPPING_FORM_MOBILE_NUMBER,
                type: 'number',
            },
        ],
    },
    {
        id: 1,
        data: [
            {
                id: 5,
                register: 'address1',
                options: {
                    required: {
                        value: true,
                        message: message.CARD_COMMON_ADDRESS_ONE_ERROR,
                    },
                },
                label: message.CARD_COMMON_ADDRESS_ONE,
            },
            {
                id: 6,
                register: 'address2',
                label: message.CARD_COMMON_ADDRESS_TWO,
                info: message.CARD_COMMON_OPTIONAL,
            },
            {
                id: 7,
                register: 'city',
                options: {
                    required: {
                        value: true,
                        message: message.CARD_COMMON_CITY_ERROR,
                    },
                },
                label: message.CARD_COMMON_CITY,
            },
        ],
    },
];

export const printerSerialNumberField: (
    message: Record<string, string>
) => FormArray<InputFields> = (message) => ({
    id: 5,
    register: 'printerSerialNumber',
    options: {
        required: {
            value: true,
            message: message.CARD_SHIPPING_FORM_SERIAL_NUMBER_ERROR,
        },
    },
    label: message.CARD_SHIPPING_FORM_SERIAL_NUMBER,
    info: message.CARD_SHIPPING_FORM_SERIAL_NUMBER_EXAMPLE,
});
