import { createContext, ReactNode, useContext, useMemo } from 'react';
import { usePrinterChoice } from 'contexts/PrinterChoice';
import { CONTEXT_ERROR } from 'contexts/PrinterChoice/constants';
import { useQuery } from 'hooks';

interface Props {
    children: ReactNode;
}

interface ContextProps {
    isOOBE: boolean;
    enableQuery: boolean;
}

export const OobeCheckContext = createContext<ContextProps>({
    isOOBE: false,
    enableQuery: false,
});

function OobeCheckProvider({ children }: Props) {
    const {
        data: { oobeFlag },
    } = usePrinterChoice();
    const { oobe } = useQuery<{ oobe: boolean }>();

    let result = false;
    let apiOOBEFlag = false;

    try {
        apiOOBEFlag = JSON.parse(oobeFlag);
    } catch (err) {
        apiOOBEFlag = false;
    }

    if (!oobe) {
        result = false;
    } else if (oobe || apiOOBEFlag) {
        result = true;
    }

    const value = useMemo(
        () => ({
            isOOBE: result,
            enableQuery: !oobe,
        }),
        [oobe, result]
    );

    return (
        <OobeCheckContext.Provider value={value}>
            {children}
        </OobeCheckContext.Provider>
    );
}
export default OobeCheckProvider;

export const useOobeCheck = () => {
    const ctx = useContext(OobeCheckContext);

    if (!ctx) {
        throw new Error(CONTEXT_ERROR);
    }
    return ctx;
};
