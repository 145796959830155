import { FormState, UseFormRegister } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Dropdown, Input } from 'components';
import { useFormat, useTranslation } from 'hooks';
import {
    CheckoutMessages,
    checkoutMessagesValues,
} from 'lang/messages/checkout';
import { InputFields } from 'screens/Checkout/components/ShippingModal/constants';
import { PROVINCES, STATES, ZIP_VALIDATION } from 'screens/Checkout/constants';
import { validateZip } from 'screens/Checkout/utils';

import styles from './Zip.module.scss';

interface Props {
    formState: FormState<InputFields>;
    register: UseFormRegister<InputFields>;
}

function Zip({ formState: { dirtyFields, errors }, register }: Props) {
    const { isSpain: isSpanish } = useTranslation();
    const shippingText = useFormat(
        CheckoutMessages,
        checkoutMessagesValues({})
    );

    const { formatMessage } = useIntl();

    return (
        <div className={styles.zipContainer}>
            <Dropdown
                {...register('state', {
                    required: {
                        value: true,
                        message: shippingText.CARD_COMMON_STATE_ERROR,
                    },
                })}
                id={shippingText.CARD_COMMON_STATE}
                optionsList={isSpanish() ? PROVINCES(true) : STATES}
                label={shippingText.CARD_COMMON_STATE}
                isDirty={Boolean(dirtyFields.state)}
                hasError={!!errors.state}
                errorMessage={errors.state?.message && errors.state?.message}
            />

            <Input
                small
                id={shippingText.CARD_COMMON_ZIP}
                {...register('zip', {
                    ...ZIP_VALIDATION,
                    validate: (zip) =>
                        validateZip(zip, isSpanish(), shippingText),
                })}
                label={shippingText.CARD_COMMON_ZIP}
                isDirty={Boolean(dirtyFields.zip)}
                hasError={!!errors.zip}
                errorMessage={
                    errors.zip?.message &&
                    formatMessage({ id: errors.zip?.message })
                }
            />
        </div>
    );
}
export default Zip;
