import { LandingPage } from 'screens';
import Checkout from 'screens/Checkout';
import FAQ from 'screens/FAQ/FAQ';
import NotFound from 'screens/NotFound/NotFound';
import PrinterSelection from 'screens/PrinterSelection';
import LandingUpgrade from 'screens/PrinterUpgrade/LandingUpgrade';
import UpgradePrinterSelection from 'screens/PrinterUpgrade/UpgradePrinterSelection';
import UpgradePurchaseComplete from 'screens/PrinterUpgrade/UpgradePurchaseComplete';
import PurchaseComplete from 'screens/PurchaseComplete';
import RenewalNotice from 'screens/RenewalNotice';
import TermsAndConditions from 'screens/TermsAndConditions';

import { Paths, UpgradePaths } from './paths';

export function buildVanityURL(
    eci: boolean,
    source: 'ECI',
    medium: 'Att' | 'Unatt',
    campaign: number
) {
    return `${
        eci ? 'el-corte-ingles' : ''
    }?utm_source=${source}&utm_medium=${medium}&utm_campaign=${campaign}`;
}

export const ROUTES = [
    {
        id: 99,
        path: Paths.NotFound,
        Component: NotFound,
    },
    {
        id: 0,
        path: Paths.Index,
        Component: LandingPage,
    },
    {
        id: 1,
        path: Paths.PrinterSelection,
        Component: PrinterSelection,
    },
    {
        id: 2,
        path: Paths.Checkout,
        Component: Checkout,
    },
    {
        id: 3,
        path: Paths.CheckoutCompleted,
        Component: PurchaseComplete,
    },
    {
        id: 4,
        path: Paths.Terms,
        Component: TermsAndConditions,
    },
    {
        id: 5,
        path: Paths.ActivationTerms,
        Component: TermsAndConditions,
    },
    {
        id: 6,
        path: Paths.FAQ,
        Component: FAQ,
    },
    {
        id: 7,
        path: Paths.RenewalNotice,
        Component: RenewalNotice,
    },
    {
        id: 8,
        path: Paths.ActivationRenewalNotice,
        Component: RenewalNotice,
    },
];

export const UPGRADE_ROUTES = [
    {
        id: 9,
        path: UpgradePaths.Index,
        Component: LandingUpgrade,
    },
    {
        id: 10,
        path: UpgradePaths.PrinterSelection,
        Component: UpgradePrinterSelection,
    },
    {
        id: 11,
        path: UpgradePaths.Checkout,
        Component: () => Checkout({ isUpgrade: true }),
    },
    {
        id: 12,
        path: UpgradePaths.CheckoutCompleted,
        Component: UpgradePurchaseComplete,
    },
];
