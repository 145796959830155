import { forwardRef, ReactNode } from 'react';
import classNames from 'classnames';
import { useBreakpoint } from 'hooks';
import { CommonRef } from 'types/globals';

import styles from './Container.module.scss';

interface Props {
    children: ReactNode;
    mainImage: string;
    title?: string;
    limitImage?: boolean;
}

function Container(
    { children, mainImage, title = undefined, limitImage = false }: Props,
    ref: CommonRef
) {
    const breakpoint = useBreakpoint();
    const isMobile = breakpoint === 'xs';

    return (
        <section className={styles.container} ref={ref}>
            {isMobile && title && (
                <h2
                    title={title}
                    className={styles.title}
                    data-testid="mobileHeader"
                >
                    {title}
                </h2>
            )}
            <div
                title="img"
                className={classNames(styles.image, {
                    [styles.imageSmall]: limitImage,
                })}
                style={{
                    backgroundImage: `url(${mainImage})`,
                }}
            />
            <div className={styles.rightSectionContainer}>
                {!isMobile && title && (
                    <h2
                        title={title}
                        className={styles.title}
                        data-testid="desktopHeader"
                    >
                        {title}
                    </h2>
                )}
                <div className={styles.buttonContainer}>{children}</div>
            </div>
        </section>
    );
}

export default forwardRef(Container);
