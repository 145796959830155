import { Fragment } from 'react';
import {
    LOCALSTORAGE_PRINTER,
    UserChoice,
} from 'contexts/PrinterChoice/constants';
import { useFormat, useLocalStorage } from 'hooks';
import { ComparisonTableMessages } from 'lang/messages/comparisonTable';
import { IOutput } from 'screens/PrinterSelection/components/ComparisonTable/constants';

import styles from './TableFooter.module.scss';

interface Props {
    data: IOutput[];
    handleSeeTechSpecs: (id: string) => void;
}

function TableFooter({ data, handleSeeTechSpecs }: Props) {
    const { VIEW_SPECS } = useFormat(ComparisonTableMessages);
    const [localStorageData] =
        useLocalStorage<UserChoice>(LOCALSTORAGE_PRINTER);

    const disabledPrinters = localStorageData?.disabledPrinterIds || [];

    return (
        <tfoot>
            <tr>
                {data.map((printer, index) => (
                    <Fragment key={printer.index}>
                        {index === 0 && <td aria-label="Spacing" />}
                        <td
                            style={{
                                opacity: disabledPrinters.includes(printer.id)
                                    ? 0.5
                                    : 1,
                            }}
                        >
                            <button
                                type="button"
                                onClick={() => handleSeeTechSpecs(printer.id)}
                                disabled={disabledPrinters.includes(printer.id)}
                                className={styles.link}
                            >
                                {VIEW_SPECS}
                            </button>
                        </td>
                    </Fragment>
                ))}
            </tr>
        </tfoot>
    );
}
export default TableFooter;
