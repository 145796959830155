import printer from 'assets/hp-all-in-hero.webp';
import classNames from 'classnames';
import { Button, NavigationBar } from 'components';
import { Paths } from 'components/RoutedContent/paths';
import { TrackAction } from 'constants/analytics';
import { useFormat, useRedirect } from 'hooks';
import { SunsetMessages } from 'lang/messages/sunset';

import { DASHBOARD_LINK, LEARN_MORE_LINK } from './constants';
import useRedirectOutside from './Sunset.hooks';

import styles from './Sunset.module.scss';

function Sunset() {
    const {
        BODY_BOTTOM,
        BODY_TOP,
        BUTTON,
        DASHBOARD_LINK: DASHBOARD_TEXT,
        FAQ_LINK,
        FOOTER,
        TITLE,
    } = useFormat(SunsetMessages);
    const redirectOutside = useRedirectOutside();
    const { push } = useRedirect();

    return (
        <div className={styles.container}>
            <NavigationBar background="#6e79f0" />

            <header className={classNames(styles.textConfig, styles.header)}>
                <h1>{TITLE}</h1>
                <img src={printer} alt="" />
                <hr />
            </header>

            <main className={classNames(styles.textConfig, styles.main)}>
                <h2>{BODY_TOP}</h2>
                <p>{BODY_BOTTOM}</p>
                <Button
                    onClick={() => {
                        redirectOutside(
                            LEARN_MORE_LINK,
                            TrackAction.SunsetClickedLearnMore
                        );
                    }}
                >
                    {BUTTON}
                </Button>
            </main>

            <footer className={classNames(styles.textConfig, styles.footer)}>
                <p>{FOOTER}</p>
                <ul>
                    <li>
                        <button
                            type="button"
                            onClick={() =>
                                redirectOutside(
                                    DASHBOARD_LINK,
                                    TrackAction.SunsetClickedLearnMore
                                )
                            }
                        >
                            {DASHBOARD_TEXT}
                        </button>
                    </li>
                    <li className={styles.separator}>|</li>
                    <li>
                        <button
                            type="button"
                            onClick={() => {
                                push(Paths.FAQ);
                            }}
                        >
                            {FAQ_LINK}
                        </button>
                    </li>
                </ul>
            </footer>
        </div>
    );
}
export default Sunset;
