export const TEXTS = (
    messages: Record<string, string>,
    editMessage: string,
    isRetail?: boolean,
    isUpgrade?: boolean
) => ({
    plan: {
        title: messages.CARD_PLAN_TITLE,
        subtitle: messages.CARD_PLAN_SUBTITLE,
        edit: messages.CARD_PLAN_VIEW_PLANS,
        button: messages.CARD_PLAN_VIEW_PLANS,
    },
    shipping: {
        title: isRetail
            ? messages.CARD_SHIPPING_RETAIL_TITLE
            : messages.CARD_SHIPPING_TITLE,

        subtitle: isUpgrade
            ? messages.CARD_SHIPPING_SUBTITLE_UPGRADE
            : messages.CARD_SHIPPING_SUBTITLE,

        edit: editMessage,

        button: isRetail
            ? messages.CARD_SHIPPING_ADDDETAILS
            : messages.CARD_SHIPPING_ADDSHIPPING,
    },
    billing: {
        title: messages.CARD_BILLING_TITLE,

        subtitle: messages.CARD_BILLING_SUBTITLE,

        edit: editMessage,

        button: messages.CARD_BILLING_ADDBILLING,
    },
    billingUpgrade: {
        title: messages.CARD_BILLING_TITLE,

        subtitle: messages.CARD_BILLING_SUBTITLE_UPGRADE,

        edit: '',
        button: '',
    },
});
