import { ReactNode, useEffect } from 'react';
import { useTrackVariation } from 'hooks';

interface Props {
    children: ReactNode;
}

function TrackVariationInit({ children }: Props) {
    const track = useTrackVariation();

    useEffect(() => {
        track();
    }, [track]);

    return children;
}
export default TrackVariationInit;
