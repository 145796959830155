import { useState } from 'react';
import { FieldErrors, UseFormRegister } from 'react-hook-form';
import { Input } from 'components';

import { ZipStateInput } from './components';
import { FORM, InputFields } from '../../constants';
import { useBillingTranslations } from '../../hooks';

interface Props {
    register: UseFormRegister<InputFields>;
    errors: FieldErrors<InputFields>;
    dirtyFields: Partial<Record<string, unknown>>;
}

function ShippingInfoFields({ register, dirtyFields, errors }: Props) {
    const [currentZip, setCurrentZip] = useState('0');
    const text = useBillingTranslations({ currentZip });

    return (
        <>
            {FORM(text).map(
                ({ register: registerAs, options, label, info, id }) => (
                    <Input
                        key={id}
                        {...register(registerAs, options)}
                        label={label}
                        info={info && info}
                        isDirty={Boolean(dirtyFields[registerAs])}
                        hasError={!!errors[registerAs]}
                        errorMessage={
                            errors[registerAs]?.message &&
                            errors[registerAs]?.message
                        }
                    />
                )
            )}

            <ZipStateInput
                setCurrentZip={setCurrentZip}
                register={register}
                errors={errors}
                dirtyFields={dirtyFields}
            />
        </>
    );
}
export default ShippingInfoFields;
