import classNames from 'classnames';

import styles from './Label.module.scss';

interface Props {
    id?: string;
    label: string;
    info?: string;
    className?: string;
}

function Label({
    id = '',
    label,
    info = undefined,
    className = undefined,
}: Props) {
    return (
        <label
            id={id}
            htmlFor={id}
            data-focused="false"
            className={classNames(styles.label, className)}
        >
            {label} {info && <span className={styles.info}>({info})</span>}
        </label>
    );
}
export default Label;
