import instantInkLogo from 'assets/instantink_logo_white.svg';
import { useFormat } from 'hooks';
import { NotFoundMessages } from 'lang/messages/notFound';
import Footer from 'screens/LandingPage/components/Footer/Footer';

import styles from './NotFound.module.scss';

function NotFound() {
    const {
        bottomSection,
        container,
        content,
        image,
        leftSide,
        logo,
        text,
        title,
        topSection,
    } = styles;

    const { SUBTITLE, TITLE } = useFormat(NotFoundMessages);

    return (
        <div className={container}>
            <img src={instantInkLogo} className={logo} alt="" />

            <main className={content}>
                <div className={leftSide}>
                    <div className={topSection}>
                        <h1 className={title}>{TITLE}</h1>
                    </div>

                    <div className={bottomSection}>
                        <p className={text}>{SUBTITLE}</p>
                    </div>
                </div>

                <div className={image} />
            </main>

            <Footer />
        </div>
    );
}
export default NotFound;
