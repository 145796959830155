import Envy from './assets/Envy.png';
import HighVolume from './assets/HighVolume.png';
import printerImg from './assets/image.png';
import Inspire from './assets/Inspire.png';
import Lebi from './assets/Lebi.webp';
import OfficeJet from './assets/OfficeJet.png';

export const printerImage = (productName?: string) => {
    switch (productName) {
        case 'Essential':
        case 'Esencial':
            return Envy;

        case 'Versatile':
        case 'Versátil':
            return Inspire;

        case 'Professional':
        case 'Profesional':
            return OfficeJet;

        case 'High Volume Advanced':
        case 'Alto Volumen Avanzada':
            return HighVolume;

        case 'High Volume Basic':
        case 'Alto Volumen Básica':
            return Lebi;

        default:
            return printerImg;
    }
};
