export enum Paths {
    NotFound = '/404',
    Index = `/`,
    Checkout = `/checkout/:planId/:inkPlanId`,
    CheckoutCompleted = `/checkout/:planId/:inkPlanId/complete`,
    Terms = `/terms`,
    ActivationTerms = `/offerterms`,
    FAQ = '/faq',
    PrinterSelection = '/printerSelection',
    PurchaseComplete = '/purchaseComplete/',
    RenewalNotice = '/renewalnotice',
    ActivationRenewalNotice = '/offerrenewalnotice',
    TokenManagement = '/auth/token',
}

export enum UpgradePaths {
    Index = '/',
    PrinterSelection = '/printerSelection',
    Checkout = '/checkout/:planId/:inkPlanId/',
    CheckoutCompleted = '/complete',
}
