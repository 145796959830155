import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useSpringRef, useTransition } from '@react-spring/web';
import { Printer } from 'contexts/PrinterChoice/constants';
import { Nullable } from 'types/globals';

import { getAnimationConfig } from './utils';

function useComparisonTable(printers: Printer[], onClose: () => void) {
    const [currentPage, setCurrentPage] = useState(0);
    const [selectedPrinter, setSelectedPrinter] =
        useState<Nullable<string>>(null);

    const transRef = useSpringRef();
    const testRef = useRef<Nullable<HTMLDivElement>>(null);

    const transitions = useTransition(
        currentPage,
        getAnimationConfig(currentPage, transRef)
    );

    const currentPrinter = selectedPrinter
        ? printers.find((printer) => printer.id === selectedPrinter)
        : null;

    const toggleSpecs = (printerToSee: string | null | undefined) => {
        setCurrentPage(typeof printerToSee !== 'string' ? 0 : 1);

        setSelectedPrinter(printerToSee || null);
    };

    const handleClose = () => {
        toggleSpecs(null);
        onClose();
    };

    useEffect(() => {
        transRef.start();
    }, [currentPage, transRef]);

    useLayoutEffect(() => {
        if (testRef.current) {
            testRef.current.scrollIntoView();
        }
    }, [currentPage]);

    return { handleClose, toggleSpecs, testRef, currentPrinter, transitions };
}
export default useComparisonTable;
