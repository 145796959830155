import { Lookup, SpringRef } from '@react-spring/web';

export const getAnimationConfig = (
    currentPage: number,
    transRef: SpringRef<Lookup>
) => ({
    ref: transRef,
    keys: null,

    from: {
        opacity: 0,
        transform: currentPage === 1 ? 'translateX(0%)' : 'translateX(-100%)',
    },
    enter: {
        opacity: 1,
        transform: 'translateX(0%)',
    },
    leave: {
        opacity: 0,
        transform: currentPage === 1 ? 'translateX(-100%)' : 'translateX(0%)',
    },
});
