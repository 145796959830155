import { useUpgradeInfo } from 'contexts';
import { NewInkPlan, Printer } from 'contexts/PrinterChoice/constants';
import { useFormat, useTranslation } from 'hooks';
import {
    CheckoutMessages,
    checkoutMessagesValues,
} from 'lang/messages/checkout';
import { CommonMessages, commonMessagesValues } from 'lang/messages/common';
import { calculateTotalPrice } from 'screens/PrinterSelection/utils';
import { PilotIdInfo } from 'services/pilotIdInfo';
import { Nullable } from 'types/globals';

import styles from './PlanCard.module.scss';

interface Props {
    data: {
        inkPlan: Nullable<NewInkPlan>;
        plan: Nullable<Printer>;
        hasPaper?: boolean;
        planPages?: number;
    };
    isUpgrade?: boolean;
}

function PlanCard({
    isUpgrade = false,
    data: { plan, inkPlan, hasPaper = false, planPages = undefined },
}: Props) {
    const { MONTH_LONG, YES, NO } = useFormat(
        CommonMessages,
        commonMessagesValues({})
    );

    const cardText = useFormat(
        CheckoutMessages,
        checkoutMessagesValues({
            plan: plan?.name,
            pages: isUpgrade
                ? String(planPages)
                : inkPlan?.pages?.toLowerCase(),
            morePaper: hasPaper ? YES : NO,
        })
    );
    const { data } = useUpgradeInfo();
    const { printWithCurrency } = useTranslation();

    const currentPrinter = (plan?.productName.toLowerCase() ||
        'professional') as keyof PilotIdInfo['printerPrices'];

    const price = data
        ? data.printerPrices[currentPrinter]
        : calculateTotalPrice(inkPlan!, plan?.id);

    return (
        <div className={styles.container}>
            <h6 className={styles.pricePerMonth}>
                {printWithCurrency(price || 0)}
                {MONTH_LONG}
            </h6>

            <p className={styles.text} title="planName">
                {cardText.CARD_PLAN_NAME}
            </p>

            <div className={styles.pagesSection}>
                <p className={styles.text} title="includesPages">
                    {cardText.CARD_PLAN_INCLUDES_PAGES}
                </p>
            </div>

            {isUpgrade ? (
                <div className={styles.pagesSection}>
                    <p className={styles.text} title="morePaper">
                        {cardText.CARD_PLAN_MORE_PAPER}
                    </p>
                </div>
            ) : null}
        </div>
    );
}

export default PlanCard;
