import { Button, HyperLink, Modal } from 'components';
import { ModalType } from 'constants/modals';
import { useCheckoutModals } from 'contexts';
import { useFormat } from 'hooks';
import {
    CheckoutMessages,
    checkoutMessagesValues,
} from 'lang/messages/checkout';

import { SURVEY_URL } from './constants';

import styles from './SurveyModal.module.scss';

function SurveyModal() {
    const {
        modals: { '3': show },
        toggleModals,
    } = useCheckoutModals();
    const { SURVEY_BUTTON, SURVEY_LINK, SURVEY_SUBTITLE, SURVEY_TITLE } =
        useFormat(CheckoutMessages, checkoutMessagesValues({}));

    const handleCloseModal = () => {
        toggleModals(ModalType.Questionnare);
    };

    const redirectToSurvey = () => {
        window.location.href = SURVEY_URL;
    };

    return (
        <Modal show={show} onClose={handleCloseModal}>
            <div className={styles.container}>
                <header className={styles.header}>
                    <h1>{SURVEY_TITLE}</h1>
                    <p>{SURVEY_SUBTITLE}</p>
                </header>

                <footer className={styles.footer}>
                    <Button onClick={redirectToSurvey}>{SURVEY_BUTTON}</Button>
                    <HyperLink
                        className={styles.link}
                        linkText={SURVEY_LINK}
                        onClick={handleCloseModal}
                    />
                </footer>
            </div>
        </Modal>
    );
}
export default SurveyModal;
