import { ThemeProvider, ToastProvider } from '@veneer/core';
import RoutedContent from 'components/RoutedContent';

function App() {
    return (
        <ThemeProvider shape="round">
            <ToastProvider position="top">
                <RoutedContent />
            </ToastProvider>
        </ThemeProvider>
    );
}

export default App;
