import { useIntl } from 'react-intl';
import {
    LOCALSTORAGE_PRINTER,
    UserChoice,
} from 'contexts/PrinterChoice/constants';
import { useLocalStorage } from 'hooks';
import { IOutput } from 'screens/PrinterSelection/components/ComparisonTable/constants';

import styles from './TableHeader.module.scss';

interface Props {
    activePrinter: string;
    data: IOutput[];
}

function PrinterHeader({ data, activePrinter }: Props) {
    const { formatMessage } = useIntl();
    const [localStorageData] =
        useLocalStorage<UserChoice>(LOCALSTORAGE_PRINTER);

    const disabledPrinters = localStorageData?.disabledPrinterIds || [];

    const getThumbStyle = (id: string) =>
        id === activePrinter ? styles.selectedPrinter : styles.printer;

    return (
        <thead>
            <tr>
                {data.map(({ id, img }, index) => (
                    <>
                        {index === 0 && <td aria-label="Spacing" />}
                        <td
                            className={styles.headerContainer}
                            style={{
                                opacity: disabledPrinters.includes(id)
                                    ? 0.5
                                    : 1,
                            }}
                        >
                            <h1 className={styles.headerTitle}>
                                {formatMessage({
                                    id: `comparisonTable.${id}`,
                                })}
                            </h1>

                            <img
                                src={img}
                                className={getThumbStyle(id)}
                                alt={formatMessage({
                                    id: `comparisonTable.${id}`,
                                })}
                            />
                        </td>
                    </>
                ))}
            </tr>
        </thead>
    );
}

export default PrinterHeader;
