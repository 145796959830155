import essentialIcon from '../../assets/essential-icon.png';
import professionalIcon from '../../assets/professional-icon.png';
import versatileIcon from '../../assets/versatile-icon.png';

export function imageLookUp(printerName: string) {
    switch (printerName.toLowerCase()) {
        case 'professional':
        case 'profesional':
            return professionalIcon;

        case 'versatile':
        case 'versátil':
            return versatileIcon;

        case 'essential':
        case 'esencial':
        default:
            return essentialIcon;
    }
}
