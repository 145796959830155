import { ButtonVariants } from 'components/CustomButton/constants';
import { Paths } from 'components/RoutedContent/paths';
import { TrackLocation, TrackType } from 'constants/analytics';
import { CookieVariation } from 'constants/cookiesEnums';
import { useDataProvider } from 'contexts/DataProvider';
import { useFormat, useVariation } from 'hooks';
import { CommonMessages, commonMessagesValues } from 'lang/messages/common';
import { FAQSectionMessages } from 'lang/messages/faqSection';

import Accordeon from './components/Accordeon';
import { getLandingFAQs } from './constants';
import RedirectButton from '../RedirectButton/RedirectButton';

import styles from './FAQs.module.scss';

function FAQs() {
    const { variation } = useVariation();

    const { showActivation } = useDataProvider();
    const { TITLE } = useFormat(FAQSectionMessages);
    const { VIEW_MORE } = useFormat(CommonMessages, commonMessagesValues({}));

    return (
        <section className={styles.container}>
            <h1 className={styles.title}>{TITLE}</h1>

            <div className={styles.accordionContainer}>
                {getLandingFAQs(variation || CookieVariation.Control).map(
                    ({
                        id,
                        answer,
                        question,
                        activationAnswer,
                        activationQuestion,
                    }) => (
                        <Accordeon
                            id={String(id)}
                            content={
                                showActivation && activationAnswer
                                    ? activationAnswer
                                    : answer
                            }
                            title={
                                showActivation && activationQuestion
                                    ? activationQuestion
                                    : question
                            }
                            key={id}
                        />
                    )
                )}
            </div>
            <RedirectButton
                action={TrackType.CTA}
                from={TrackLocation.FAQ}
                redirectTo={Paths.FAQ}
                className={styles.button}
                variant={ButtonVariants.secondary}
            >
                {VIEW_MORE}
            </RedirectButton>
        </section>
    );
}
export default FAQs;
