import { RegisterOptions } from 'react-hook-form';
import { ToastNotificationType } from '@veneer/core';
import { CheckoutMessages } from 'lang/messages/checkout';

import { InputFields } from './components/ShippingModal/constants';

export interface Params {
    planId?: string;
    inkPlanId?: string;
    morePaper?: string;
    completed?: string;
}

export interface InkPlan {
    id: string;
    pages: string;
    cost: number;
    title: string;
    paperModuleCost: number;
}

export const TOAST_ERROR_MESSAGE: ToastNotificationType = {
    id: Math.random(),
    type: 'warning',
    text: 'Failed to submit request',
};

export const STATES = [
    { value: 'AL' },
    { value: 'AZ' },
    { value: 'AR' },
    { value: 'CA' },
    { value: 'CO' },
    { value: 'CT' },
    { value: 'DE' },
    { value: 'DC' },
    { value: 'FL' },
    { value: 'GA' },
    { value: 'ID' },
    { value: 'IL' },
    { value: 'IN' },
    { value: 'IA' },
    { value: 'KS' },
    { value: 'KY' },
    { value: 'LA' },
    { value: 'ME' },
    { value: 'MD' },
    { value: 'MA' },
    { value: 'MI' },
    { value: 'MN' },
    { value: 'MS' },
    { value: 'MO' },
    { value: 'MT' },
    { value: 'NE' },
    { value: 'NV' },
    { value: 'NH' },
    { value: 'NJ' },
    { value: 'NM' },
    { value: 'NY' },
    { value: 'NC' },
    { value: 'ND' },
    { value: 'OH' },
    { value: 'OK' },
    { value: 'OR' },
    { value: 'PA' },
    { value: 'RI' },
    { value: 'SC' },
    { value: 'SD' },
    { value: 'TN' },
    { value: 'TX' },
    { value: 'UT' },
    { value: 'VT' },
    { value: 'VA' },
    { value: 'WA' },
    { value: 'WV' },
    { value: 'WI' },
    { value: 'WY' },
];

export const PROVINCES = (disableProvinces?: boolean) => [
    { value: 'Álava' },
    { value: 'Albacete' },
    { value: 'Alicante' },
    { value: 'Almería' },
    { value: 'Asturias' },
    { value: 'Ávila' },
    { value: 'Badajoz' },
    { value: 'Baleares' },
    { value: 'Barcelona' },
    { value: 'Burgos' },
    { value: 'Cáceres' },
    { value: 'Cádiz' },
    { value: 'Cantabria' },
    { value: 'Castellón' },
    { value: 'Ceuta', disabled: disableProvinces },
    { value: 'Ciudad Real' },
    { value: 'Córdoba' },
    { value: 'Cuenca' },
    { value: 'Gerona' },
    { value: 'Granada' },
    { value: 'Guadalajara' },
    { value: 'Guipúzcoa' },
    { value: 'Huelva' },
    { value: 'Huesca' },
    { value: 'Jaén' },
    { value: 'La Coruña' },
    { value: 'La Rioja' },
    { value: 'Las Palmas', disabled: disableProvinces },
    { value: 'León' },
    { value: 'Lérida' },
    { value: 'Lugo' },
    { value: 'Madrid' },
    { value: 'Málaga' },
    { value: 'Melilla', disabled: disableProvinces },
    { value: 'Murcia' },
    { value: 'Navarra' },
    { value: 'Orense' },
    { value: 'Palencia' },
    { value: 'Pontevedra' },
    { value: 'Salamanca' },
    { value: 'Santa Cruz de Tenerife', disabled: disableProvinces },
    { value: 'Segovia' },
    { value: 'Sevilla' },
    { value: 'Soria' },
    { value: 'Tarragona' },
    { value: 'Teruel' },
    { value: 'Toledo' },
    { value: 'Valencia' },
    { value: 'Valladolid' },
    { value: 'Vizcaya' },
    { value: 'Zamora' },
    { value: 'Zaragoza' },
];

export const PRINTER_SELECTION_PATH = '/printerSelection';
export const OOBE_ADDRESSES_API_PATH = `api/comfe/v1/shipping/addresses`;
export const OOBE_BILLING_API_PATH = `api/comfe/v1/billing/`;

export const ZIP_VALIDATION: RegisterOptions<InputFields, 'zip'> = {
    maxLength: {
        value: 5,
        message: CheckoutMessages.CARD_COMMON_ZIP_ERROR_MAXLENGTH,
    },
    required: {
        value: true,
        message: CheckoutMessages.CARD_COMMON_ZIP_ERROR,
    },
    pattern: {
        value: /^[0-9]{5}([0-9]{4})?$/,
        message: CheckoutMessages.CARD_COMMON_ZIP_ERROR_INVALID_FORMAT,
    },
};

export const ZIP_UNAVAILABLE = ['35', '38', '51', '52'];
export const ZIP_BLACKLIST_SPAIN = ['49581'];

export const ZIP_BLACKLIST = [
    '995',
    '996',
    '997',
    '998',
    '999',
    '967',
    '968',
    '969',
    '006',
    '007',
    '008',
    '009',
    '340',
    '090',
    '091',
    '092',
    '093',
    '094',
    '095',
    '096',
    '097',
    '098',
    '962',
    '963',
    '964',
    '965',
    '966',
];
