import { useCallback } from 'react';
import { ToastNotificationType, useToast } from '@veneer/core';

function useNotification() {
    const { addToast } = useToast();
    const id = 1;

    const notify = useCallback(
        (text: string, type?: ToastNotificationType['type']) => {
            if (addToast) {
                addToast({
                    id,
                    type: type ?? 'negative',
                    text,
                });
            } else {
                throw new Error(
                    'Veneer Toast wasnt ready. Please, refresh the page'
                );
            }
        },
        [addToast]
    );

    return { notify };
}
export default useNotification;
