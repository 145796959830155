import { ReactNode } from 'react';
import instantInkLogoBlack from 'assets/instantink_logo.svg';
import instantInkLogoWhite from 'assets/instantink_logo_white.svg';
import classNames from 'classnames';
import { Paths } from 'components/RoutedContent/paths';
import { useRedirect } from 'hooks';

import styles from './NavigationBar.module.scss';

interface Props {
    aside?: ReactNode;
    variant?: 'black' | 'white';
    background?: string;
    float?: boolean;
}

function NavigationBar({
    variant = 'white',
    background = undefined,
    aside = undefined,
    float = false,
}: Props) {
    const { push } = useRedirect();

    const handleRedirect = () => {
        push(Paths.Index);
    };

    return (
        <nav
            style={{ backgroundColor: background }}
            className={classNames(styles.navigationBar, {
                [styles.float]: float,
            })}
        >
            <button
                type="button"
                onClick={handleRedirect}
                className={styles.button}
                aria-label="HP InstantInk Logo"
            >
                <img
                    src={
                        variant === 'white'
                            ? instantInkLogoWhite
                            : instantInkLogoBlack
                    }
                    alt="HP Instant Ink"
                />
            </button>

            {aside}
        </nav>
    );
}
export default NavigationBar;
