import instantInkLogo from 'assets/instantink_logo.svg';
import { useFormat } from 'hooks';
import { UpgradeLandingMessages } from 'lang/messages/upgradeLanding';
import Footer from 'screens/LandingPage/components/Footer/Footer';

import supportIcon from './assets/support-icon.svg';

import styles from './styles.module.scss';

function AlreadyRedeemed() {
    const {
        bottomSection,
        container,
        content,
        image,
        leftSide,
        logo,
        text,
        title,
        topSection,
    } = styles;

    const {
        ALREADY_REDEEMED_BODY,
        ALREADY_REDEEMED_SUPPORT,
        ALREADY_REDEEMED_TITLE,
    } = useFormat(UpgradeLandingMessages, {
        [UpgradeLandingMessages.SLIDE_TWO_BODY_PRICE_DROP]: { price: ' ' },
    });

    return (
        <div className={container}>
            <img src={instantInkLogo} className={logo} alt="" />

            <main className={content}>
                <div className={leftSide}>
                    <div className={topSection}>
                        <h1 className={title}>{ALREADY_REDEEMED_TITLE}</h1>
                    </div>

                    <div className={bottomSection}>
                        <p className={text}>{ALREADY_REDEEMED_BODY}</p>
                        <div className={styles.supportCard}>
                            <img src={supportIcon} alt="" />
                            <p className={text}>{ALREADY_REDEEMED_SUPPORT}</p>
                        </div>
                    </div>
                </div>

                <div className={image} />
            </main>

            <Footer />
        </div>
    );
}
export default AlreadyRedeemed;
