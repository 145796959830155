import classNames from 'classnames';

import MostPopularBadge from '../MostPopularBadge/MostPopularBadge';

import styles from './PrinterButton.module.scss';

interface Props {
    title: string;
    subtitle: string;
    content: string;
    image: string;
    isSelected: boolean;
    mostPopular?: boolean;
    isUserChoice?: boolean;
    disabled?: boolean;
    onClick: () => void;
}

function PrinterButton({
    title,
    subtitle,
    content,
    image,
    isSelected,
    disabled = false,
    isUserChoice = undefined,
    onClick,
    mostPopular = undefined,
}: Props) {
    const fullDescription = content.split(',');
    const highlightedPrice = fullDescription.pop();

    return (
        <button
            disabled={disabled}
            type="button"
            className={classNames({
                [styles.container]: !isSelected,
                [isUserChoice
                    ? styles.containerSelected
                    : styles.containerPreviousSelected]: isSelected,
            })}
            onClick={onClick}
        >
            <div
                title="printerThumbnail"
                className={styles.image}
                style={{ backgroundImage: `url(${image})` }}
            />

            <div className={styles.textContainer}>
                <div className={styles.titleContainer}>
                    <h1 className={styles.title}>{title}</h1>
                    <h2 className={styles.subtitle}>{subtitle}</h2>
                </div>
                <div className={styles.content} title="content">
                    {fullDescription.join(', ').trim()}
                    {', '}
                    {highlightedPrice && (
                        <span title="highlighted-price">
                            {highlightedPrice.trim()}
                        </span>
                    )}{' '}
                    {mostPopular && <MostPopularBadge />}
                </div>
            </div>
        </button>
    );
}

export default PrinterButton;
