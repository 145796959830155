import styles from './styles.module.scss';

export default function Card({
    icon,
    title,
    body,
}: {
    icon: string;
    title: string;
    body: string;
}) {
    return (
        <li className={styles.container}>
            <img src={icon} alt="" className={styles.icon} />
            <h3>{title}</h3>
            <p>{body}</p>
        </li>
    );
}
