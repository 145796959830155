import { LOCALES } from 'lang/locales';

export enum AnniversarySunsetMessages {
    TITLE = 'anniversary_sunset.title',
    BODY = 'anniversary_sunset.body',
    SUBTITLE = 'anniversary_sunset.subtitle',
    BOX_ONE_TITLE = 'anniversary_sunset.box_one.title',
    BOX_ONE_BODY = 'anniversary_sunset.box_one.body',
    BOX_TWO_TITLE = 'anniversary_sunset.box_two.title',
    BOX_TWO_BODY = 'anniversary_sunset.box_two.body',
    BOX_THREE_TITLE = 'anniversary_sunset.box_three.title',
    BOX_THREE_BODY = 'anniversary_sunset.box_three.body',

    CTA_ITEM_ONE = 'anniversary_sunset.cta.item_one',
    CTA_ITEM_TWO = 'anniversary_sunset.cta.item_two',
    CTA_BUTTON = 'anniversary_sunset.cta.button',
    TERMS = 'anniversary_sunset.terms',
    FAQ = 'anniversary_sunset.faq',
    DISCLAIMER_TITLE = 'anniversary_sunset.disclaimer.title',
    DISCLAIMER_ITEM_ONE = 'anniversary_sunset.disaclaimer.item_one',
    DISCLAIMER_ITEM_TWO = 'anniversary_sunset.disclaimer.item_two',
    DISCLAIMER_ITEM_THREE = 'anniversary_sunset.disclaimer.item_three',

    FAQ_TITLE = 'anniversary_sunset.faq.title',
    FAQ_1Q = 'anniversary_sunset.faq.1q',
    FAQ_1A = 'anniversary_sunset.faq.1a',
    FAQ_2Q = 'anniversary_sunset.faq.2q',
    FAQ_2A = 'anniversary_sunset.faq.2a',
    FAQ_3Q = 'anniversary_sunset.faq.3q',
    FAQ_3A = 'anniversary_sunset.faq.3a',
    FAQ_4Q = 'anniversary_sunset.faq.4q',
    FAQ_4A = 'anniversary_sunset.faq.4a',
    FAQ_5Q = 'anniversary_sunset.faq.5q',
    FAQ_5A = 'anniversary_sunset.faq.5a',
}

export const anniversarySunsetMessages = {
    [LOCALES.ENGLISH]: {
        [AnniversarySunsetMessages.TITLE]:
            'Ready for your new\nsubscription printer?',
        [AnniversarySunsetMessages.BODY]:
            'You’re welcome to stay subscribed to Platinum with your current printer, but if you’d like a new\nprinter, you can enroll via the HP All-In Plan, HP’s newest printer subscription.',
        [AnniversarySunsetMessages.SUBTITLE]:
            'When you subscribe to the HP All-In Plan, you will…',
        [AnniversarySunsetMessages.BOX_ONE_TITLE]:
            'Continue with an\nall-inclusive printer\nsubscription.\n ',
        [AnniversarySunsetMessages.BOX_ONE_BODY]:
            'You’ll still receive all your\nprinting essentials for one\nlow monthly payment but now\nwith a new look & feel. There\nwill be some differences in\nthe services¹.',
        [AnniversarySunsetMessages.BOX_TWO_TITLE]:
            'Choose a new\nprinter from a\nrange of updated\nHP models.',
        [AnniversarySunsetMessages.BOX_TWO_BODY]:
            'Get the latest technology for\nyour new subscription. You\ncan send in your original\nPlatinum printer to be\nrecycled, or you can keep it.',
        [AnniversarySunsetMessages.BOX_THREE_TITLE]:
            'Continue printing\nwith no\ninterruptions.\n ',
        [AnniversarySunsetMessages.BOX_THREE_BODY]:
            'Get your new HP All-In Plan\nprinter in 2 business days² and\nenjoy the same 24/7 support\nand service.',
        [AnniversarySunsetMessages.CTA_ITEM_ONE]: '30-day risk-free trial³',
        [AnniversarySunsetMessages.CTA_ITEM_TWO]: 'no upfront cost',
        [AnniversarySunsetMessages.CTA_BUTTON]: 'Subscribe to HP All-In Plan',
        [AnniversarySunsetMessages.TERMS]: 'Terms and conditions',
        [AnniversarySunsetMessages.FAQ]: 'View Platinum FAQ',
        [AnniversarySunsetMessages.FAQ_TITLE]: 'FAQs',
        [AnniversarySunsetMessages.FAQ_1Q]: 'What happens to my old printer?',
        [AnniversarySunsetMessages.FAQ_1A]:
            'Your original Platinum printer is yours to keep. If you’d like to recycle it, you can do so here: <a>www.hp.com/us-en/sustainable-impact/planet-product-recycling-results.html#section=recycle</a>',
        [AnniversarySunsetMessages.FAQ_2Q]:
            'What happens to my old subscription?',
        [AnniversarySunsetMessages.FAQ_2A]:
            'While subscribing to the HP All-In Plan, make sure to use the same HPID you used for your Platinum subscription. That way our system will recognize that you have already been with us for two or more years. When you click on the final “enroll” button, you can choose to either cancel your existing Instant Ink/Platinum subscription or to keep it.',
        [AnniversarySunsetMessages.FAQ_3Q]: 'Will my billing cycle restart?',
        [AnniversarySunsetMessages.FAQ_3A]:
            'Yes, your new billing cycle will begin once you’ve subscribed to the HP All-In Plan. At this moment, we are not able to transfer certain information from your Platinum subscription.',
        [AnniversarySunsetMessages.FAQ_4Q]:
            'What happens to my cancellation fee?',
        [AnniversarySunsetMessages.FAQ_4A]:
            'Regardless of whether you choose to upgrade via the HP All-In Plan or stick with your current Platinum subscription, your Platinum cancellation fee will drop to $0 after two years of an active subscription. You can learn more about cancel fees in the <a>platinum.hpinstantink.com/us/en/terms|Platinum terms & conditions</a>.',
        [AnniversarySunsetMessages.FAQ_5Q]:
            'Will I be able to access my rollover bank from Platinum?',
        [AnniversarySunsetMessages.FAQ_5A]:
            "We are not able to migrate your rollover pages at this time, but we're actively working on it!",
        [AnniversarySunsetMessages.DISCLAIMER_TITLE]: 'Disclaimers',
        [AnniversarySunsetMessages.DISCLAIMER_ITEM_ONE]:
            'The HP All-In Plan is HP’s newest printer subscription and will replace HP Instant Ink Platinum for all new subscribers. “New” subscribers includes post-two-year upgrades, which will now be offered via the HP All-In Plan instead of via Platinum. As of Nov 2024, there will be no interruption to current Platinum subscribers. The two printer subscriptions, Platinum and HP All-In Plan, differ slightly in, but not limited to, the following ways: paper availability date, printer model availability, user dashboard features, printer ownership, and pricing. Please refer to either program’s <a>www.hp.com/us-en/all-in-plan/terms.html|terms & conditions</a> for details. Shipping times apply to continental U.S.',
        [AnniversarySunsetMessages.DISCLAIMER_ITEM_TWO]:
            'Shipping times may vary based on the time of order placement, geographic location and carrier delays.',
        [AnniversarySunsetMessages.DISCLAIMER_ITEM_THREE]:
            '30 Day Risk-Free Period: To avoid monthly fees and the early termination fee applicable to your All-In Plan for printers, simply cancel within the first 30 days by clicking MyAccount and clicking “Cancel” or call 1-888-447-0148. You will be emailed a prepaid return label for your printer and printer cartridge. Your printer and printer cartridge must be returned within 10 days of cancellation to avoid the early cancellation fee applicable for your Plan. You may be charged for the first month if you cancel near the end of the first 30 days, but your payment will be fully refunded.',
    },

    [LOCALES.SPANISH]: {
        [AnniversarySunsetMessages.TITLE]: '',
        [AnniversarySunsetMessages.BODY]: '',
        [AnniversarySunsetMessages.SUBTITLE]: '',
        [AnniversarySunsetMessages.BOX_ONE_TITLE]: '',
        [AnniversarySunsetMessages.BOX_ONE_BODY]: '',
        [AnniversarySunsetMessages.BOX_TWO_TITLE]: '',
        [AnniversarySunsetMessages.BOX_TWO_BODY]: '',
        [AnniversarySunsetMessages.BOX_THREE_TITLE]: '',
        [AnniversarySunsetMessages.BOX_THREE_BODY]: '',
        [AnniversarySunsetMessages.CTA_ITEM_ONE]: '',
        [AnniversarySunsetMessages.CTA_ITEM_TWO]: '',
        [AnniversarySunsetMessages.CTA_BUTTON]: '',
        [AnniversarySunsetMessages.TERMS]: '',
        [AnniversarySunsetMessages.FAQ]: '',
        [AnniversarySunsetMessages.DISCLAIMER_TITLE]: '',
        [AnniversarySunsetMessages.DISCLAIMER_ITEM_ONE]: '',
        [AnniversarySunsetMessages.DISCLAIMER_ITEM_TWO]: '',
        [AnniversarySunsetMessages.DISCLAIMER_ITEM_THREE]: '',
        [AnniversarySunsetMessages.FAQ_TITLE]: '',
        [AnniversarySunsetMessages.FAQ_1Q]: '',
        [AnniversarySunsetMessages.FAQ_1A]: '',
        [AnniversarySunsetMessages.FAQ_2Q]: '',
        [AnniversarySunsetMessages.FAQ_2A]: '',
        [AnniversarySunsetMessages.FAQ_3Q]: '',
        [AnniversarySunsetMessages.FAQ_3A]: '',
        [AnniversarySunsetMessages.FAQ_4Q]: '',
        [AnniversarySunsetMessages.FAQ_4A]: '',
        [AnniversarySunsetMessages.FAQ_5Q]: '',
        [AnniversarySunsetMessages.FAQ_5A]: '',
    },
};
