import { forwardRef, InputHTMLAttributes, useState } from 'react';
import classNames from 'classnames';
import InputErrorMessage from 'components/InputErrorMessage/InputErrorMessage';
import { useExpandingAnimation } from 'hooks';
import { CommonRef } from 'types/globals';

import { Label } from './components';

import styles from './CustomInput.module.scss';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    isDirty?: boolean;
    hasError?: boolean;
    errorMessage?: string;
    label: string;
    info?: string;
    small?: boolean;
}

function CustomInput(
    {
        isDirty = false,
        hasError = false,
        small = false,
        errorMessage = undefined,
        info = undefined,
        ...restProps
    }: Props,
    ref: CommonRef<HTMLInputElement>
) {
    const displayError = (hasError && errorMessage !== '') || false;

    const [isFocused, setIsFocused] = useState(false);
    const { heightRef, transitions } = useExpandingAnimation(displayError);

    const onFocus = (state: boolean) => () => {
        setIsFocused(state);
    };

    return (
        <div
            onFocus={onFocus(true)}
            onBlur={onFocus(false)}
            className={classNames(styles.container, {
                [styles.small]: small,
            })}
        >
            <Label
                id={restProps.id}
                info={info}
                label={restProps.label}
                className={classNames({
                    [styles.selected]: isFocused || isDirty,
                })}
            />

            <input
                aria-labelledby={restProps.id}
                ref={ref}
                className={classNames(styles.input, {
                    [styles.error]: hasError,
                })}
                {...restProps}
            />

            {transitions((style, item) => {
                return item ? (
                    <InputErrorMessage
                        message={errorMessage}
                        style={style}
                        ref={heightRef}
                    />
                ) : null;
            })}
        </div>
    );
}

export default forwardRef(CustomInput);
