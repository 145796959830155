import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';
import reportWebVitals from './reportWebVitals';

import './scss/application.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

import 'url-search-params-polyfill';

const container = document.getElementById('root');

if (container) {
    const root = createRoot(container);

    root.render(
        <StrictMode>
            <App />
        </StrictMode>
    );

    reportWebVitals();
}
