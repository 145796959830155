import { useFormat } from 'hooks';
import {
    CheckoutMessages,
    checkoutMessagesValues,
} from 'lang/messages/checkout';

import basicPrinter from './assets/basic_printer.webp';
import instantInkPrinter from './assets/instantink_printer.webp';
import { Accordeon, ShopMethod } from './components';
import { bulletPoints } from './constants';

function ShopComparison() {
    const TEXT = useFormat(CheckoutMessages, checkoutMessagesValues({}));
    const { buy, subscribe } = bulletPoints(TEXT);

    return (
        <Accordeon id="0" title={TEXT.SHOP_COMPARISON_TITLE}>
            <ShopMethod
                headerImg={basicPrinter}
                title={TEXT.SHOP_COMPARISON_BUY_TITLE}
                bulletPoints={buy}
            />

            <ShopMethod
                active
                headerImg={instantInkPrinter}
                title={TEXT.SHOP_COMPARISON_SUBSCRIBE_TITLE}
                bulletPoints={subscribe}
            />
        </Accordeon>
    );
}

export default ShopComparison;
