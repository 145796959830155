import { UpgradePurchaseCompleteMessages } from 'lang/messages/upgradePurchaseComplete';

import mailConfirm from './assets/mail_confirm.svg';
import printer from './assets/printer.svg';
import radioButtons from './assets/radio_buttons.svg';
import smartApp from './assets/smart_app.svg';

export const displayInfoCards = (
    translations: Record<keyof typeof UpgradePurchaseCompleteMessages, string>
) => {
    return [
        {
            key: 0,
            title: translations.CARD_ONE_TITLE,
            body: translations.CARD_ONE_BODY,
            icon: smartApp,
        },
        {
            key: 1,
            title: translations.CARD_TWO_TITLE,
            body: translations.CARD_TWO_BODY,
            icon: printer,
        },
        {
            key: 2,
            title: translations.CARD_THREE_TITLE,
            body: translations.CARD_THREE_BODY,
            icon: radioButtons,
        },
        {
            key: 3,
            title: translations.CARD_FOUR_TITLE,
            body: translations.CARD_FOUR_BODY,
            icon: mailConfirm,
        },
    ];
};
