import { useEffect, useState } from 'react';
import { TrackAction, TrackLocation } from 'constants/analytics';
import { usePrinterChoice, useUpgradeInfo } from 'contexts';
import { useQuery, useRedirect } from 'hooks';
import { useTrackLink, useTrackPage } from 'hooks/useTrack';
import { UpgradeQueryParams } from 'services/pilotIdInfo';

function usePrinterSelection() {
    const trackLink = useTrackLink();
    const trackPage = useTrackPage();
    const [comparisonModal, setComparisonModal] = useState(false);
    const { push } = useRedirect();
    const {
        userChoice: { printer },
        data,
        handleChangePreferences,
    } = usePrinterChoice();
    const { data: upgradeData } = useUpgradeInfo();

    const { planPages } = useQuery<UpgradeQueryParams>();
    const currentInkPlan = data.newInkPlans.filter(
        (plan) => Number(plan.pages) === planPages
    )[0];

    const handleRedirect = () => {
        push(`/checkout/${printer?.id}/${currentInkPlan?.id}`);
    };

    const toggleComparisonModal = () => {
        setComparisonModal((prevState) => {
            trackLink(
                prevState
                    ? TrackAction.ClosedComparisonTable
                    : TrackAction.OpenedComparisonTable,
                TrackLocation.UpgradePrinterSelection
            );
            return !prevState;
        });
    };

    const handleGoBack = () => {
        push(`/`);
    };

    useEffect(() => {
        trackPage(TrackLocation.UpgradePrinterSelection);
    }, [trackPage]);

    return {
        isLoading: false,
        data: [
            {
                ...data.printers[2],
                monthlyFee: upgradeData?.printerPrices.professional,
            },
            {
                ...data.printers[3],
                monthlyFee: upgradeData?.printerPrices.versatile,
            },
            {
                ...data.printers[4],
                monthlyFee: upgradeData?.printerPrices.essential,
            },
        ],
        inkPlan: currentInkPlan,
        printer,
        comparisonModal,
        handleChangePreferences,
        handleRedirect,
        handleGoBack,
        toggleComparisonModal,
    };
}
export default usePrinterSelection;
