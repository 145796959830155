import Lebi from 'assets/not_available_lebi.webp';
import LebiXS from 'assets/not_available_lebi_xs.webp';
import Sayan from 'assets/not_available_sayan.webp';
import SayanXS from 'assets/not_available_sayan_xs.webp';
import { CookieVariation } from 'constants/cookiesEnums';
import { AvailablePrinters } from 'contexts/PrinterChoice/constants';
import { Breakpoint } from 'hooks/useBreakpoint/types';

import {
    BUY_NOW_LINK_VARIATION_B,
    BUY_NOW_LINK_VARIATION_D_LEBI,
    BUY_NOW_LINK_VARIATION_D_SAYAN,
} from './constants';

export const getPrinterImage = (brkp: Breakpoint, planId?: string) => {
    if (!planId) return Sayan;

    switch (planId) {
        case AvailablePrinters.LEBI:
            return brkp === 'xs' ? LebiXS : Lebi;

        case AvailablePrinters.HighVolume:
        default:
            return brkp === 'xs' ? SayanXS : Sayan;
    }
};

export const getBuyLink = (variation: CookieVariation, planId?: string) => {
    if (variation === CookieVariation.LEBI) {
        switch (planId) {
            case AvailablePrinters.HighVolume:
                return BUY_NOW_LINK_VARIATION_D_SAYAN;
            case AvailablePrinters.LEBI:
                return BUY_NOW_LINK_VARIATION_D_LEBI;
            default:
                return BUY_NOW_LINK_VARIATION_B;
        }
    }

    return BUY_NOW_LINK_VARIATION_B;
};
