import { AvailablePrinters } from './constants';

export const buildDefaultSelection = () => {
    return {
        printer: undefined,
        inkPlan: undefined,
        paper: undefined,
        disabledPrinterIds: [
            AvailablePrinters.LEBI,
            AvailablePrinters.HighVolume,
        ],
        isInkPlanSelected: false,
        isPrinterSelected: false,
    };
};
