// TODO: Add modal tracking

import { forwardRef, ReactNode } from 'react';
import { animated } from '@react-spring/web';
import classNames from 'classnames';
import { CommonRef } from 'types/globals';

import { Backdrop, Header } from './components';
import { useModalEventListeners, useModalTransitions } from './hooks';

import styles from './CustomModal.module.scss';

interface Props {
    show: boolean;
    onClose: () => void;
    children: ReactNode;
    title?: string;
    subtitle?: string;
    hideHeader?: boolean;
    forceFullWidth?: boolean;
}

function CustomModal(
    {
        show,
        onClose,
        children,
        title = undefined,
        subtitle = undefined,
        hideHeader = false,
        forceFullWidth = false,
    }: Props,
    _ref: CommonRef
) {
    const { modalTransition } = useModalTransitions(show);
    useModalEventListeners({ show, onClose });

    return (
        <div>
            <Backdrop show={show} onClose={onClose} />

            {modalTransition(
                (style, item) =>
                    item && (
                        <animated.div
                            className={classNames({
                                [styles.container]: !hideHeader,
                                [styles.containerWithoutPadding]: hideHeader,
                            })}
                            style={style}
                            role="alertdialog"
                        >
                            <button
                                type="button"
                                className={styles.closeButton}
                                onClick={onClose}
                            >
                                X
                            </button>
                            <div
                                className={classNames(styles.contentContainer, {
                                    [styles.forceFullWidth]: forceFullWidth,
                                })}
                            >
                                {!hideHeader && (
                                    <Header title={title} subtitle={subtitle} />
                                )}

                                {children}
                            </div>
                        </animated.div>
                    )
            )}
        </div>
    );
}

export default forwardRef(CustomModal);
