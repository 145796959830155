export const bulletPoints = (texts: Record<string, string>) => ({
    buy: [
        texts.SHOP_COMPARISON_BUY_ITEM_ONE,
        texts.SHOP_COMPARISON_BUY_ITEM_TWO,
        texts.SHOP_COMPARISON_BUY_ITEM_THREE,
    ],
    subscribe: [
        texts.SHOP_COMPARISON_SUBSCRIBE_ITEM_ONE,
        texts.SHOP_COMPARISON_SUBSCRIBE_ITEM_TWO,
        texts.SHOP_COMPARISON_SUBSCRIBE_ITEM_THREE,
        texts.SHOP_COMPARISON_SUBSCRIBE_ITEM_FOUR,
        texts.SHOP_COMPARISON_SUBSCRIBE_ITEM_FIVE,
        texts.SHOP_COMPARISON_SUBSCRIBE_ITEM_SIX,
        texts.SHOP_COMPARISON_SUBSCRIBE_ITEM_SEVEN,
        texts.SHOP_COMPARISON_SUBSCRIBE_ITEM_EIGHT,
    ],
});
