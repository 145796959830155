import printerImage from 'assets/printer_display.webp';
import printerImageLebi from 'assets/printer_display_lebi.webp';
import printerImageSayan from 'assets/printer_display_sayan.webp';
import printerImageSayanReplacing from 'assets/printer_display_sayan_replacing.webp';
import { CookieVariation } from 'constants/cookiesEnums';

export const displayImage = (variation: CookieVariation) => {
    switch (variation) {
        case CookieVariation.LEBI:
            return printerImageLebi;

        case CookieVariation.SayanReplacing:
            return printerImageSayanReplacing;

        case CookieVariation.PlusSayan:
            return printerImageSayan;

        case CookieVariation.Control:
        default:
            return printerImage;
    }
};
