import { ValuesMessages } from 'lang/messages/values';

export const POINTS = (
    messages: Record<keyof typeof ValuesMessages, string>,
    isControl: boolean
) => [
    {
        id: 0,
        title: messages.TITLE_ONE,
        content: isControl
            ? messages.DESCRIPTION_ONE
            : messages.DESCRIPTION_ONE_INK,
    },
    {
        id: 1,
        title: messages.TITLE_TWO,
        content: messages.DESCRIPTION_TWO,
        contentActivation: messages.DESCRIPTION_TWO_ACTIVATION,
    },
    {
        id: 2,
        title: messages.TITLE_THREE,
        content: messages.DESCRIPTION_THREE,
        contentActivation: messages.DESCRIPTION_THREE,
    },
];
