import carbonNeutralImage from 'assets/carbon_neutral_badge.svg';
import mobileBackground from 'assets/up_to_date_background.webp';
import { Paths } from 'components/RoutedContent/paths';
import { TrackLocation, TrackType } from 'constants/analytics';
import { useDataProvider } from 'contexts/DataProvider';
import { useFormat, useTranslation } from 'hooks';
import { CommonMessages, commonMessagesValues } from 'lang/messages/common';
import { UpgradesMessages } from 'lang/messages/upgrades';
import { ES_PRICE, US_PRICE } from 'screens/LandingPage/constants';

import RedirectButton from '../RedirectButton/RedirectButton';

import styles from './UpToDate.module.scss';

function UpToDate() {
    const { isSpain } = useTranslation();
    const price = String(isSpain() ? ES_PRICE : US_PRICE);

    const { DESCRIPTION, DESCRIPTION_ACTIVATION, TITLE } =
        useFormat(UpgradesMessages);

    const { GET_STARTED_FROM } = useFormat(
        CommonMessages,
        commonMessagesValues({ price })
    );
    const { showActivation } = useDataProvider();
    const contentMessage = showActivation
        ? DESCRIPTION_ACTIVATION
        : DESCRIPTION;

    return (
        <section className={styles.container}>
            <img
                src={mobileBackground}
                className={styles.mobileBackground}
                alt={TITLE}
            />
            <h1 className={styles.title}>{TITLE}</h1>

            {isSpain() ? (
                <div className={styles.carbonNeutralImage}>
                    <img src={carbonNeutralImage} alt="Carbon Neutral" />
                </div>
            ) : null}

            <p className={styles.content}>{contentMessage}</p>
            <RedirectButton
                action={TrackType.CTA}
                from={TrackLocation.Upgrades}
                redirectTo={Paths.PrinterSelection}
                className={styles.button}
            >
                {GET_STARTED_FROM}
            </RedirectButton>
        </section>
    );
}
export default UpToDate;
