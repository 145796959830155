import { LOCALES } from 'lang/locales';

export enum PaperQuantityMessages {
    TITLE = 'paperQuantity.title',
    LINK = 'paperQuantity.link',
    YES = 'paperQuantity.yes',
    NO = 'paperQuantity.no',
    MODAL_TITLE = 'paperQuantity.modal.title',
    MODAL_CONTENT = 'paperQuantity.modal.content',
    MODAL_CONTENT_MOBILE = 'paperQuantity.modal.contentMobile',
}

export const paperQuantityMessages = {
    [LOCALES.ENGLISH]: {
        [PaperQuantityMessages.TITLE]: 'Add paper to your\nsubscription',
        [PaperQuantityMessages.LINK]: 'Why add paper?',
        [PaperQuantityMessages.YES]: 'Yes, add it to my subscription',
        [PaperQuantityMessages.NO]: 'No, thanks',
        [PaperQuantityMessages.MODAL_TITLE]:
            'Paper Delivery for\nInstant Ink Platinum',
        [PaperQuantityMessages.MODAL_CONTENT]:
            "When you add paper to your subscription, you'll enjoy\nthe convenience of automatic paper delivery when you're running\nlow, so you'll never run out of HP paper again.\n\nWe deliver 20lb, 96-bright HP paper, optimized for HP printers\nand HP inks and suitable for a wide range of everyday printing\nneeds. We source from responsibly managed forests and our HP\npaper is Forest Stewardship Council® (FSC®) certified to support\nhealthy forest growth for future generations.",
        [PaperQuantityMessages.MODAL_CONTENT_MOBILE]:
            "When you add paper to your subscription, you'll enjoy the convenience of automatic paper delivery when you're running low, so you'll never run out of HP paper again.\n\nWe deliver 20lb, 96-bright HP paper, optimized for HP printers and HP inks and suitable for a wide range of everyday printing needs. We source from responsibly managed forests and our HP paper is Forest Stewardship Council® (FSC®) certified to support healthy forest growth for future generations.",
    },
    [LOCALES.SPANISH]: {
        [PaperQuantityMessages.TITLE]: 'Agrega papel a tu\nsuscripción',
        [PaperQuantityMessages.LINK]: '¿Por qué agregar papel?',
        [PaperQuantityMessages.YES]: 'Sí, añádelo a mi suscripción',
        [PaperQuantityMessages.NO]: 'No, gracias',
        [PaperQuantityMessages.MODAL_TITLE]:
            'Entrega de papel para\nInstant Ink Platinum',
        [PaperQuantityMessages.MODAL_CONTENT]:
            'Cuando agregue papel a su suscripción, disfrutará\nde la comodidad de la entrega automática de papel cuando se esté\nbajando, por lo que nunca más se quedará sin papel HP.\n \nOfrecemos papel HP de 20 lb, 96-bright, optimizado para impresoras HP\ny tintas HP y adecuado para una amplia gama de necesidades\nde impresión diarias. Procedemos de bosques gestionados de manera responsable y nuestro papel\nHP es Forest Stewardship Council® (FSC® ) certificado para apoyar\nel crecimiento forestal saludable para las generaciones futuras.',
        [PaperQuantityMessages.MODAL_CONTENT_MOBILE]:
            'Cuando agregue papel a su suscripción, disfrutará de la comodidad de la entrega automática de papel cuando se esté quedando sin papel, por lo que nunca más se quedará sin papel HP.\n \nOfrecemos papel HP de 20 libras y 96 brillo, optimizado para impresoras HP y tintas HP y adecuado para una amplia variedad de necesidades de impresión diarias. Procedemos de bosques gestionados de forma responsable y nuestro papel HP cuenta con la certificación Forest Stewardship Council® (FSC®) para apoyar el crecimiento saludable de los bosques para las generaciones futuras',
    },
};
