import styles from './SerialNumberDisplay.module.scss';

interface Props {
    printerName?: string;
    serialNumber?: string;
}

function SerialNumberDisplay({
    printerName = 'Printer not selected',
    serialNumber = '[Not Available]',
}: Props) {
    return (
        <div
            className={styles.serialNumberContainer}
            title="serialNumberDisplay"
        >
            <h1 className={styles.printerName}>{printerName}</h1>
            <p className={styles.serialNumber} title="serialNumber">
                {serialNumber}
            </p>
        </div>
    );
}

export default SerialNumberDisplay;
