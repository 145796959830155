import { useMemo } from 'react';
import { useLocation } from 'react-router-dom-v5-compat';

import { parseKey } from './utils';

const useQuery = <T = Record<string, string | number | undefined>,>() => {
    const { search } = useLocation();

    const searchParams = useMemo(() => new URLSearchParams(search), [search]);

    let result: Partial<T> = {} as T;

    searchParams.forEach((value, key) => {
        const { parsedKey, parsedValue } = parseKey(value, key);

        result = { ...result, [parsedKey]: parsedValue };
    });

    return result;
};

export { useQuery };
