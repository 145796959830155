import { forwardRef, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import mainImage from 'assets/hmp_default.webp';
import sayanImage from 'assets/hmp_sayan.webp';
import { HyperLink } from 'components';
import { TrackAction, TrackLocation } from 'constants/analytics';
import {
    AvailablePrinters,
    NewInkPlan,
} from 'contexts/PrinterChoice/constants';
import { useTrackLink, useTranslation } from 'hooks';
import { CommonMessages } from 'lang/messages/common';
import { HowManyPagesMessages } from 'lang/messages/howManyPages';
import { Nullable } from 'types/globals';

import { Modal } from './components';
import { calculateTotalPrice } from '../../utils';
import Container from '../Container/Container';
import PillButton from '../PillButton/PillButton';

import styles from './HowManyPages.module.scss';

interface Props {
    data: NewInkPlan[];
    userChoice?: NewInkPlan;
    popularInkPlan?: string;
    selectedPrinterId?: string;
    setUserChoice: (type: 'inkPlan', newPrinter: NewInkPlan) => void;
}

const HowManyPages = forwardRef<Nullable<HTMLDivElement>, Props>(
    function HowManyPages(
        { data, userChoice, setUserChoice, selectedPrinterId, popularInkPlan },
        ref
    ) {
        const [showModal, setShowModal] = useState(false);

        const trackLink = useTrackLink();
        const { printWithCurrency } = useTranslation();
        const { formatMessage } = useIntl();

        const toggleModal = useCallback(() => {
            setShowModal((prevState) => {
                trackLink(
                    prevState
                        ? TrackAction.NeedMorePlanModalClose
                        : TrackAction.NeedMorePlanModalOpen,
                    TrackLocation.PrinterSelection
                );
                return !prevState;
            });
        }, [trackLink]);

        const handleInkPlanSelection = (newPrinter: NewInkPlan) => {
            trackLink(
                `${TrackAction.PlanSelected}${newPrinter.pages}pgs`,
                TrackLocation.PrinterSelection
            );
            setUserChoice('inkPlan', newPrinter);
        };

        return (
            <>
                <Modal show={showModal} toggleModal={toggleModal} />
                <Container
                    limitImage={
                        selectedPrinterId === AvailablePrinters.HighVolume
                    }
                    mainImage={
                        selectedPrinterId === AvailablePrinters.HighVolume
                            ? sayanImage
                            : mainImage
                    }
                    title={formatMessage({
                        id: HowManyPagesMessages.TITLE,
                    })}
                    ref={ref}
                >
                    {data.map((currentInkPlan) => (
                        <PillButton
                            key={currentInkPlan.id}
                            title={
                                <div className={styles.pillTextContainer}>
                                    <span>{currentInkPlan.title}</span>{' '}
                                    <span className={styles.titlePages}>
                                        {formatMessage(
                                            { id: HowManyPagesMessages.PAGES },
                                            { pages: currentInkPlan.pages }
                                        )}
                                    </span>
                                </div>
                            }
                            subtitle={`${printWithCurrency(
                                calculateTotalPrice(
                                    currentInkPlan,
                                    selectedPrinterId
                                )
                            )}${formatMessage({
                                id: CommonMessages.MONTH_SHORT,
                            })}`}
                            isSelected={userChoice?.id === currentInkPlan.id}
                            onClick={() =>
                                handleInkPlanSelection(currentInkPlan)
                            }
                            mostPopular={popularInkPlan === currentInkPlan.id}
                        />
                    ))}
                    <HyperLink
                        onClick={toggleModal}
                        linkText={formatMessage({
                            id: HowManyPagesMessages.WILL_I_NEED,
                        })}
                    />
                </Container>
            </>
        );
    }
);

HowManyPages.defaultProps = {
    userChoice: undefined,
    popularInkPlan: undefined,
    selectedPrinterId: undefined,
};

export default HowManyPages;
