import { LOCALES } from 'lang/locales';

export enum SunsetMessages {
    TITLE = 'sunset.title',
    BODY_TOP = 'sunset.body.top',
    BODY_BOTTOM = 'sunset.body.bottom',
    BUTTON = 'sunset.button',
    FOOTER = 'sunset.footer',
    DASHBOARD_LINK = 'sunset.dashboard',
    FAQ_LINK = 'sunset.faq',
}

export const sunsetMessages = {
    [LOCALES.ENGLISH]: {
        [SunsetMessages.TITLE]: 'HP All-In Plan',
        [SunsetMessages.BODY_TOP]:
            'Get a printer plus ink and\n24/7 support with the HP All-In Plan',
        [SunsetMessages.BODY_BOTTOM]:
            'Enjoy hassle-free printing with HP All-In Plan. Get all\nyour printing essentials for one low monthly\npayment. Plans start at just $6.99 per month.',
        [SunsetMessages.BUTTON]: 'Learn More',
        [SunsetMessages.FOOTER]:
            'Have HP Instant Ink Platinum service?\nYou can still access your dashboard and important links below.',
        [SunsetMessages.DASHBOARD_LINK]: 'View Platinum Dashboard',
        [SunsetMessages.FAQ_LINK]: 'View Platinum FAQ',
    },

    [LOCALES.SPANISH]: {
        [SunsetMessages.TITLE]: 'HP All-In Plan',
        [SunsetMessages.BODY_TOP]:
            'Get a printer plus ink and\n24/7 support with the HP All-In Plan',
        [SunsetMessages.BODY_BOTTOM]:
            'Enjoy hassle-free printing with HP All-In Plan. Get all\nyour printing essentials for one low monthly\npayment. Plans start at just $6.99 per month.',
        [SunsetMessages.BUTTON]: 'Learn More',
        [SunsetMessages.FOOTER]:
            'Have HP Instant Ink Platinum service? You can still access your dashboard and important links below.',
        [SunsetMessages.DASHBOARD_LINK]: 'View Platinum Dashboard',
        [SunsetMessages.FAQ_LINK]: 'View Platinum FAQ',
    },
};
