import { FieldErrors, UseFormRegister } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Dropdown, Input } from 'components';
import { useTranslation } from 'hooks';
import { CheckoutMessages } from 'lang/messages/checkout';
import { PROVINCES, STATES, ZIP_VALIDATION } from 'screens/Checkout/constants';
import { validateZip } from 'screens/Checkout/utils';
import { StateDispatch } from 'types/globals';

import { InputFields } from '../../../../constants';
import { useBillingTranslations } from '../../../../hooks';

import styles from './ZipStateInput.module.scss';

interface Props {
    setCurrentZip: StateDispatch<string>;
    register: UseFormRegister<InputFields>;
    errors: FieldErrors<InputFields>;
    dirtyFields: Partial<Record<string, unknown>>;
}

function ZipStateInput({
    setCurrentZip,
    register,
    errors,
    dirtyFields,
}: Props) {
    const text = useBillingTranslations({ currentZip: '0' });
    const { isSpain: isSpanish } = useTranslation();

    const validate = (zip: string) => {
        setCurrentZip(zip);
        return validateZip(zip, isSpanish(), text, true);
    };

    const { formatMessage } = useIntl();

    return (
        <div className={styles.zipContainer}>
            <Dropdown
                {...register('state', {
                    required: {
                        value: true,
                        message: CheckoutMessages.CARD_COMMON_STATE_ERROR,
                    },
                })}
                optionsList={isSpanish() ? PROVINCES() : STATES}
                label={text.CARD_COMMON_STATE}
                isDirty={Boolean(dirtyFields.state)}
                hasError={!!errors.state}
                errorMessage={errors.state?.message && errors.state?.message}
            />

            <Input
                small
                {...register('zip', {
                    ...ZIP_VALIDATION,
                    validate,
                })}
                label={text.CARD_COMMON_ZIP}
                isDirty={Boolean(dirtyFields.zip)}
                hasError={!!errors.zip}
                errorMessage={
                    errors.zip?.message &&
                    formatMessage({ id: errors.zip?.message })
                }
            />
        </div>
    );
}
export default ZipStateInput;
