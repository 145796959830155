import { forwardRef } from 'react';
import { animated } from '@react-spring/web';
import { useVariation } from 'hooks';
import { Nullable } from 'types/globals';

import {
    DescriptionDisplay,
    TableFooter,
    TableHeader,
    ValueDisplay,
} from './components';
import { getFormattedPrinters } from './utils';
import { PRINTER_INFO, SPECS } from '../../constants';

import styles from './Table.module.scss';

interface Props {
    activePrinter: string;
    handleSeeTechSpecs: (printer: string) => void;
    style: object;
}

const Table = forwardRef<Nullable<HTMLDivElement>, Props>(
    function TableComponent({ activePrinter, handleSeeTechSpecs, style }, ref) {
        const { variation } = useVariation();

        const formattedPrinters = getFormattedPrinters(variation);

        return (
            <animated.div style={style} className={styles.container} ref={ref}>
                <table className={styles.tableContainer}>
                    <TableHeader
                        activePrinter={activePrinter}
                        data={formattedPrinters}
                    />

                    <tbody>
                        {SPECS.map((spec) => (
                            <tr key={spec}>
                                <DescriptionDisplay spec={spec} />
                                <ValueDisplay data={PRINTER_INFO} spec={spec} />
                            </tr>
                        ))}
                    </tbody>

                    <TableFooter
                        data={formattedPrinters}
                        handleSeeTechSpecs={handleSeeTechSpecs}
                    />
                </table>
            </animated.div>
        );
    }
);

export default Table;
