import styles from './Step.module.scss';

interface Props {
    step: number;
    title: string;
    content: string;
}

function Step({ step, title, content }: Props) {
    return (
        <li className={styles.container}>
            <div className={styles.stepContainer}>
                <h1 className={styles.stepNumber}>{step}</h1>
            </div>
            <div>
                <h2 className={styles.title}>{title}</h2>
                <p className={styles.description}>{content}</p>
            </div>
        </li>
    );
}
export default Step;
