export const getTransition = (currentHeight: number) => ({
    from: {
        height: '0px',
        opacity: 0,
        overflow: 'hidden',
    },
    enter: {
        height: `${currentHeight + 10}px`,
        opacity: 1,
    },
    leave: {
        height: `0px`,
        opacity: 0,
    },
});
