import { CSSProperties } from 'react';
import { AnimatedProps } from '@react-spring/web';
import { Modal } from 'components';
import { Printer } from 'contexts/PrinterChoice/constants';

import { Specifications, Table } from './components';
import useComparisonTable from './hooks';

import styles from './ComparisonTable.module.scss';

interface Props {
    activePrinter: string;
    printers: Printer[];
    show: boolean;
    onClose: () => void;
}

function ComparisonTable({ activePrinter, printers, onClose, show }: Props) {
    const { handleClose, toggleSpecs, testRef, currentPrinter, transitions } =
        useComparisonTable(printers, onClose);

    const Pages: ((
        props: AnimatedProps<{ style: CSSProperties }>
    ) => React.ReactElement)[] = [
        ({ style }) => (
            <Table
                style={style}
                activePrinter={activePrinter}
                handleSeeTechSpecs={toggleSpecs}
                ref={testRef}
            />
        ),
        ({ style }) => (
            <Specifications
                style={style}
                currentPrinter={currentPrinter}
                handleReturn={toggleSpecs}
                ref={testRef}
            />
        ),
    ];

    return (
        <Modal show={show} onClose={handleClose} hideHeader>
            <div className={styles.container}>
                {transitions((currentStyles, index) => {
                    const CurrentPage = Pages[index];
                    return <CurrentPage style={currentStyles} />;
                })}
            </div>
        </Modal>
    );
}
export default ComparisonTable;
