import defaultThumb from 'assets/main_default.webp';
import displayLebi from 'assets/main_lebi.webp';
import displayLebiBothDisabled from 'assets/main_lebi_both_disabled.webp';
import displayLebiLebiDisabled from 'assets/main_lebi_lebi_disabled.webp';
import displayLebiSayanDisabled from 'assets/main_lebi_sayan_disabled.webp';
import displaySayanPlus from 'assets/main_sayan_plus.webp';
import displaySayanPlusDisabled from 'assets/main_sayan_plus_disabled.webp';
import displaySayanReplacing from 'assets/main_sayan_replacing.webp';
import displaySayanReplacingDisabled from 'assets/main_sayan_replacing_disabled.webp';
import essentialThumb from 'assets/thumb_essential.webp';
import highVolumeThumb from 'assets/thumb_high_volume.webp';
import lebiThumb from 'assets/thumb_lebi.webp';
import professionalThumb from 'assets/thumb_professional.webp';
import versatileThumb from 'assets/thumb_versatile.webp';
import { CookieVariation } from 'constants/cookiesEnums';
import { AvailablePrinters, Printer } from 'contexts/PrinterChoice/constants';

// Printer image based on printer ID
export const printerImage = (printer: string) => {
    switch (printer) {
        case 'printer6':
            return lebiThumb;

        case 'printer2':
            return highVolumeThumb;

        case 'printer3':
            return professionalThumb;

        case 'printer4':
            return versatileThumb;

        case 'printer5':
        default:
            return essentialThumb;
    }
};

const getLebiImage = (disabledPrinters?: string[]) => {
    if (
        disabledPrinters?.includes(AvailablePrinters.LEBI) &&
        disabledPrinters?.includes(AvailablePrinters.HighVolume)
    ) {
        return displayLebiBothDisabled;
    }

    if (disabledPrinters?.includes(AvailablePrinters.LEBI)) {
        return displayLebiLebiDisabled;
    }

    if (disabledPrinters?.includes(AvailablePrinters.HighVolume)) {
        return displayLebiSayanDisabled;
    }

    return displayLebi;
};

// Default printer thumb based on variation
export const defaultPrinterMainImage = (
    variation: number,
    disabledPrinters?: string[]
) => {
    switch (variation) {
        case CookieVariation.LEBI:
            return getLebiImage(disabledPrinters);

        case CookieVariation.PlusSayan:
            return disabledPrinters?.includes(AvailablePrinters.HighVolume)
                ? displaySayanPlusDisabled
                : displaySayanPlus;

        case CookieVariation.SayanReplacing:
            return disabledPrinters?.includes(AvailablePrinters.HighVolume)
                ? displaySayanReplacingDisabled
                : displaySayanReplacing;

        case CookieVariation.Control:
        default:
            return defaultThumb;
    }
};

// Filter printers based on variation
export const filterUnusedPrinters = (
    printerArray: Printer[],
    currentVariation: CookieVariation
) => {
    // Remove dummy printers
    const sanitizedArray = printerArray.filter(
        (printer) => !String(printer.id).includes('DUMMY')
    );

    // Disable printers

    sanitizedArray[4].disabled = true;
    sanitizedArray[3].disabled = true;

    switch (currentVariation) {
        case CookieVariation.LEBI:
            return sanitizedArray;

        case CookieVariation.PlusSayan:
            return sanitizedArray.filter(
                (printer) => printer.id !== AvailablePrinters.LEBI
            );

        case CookieVariation.SayanReplacing:
            return sanitizedArray.filter(
                (printer) =>
                    printer.id !== AvailablePrinters.Professional &&
                    printer.id !== AvailablePrinters.LEBI
            );

        case CookieVariation.Control:
        default:
            return sanitizedArray.filter(
                (printer) =>
                    printer.id !== AvailablePrinters.HighVolume &&
                    printer.id !== AvailablePrinters.LEBI
            );
    }
};
