import { useFormat } from 'hooks';
import { ComparisonTableMessages } from 'lang/messages/comparisonTable';
import { SPEC_ICONS } from 'screens/PrinterSelection/components/ComparisonTable/constants';

import { getFormattedNames } from '../../utils';

import styles from './DescriptionDisplay.module.scss';

interface Props {
    spec: string;
}

function DescriptionDisplay({ spec }: Props) {
    const comparisonText = useFormat(ComparisonTableMessages) as Record<
        string,
        string
    >;

    return (
        <td className={styles.specsTitleContainer}>
            <img
                src={SPEC_ICONS[spec]}
                alt={comparisonText[spec]}
                className={styles.icon}
            />
            <p className={styles.specsTitleText}>
                {comparisonText[getFormattedNames(spec)]}
            </p>
        </td>
    );
}
export default DescriptionDisplay;
