import { Breakpoint } from './types';

export const getDeviceConfig = (width: number): Breakpoint => {
    if (width < 767) {
        return 'xs';
    }
    if (width <= 1439) {
        return 'md';
    }
    if (width < 1919) {
        return 'lg';
    }
    return 'xl';
};
