import {
    createContext,
    ReactNode,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useDataProvider } from 'contexts/DataProvider';
import { useOobeCheck } from 'contexts/OobeCheck';
import { usePrinterChoice } from 'contexts/PrinterChoice';
import { ORDER_ERROR } from 'hooks/constants';
import {
    OOBE_ADDRESSES_API_PATH,
    OOBE_BILLING_API_PATH,
} from 'screens/Checkout/constants';
import { Nullable } from 'types/globals';

import {
    BillingInfo,
    INITIAL_STATE,
    IUserInfo,
    ShippingInfo,
} from './constants';
import { buildShippingBillingData } from './utils';

export const UserInfoContext = createContext<IUserInfo>(INITIAL_STATE);

interface Props {
    children: ReactNode;
}

export default function UserInfoProvider({ children }: Props) {
    const { planData } = useDataProvider();
    const { isOOBE, enableQuery } = useOobeCheck();
    const { userToken } = usePrinterChoice();

    const [userInfo, setUserInfo] = useState<IUserInfo>(INITIAL_STATE);

    const changeInfo = useCallback(
        (
            whatToChange: 'shipping' | 'billing',
            info:
                | Nullable<Partial<ShippingInfo>>
                | Nullable<Partial<BillingInfo>>
        ) => {
            setUserInfo((prevState) => ({
                ...prevState,
                [whatToChange]: info
                    ? { ...prevState[whatToChange], ...info }
                    : null,
            }));

            if ((info as Nullable<BillingInfo>)?.useShippingAddress) {
                setUserInfo(
                    (prevState) =>
                        ({
                            ...prevState,
                            billing: {
                                ...prevState.billing,
                                street: prevState.shipping?.address1 || '',
                                street2: prevState.shipping?.address2,
                                city: prevState.shipping?.city || '',
                                state: prevState.shipping?.state || '',
                                zip: prevState.shipping?.zip || '',
                            },
                        }) as IUserInfo
                );
            }
        },
        []
    );

    const fetchOOBEData = useCallback(
        (signal?: AbortSignal) => {
            if (!userToken) {
                return;
            }

            const options = {
                headers: { Authorization: `Bearer ${userToken}` },
                signal,
            };

            setUserInfo((prevState) => ({
                ...prevState,
                shippingLoading: true,
                billingLoading: true,
            }));

            Promise.all([
                fetch(
                    `${planData?.comfeBase}/${OOBE_ADDRESSES_API_PATH}`,
                    options
                ).then((response) => {
                    setUserInfo((prevState) => ({
                        ...prevState,
                        shippingLoading: false,
                    }));

                    return response.json();
                }),

                fetch(
                    `${planData?.comfeBase}/${OOBE_BILLING_API_PATH}`,
                    options
                ).then((response) => {
                    setUserInfo((prevState) => ({
                        ...prevState,
                        billingLoading: false,
                    }));

                    return response.json();
                }),
            ])
                .then((data) => {
                    const { shipping, billing } =
                        buildShippingBillingData(data);

                    setUserInfo((prevState) => ({
                        ...prevState,
                        shipping,
                        billing,
                    }));
                })
                .catch(() => {
                    localStorage.setItem(
                        'oobe',
                        JSON.stringify({ isOOBE, enableQuery })
                    );
                });
        },
        [enableQuery, isOOBE, planData?.comfeBase, userToken]
    );

    useEffect(() => {
        if (isOOBE) {
            fetchOOBEData();
        }
    }, [fetchOOBEData, isOOBE]);

    const value = useMemo(
        () => ({
            ...userInfo,
            changeInfo,
        }),
        [changeInfo, userInfo]
    );

    return (
        <UserInfoContext.Provider value={value}>
            {children}
        </UserInfoContext.Provider>
    );
}

export const useUserInfo = () => {
    const ctx = useContext(UserInfoContext);

    if (!ctx) {
        throw new Error(ORDER_ERROR);
    }

    return ctx;
};
