import { useDataProvider } from 'contexts/DataProvider';
import { useFormat } from 'hooks';
import {
    CheckoutMessages,
    checkoutMessagesValues,
} from 'lang/messages/checkout';

import styles from './Header.module.scss';

interface Props {
    isUpgrade?: boolean;
}

function Header({ isUpgrade = false }: Props) {
    const { showSixMonthFreeBanner } = useDataProvider();
    const { TITLE, TITLE_SIX_MONTHS, TITLE_UPGRADE } = useFormat(
        CheckoutMessages,
        checkoutMessagesValues({})
    );

    let text = TITLE;

    if (isUpgrade) {
        text = TITLE_UPGRADE;
    }

    if (showSixMonthFreeBanner) {
        text = TITLE_SIX_MONTHS;
    }

    return (
        <header className={styles.container}>
            <h1 className={styles.pageTitle}>{text}</h1>
        </header>
    );
}

export default Header;
