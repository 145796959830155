import { useEffect, useMemo } from 'react';
import { useCookies } from 'react-cookie';
import { useLocation } from 'react-router-dom-v5-compat';
import { DownloadButton, NavigationBar } from 'components';
import { TrackLocation } from 'constants/analytics';
import { CookieVariation } from 'constants/cookiesEnums';
import { useDataProvider } from 'contexts/DataProvider';
import { useFormat, usePrinter, useTrackPage } from 'hooks';
import { termsTypes } from 'lang/messages/terms';

import { Addendum, ChapterSelection, Header, TermSection } from './components';
import { SECTIONS } from './constants';

import styles from './TermsAndConditions.module.scss';

function TermsAndConditions() {
    const [cookies] = useCookies(['variation']);

    const sectionSelected = useMemo(
        () =>
            termsTypes[
                (cookies.variation ||
                    CookieVariation.Control) as keyof typeof termsTypes
            ],
        [cookies]
    );

    const { ref, handlePrint } = usePrinter({ styles: styles.print });
    const { pathname } = useLocation();
    const { TOP_INFO } = useFormat(sectionSelected);
    const { showActivation } = useDataProvider();
    const trackPage = useTrackPage();

    useEffect(() => {
        trackPage(TrackLocation.TermsAndServices);
    }, [trackPage]);

    const offerTerms = pathname.startsWith('/offerterms');
    const alternativeTerms = showActivation || offerTerms;

    const selectedSections = useMemo(() => {
        return SECTIONS(sectionSelected);
    }, [sectionSelected]);

    return (
        <>
            <NavigationBar
                aside={<DownloadButton onClick={handlePrint} />}
                variant="black"
            />
            <div className={styles.container} ref={ref}>
                <Header terms={sectionSelected} />
                <span className={styles.introductiveText}>{TOP_INFO}</span>

                <ChapterSelection sections={selectedSections} />

                <ol className={styles.termsContent}>
                    <TermSection
                        activationTerms={alternativeTerms}
                        sections={selectedSections}
                    />
                </ol>

                <Addendum />
            </div>
        </>
    );
}

export default TermsAndConditions;
