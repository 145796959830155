import { useCallback, useLayoutEffect, useRef, useState } from 'react';

interface Props {
    on: boolean;
}

const useHeight = ({ on = true }: Props) => {
    const ref = useRef<HTMLDivElement | null>(null);
    const [height, setHeight] = useState(0);
    const heightRef = useRef(height);
    const resizeObserver = useCallback(
        () =>
            new ResizeObserver(() => {
                if (ref.current && height !== ref.current.offsetHeight) {
                    heightRef.current = ref.current.offsetHeight;
                    setHeight(ref.current.offsetHeight);
                }
            }),
        [height]
    );

    useLayoutEffect(() => {
        if (on && ref.current) {
            setHeight(ref.current.offsetHeight);
            resizeObserver().observe(ref.current);
        }

        return () => resizeObserver().disconnect();
    }, [on, resizeObserver]);

    return { ref, height };
};

export { useHeight };
