import {
    DetailedHTMLProps,
    forwardRef,
    OptionHTMLAttributes,
    SelectHTMLAttributes,
    useState,
} from 'react';
import { animated } from '@react-spring/web';
import chevronDown from 'assets/chevron_down.svg';
import errorIcon from 'assets/error.svg';
import classNames from 'classnames';
import { useExpandingAnimation } from 'hooks';
import { CommonRef } from 'types/globals';

import styles from './CustomDropdown.module.scss';

export interface Props
    extends DetailedHTMLProps<
        SelectHTMLAttributes<HTMLSelectElement>,
        HTMLSelectElement
    > {
    id?: string;
    label: string;
    optionsList: DetailedHTMLProps<
        OptionHTMLAttributes<HTMLOptionElement>,
        HTMLOptionElement
    >[];
    isDirty?: boolean;
    info?: string;
    hasError: boolean;
    errorMessage?: string;
}

function CustomDropdown(
    {
        label,
        optionsList,
        hasError,
        isDirty = false,
        id = undefined,
        info = undefined,
        errorMessage = undefined,
        ...restProps
    }: Props,
    ref: CommonRef<HTMLSelectElement>
) {
    const displayError = (hasError && errorMessage !== '') || false;
    const [isFocused, setIsFocused] = useState(false);
    const { heightRef, transitions } = useExpandingAnimation(displayError);

    const onFocus = (state: boolean) => () => {
        setIsFocused(state);
    };

    return (
        <div
            onFocus={onFocus(true)}
            onBlur={onFocus(false)}
            className={styles.container}
        >
            <label
                htmlFor={id}
                data-focused="false"
                className={classNames(styles.label, {
                    [styles.selected]: isFocused || isDirty,
                })}
            >
                {label} {info && <span className={styles.info}>({info})</span>}
            </label>
            <img
                src={chevronDown}
                alt="Expand"
                className={classNames(styles.chevron, {
                    [styles.chevronSelected]: isFocused,
                })}
            />
            <select
                name={id}
                id={id}
                className={classNames(styles.input, {
                    [styles.error]: hasError,
                })}
                {...restProps}
                ref={ref}
            >
                <option className={styles.option} value="" hidden>
                    {' '}
                </option>
                {optionsList.map((items, index) => (
                    <option
                        data-testid="selectOption"
                        key={items.id || index}
                        value={items.value}
                        disabled={items.disabled}
                    >
                        {items.label ?? items.value}
                    </option>
                ))}
            </select>
            {transitions((style, item) =>
                item ? (
                    <animated.div style={style} title="errorMessage">
                        <div className={styles.errorContainer}>
                            <span className={styles.icon}>
                                <img src={errorIcon} alt="" />
                            </span>
                            <p className={styles.errorMessage} ref={heightRef}>
                                {errorMessage}
                            </p>
                        </div>
                    </animated.div>
                ) : null
            )}
        </div>
    );
}

export default forwardRef(CustomDropdown);
