import { LOCALES } from 'lang/locales';

export enum AmountPrintMessages {
    TITLE = 'amountPrint.title',
    TITLE_INK = 'amountPrint.title.ink',
    TITLE_MOBILE = 'amountPrint.titleMobile',
    DESCRIPTION = 'amountPrint.description',
    DESCRIPTION_SAYAN = 'amountPrint.description.sayan',
}

export const amountPrintMessages = {
    [LOCALES.ENGLISH]: {
        [AmountPrintMessages.TITLE]:
            'Print a little or a lot,\nthere’s an option for\neveryone',
        [AmountPrintMessages.TITLE_INK]:
            'Print a little or a lot,\nthere’s an option for\neveryone',
        [AmountPrintMessages.TITLE_MOBILE]:
            'Print a little or a lot, there’s an\noption for everyone',
        [AmountPrintMessages.DESCRIPTION]:
            'Your Platinum subscription includes hassle-free ink delivery and\nprepaid cartridge recycling. There are plans designed for\noccasional and frequent printers. Regardless of which plan you choose,\nyour Platinum printer will automatically reorder ink when you’re running\nlow so you’ll never run out again.²',
        [AmountPrintMessages.DESCRIPTION_SAYAN]:
            'Your Platinum subscription includes hassle-free ink delivery and\nprepaid cartridge recycling. There are plans designed for\noccasional and frequent printers. Regardless of which plan you choose,\nyour Platinum printer will automatically reorder ink when you’re running\nlow so you’ll never run out again.²',
    },
    [LOCALES.SPANISH]: {
        [AmountPrintMessages.TITLE]:
            'Despreocúpate de\nlos cartuchos para\nsiempre',
        [AmountPrintMessages.TITLE_INK]:
            'Despreocúpate de la\ntinta para siempre',
        [AmountPrintMessages.TITLE_MOBILE]:
            'Imprime mucho o poco, hay una\nopción para todos',
        [AmountPrintMessages.DESCRIPTION]:
            'HP Instant Ink Platinum es un servicio cómodo, económico,\nflexible y sin compromiso de permanencia que se adapta a tus\nnecesidades. La suscripción incluye la entrega de tinta antes de\nque se agote y el reciclaje de los cartuchos usados. Disfruta de\nplanes de impresión flexibles diseñados tanto para usos\nocasionales como para usos frecuentes.',
        [AmountPrintMessages.DESCRIPTION_SAYAN]:
            'Si eliges la impresora HP Smart Tank, te enviaremos tu tinta\nsiempre que la necesites, aunque puede que sea con menos\nfrecuencia, ya que nuestras botellas contienen mucha tinta.\nAprovecha todos los demás beneficios que te ofrece HP Instant\nInk Platinum como suscriptor estándar.',
    },
};
