import { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
} from '@stripe/react-stripe-js';
import { Button, Checkbox, Input, Modal, StripeInput } from 'components';
import { ModalType } from 'constants/modals';
import { useCheckoutModals, useUserInfo } from 'contexts';

import { ColumnHeader, ShippingInfoFields } from './components';
import { DEFAULT_VALUES, InputFields } from './constants';
import { useBillingTranslations, useFormLogic, useStripeForm } from './hooks';

import styles from './BillingModal.module.scss';

function BillingModal() {
    const {
        register,
        formState: { dirtyFields, errors, isValid },
        handleSubmit,
        watch,
        reset,
        setValue,
    } = useForm<InputFields>({
        defaultValues: DEFAULT_VALUES,
        mode: 'onBlur',
    });

    const {
        toggleModals,
        modals: { '1': show },
    } = useCheckoutModals();

    const text = useBillingTranslations({});
    const { resetData, saveData } = useFormLogic({ reset, setValue });
    const [isLoading, setIsLoading] = useState(false);
    const { billing } = useUserInfo();
    const stripe = useStripeForm();

    const onSubmit = async (data: InputFields) => {
        setIsLoading(true);

        const token = await stripe.submitStripeData(data);

        if (token) {
            setIsLoading(false);
            saveData(token, data);
            toggleModals(ModalType.Billing);
        } else {
            setIsLoading(false);
        }
    };

    const handleCloseWithoutSaving = () => {
        resetData();

        toggleModals(ModalType.Billing);
        toggleModals(ModalType.Questionnare);
    };

    const isFormComplete = isValid && stripe.isStripeInfoValid;
    const watchUseShipping = watch(
        'useShippingAddress',
        billing?.useShippingAddress
    );

    return (
        <Modal
            show={show}
            onClose={handleCloseWithoutSaving}
            title={text.CARD_BILLING_FORM_TITLE}
            subtitle={text.CARD_BILLING_FORM_SUBTITLE}
            forceFullWidth
        >
            <div className={styles.innerContainer}>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className={styles.container}
                >
                    <div className={styles.columnContainer}>
                        <div className={styles.column}>
                            <ColumnHeader
                                title={text.CARD_BILLING_FORM_CCINFO}
                            />

                            <Input
                                {...register('ccNameOnCard', {
                                    required: {
                                        value: true,
                                        message:
                                            text.CARD_BILLING_FORM_NAME_ERROR,
                                    },
                                })}
                                isDirty={Boolean(dirtyFields.ccNameOnCard)}
                                hasError={!!errors.ccNameOnCard}
                                errorMessage={errors.ccNameOnCard?.message}
                                label={text.CARD_BILLING_FORM_NAME}
                            />
                            <StripeInput
                                StripeElement={CardNumberElement}
                                onChange={stripe.onStripeChange}
                                errorMsg={stripe.stripeErrors.cardNumber}
                                showIcon
                            />
                            <div className={styles.expAndCvcContainer}>
                                <StripeInput
                                    StripeElement={CardExpiryElement}
                                    onChange={stripe.onStripeChange}
                                    errorMsg={stripe.stripeErrors.cardExpiry}
                                    halfSized
                                />
                                <StripeInput
                                    StripeElement={CardCvcElement}
                                    onChange={stripe.onStripeChange}
                                    errorMsg={stripe.stripeErrors.cardCvc}
                                    halfSized
                                />
                            </div>
                        </div>

                        <div className={styles.column}>
                            <ColumnHeader
                                title={text.CARD_BILLING_FORM_BILLING_ADDRESS}
                            />

                            <Checkbox
                                label={
                                    text.CARD_BILLING_FORM_USE_SHIPPING_ADDRESS
                                }
                                {...register('useShippingAddress')}
                            />
                            {!watchUseShipping ? (
                                <ShippingInfoFields
                                    register={register}
                                    errors={errors}
                                    dirtyFields={dirtyFields}
                                />
                            ) : null}
                        </div>
                    </div>

                    <footer className={styles.footerContainer}>
                        <Button
                            type="submit"
                            disabled={!isFormComplete || isLoading}
                        >
                            {text.SAVE}
                        </Button>
                    </footer>
                </form>
            </div>
        </Modal>
    );
}

export default BillingModal;
