import { useLayoutEffect, useState } from 'react';
import { Nullable } from 'types/globals';
import { BaseResponse } from 'utils/requestHandler';

import useNotification from './useNotifications/useNotification';

interface Props<T, R> {
    service: (params?: R) => BaseResponse<T>;
    params?: R;
    options: {
        waitForBool?: boolean;
        deps?: unknown[];
        transform?: (response: T) => void;
        onError?: (response: string) => void;
    };
}

export const useRequest = <T, R>({
    service,
    params,
    options: { waitForBool, deps, transform, onError },
}: Props<T, R>) => {
    const { notify } = useNotification();

    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [data, setData] = useState<Nullable<T>>(null);
    const [currentDeps, setCurrentDeps] = useState<unknown[]>([]);

    useLayoutEffect(() => {
        let isMounted = true;
        const hasDepsChanged =
            JSON.stringify(currentDeps) !== JSON.stringify(deps || []);

        if (!waitForBool) {
            return () => {};
        }

        if ((!service || Boolean(data) || hasError) && !hasDepsChanged) {
            return () => {};
        }

        const performQuery = async () => {
            setIsLoading(true);

            if (deps && JSON.stringify(currentDeps) !== JSON.stringify(deps)) {
                setCurrentDeps(deps || []);
            }

            const response = await service(params);

            if (!isMounted) {
                return;
            }

            if (response.status === 'error') {
                if (typeof onError === 'function') {
                    onError(response.error);
                }

                notify(response.error);
                setIsLoading(false);
                setHasError(true);
            } else {
                if (typeof transform === 'function') {
                    transform(response.data);
                }

                setData(response.data);
                setIsLoading(false);
                setHasError(false);
            }
        };

        if (!data) {
            performQuery();
        }

        return () => {
            isMounted = false;
        };
    }, [
        currentDeps,
        data,
        deps,
        hasError,
        notify,
        onError,
        params,
        service,
        transform,
        waitForBool,
    ]);

    return { isLoading, hasError, data };
};
