import { reportError } from 'utils/reportError';

export type Callbacks<R> = {
    success: (arg0: { success: true; data: R; error: null }) => void;
    error: (arg0: { success: false; data: null; error: string }) => void;
    finally?: () => void;
};

export async function doPost<T, R>(
    endpoint: string,
    body: T,
    callbacks: Callbacks<R>
) {
    try {
        const serverResponse = await fetch(endpoint, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: { 'Content-Type': 'application/json' },
        });

        if (serverResponse.status >= 400) {
            const error = await serverResponse.text();

            return callbacks.error({
                success: false,
                data: null,
                error: reportError(JSON.parse(error).message),
            });
        }

        const response = (await serverResponse.json()) as R;
        return callbacks.success({
            success: true,
            data: response,
            error: null,
        });
    } catch (err) {
        return callbacks.error({
            success: false,
            data: null,
            error: reportError(err),
        });
    } finally {
        if (callbacks.finally) {
            callbacks.finally();
        }
    }
}
