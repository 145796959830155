import { BrowserRouter, Switch } from 'react-router-dom';
import { CompatRoute, CompatRouter } from 'react-router-dom-v5-compat';
import { AnniversarySunset } from 'screens';

import CheckForSunset from './components/CheckForSunset';
import ContextWrapper from './components/ContextWrapper';
import URLFixer from './components/URLFixer';
import { ROUTES, UPGRADE_ROUTES } from './constants';
import { languageCountryIterator } from './utils';

/* 
This is where routes are managed

Im using the languageCountryIterator function to iterate over the languages and countries,
there are only two possible languages and countries, we're mainly focusing on Spain right now
since USA development is on hold.

CheckForSunset component checks for the url, and if it detects that the user is trying
to access /us, it'll display the sunset page regardless of what route the user is trying to access.

The first iteration is for the regular routes (/es/es), 
the second iteration is for "El Corte Inglés" (/el-corte-ingles) (CURRENTLY DISABLED),
the third iteration is for the upgrade routes (/upgrade),
Fourth iteration is for the vanity urls (/goya, /pozuelo, /diagonal, /callao)

Vanity urls redirect to a specific page on the El corte ingles variation
check function buildVanityURL in utils.tsx for more info,
*/

function RoutedContent() {
    return (
        <BrowserRouter>
            <CompatRouter>
                <ContextWrapper>
                    <Switch>
                        {languageCountryIterator((country, language) =>
                            ROUTES.map(({ path, Component, id }) => (
                                <CompatRoute
                                    key={id}
                                    path={`/${country}/${language}${path}`}
                                    exact
                                >
                                    <CheckForSunset>
                                        <Component />
                                    </CheckForSunset>
                                </CompatRoute>
                            ))
                        )}

                        {/* {languageCountryIterator((country, language) =>
                            ROUTES.map(({ path, Component, id }) => (
                                <CompatRoute
                                    key={id}
                                    path={`/${country}/${language}/el-corte-ingles${path}`}
                                    exact
                                >
                                    <CheckForSunset>
                                        <Component />
                                    </CheckForSunset>
                                </CompatRoute>
                            ))
                        )} */}

                        {UPGRADE_ROUTES.map(({ path, id }) => (
                            <CompatRoute
                                key={id}
                                path={`/us/en/upgrade${path}`}
                                exact
                            >
                                <AnniversarySunset />
                            </CompatRoute>
                        ))}

                        {/* {VALID_VANITY_URLS.map(({ vanity, output, id }) => (
                            <CompatRoute
                                key={id}
                                path={`/${vanity}`}
                                render={() => (
                                    <Redirect to={`/es/es/${output}`} />
                                )}
                                exact
                            />
                        ))} */}
                        <URLFixer />
                    </Switch>
                </ContextWrapper>
            </CompatRouter>
        </BrowserRouter>
    );
}

export default RoutedContent;
