import { Control, Controller, FieldErrors } from 'react-hook-form';
import { Checkbox, Dropdown, Input } from 'components';

import { Form } from '../../types';

import styles from './ControlPanel.module.scss';

interface Props {
    control: Control<Form, unknown>;
    errors: FieldErrors<Form>;
    dirty: Partial<
        Readonly<{
            hasPaper?: boolean | undefined;
            paperQuantity?: boolean | undefined;
            priceCents?: boolean | undefined;
        }>
    >;
}

function ControlPanel({ control, errors, dirty }: Props) {
    return (
        <div className={styles.configPanel}>
            <div className={styles.form}>
                <Controller
                    control={control}
                    name="paperQuantity"
                    rules={{
                        required: {
                            value: true,
                            message: 'This field is required',
                        },
                    }}
                    render={({ field }) => (
                        <Dropdown
                            {...field}
                            hasError={!!errors.paperQuantity}
                            errorMessage={errors.paperQuantity?.message}
                            isDirty={Boolean(dirty.paperQuantity)}
                            label="Pages per month"
                            optionsList={[
                                { value: '10' },
                                { value: '15' },
                                { value: '50' },
                                { value: '100' },
                                { value: '300' },
                                { value: '700' },
                            ]}
                        />
                    )}
                />

                <Controller
                    control={control}
                    name="priceCents"
                    rules={{
                        required: {
                            value: true,
                            message: 'This field is required',
                        },
                    }}
                    render={({ field }) => (
                        <Input
                            {...field}
                            data-testid="paperQuantity"
                            isDirty={Boolean(dirty.priceCents)}
                            label="Price cents"
                            type="number"
                            hasError={!!errors.priceCents}
                            errorMessage={errors.priceCents?.message}
                        />
                    )}
                />
            </div>

            <Controller
                control={control}
                name="hasPaper"
                render={({ field }) => (
                    <Checkbox
                        {...field}
                        value={undefined}
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                        label="Has paper"
                        className={styles.hasPaperCheckbox}
                    />
                )}
            />
        </div>
    );
}
export default ControlPanel;
