import { AnniversarySunsetMessages } from 'lang/messages/anniversarySunset';

import blockOneImg from './assets/block_one.png';
import blockThreeImg from './assets/block_three.png';
import blockTwoImg from './assets/block_two.png';

type TextType = Record<keyof typeof AnniversarySunsetMessages, string>;

export const LEARN_MORE_LINK =
    'https://www.hp.com/us-en/all-in-plan/printers.html?jumpid=ba_us_hpg_ot_ot_cm000011_co_ot_platinumupgrade-aa';

export const FAQS = [
    {
        id: 0,
        question: AnniversarySunsetMessages.FAQ_1Q,
        answer: AnniversarySunsetMessages.FAQ_1A,
    },
    {
        id: 1,
        question: AnniversarySunsetMessages.FAQ_2Q,
        answer: AnniversarySunsetMessages.FAQ_2A,
    },
    {
        id: 2,
        question: AnniversarySunsetMessages.FAQ_3Q,
        answer: AnniversarySunsetMessages.FAQ_3A,
    },
    {
        id: 3,
        question: AnniversarySunsetMessages.FAQ_4Q,
        answer: AnniversarySunsetMessages.FAQ_4A,
    },
    {
        id: 4,
        question: AnniversarySunsetMessages.FAQ_5Q,
        answer: AnniversarySunsetMessages.FAQ_5A,
    },
];

export const BLOCKS = (text: TextType) => [
    {
        id: 0,
        title: text.BOX_ONE_TITLE,
        body: text.BOX_ONE_BODY,
        image: blockOneImg,
    },
    {
        id: 1,
        title: text.BOX_TWO_TITLE,
        body: text.BOX_TWO_BODY,
        image: blockTwoImg,
    },
    {
        id: 2,
        title: text.BOX_THREE_TITLE,
        body: text.BOX_THREE_BODY,
        image: blockThreeImg,
    },
];
