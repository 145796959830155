import { useCookies } from 'react-cookie';
import { CookieVariation } from 'constants/cookiesEnums';
import { disclaimersTypes } from 'lang/messages/disclaimers';

export const useDisclaimers = () => {
    const [cookies] = useCookies(['variation']);

    if (
        cookies.variation &&
        disclaimersTypes[cookies.variation as CookieVariation]
    ) {
        return disclaimersTypes[cookies.variation as CookieVariation];
    }

    return disclaimersTypes[CookieVariation.Control];
};
