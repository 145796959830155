import { animated } from '@react-spring/web';
import { useModalTransitions } from 'components/CustomModal/hooks';

import styles from './Backdrop.module.scss';

interface Props {
    show: boolean;
    onClose: () => void;
}

function Backdrop({ show, onClose }: Props) {
    const { backdropTransition } = useModalTransitions(show);

    return backdropTransition((style, item) => {
        return (
            item && (
                <animated.div
                    className={styles.backdrop}
                    style={style}
                    onClick={onClose}
                    role="presentation"
                />
            )
        );
    });
}

export default Backdrop;
