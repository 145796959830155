import { useFormat } from 'hooks';
import {
    TermsOfServicesMessages,
    TermsOfServicesMessagesB,
    TermsOfServicesMessagesC,
    TermsOfServicesMessagesD,
} from 'lang/messages/terms';

import styles from './Header.module.scss';

function Header({
    terms,
}: {
    terms:
        | typeof TermsOfServicesMessages
        | typeof TermsOfServicesMessagesB
        | typeof TermsOfServicesMessagesC
        | typeof TermsOfServicesMessagesD;
}) {
    const { TITLE, SUBTITLE } = useFormat(terms);

    return (
        <header className={styles.container}>
            <h1 className={styles.title}>{TITLE}</h1>
            <h2 className={styles.subtitle}>{SUBTITLE}</h2>
        </header>
    );
}
export default Header;
