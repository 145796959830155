import { forwardRef } from 'react';
import { useIntl } from 'react-intl';
import { TrackAction, TrackLocation } from 'constants/analytics';
import { useTrackLink } from 'hooks/useTrack';

import styles from './styles.module.scss';

interface Props {
    id: string;
    title: string;
    content: string;
}

const Accordeon = forwardRef<HTMLInputElement, Props>(function Accordeon(
    { id, title, content },
    ref
) {
    const trackLink = useTrackLink();
    const { formatMessage } = useIntl();

    const handleOnClick = () => {
        trackLink(
            `${TrackAction.ClickedQuestion}${title}`,
            `${TrackLocation.Home}/${TrackLocation.FAQ}`
        );
    };

    return (
        <section>
            <div className={styles.tab}>
                <input
                    type="checkbox"
                    onClick={handleOnClick}
                    id={id}
                    className={styles.input}
                    ref={ref}
                />
                <label className={styles.tabLabel} htmlFor={id}>
                    {formatMessage({ id: title })}
                </label>
                <div className={styles.tabContent}>
                    {formatMessage({ id: content })}
                </div>
            </div>
        </section>
    );
});
export default Accordeon;
