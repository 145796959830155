import { Button } from 'components';

import styles from './ToggleButton.module.scss';

interface Props {
    toggleTestModal: () => void;
}

function ToggleButton({ toggleTestModal }: Props) {
    return (
        <Button
            type="button"
            className={styles.openDebugButton}
            onClick={toggleTestModal}
        >
            Anniversary Debug
        </Button>
    );
}

export default ToggleButton;
