import { LOCALES } from 'lang/locales';

export enum HowManyPagesMessages {
    TITLE = 'howManyPages.title',
    PAGES = 'howManyPages.pages',
    WILL_I_NEED = 'howManyPages.willINeed',
    MODAL_TITLE = 'howManyPages.modal.title',
    MODAL_CONTENT = 'howManyPages.modal.content',
    MODAL_CONTENT_MOBILE = 'howManyPages.modal.content.mobile',
}

export const howManyPagesMessages = {
    [LOCALES.ENGLISH]: {
        [HowManyPagesMessages.TITLE]:
            'How many pages do you\nneed to print per month?',
        [HowManyPagesMessages.PAGES]: '{pages} pages',
        [HowManyPagesMessages.WILL_I_NEED]: 'Need more plan info?',
        [HowManyPagesMessages.MODAL_TITLE]:
            'Print less in a month?\nPrint more in a month?',
        [HowManyPagesMessages.MODAL_CONTENT]:
            'If you do not use all your plan pages in a month, the unused pages will be\nkept in your account as rollover pages. You can continue to roll over up to 2\nor 3 times the number of pages in your monthly plan (varies by printer\nmodel and type). See your dashboard for specific rollover page counts.\n\nIf you have printed all of the pages in your plan and you have used all of\nyour rollover pages, you can still easily print additional pages. Additional\npages are available in sets of 10-15 pages, depending on your plan, for only\n$1.00 per set.\n\nAs long as your printer is connected to the internet, we will send you an\nemail to remind you when you are near printing all of the pages in your\nplan. You can easily change your monthly plan by logging in at\n<a>hpinstantink.com/users/signin</a> and clicking Change Plan. Since you can\nchoose to make a plan upgrade effective immediately, you can choose to\nupgrade your plan rather than purchasing additional pages',
        [HowManyPagesMessages.MODAL_CONTENT_MOBILE]:
            'If you do not use all your plan pages in a month, the unused pages will be kept in your account as rollover pages. You can continue to roll over up to 2 or 3 times the number of pages in your monthly plan (varies by printer model and type). See your dashboard for specific rollover page counts.\n\nIf you have printed all of the pages in your plan and you have used all of your rollover pages, you can still easily print additional pages. Additional pages are available in sets of 10-15 pages, depending on your plan, for only $1.00 per set.\n\nAs long as your printer is connected to the internet, we will send you an email to remind you when you are near printing all of the pages in your plan. You can easily change your monthly plan by logging in at <a>hpinstantink.com/users/signin</a> and clicking Change Plan. Since you can choose to make a plan upgrade effective immediately, you can choose to upgrade your plan rather than purchasing additional pages',
    },
    [LOCALES.SPANISH]: {
        [HowManyPagesMessages.TITLE]:
            '¿Cuántas páginas\nnecesitas imprimir por\nmes?',
        [HowManyPagesMessages.PAGES]: '{pages} páginas',
        [HowManyPagesMessages.WILL_I_NEED]:
            '¿Necesitas más información de los planes?',
        [HowManyPagesMessages.MODAL_TITLE]:
            '¿Imprimes menos un mes?\n¿Imprimes más en otro mes?',
        [HowManyPagesMessages.MODAL_CONTENT]:
            'Si no utilizas todas las páginas de tu plan en un mes, las páginas no\nutilizadas se mantendrán en tu cuenta como páginas acumuladas.\nPuedes acumular hasta un máximo de 3 veces la cantidad de páginas de\ntu plan mensual. Consulta tu panel de control para conocer el número de\npáginas que se pueden acumular.\n\nSi has impreso todas las páginas de tu plan y has utilizado todas las\npáginas acumuladas, aún puedes imprimir páginas adicionales\nfácilmente. Puedes contratar paquetes de 10 o 15 páginas adicionales,\nsegún tu plan, por solo 1€.\n\nSiempre que tu impresora esté conectada a Internet, te enviaremos un\ncorreo electrónico para recordarte cuando estés cerca de imprimir todas\nlas páginas de tu plan. Puedes cambiar fácilmente tu plan mensual\niniciando sesión en <a>hpinstantink.com/users/signin</a> y haciendo clic en\nCambiar plan. Dado que puedes optar por hacer que la actualización de\nun plan entre en vigor de inmediato, puedes optar por actualizar tu plan en\nlugar de comprar páginas adicionales.',
        [HowManyPagesMessages.MODAL_CONTENT_MOBILE]:
            'Si no utilizas todas las páginas de tu plan en un mes, las páginas no utilizadas se mantendrán en tu cuenta como páginas acumuladas. Puedes acumular hasta un máximo de 3 veces la cantidad de páginas de tu plan mensual. Consulta tu panel de control para conocer el número de páginas que se pueden acumular.\n\nSi has impreso todas las páginas de tu plan y has utilizado todas las páginas acumuladas, aún puedes imprimir páginas adicionales fácilmente. Puedes contratar paquetes de 10 o 15 páginas adicionales, según tu plan, por solo 1€.\n\nSiempre que tu impresora esté conectada a Internet, te enviaremos un correo electrónico para recordarte cuando estés cerca de imprimir todas las páginas de tu plan. Puedes cambiar fácilmente tu plan mensual iniciando sesión en <a>hpinstantink.com/users/signin</a> y haciendo clic en Cambiar plan. Dado que puedes optar por hacer que la actualización de un plan entre en vigor de inmediato, puedes optar por actualizar tu plan en lugar de comprar páginas adicionales.',
    },
};

export const howManyPagesValues = ({ pages = ' ' }) => ({
    [HowManyPagesMessages.PAGES]: {
        pages,
    },
});
