import { ReactNode, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useLocation } from 'react-router-dom-v5-compat';

import { getRandomNumber } from './utils';

interface Props {
    children: ReactNode;
}

function RandomVariationAssignator({ children }: Props) {
    const { pathname } = useLocation();
    const [cookies, setCookie] = useCookies(['variation']);

    useEffect(() => {
        // if there is a cookie, do nothing
        if (cookies.variation !== undefined) {
            return;
        }

        // if the user is on the upgrade page, do nothing
        if (pathname.includes('upgrade')) {
            return;
        }

        // if there is no cookie, assign a random one
        const randomNumber = getRandomNumber(0, 3);
        setCookie('variation', randomNumber);
    }, [cookies.variation, pathname, setCookie]);

    return children;
}
export default RandomVariationAssignator;
