import { useEffect, useState } from 'react';
import { Button } from 'components';
import { TrackAction, TrackLocation } from 'constants/analytics';
import {
    useBreakpoint,
    useFormat,
    useNotification,
    useRedirect,
    useTrackLink,
    useTranslation,
} from 'hooks';
import { CommonMessages, commonMessagesValues } from 'lang/messages/common';
import { SummaryMessages } from 'lang/messages/summary';
import { buildPathToCheckout } from 'utils/pathConstructor';

import styles from './Summary.module.scss';

interface Props {
    totalPrice: number | null;
    printerId?: string;
    inkPlanId?: string;
    isPrinterSelected?: boolean;
    isInkPlanSelected?: boolean;
}

function Summary({
    totalPrice,
    printerId = undefined,
    inkPlanId = undefined,
    isPrinterSelected = false,
    isInkPlanSelected = false,
}: Props) {
    const breakPoint = useBreakpoint();
    const isMobile = breakPoint === 'xs';
    const trackLink = useTrackLink();
    const { notify } = useNotification();
    const { CHECKOUT, FREE_SHIPPING, ERROR } = useFormat(SummaryMessages);
    const { MONTH_LONG, MONTH_SHORT } = useFormat(
        CommonMessages,
        commonMessagesValues({})
    );
    const { push } = useRedirect();
    const { printWithCurrency } = useTranslation();
    const [isNextPageLoading, setIsNextPageLoading] = useState(false);

    const handleRedirect = () => {
        if (!printerId || !inkPlanId) {
            notify(ERROR);
            return;
        }

        setIsNextPageLoading(true);
        trackLink(TrackAction.ContinueCheckout, TrackLocation.PrinterSelection);

        push(
            buildPathToCheckout({
                printerId,
                inkPlanId,
                isCompleted: false,
            })
        );
    };

    useEffect(() => {
        return () => {
            setIsNextPageLoading(false);
        };
    }, []);

    return (
        <div className={styles.container}>
            <div className={styles.leftContainer}>
                <h1 title="totalPrice">
                    {printWithCurrency(
                        totalPrice ? totalPrice.toFixed(2) : (0.0).toFixed(2)
                    )}
                    {isMobile ? MONTH_SHORT : MONTH_LONG}
                </h1>
                <p title="info">{FREE_SHIPPING}</p>
            </div>
            <Button
                onClick={handleRedirect}
                disabled={
                    isNextPageLoading ||
                    !isPrinterSelected ||
                    !isInkPlanSelected
                }
            >
                {CHECKOUT}
            </Button>
        </div>
    );
}

export default Summary;
