import { useState } from 'react';
import {
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
} from '@stripe/react-stripe-js';
import {
    StripeCardCvcElementChangeEvent,
    StripeCardExpiryElementChangeEvent,
    StripeCardNumberElementChangeEvent,
} from '@stripe/stripe-js';
import classNames from 'classnames';
import InputErrorMessage from 'components/InputErrorMessage/InputErrorMessage';
import { useExpandingAnimation } from 'hooks';

import { stylesOptions } from './constants';

import styles from './StripeInput.module.scss';

export type ChangeEvtHandler = (
    evt:
        | StripeCardCvcElementChangeEvent
        | StripeCardExpiryElementChangeEvent
        | StripeCardNumberElementChangeEvent
) => void;

interface Props {
    StripeElement:
        | typeof CardNumberElement
        | typeof CardExpiryElement
        | typeof CardCvcElement;
    onChange: ChangeEvtHandler;
    errorMsg: string | null;
    showIcon?: boolean;
    halfSized?: boolean;
}

function StripeInput({
    StripeElement,
    onChange,
    errorMsg,
    showIcon = undefined,
    halfSized = false,
}: Props) {
    const [isFocused, setIsFocused] = useState(false);
    const options = stylesOptions(!!errorMsg || false);
    const { transitions, heightRef } = useExpandingAnimation(!!errorMsg);

    return (
        <div
            className={classNames({
                [styles.halfSized]: halfSized,
            })}
        >
            <StripeElement
                options={{ ...options, showIcon }}
                onChange={onChange}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                className={classNames(styles.container, {
                    [styles.error]: !!errorMsg,
                    [styles.selected]: isFocused,
                })}
            />
            {transitions((style, item) => {
                return item ? (
                    <InputErrorMessage
                        message={errorMsg}
                        style={style}
                        ref={heightRef}
                    />
                ) : null;
            })}
        </div>
    );
}
export default StripeInput;
