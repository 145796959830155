import { LOCALES } from 'lang/locales';

export enum SimplePrintMessages {
    TITLE = 'simplePrint.title',
    SUB_NUMBER = 'simplePrint.subNumber',
    DESCRIPTION = 'simplePrint.description',
    IMG_ALT = 'simplePrint.imgAlt',
}

export const simplePrintMessages = {
    [LOCALES.ENGLISH]: {
        [SimplePrintMessages.TITLE]:
            'One complete\nsubscription,\neverything you need',
        [SimplePrintMessages.SUB_NUMBER]: '¹',
        [SimplePrintMessages.DESCRIPTION]: 'Printer. Ink. Paper. Support.',
        [SimplePrintMessages.IMG_ALT]: 'Simple',
    },
    [LOCALES.SPANISH]: {
        [SimplePrintMessages.TITLE]:
            'Una suscripción\ncompleta con\ntodo incluido',
        [SimplePrintMessages.SUB_NUMBER]: '¹',
        [SimplePrintMessages.DESCRIPTION]: 'Impresora. Tinta. Soporte.',
        [SimplePrintMessages.IMG_ALT]: 'Simple',
    },
};
