import { MouseEventHandler } from 'react';
import classNames from 'classnames';

import styles from './HyperLink.module.scss';

interface Props {
    text?: string;
    className?: string;
    linkText: string;
    onClick: MouseEventHandler<HTMLButtonElement>;
}

function HyperLink({
    text = undefined,
    className = undefined,
    linkText,
    onClick,
}: Props) {
    return (
        <p className={styles.textContainer} title="hyperlink">
            {text && `${text} `}
            <button
                type="button"
                onClick={onClick}
                className={classNames(styles.link, className)}
                role="link"
            >
                {linkText}
            </button>
        </p>
    );
}

export default HyperLink;
