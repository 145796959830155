import { Fragment } from 'react';
import { FormState, UseFormRegister } from 'react-hook-form';
import { useFormat } from 'hooks';
import useRetailCheck from 'hooks/useRetailCheck';
import {
    CheckoutMessages,
    checkoutMessagesValues,
} from 'lang/messages/checkout';

import Zip from './components/Zip/Zip';
import {
    FormArray,
    InputFields,
    printerSerialNumberField,
} from '../../constants';
import HookFormInput from '../HookFormInput/HookFormInput';

import styles from './ColumnContainer.module.scss';

interface Props {
    data: FormArray<InputFields>[];
    index: number;
    register: UseFormRegister<InputFields>;
    formState: FormState<InputFields>;
}

function ColumnContainer({ data, index, formState, register }: Props) {
    const { isRetail } = useRetailCheck();
    const shippingText = useFormat(
        CheckoutMessages,
        checkoutMessagesValues({})
    );

    return (
        <div className={styles.column}>
            {data.map((input, inputIndex) => (
                <Fragment key={input.id}>
                    <HookFormInput
                        formState={formState}
                        input={input}
                        register={register}
                    />

                    {isRetail && index === 0 && inputIndex === 4 && (
                        <HookFormInput
                            formState={formState}
                            input={printerSerialNumberField(shippingText)}
                            register={register}
                        />
                    )}

                    {index === 1 && inputIndex === 2 && (
                        <Zip formState={formState} register={register} />
                    )}
                </Fragment>
            ))}
        </div>
    );
}
export default ColumnContainer;
