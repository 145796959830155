interface IBuildPathToCheckout {
    printerId: string;
    inkPlanId: string;
    isCompleted: boolean;
}

export const buildPathToCheckout = ({
    printerId,
    inkPlanId,
    isCompleted,
}: IBuildPathToCheckout) => {
    return `/checkout/${printerId}/${inkPlanId}/${
        isCompleted ? 'complete' : ''
    }`;
};
