import { LOCALES } from 'lang/locales';

export enum FooterMessages {
    COPYRIGHT = 'footer.copyright',
    COOKIES = 'footer.title',
    PRIVACY = 'footer.subtitle',
    CHOICES = 'footer.printer',
    TOS = 'footer.delivery',
}

export const footerMessages = {
    [LOCALES.ENGLISH]: {
        [FooterMessages.COOKIES]: 'Use of Cookies',
        [FooterMessages.PRIVACY]: 'Privacy Statement',
        [FooterMessages.CHOICES]: 'Your Privacy Choices',
        [FooterMessages.TOS]: 'Terms of Service',
        [FooterMessages.COPYRIGHT]:
            '©Copyright {year} HP Development Company, L.P. ',
    },

    [LOCALES.SPANISH]: {
        [FooterMessages.COOKIES]: 'Uso de Cookies',
        [FooterMessages.PRIVACY]: 'Declaración de privacidad',
        [FooterMessages.CHOICES]: 'Opciones de privacidad',
        [FooterMessages.TOS]: 'Términos del servicio',
        [FooterMessages.COPYRIGHT]:
            '©Copyright {year} HP Development Company, L.P.',
    },
};

export const footerMessagesValues = ({ year = ' ' }) => ({
    [FooterMessages.COPYRIGHT]: {
        year,
    },
});
