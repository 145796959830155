import CarbonNeutralCert from 'assets/carbon_neutral_badge.svg';
import InstantInkLogo from 'assets/instantink_logo_white.svg';
import { heroValues } from 'constants/formatValues';
import { useFormat } from 'hooks';
import { HeroMessages } from 'lang/messages/heroScreen';

import { Information } from './components';

import styles from './SpainHero.module.scss';

function SpainHero() {
    const { TITLE } = useFormat(HeroMessages, heroValues);

    return (
        <>
            <div title="main-spain-container" className={styles.heroContainer}>
                <header>
                    <nav>
                        <img
                            src={InstantInkLogo}
                            alt="Instant Ink Logo"
                            className={styles.instantInkNavigationLogo}
                        />
                    </nav>
                </header>

                <div title="empty-space" className={styles.emptySpace} />

                <main className={styles.mainTitle}>
                    <div className={styles.textContainer}>
                        <h1>{TITLE}</h1>
                    </div>
                    <img
                        src={CarbonNeutralCert}
                        alt="Certificado CarbonNeutral"
                    />
                </main>

                <Information />
            </div>
            <Information isMobile />
        </>
    );
}

export default SpainHero;
