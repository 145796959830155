import { FormState, UseFormRegister } from 'react-hook-form';
import { Input } from 'components';
import { checkForNumber } from 'utils/checkForNumber';

import { FormArray, InputFields } from '../../constants';

interface Props {
    input: FormArray<InputFields>;
    register: UseFormRegister<InputFields>;
    formState: FormState<InputFields>;
}

function HookFormInput({
    input,
    register,
    formState: { dirtyFields, errors },
}: Props) {
    return (
        <Input
            {...register(input.register, input.options)}
            aria-label={input.label}
            label={input.label}
            isDirty={Boolean(dirtyFields[input.register])}
            hasError={!!errors[input.register]}
            errorMessage={
                errors[input.register]?.message &&
                errors[input.register]?.message
            }
            id={input.label}
            info={input.info && input.info}
            type={input.type}
            onKeyDown={checkForNumber(input.type)}
            onPaste={(e) => {
                e.preventDefault();
            }}
        />
    );
}
export default HookFormInput;
