import { config, useTransition } from '@react-spring/web';

function useModalTransitions(show: boolean) {
    const modalTransition = useTransition(show, {
        config: config.gentle,
        from: {
            transform: 'translate(-50%, -150%)',
            opacity: 0,
        },
        enter: { transform: 'translate(-50%, -50%)', opacity: 1 },
        leave: { transform: 'translate(-50%, -200%)', opacity: 0 },
    });

    const backdropTransition = useTransition(show, {
        config: { ...config.wobbly, duration: 150 },
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
    });

    return { modalTransition, backdropTransition };
}

export default useModalTransitions;
