import image from 'assets/steps_aside.webp';
import { Paths } from 'components/RoutedContent/paths';
import { TrackLocation, TrackType } from 'constants/analytics';
import { CookieVariation } from 'constants/cookiesEnums';
import { useDataProvider } from 'contexts/DataProvider';
import { useFormat, useTranslation, useVariation } from 'hooks';
import { CommonMessages, commonMessagesValues } from 'lang/messages/common';
import {
    StepsMessages,
    StepsMessagesB,
    StepsMessagesC,
} from 'lang/messages/steps';
import { ES_PRICE, US_PRICE } from 'screens/LandingPage/constants';

import { Step } from './components';
import { STEPS } from './constants';
import RedirectButton from '../RedirectButton/RedirectButton';

import styles from './Steps.module.scss';

function Steps() {
    const { showActivation } = useDataProvider();
    const { isSpain } = useTranslation();
    const { variation } = useVariation();

    const price = String(isSpain() ? ES_PRICE : US_PRICE);

    const { GET_STARTED_FROM } = useFormat(
        CommonMessages,
        commonMessagesValues({ price })
    );

    const messages = {
        [CookieVariation.Control]: StepsMessages,
        [CookieVariation.PlusSayan]: StepsMessagesB,
        [CookieVariation.SayanReplacing]: StepsMessagesC,
        [CookieVariation.LEBI]: StepsMessagesC, // TODO: Add LEBI steps messages
    };

    const stepsText = useFormat(messages[variation || CookieVariation.Control]);

    return (
        <section className={styles.container}>
            <div className={styles.left}>
                <img
                    src={image}
                    alt={stepsText.TITLE_ONE}
                    className={styles.image}
                />
            </div>
            <div className={styles.right}>
                {STEPS(stepsText).map(
                    ({ content, step, title, activationContent }) => (
                        <Step
                            key={step}
                            step={step}
                            title={title}
                            content={
                                showActivation && activationContent
                                    ? activationContent
                                    : content
                            }
                        />
                    )
                )}
                <RedirectButton
                    action={TrackType.CTA}
                    from={TrackLocation.Steps}
                    redirectTo={Paths.PrinterSelection}
                    className={styles.button}
                >
                    {GET_STARTED_FROM}
                </RedirectButton>
            </div>
        </section>
    );
}
export default Steps;
