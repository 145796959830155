import { useUpgradeInfo } from 'contexts';
import { useQuery, useRequest } from 'hooks';
import {
    checkUpgradeElegibility,
    getPilotIdInfo,
    UpgradeQueryParams,
} from 'services/pilotIdInfo';

function useUpgradeFetch() {
    const { accountIdentifier, hasPaper, pilotId, planPages, priceCents } =
        useQuery<UpgradeQueryParams>();

    const { data, error, setData } = useUpgradeInfo();

    const {
        isLoading: elegibilityCheckLoading,
        data: elegibilityCheckData,
        hasError: elegibilityCheckError,
    } = useRequest({
        service: checkUpgradeElegibility,
        params: {
            account_identifier: accountIdentifier,
            has_paper: hasPaper,
            pilot_id: String(pilotId),
            plan_page: String(planPages),
        },
        options: {
            waitForBool: Boolean(accountIdentifier) || Boolean(pilotId),
        },
    });

    const { isLoading } = useRequest({
        service: getPilotIdInfo,
        params: {
            accountIdentifier,
            hasPaper,
            pilotId,
            planPage: planPages,
            priceCents,
        },
        options: {
            waitForBool:
                Boolean(accountIdentifier) &&
                Boolean(pilotId) &&
                !elegibilityCheckLoading &&
                !elegibilityCheckData?.hasUpgraded &&
                elegibilityCheckData?.isEligible,
            transform: (res) => {
                setData(res);
            },
        },
    });

    return {
        data,
        error,
        elegibilityCheckError,
        elegibilityCheckData,
        elegibilityCheckLoading,
        isLoading,
    };
}
export default useUpgradeFetch;
