import axios from 'axios';
import { PrinterSelectionResponse } from 'contexts/PrinterChoice/constants';
import { requestHandler } from 'utils/requestHandler';

export interface PlanDataQueryParams {
    zone: 'US' | 'ES';
    customerId?: string;
}

export const getPlanData = requestHandler<
    PlanDataQueryParams,
    PrinterSelectionResponse
>((params) => axios.get(`/api/planData/`, { params }));
