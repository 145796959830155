export const POSSIBLE_PRINTERS = [
    'basic',
    'productive',
    'highVolume',
    'professional',
    'versatile',
    'essential',
];

export const localDisallowedInkPlans = ['inkPlan5', 'inkPlan8'];
export const highVolumeExclusivePlans = ['inkPlan10', 'inkPlan11'];
