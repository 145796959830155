import { NavigationBar } from 'components';

import ChoosePrinter from './components/ChooseYourPrinter/ChooseYourPrinter';
import HowManyPages from './components/HowManyPages/HowManyPages';
import OrderYourPlan from './components/OrderYourPlan/OrderYourPlan';
import Summary from './components/Summary/Summary';
import usePrinterSelection from './hooks';

import styles from './styles.module.scss';

function PrinterSelection() {
    const {
        backgroundStyles,
        refPosition,
        chooseYourPrinter,
        howManyPages,
        orderYourPlan,
        summary,
    } = usePrinterSelection();

    return (
        <>
            <NavigationBar variant="black" background="#eaf0f0" />

            <main className={styles.container} style={backgroundStyles}>
                <ChoosePrinter ref={refPosition(0)} {...chooseYourPrinter} />
                <HowManyPages ref={refPosition(1)} {...howManyPages} />
                <OrderYourPlan ref={refPosition(2)} {...orderYourPlan} />
            </main>

            <footer>
                <Summary {...summary} />
            </footer>
        </>
    );
}
export default PrinterSelection;
