import {
    createContext,
    ReactNode,
    useCallback,
    useContext,
    useMemo,
    useState,
} from 'react';
import { useQuery } from 'hooks';
import useLocalStorage from 'hooks/useLocalStorage';
import { localDisallowedInkPlans } from 'screens/PrinterSelection/constants';
import { UpgradeQueryParams } from 'services/pilotIdInfo';
import { StateDispatch } from 'types/globals';

import {
    AvailablePrinters,
    CONTEXT_ERROR,
    EMPTY_STATE,
    InkPlans,
    LOCALSTORAGE_PRINTER,
    NewInkPlan,
    Printer,
    PrinterSelectionResponse,
    USER_CHOICE_EMPTY,
    UserChoice,
} from './constants';
import { buildDefaultSelection } from './utils';

interface Props {
    children: ReactNode;
}

export interface ContextProps {
    userChoice: UserChoice;
    userToken: string;
    idToken: string;
    data: PrinterSelectionResponse;
    saveData: (arg0: PrinterSelectionResponse) => void;
    handleChangePreferences: (
        type: 'printer' | 'inkPlan',
        choice: Printer | NewInkPlan | boolean
    ) => void;
    setUserToken: StateDispatch<string>;
    setIdToken: StateDispatch<string>;
    resetUserChoice: (arg0: AvailablePrinters) => void;
}

export const PrinterChoiceContext = createContext<ContextProps>({
    userChoice: USER_CHOICE_EMPTY,
    userToken: '',
    idToken: '',
    data: EMPTY_STATE,
    saveData: () => {},
    handleChangePreferences: () => {},
    setUserToken: () => {},
    setIdToken: () => {},
    resetUserChoice: () => {},
});

function PrinterChoiceProvider({ children }: Props) {
    const { accountIdentifier, pilotId } = useQuery<UpgradeQueryParams>();

    const isUpgrade = Boolean(accountIdentifier) && Boolean(pilotId);

    const [savedPrinterSelection, setSavedPrinterSelection] = useLocalStorage(
        LOCALSTORAGE_PRINTER,
        USER_CHOICE_EMPTY
    );

    const [userToken, setUserToken] = useState('');
    const [idToken, setIdToken] = useState('');
    const [userChoice, setUserChoice] = useState<UserChoice>(
        isUpgrade || !savedPrinterSelection
            ? USER_CHOICE_EMPTY
            : savedPrinterSelection
    );

    const [data, setData] = useState<PrinterSelectionResponse>(EMPTY_STATE);

    const resetUserChoice = useCallback(() => {
        const defaultSelection = buildDefaultSelection();

        setUserChoice(defaultSelection);
        setSavedPrinterSelection(defaultSelection);
    }, [setSavedPrinterSelection]);

    const saveData = useCallback(
        (res: PrinterSelectionResponse) => {
            setData(res);
            setUserChoice(
                isUpgrade || !savedPrinterSelection
                    ? buildDefaultSelection()
                    : savedPrinterSelection
            );
            if (!savedPrinterSelection) {
                setSavedPrinterSelection(buildDefaultSelection());
            }
        },
        [isUpgrade, savedPrinterSelection, setSavedPrinterSelection]
    );

    const handleChangePreferences = useCallback(
        (
            type: 'printer' | 'inkPlan',
            choice: Printer | NewInkPlan | boolean
        ) => {
            setUserChoice((prevState) => {
                //   Verify that the inkPlan is available for the selected printer and
                //   and is not a disallowed ink plan

                const AVAILABLE_INK_PLANS = data.newInkPlans.filter(
                    (plan) =>
                        !(choice as Printer).disallowedInkPlans?.includes(
                            plan.id as InkPlans
                        ) &&
                        !localDisallowedInkPlans.includes(plan.id as InkPlans)
                );

                // Check if the selected ink plan is not available for the selected printer
                // if it is not available, select the last item in the available inkPlan list

                const VALID_INK_PLAN =
                    (choice as Printer).disallowedInkPlans?.includes(
                        prevState.inkPlan?.id || ''
                    ) ||
                    localDisallowedInkPlans.includes(
                        prevState.inkPlan?.id || ''
                    )
                        ? AVAILABLE_INK_PLANS[AVAILABLE_INK_PLANS.length - 1]
                        : prevState.inkPlan;

                // Build the final object to be stored on the state

                const FINAL_SELECTION = {
                    ...prevState,
                    inkPlan: VALID_INK_PLAN,
                    isPrinterSelected:
                        type === 'printer' ? true : prevState.isPrinterSelected,
                    isInkPlanSelected:
                        type === 'inkPlan' ? true : prevState.isInkPlanSelected,
                    [type]: choice,
                };

                // Save the final object to the local storage
                localStorage.setItem(
                    LOCALSTORAGE_PRINTER,
                    JSON.stringify(FINAL_SELECTION)
                );

                return FINAL_SELECTION;
            });
        },
        [data.newInkPlans]
    );

    const value = useMemo(
        () => ({
            userChoice,
            userToken,
            idToken,
            data,
            resetUserChoice,
            handleChangePreferences,
            setUserToken,
            setIdToken,
            saveData,
        }),
        [
            data,
            resetUserChoice,
            handleChangePreferences,
            idToken,
            saveData,
            userChoice,
            userToken,
        ]
    );

    return (
        <PrinterChoiceContext.Provider value={value}>
            {children}
        </PrinterChoiceContext.Provider>
    );
}

export default PrinterChoiceProvider;

export const usePrinterChoice = () => {
    const ctx = useContext(PrinterChoiceContext);

    if (!ctx) {
        throw new Error(CONTEXT_ERROR);
    }
    return ctx;
};
