import { Link } from 'react-router-dom-v5-compat';
import { Paths } from 'components/RoutedContent/paths';
import { useDataProvider } from 'contexts/DataProvider';
import { useFormat, useTranslation } from 'hooks';
import {
    CheckoutMessages,
    checkoutMessagesValues,
} from 'lang/messages/checkout';

import styles from './Label.module.scss';

function Label() {
    const { ARN_CONFIRMATION_LABEL, ARN_CONFIRMATION_LINK } = useFormat(
        CheckoutMessages,
        checkoutMessagesValues({})
    );
    const { currentLang, currentLocation } = useTranslation();
    const { showActivation } = useDataProvider();

    const activationLink = `/${currentLocation}/${currentLang}${
        showActivation ? Paths.ActivationTerms : Paths.Terms
    }`;

    return (
        <p className={styles.text}>
            <span>{ARN_CONFIRMATION_LABEL}</span>
            <Link target="_blank" to={activationLink} className={styles.link}>
                {ARN_CONFIRMATION_LINK}
            </Link>
        </p>
    );
}
export default Label;
