import { useLocation, useNavigate } from 'react-router-dom-v5-compat';

import useRetailCheck from '../useRetailCheck';
import { useTranslation } from '../useTranslation/useTranslation';

export const useRedirect = () => {
    const navigate = useNavigate();
    const { currentLang, currentLocation } = useTranslation();
    const { pathname, search } = useLocation();
    const { checkForRetail } = useRetailCheck();

    const isRetail = checkForRetail ? '/el-corte-ingles' : '';

    const push = (path: string, append?: boolean) => {
        if (append) {
            navigate(`${pathname}${path}`);
        } else {
            navigate(
                `/${currentLocation}/${currentLang}${isRetail}${path}${search}`
            );
        }
    };

    const pushWithQuery = (path: string, query: string) => {
        navigate(
            `/${currentLocation}/${currentLang}${isRetail}${path}${query}`
        );

        navigate(0);
    };

    return { push, pushWithQuery };
};
