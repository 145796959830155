import { ReactNode } from 'react';
import classNames from 'classnames';
import { useBreakpoint } from 'hooks';

import MostPopularBadge from '../MostPopularBadge/MostPopularBadge';

import styles from './PillButton.module.scss';

interface Props {
    title: string | ReactNode;
    subtitle?: string;
    altSubtitle?: boolean;
    isSelected: boolean;
    mostPopular?: boolean;
    disabled?: boolean;
    onClick: () => void;
}

function PillButton({
    isSelected,
    title,
    disabled = false,
    subtitle = undefined,
    altSubtitle = undefined,
    mostPopular = undefined,
    onClick,
}: Props) {
    const breakPoint = useBreakpoint();
    const isMobile = breakPoint === 'xs';

    return (
        <button
            disabled={disabled}
            type="button"
            title="pillButton"
            className={classNames({
                [styles.container]: !isSelected,
                [styles.containerSelected]: isSelected,
            })}
            onClick={onClick}
        >
            <div className={styles.textContainer}>
                <div className={styles.title}>
                    {title}
                    {!isMobile && mostPopular && (
                        <MostPopularBadge testId="desktop" />
                    )}
                </div>
                <p
                    title="subtitle"
                    className={classNames({
                        [styles.subtitle]: !altSubtitle,
                        [styles.altSubtitle]: altSubtitle,
                    })}
                >
                    {subtitle}
                </p>
            </div>
            {isMobile && mostPopular && <MostPopularBadge testId="mobile" />}
        </button>
    );
}

export default PillButton;
