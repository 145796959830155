import sideImage from 'assets/values_background.webp';
import { Paths } from 'components/RoutedContent/paths';
import { TrackLocation, TrackType } from 'constants/analytics';
import { commonValues } from 'constants/formatValues';
import { useFormat, useTranslation, useVariation } from 'hooks';
import { CommonMessages } from 'lang/messages/common';
import { ValuesMessages } from 'lang/messages/values';

import { POINTS } from './constants';
import RedirectButton from '../RedirectButton/RedirectButton';

import styles from './SimplestWayToPrint.module.scss';

function SimplestWayToPrint() {
    const { isSpain } = useTranslation();
    const { GET_STARTED_FROM } = useFormat(
        CommonMessages,
        commonValues(isSpain())
    );
    const { isControl } = useVariation();

    const simplestText = useFormat(ValuesMessages);

    return (
        <section className={styles.container}>
            <div className={styles.left}>
                <ul>
                    {POINTS(simplestText, isControl).map(
                        ({ id, content, title }) => (
                            <li className={styles.point} key={id}>
                                <h1 className={styles.title}>{title}</h1>
                                <p className={styles.content}>{content}</p>
                            </li>
                        )
                    )}
                </ul>
                <RedirectButton
                    action={TrackType.CTA}
                    from={TrackLocation.Values}
                    redirectTo={Paths.PrinterSelection}
                    className={styles.button}
                >
                    {GET_STARTED_FROM}
                </RedirectButton>
            </div>
            <div className={styles.right}>
                <img
                    src={sideImage}
                    alt="Get Started"
                    className={styles.image}
                />
            </div>
        </section>
    );
}
export default SimplestWayToPrint;
