import { useParams } from 'react-router-dom-v5-compat';
import { Paths } from 'components/RoutedContent/paths';
import { useDataProvider } from 'contexts/DataProvider';
import { useQuery, useRedirect, useTrackBuyflowRemove } from 'hooks';
import useRetailCheck from 'hooks/useRetailCheck';
import { UpgradeQueryParams } from 'services/pilotIdInfo';

import { ParamsType } from './types';

function useCheckoutInfo() {
    const { planId, inkPlanId } = useParams<ParamsType>();
    const { hasPaper, planPages } = useQuery<UpgradeQueryParams>();
    const { getCurrentInkPlanAndPlanId } = useDataProvider();
    const { plan, inkPlan } = getCurrentInkPlanAndPlanId({ planId, inkPlanId });
    const { isRetail } = useRetailCheck();
    const { push } = useRedirect();
    const trackRemove = useTrackBuyflowRemove();

    const onViewPlans = () => {
        if (plan && inkPlan) {
            trackRemove(plan, inkPlan);
        }

        push(Paths.PrinterSelection);
    };

    return {
        data: {
            hasPaper,
            planPages,
            plan,
            inkPlan,
        },
        isRetail,
        onViewPlans,
    };
}
export default useCheckoutInfo;
