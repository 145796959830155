import { useFormat } from 'hooks';
import { FAQSectionMessages } from 'lang/messages/faqSection';

import styles from './Header.module.scss';

function Header() {
    const { TITLE_LONG } = useFormat(FAQSectionMessages);

    return (
        <header>
            <h4 className={styles.title}>{TITLE_LONG}</h4>
        </header>
    );
}
export default Header;
