import { useCookies } from 'react-cookie';
import { CookieVariation } from 'constants/cookiesEnums';
import {
    AvailablePrinters,
    LOCALSTORAGE_PRINTER,
    UserChoice,
} from 'contexts/PrinterChoice/constants';
import { useLocalStorage } from 'hooks';
import { PrinterInfo } from 'screens/PrinterSelection/components/ComparisonTable/constants';

import styles from './ValueDisplay.module.scss';

interface Props {
    data: PrinterInfo[];
    spec: string;
}

function ValueDisplay({ data, spec }: Props) {
    const [{ variation }] = useCookies(['variation']);
    const [localStorageData] =
        useLocalStorage<UserChoice>(LOCALSTORAGE_PRINTER);

    const disabledPrinters = localStorageData?.disabledPrinterIds || [];

    return data.map((printer) => {
        if (
            (variation !== CookieVariation.LEBI &&
                printer.id === AvailablePrinters.LEBI) ||
            (printer.id === AvailablePrinters.Professional &&
                variation === CookieVariation.SayanReplacing)
        ) {
            return null;
        }

        if (
            (variation === CookieVariation.Control || !variation) &&
            printer.id === AvailablePrinters.HighVolume
        ) {
            return null;
        }

        const value = printer[spec as keyof PrinterInfo];

        return (
            <td
                className={styles.valueContainer}
                key={printer.id}
                style={{
                    opacity: disabledPrinters.includes(printer.id) ? 0.5 : 1,
                }}
            >
                {typeof value === 'boolean' ? (
                    <input
                        type="checkbox"
                        checked={value}
                        className={styles.checkbox}
                        readOnly
                    />
                ) : (
                    <p className={styles.valueText}>{value}</p>
                )}
            </td>
        );
    });
}
export default ValueDisplay;
