import { LOCALES } from 'lang/locales';

export enum CommonMessages {
    GET_STARTED_FROM = 'common.getStartedFrom',
    MOST_POPULAR = 'common.mostPopular',
    VIEW_MORE = 'common.viewMore',
    VIEW_LESS = 'common.viewLess',
    MONTH_SHORT = 'common.monthShort',
    MONTH_LONG = 'common.month',
    DOWNLOAD = 'common.download',
    YES = 'common.yes',
    NO = 'common.no',
    CONTINUE = 'common.continue',
    BACK = 'common.back',
    EDIT = 'common.edit',
    SAVE = 'common.save',
    LOADING = 'common.loading',
}

const USD = '$';

export const commonMessages = {
    [LOCALES.ENGLISH]: {
        [CommonMessages.GET_STARTED_FROM]: `Get Started from ${USD}{price}/mo`,
        [CommonMessages.MOST_POPULAR]: 'Most Popular',
        [CommonMessages.VIEW_MORE]: 'View More',
        [CommonMessages.VIEW_LESS]: 'View Less',
        [CommonMessages.MONTH_SHORT]: '/mo',
        [CommonMessages.MONTH_LONG]: '/month',
        [CommonMessages.DOWNLOAD]: 'Download',
        [CommonMessages.YES]: 'Yes',
        [CommonMessages.NO]: 'No',
        [CommonMessages.CONTINUE]: 'Continue',
        [CommonMessages.BACK]: 'Back',
        [CommonMessages.EDIT]: 'Edit',
        [CommonMessages.SAVE]: 'Save',
        [CommonMessages.LOADING]: 'Loading',
    },
    [LOCALES.SPANISH]: {
        [CommonMessages.GET_STARTED_FROM]: 'Comienza desde {price}€ al mes.',
        [CommonMessages.MOST_POPULAR]: 'Más popular',
        [CommonMessages.VIEW_MORE]: 'Ver más',
        [CommonMessages.VIEW_LESS]: 'Ver menos',
        [CommonMessages.MONTH_SHORT]: '/mes',
        [CommonMessages.MONTH_LONG]: '/mes',
        [CommonMessages.DOWNLOAD]: 'Descargar',
        [CommonMessages.YES]: 'Sí',
        [CommonMessages.NO]: 'No',
        [CommonMessages.CONTINUE]: 'Continuar',
        [CommonMessages.BACK]: 'Atrás',
        [CommonMessages.EDIT]: 'Editar',
        [CommonMessages.SAVE]: 'Guardar',
        [CommonMessages.LOADING]: 'Cargando',
    },
};

export const commonMessagesValues = ({ price = ' ' }) => ({
    [CommonMessages.GET_STARTED_FROM]: {
        price,
    },
});
