import { BillingResponse, ShippingResponse } from './constants';

export const buildShippingBillingData = (
    data: [ShippingResponse[], BillingResponse]
) => {
    const shippingResponse = data[0][0];
    const billingResponse = data[1];

    const creditCardInfo = billingResponse.creditCard;
    const expirationDate = creditCardInfo.displayExpirationDate
        ? creditCardInfo.displayExpirationDate.split('/')
        : undefined;
    const last4 = creditCardInfo.cardNumber
        ? creditCardInfo.cardNumber.split('-')[3]
        : undefined;

    const shipping = {
        firstName: shippingResponse.firstName,
        lastName: shippingResponse.lastName,
        company: shippingResponse.company,
        address1: shippingResponse.street1,
        address2: shippingResponse.street2,
        city: shippingResponse.city,
        state: shippingResponse.state,
        zip: shippingResponse.zipCode,
        phone: shippingResponse.phoneNumber1,
        email: '',
        recieveMobileAlerts: false,
    };

    const billing = {
        ccNameOnCard: shippingResponse.fullName,
        street: shippingResponse.street1,
        street2: shippingResponse.street2,
        city: shippingResponse.city,
        state: shippingResponse.state,
        zip: shippingResponse.zipCode,
        paymentType: billingResponse.paymentType,
        useShippingAddress: true,
        paypal: billingResponse.payPal,
        oobeCardInfo:
            expirationDate && last4
                ? {
                      exp_month: expirationDate[0],
                      exp_year: expirationDate[1],
                      last4,
                      name: shippingResponse.fullName,
                  }
                : undefined,
    };

    return { shipping, billing };
};
