import { useIntl } from 'react-intl';
import { AddendumSection } from 'screens/TermsAndConditions/types';

import styles from './Links.module.scss';

interface Props {
    data: AddendumSection;
}

function Links({ data }: Props) {
    const { formatMessage } = useIntl();

    return data.map((currentSection) => (
        <li className={styles.linkItem} key={currentSection.id}>
            <a href={`#${currentSection.id}`}>
                {formatMessage({
                    id: currentSection.header,
                })}
            </a>
        </li>
    ));
}
export default Links;
