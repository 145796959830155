import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from 'components';
import CustomModal from 'components/CustomModal/CustomModal'; // TODO: Fix export
import { TrackAction, TrackLocation } from 'constants/analytics';
import { ModalType } from 'constants/modals';
import { useCheckoutModals, useUserInfo } from 'contexts';
import { ShippingInfo } from 'contexts/UserInfo/constants';
import { useTrackLink } from 'hooks';

import ColumnContainer from './components/ColumnContainer/ColumnContainer'; // TODO: Fix export
import useShippingTranslation from './hooks/useShippingTranslation'; // TODO: Fix export
import { DEFAULT_VALUES, FORM, InputFields } from './constants';

import styles from './ShippingModal.module.scss';

interface Props {
    isUpgrade?: boolean;
}

// TODO: Split the modal logic from the form logic

function ShippingModal({ isUpgrade = false }: Props) {
    const text = useShippingTranslation({ isUpgrade });
    const trackLink = useTrackLink();
    const { changeInfo, shipping } = useUserInfo();
    const { register, formState, handleSubmit, reset, setValue } =
        useForm<InputFields>({
            defaultValues: DEFAULT_VALUES,
            mode: 'onBlur',
        });

    const { isValid } = formState;

    const { toggleModals, modals } = useCheckoutModals();

    const show = modals[ModalType.Shipping];

    const onSubmit = (data: InputFields) => {
        changeInfo('shipping', data);
        trackLink(TrackAction.ShippingModalSubmitted, TrackLocation.Checkout);
        reset(shipping ?? DEFAULT_VALUES);
        toggleModals(ModalType.Shipping);
    };

    const populateData = useCallback(() => {
        if (shipping) {
            reset(DEFAULT_VALUES, { keepDirty: true });
            const keys = Object.keys(shipping) as (keyof ShippingInfo)[];
            keys.forEach((item: keyof ShippingInfo) => {
                setValue(item, shipping[item], {
                    shouldDirty: true,
                });
            });
        }
    }, [reset, setValue, shipping]);

    const handleCloseWithoutSaving = () => {
        if (!shipping) {
            reset(DEFAULT_VALUES);
        } else {
            populateData();
        }

        toggleModals(ModalType.Shipping);
        toggleModals(ModalType.Questionnare);
    };

    useEffect(() => {
        if (show) {
            populateData();
        }
    }, [populateData, setValue, shipping, show]);

    return (
        <CustomModal
            show={show}
            onClose={handleCloseWithoutSaving}
            title={text.modalTitle}
            subtitle={text.modalSubtitle}
            forceFullWidth
        >
            <div className={styles.innerContainer}>
                <form onSubmit={handleSubmit(onSubmit)} data-testid="form">
                    <main className={styles.columnContainer}>
                        {FORM(text).map((columns, index) => (
                            <ColumnContainer
                                key={columns.id}
                                data={columns.data}
                                index={index}
                                formState={formState}
                                register={register}
                            />
                        ))}
                    </main>

                    <footer className={styles.footerContainer}>
                        <Button type="submit" disabled={!isValid}>
                            {text.save}
                        </Button>
                    </footer>
                </form>
            </div>
        </CustomModal>
    );
}

export default ShippingModal;
