import cancelAnytime from 'assets/cancel-anytime.svg';
import newPrinter from 'assets/choose-new-printer.svg';
import easySignup from 'assets/easy-sign-up.svg';
import pickPrintPlan from 'assets/pick-print-plan.svg';
import { Paths } from 'components/RoutedContent/paths';
import { TrackLocation, TrackType } from 'constants/analytics';
import { useFormat } from 'hooks';
import { CommonMessages, commonMessagesValues } from 'lang/messages/common';
import { HeroVariationAMessages } from 'lang/messages/heroVariationA';
import { ES_PRICE } from 'screens/LandingPage/constants';

import Separator from './components/Separator/Separator';
import { ItemDescriptor } from './components';
import RedirectButton from '../RedirectButton/RedirectButton';

import styles from './HowItWorks.module.scss';

function HowItWorks() {
    const {
        HOW_IT_WORKS_ITEM_A_BODY,
        HOW_IT_WORKS_ITEM_A_TITLE,
        HOW_IT_WORKS_ITEM_B_BODY,
        HOW_IT_WORKS_ITEM_B_TITLE,
        HOW_IT_WORKS_ITEM_C_BODY,
        HOW_IT_WORKS_ITEM_C_TITLE,
        HOW_IT_WORKS_ITEM_D_BODY,
        HOW_IT_WORKS_ITEM_D_TITLE,
        HOW_IT_WORKS_TITLE,
    } = useFormat(HeroVariationAMessages);
    const { GET_STARTED_FROM } = useFormat(
        CommonMessages,
        commonMessagesValues({ price: String(ES_PRICE) })
    );

    return (
        <section className={styles.container}>
            <h1 className={styles.title}>{HOW_IT_WORKS_TITLE}</h1>

            <ul className={styles.itemsContainer}>
                <ItemDescriptor
                    image={newPrinter}
                    title={HOW_IT_WORKS_ITEM_A_TITLE}
                    body={HOW_IT_WORKS_ITEM_A_BODY}
                />
                <Separator />
                <ItemDescriptor
                    image={pickPrintPlan}
                    title={HOW_IT_WORKS_ITEM_B_TITLE}
                    body={HOW_IT_WORKS_ITEM_B_BODY}
                />
                <Separator />
                <ItemDescriptor
                    image={easySignup}
                    title={HOW_IT_WORKS_ITEM_D_TITLE}
                    body={HOW_IT_WORKS_ITEM_D_BODY}
                />
                <Separator />
                <ItemDescriptor
                    image={cancelAnytime}
                    title={HOW_IT_WORKS_ITEM_C_TITLE}
                    body={HOW_IT_WORKS_ITEM_C_BODY}
                />
            </ul>

            <RedirectButton
                redirectTo={Paths.PrinterSelection}
                action={TrackType.CTA}
                from={TrackLocation.Hero}
                className={styles.button}
            >
                {GET_STARTED_FROM}
            </RedirectButton>
        </section>
    );
}
export default HowItWorks;
