import { NavigationBar } from 'components';

import {
    AutomaticRenewalNotice,
    BillingModal,
    CardContainer,
    Footer,
    Header,
    PrinterNotAvailableModal,
    ShippingModal,
    ShopComparison,
    SurveyModal,
} from './components';

import styles from './styles.module.scss';

interface Props {
    isUpgrade?: boolean;
}

function Checkout({ isUpgrade = false }: Props) {
    return (
        <>
            {isUpgrade ? null : <SurveyModal />}
            {isUpgrade ? null : <BillingModal />}

            <AutomaticRenewalNotice />
            <PrinterNotAvailableModal />

            <ShippingModal isUpgrade={isUpgrade} />

            <div className={styles.container}>
                <NavigationBar variant="black" />

                <div className={styles.mainContainer}>
                    <Header isUpgrade={isUpgrade} />
                    <CardContainer isUpgrade={isUpgrade} />

                    {isUpgrade ? null : <ShopComparison />}

                    <Footer isUpgrade={isUpgrade} />
                </div>
            </div>
        </>
    );
}

export default Checkout;
