import { useFormat } from 'hooks';
import useRetailCheck from 'hooks/useRetailCheck';
import {
    CheckoutMessages,
    checkoutMessagesValues,
} from 'lang/messages/checkout';
import { CommonMessages, commonMessagesValues } from 'lang/messages/common';

interface Props {
    isUpgrade: boolean;
}

function useShippingTranslation({ isUpgrade }: Props) {
    const { isRetail } = useRetailCheck();

    const shippingText = useFormat(
        CheckoutMessages,
        checkoutMessagesValues({})
    );
    const { SAVE } = useFormat(CommonMessages, commonMessagesValues({}));

    let subtitle = shippingText.CARD_SHIPPING_FORM_SUBTITLE;

    if (isRetail) {
        subtitle = shippingText.CARD_SHIPPING_RETAIL_FORM_SUBTITLE;
    }

    if (isUpgrade) {
        subtitle = shippingText.CARD_SHIPPING_FORM_SUBTITLE_UPGRADE;
    }

    return {
        modalTitle: isRetail
            ? shippingText.CARD_SHIPPING_FORM_RETAIL_TITLE
            : shippingText.CARD_SHIPPING_FORM_TITLE,
        modalSubtitle: subtitle,
        save: SAVE,
        ...shippingText,
    };
}
export default useShippingTranslation;
