import { LOCALES } from 'lang/locales';

export enum ValuesMessages {
    TITLE_ONE = 'values.title1',
    DESCRIPTION_ONE = 'values.description1',
    DESCRIPTION_ONE_INK = 'values.description1.ink',
    TITLE_TWO = 'values.title2',
    DESCRIPTION_TWO = 'values.description2',
    DESCRIPTION_TWO_ACTIVATION = 'values.activationDescription2',
    TITLE_THREE = 'values.title3',
    DESCRIPTION_THREE = 'values.description3',
}

export const valueMessages = {
    [LOCALES.ENGLISH]: {
        [ValuesMessages.TITLE_ONE]: 'Simplest way to print',
        [ValuesMessages.TITLE_TWO]: 'Change plans any time',
        [ValuesMessages.TITLE_THREE]: 'Here when you need us',
        [ValuesMessages.DESCRIPTION_ONE]:
            'Everything you need - when you need it. Get a new printer, plus ink and paper delivered to your door before you run out, for one low monthly payment.',
        [ValuesMessages.DESCRIPTION_ONE_INK]:
            'Everything you need - when you need it. Get a new printer, plus ink and paper delivered to your door before you run out, for one low monthly payment.',
        [ValuesMessages.DESCRIPTION_TWO]:
            "Create a plan that's right for you. Choose from light to business printing plans - and easily adjust your plan if your needs change",
        [ValuesMessages.DESCRIPTION_TWO_ACTIVATION]:
            "Create a plan that's right for you.  Choose from light to business printing plans - and easily adjust your plan if your needs change. Cancel any time pay no cancelation fees as long as you return the printer.",
        [ValuesMessages.DESCRIPTION_THREE]:
            'Rely on around-the-clock customer support and next-business-day printer replacement - when it matters most.',
    },
    [LOCALES.SPANISH]: {
        [ValuesMessages.TITLE_ONE]: 'La manera más cómoda\nde imprimir',
        [ValuesMessages.TITLE_TWO]: 'Cambia de plan\ncuando lo necesites',
        [ValuesMessages.TITLE_THREE]: 'Estamos aquí cuando\nnos necesites',
        [ValuesMessages.DESCRIPTION_ONE]:
            'Todo lo que necesitas, cuando lo necesitas. Disfruta de una nueva\nimpresora HP y recibe automáticamente tus cartuchos en casa antes de\nque se agoten, todo en una única cuota mensual y a un precio muy bajo.',
        [ValuesMessages.DESCRIPTION_ONE_INK]:
            'Todo lo que necesitas, cuando lo necesitas. Disfruta de una nueva\nimpresora HP y recibe automáticamente tu tinta en casa antes de que\nse agote, todo en una única cuota mensual y a un precio muy bajo.',
        [ValuesMessages.DESCRIPTION_TWO]:
            'Elige el plan perfecto para ti - desde esporádico a profesional - y\najústalo fácilmente si tus necesidades de impresión cambian.',
        [ValuesMessages.DESCRIPTION_TWO_ACTIVATION]:
            'Cree un plan que sea adecuado para usted. Elija entre planes de impresión livianos y comerciales, y ajuste fácilmente su plan si sus necesidades cambian. Cancele en cualquier momento sin pagar tarifas de cancelación siempre y cuando devuelva la impresora. ',
        [ValuesMessages.DESCRIPTION_THREE]:
            'Confía en nuestro servicio de atención al cliente personalizado y\ndisfruta de una garantía ilimitada mientras esté activa tu\nsuscripción. En caso de necesidad, te reemplazamos la\nimpresora al siguiente día laborable.',
    },
};
