import {
    createContext,
    ReactNode,
    useCallback,
    useContext,
    useEffect,
    useMemo,
} from 'react';
import { useCookies } from 'react-cookie';
import { CONTEXT_ERROR } from 'contexts/PrinterChoice/constants';
import { useQuery } from 'hooks';
import useLocalStorage from 'hooks/useLocalStorage';
import { Nullable } from 'types/globals';

import { QueryParams } from './constants';

export interface IUtmStorage {
    utmParams: Partial<Record<string, Nullable<string>>>;
}

const INITIAL_CONTEXT_VALUE = {
    utmParams: {
        utm_source: null,
        utm_medium: null,
        utm_campaign: null,
        utm_content: null,
    },
};

const UtmStorageContext = createContext<IUtmStorage>(INITIAL_CONTEXT_VALUE);

interface Props {
    children: ReactNode;
}

export default function UtmStorageProvider({ children }: Props) {
    const { utmCampaign, utmContent, utmMedium, utmSource } =
        useQuery<QueryParams>();

    const [storedUtmParams, setStoredUtmParams] = useLocalStorage<IUtmStorage>(
        'utm',
        INITIAL_CONTEXT_VALUE
    );

    const [, setUtmCookies] = useCookies([
        'utm_source',
        'utm_medium',
        'utm_campaign',
        'utm_content',
    ]);

    const prevQueryState = JSON.stringify({
        utmSource,
        utmMedium,
        utmCampaign,
        utmContent,
    });
    const prevLocalStorageState = JSON.stringify(storedUtmParams.utmParams);

    if (
        prevQueryState !== prevLocalStorageState &&
        !window.location.pathname.includes('/auth')
    ) {
        setStoredUtmParams({
            utmParams: { utmSource, utmMedium, utmCampaign, utmContent },
        });
    }

    const writeCookies = useCallback(() => {
        setUtmCookies('utm_campaign', utmCampaign);
        setUtmCookies('utm_content', utmContent);
        setUtmCookies('utm_source', utmSource);
        setUtmCookies('utm_medium', utmMedium);
    }, [setUtmCookies, utmCampaign, utmContent, utmMedium, utmSource]);

    useEffect(() => {
        writeCookies();
    }, [writeCookies]);

    const value = useMemo(
        () => ({
            utmParams: {
                utmSource,
                utmMedium,
                utmCampaign,
                utmContent,
            },
        }),
        [utmCampaign, utmContent, utmMedium, utmSource]
    );

    return (
        <UtmStorageContext.Provider value={value}>
            {children}
        </UtmStorageContext.Provider>
    );
}

export const useUtmStorage = () => {
    const ctx = useContext(UtmStorageContext);

    if (!ctx) {
        throw new Error(CONTEXT_ERROR);
    }
    return ctx;
};
