import useUpgradeFetch from 'hooks/useUpgradeFetch';
import { PilotIdInfo } from 'services/pilotIdInfo';

export const TEST_ACCOUNTS = [
    {
        account_identifier: 'TEST_4_E',
        eligible_for_printer_upgrade: '2023-11-10',
        subscription_id: 38736,
        pilot_id: 4,
        pilot: 'ii_platinum_envy',
        old_serial: 'TEST_TCM25P',
        old_make_n_model: 'HP ENVY 6055e All-in-One Printer',
    },
    {
        account_identifier: 'TEST_5_E',
        eligible_for_printer_upgrade: '2023-11-10',
        subscription_id: 38736,
        pilot_id: 5,
        pilot: 'ii_platinum_envy',
        old_serial: 'TEST_TCM25P',
        old_make_n_model: 'HP ENVY 6055e All-in-One Printer',
    },
    {
        account_identifier: 'TEST_6_E',
        eligible_for_printer_upgrade: '2023-11-10',
        subscription_id: 38736,
        pilot_id: 6,
        pilot: 'ii_platinum_envy',
        old_serial: 'TEST_TCM25P',
        old_make_n_model: 'HP ENVY 6055e All-in-One Printer',
    },
    {
        account_identifier: 'TEST_7_E',
        eligible_for_printer_upgrade: '2023-11-10',
        subscription_id: 38736,
        pilot_id: 7,
        pilot: 'ii_platinum_envy',
        old_serial: 'TEST_TCM25P',
        old_make_n_model: 'HP ENVY 6055e All-in-One Printer',
    },
    {
        account_identifier: 'TEST_8_E',
        eligible_for_printer_upgrade: '2023-11-10',
        subscription_id: 38736,
        pilot_id: 8,
        pilot: 'ii_platinum_envy',
        old_serial: 'TEST_TCM25P',
        old_make_n_model: 'HP ENVY 6055e All-in-One Printer',
    },
    {
        account_identifier: 'TEST_15_E',
        eligible_for_printer_upgrade: '2023-11-10',
        subscription_id: 38736,
        pilot_id: 15,
        pilot: 'ii_platinum_envy',
        old_serial: 'TEST_TCM25P',
        old_make_n_model: 'HP ENVY 6055e All-in-One Printer',
    },
    {
        account_identifier: 'TEST_16_E',
        eligible_for_printer_upgrade: '2023-11-10',
        subscription_id: 38736,
        pilot_id: 16,
        pilot: 'ii_platinum_envy',
        old_serial: 'TEST_TCM25P',
        old_make_n_model: 'HP ENVY 6055e All-in-One Printer',
    },
    {
        account_identifier: 'TEST_17_E',
        eligible_for_printer_upgrade: '2023-11-10',
        subscription_id: 38736,
        pilot_id: 17,
        pilot: 'ii_platinum_envy',
        old_serial: 'TEST_TCM25P',
        old_make_n_model: 'HP ENVY 6055e All-in-One Printer',
    },
    {
        account_identifier: 'TEST_18_E',
        eligible_for_printer_upgrade: '2023-11-10',
        subscription_id: 38736,
        pilot_id: 18,
        pilot: 'ii_platinum_envy',
        old_serial: 'TEST_TCM25P',
        old_make_n_model: 'HP ENVY 6055e All-in-One Printer',
    },
    {
        account_identifier: 'TEST_19_E',
        eligible_for_printer_upgrade: '2023-11-10',
        subscription_id: 38736,
        pilot_id: 19,
        pilot: 'ii_platinum_envy',
        old_serial: 'TEST_TCM25P',
        old_make_n_model: 'HP ENVY 6055e All-in-One Printer',
    },
    {
        account_identifier: 'TEST_20_E',
        eligible_for_printer_upgrade: '2023-11-10',
        subscription_id: 38736,
        pilot_id: 20,
        pilot: 'ii_platinum_envy',
        old_serial: 'TEST_TCM25P',
        old_make_n_model: 'HP ENVY 6055e All-in-One Printer',
    },
    {
        account_identifier: 'TEST_864050deb575db0c33f020447d6',
        eligible_for_printer_upgrade: '2044-11-10',
        subscription_id: 5896,
        pilot_id: 7,
        pilot: 'ii_platinum_ojp',
        old_serial: 'TEST_832Z0PZ',
        old_make_n_model: 'HP OfficeJet Pro 9015e All-in-One Printer',
    },
];

type UseUpgradeFetchType = ReturnType<typeof useUpgradeFetch>;

export const mockedData: PilotIdInfo = {
    isReducePrice: false,
    name: 'Test acc',
    pilotID: 15,
    price: 20.48,
    printerPrices: { essential: 1, professional: 2, versatile: 3 },
};

export const mockedUpgradeFetchResult: UseUpgradeFetchType = {
    data: mockedData,
    elegibilityCheckData: {
        hasUpgraded: false,
        isEligible: true,
    },
    elegibilityCheckError: false,
    elegibilityCheckLoading: false,
    error: null,
    isLoading: false,
};

export const mockedUpgradeFetchLoading: UseUpgradeFetchType = {
    data: null,
    elegibilityCheckData: null,
    elegibilityCheckError: false,
    elegibilityCheckLoading: true,
    error: null,
    isLoading: true,
};
