import check from 'assets/green_check_outlined.svg';
import instantInkLogo from 'assets/instantink_logo.svg';
import { usePrinterChoice } from 'contexts/PrinterChoice';
import { useFormat } from 'hooks';
import { UpgradePurchaseCompleteMessages } from 'lang/messages/upgradePurchaseComplete';
import { getProductImages } from 'screens/PurchaseComplete/utils';

import Card from './components/Card';
import { displayInfoCards } from './utils';

import styles from './styles.module.scss';

function UpgradePurchaseComplete() {
    const translations = useFormat(UpgradePurchaseCompleteMessages);
    const { userChoice } = usePrinterChoice();

    return (
        <div className={styles.container}>
            <nav className={styles.nav}>
                <img
                    src={instantInkLogo}
                    alt="Go back"
                    className={styles.instantInkLogo}
                />
            </nav>

            <header className={styles.header}>
                <img
                    src={
                        getProductImages(userChoice.printer?.productName)
                            .printerImage
                    }
                    alt=""
                    className={styles.printerImage}
                />
                <div>
                    <div className={styles.headerTitleContainer}>
                        <img src={check} alt="" />
                        <h1>{translations.TITLE}</h1>
                    </div>
                    <p>{translations.BODY}</p>
                </div>
            </header>

            <main className={styles.main}>
                <h2>{translations.NEXT_STEPS_TITLE}</h2>
                <p>{translations.NEXT_STEPS_SUBTITLE}</p>
                <ul>
                    {displayInfoCards(translations).map((card) => (
                        <Card {...card} />
                    ))}
                </ul>
            </main>
        </div>
    );
}
export default UpgradePurchaseComplete;
