import styles from './Header.module.scss';

interface Props {
    title?: string;
    subtitle?: string;
}

function Header({ title = undefined, subtitle = undefined }: Props) {
    return (
        <header className={styles.headerContainer}>
            {title && <h1 className={styles.title}>{title}</h1>}
            {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
        </header>
    );
}

export default Header;
