import { useCallback, useEffect, useRef, useState } from 'react';
import { QueryProps } from 'components/ScrollToTop/constants';
import { TrackLocation } from 'constants/analytics';
import { usePrinterChoice } from 'contexts/PrinterChoice';
import { NewInkPlan, Printer } from 'contexts/PrinterChoice/constants';
import { useBreakpoint, useQuery, useTrackPage, useVariation } from 'hooks';
import { Nullable } from 'types/globals';

import { POSSIBLE_PRINTERS } from './constants';
import {
    backgroundImageSelector,
    calculateTotalPrice,
    filterItems,
} from './utils';

function usePrinterSelection() {
    const {
        userChoice,
        data: { newInkPlans, printers },
        handleChangePreferences,
    } = usePrinterChoice();

    const trackPage = useTrackPage();

    const [screenPosition, setScreenPosition] = useState<Nullable<number>>(1);
    const { isSayanReplacing, isControl, variation } = useVariation();
    const { printer: printerQuery } = useQuery<QueryProps>();
    const breakPoint = useBreakpoint();
    const isMobile = breakPoint === 'xs';
    const { printer, inkPlan, isPrinterSelected, isInkPlanSelected } =
        userChoice;

    const sections = useRef<Array<null | HTMLDivElement>>([]);

    const handleScrollToSection = useCallback(
        (pageToScroll?: number, behavior?: ScrollBehavior) => {
            // Does the next blade exist?
            if (!pageToScroll || !sections.current[pageToScroll]) return;

            // Was printed and inkPlan already selected?
            if (isPrinterSelected && isInkPlanSelected) return;

            // Scroll to the next blade
            sections.current[pageToScroll]?.scrollIntoView({
                behavior,
                block:
                    screenPosition === 2 || screenPosition === 0
                        ? 'start'
                        : 'center',
            });

            // Save it to state
            setScreenPosition(pageToScroll || 1);
        },
        [isInkPlanSelected, isPrinterSelected, screenPosition]
    );

    const changePreference = useCallback(
        (pageNumber: number) =>
            (
                type: 'printer' | 'inkPlan',
                choice: boolean | Printer | NewInkPlan,
                behavior: ScrollBehavior = 'smooth'
            ) => {
                handleChangePreferences(type, choice);
                handleScrollToSection(pageNumber, behavior);
            },
        [handleChangePreferences, handleScrollToSection]
    );

    useEffect(() => {
        trackPage(TrackLocation.PrinterSelection);

        if (
            printerQuery &&
            POSSIBLE_PRINTERS.indexOf(printerQuery) !== -1 &&
            printers.length !== 0
        ) {
            // If the user is in the control group and the printer is highVolume, do not change the printer
            if (isControl && printerQuery === 'highVolume') {
                return;
            }

            // If the user is in the sayanReplacing group and the printer is professional, do not change the printer
            if (isSayanReplacing && printerQuery === 'professional') {
                return;
            }

            changePreference(1)(
                'printer',
                printers[POSSIBLE_PRINTERS.indexOf(printerQuery)],
                'auto'
            );
        }
    }, [
        changePreference,
        printers,
        trackPage,
        printerQuery,
        isSayanReplacing,
        isControl,
    ]);

    // Depending on the selected printer, use a different background
    const backgroundStyles = {
        backgroundImage: `url(${backgroundImageSelector(
            printer?.productName,
            isMobile
        )})`,
    };

    // Props for the different sections
    const chooseYourPrinter = {
        printerData: printers,
        userChoice: printer,
        isUserSelection: isPrinterSelected,
        setUserChoice: changePreference(1),
    };

    const howManyPages = {
        data: filterItems(
            newInkPlans,
            printer?.disallowedInkPlans || [],
            variation
        ),
        userChoice: inkPlan,
        selectedPrinterId: printer?.id,
        popularInkPlan: printer?.popularInkPlan,
        setUserChoice: changePreference(2),
    };

    const orderYourPlan = {
        printer,
        inkPlan,
    };

    const summary = {
        totalPrice: calculateTotalPrice(inkPlan, printer?.id),
        printerId: printer?.id,
        inkPlanId: inkPlan?.id,
        isPrinterSelected,
        isInkPlanSelected,
    };

    return {
        backgroundStyles,
        refPosition:
            (position: number) => (element: Nullable<HTMLDivElement>) => {
                sections.current[position] = element;
            },
        chooseYourPrinter,
        howManyPages,
        orderYourPlan,
        summary,
    };
}
export default usePrinterSelection;
