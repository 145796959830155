import { useFormat } from 'hooks/index';
import { TermsOfServicesMessages } from 'lang/messages/terms';

import styles from './Addendum.module.scss';

function Addendum() {
    const { ADDENDUM_A_TITLE, ADDENDUM_A_CONTENT } = useFormat(
        TermsOfServicesMessages
    );

    return (
        <>
            <p>{ADDENDUM_A_TITLE}</p>
            <div className={styles.body}>{ADDENDUM_A_CONTENT}</div>
        </>
    );
}
export default Addendum;
