import {
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
} from '@stripe/react-stripe-js';

import { FormArray } from '../ShippingModal/constants';

export interface InputFields {
    ccNameOnCard: string;
    street: string;
    street2?: string;
    city: string;
    state: string;
    zip: string;
    useShippingAddress: boolean;
}

export const DEFAULT_VALUES = {
    ccNameOnCard: '',
    street: '',
    street2: '',
    city: '',
    state: '',
    zip: '',
    useShippingAddress: true,
};

export const FORM: (
    messages: Record<string, string>
) => FormArray<InputFields>[] = (messages) => [
    {
        id: 1,
        register: 'street',
        options: {
            required: {
                value: true,
                message: messages.CARD_COMMON_ADDRESS_ONE_ERROR,
            },
        },
        label: messages.CARD_COMMON_ADDRESS_ONE,
    },
    {
        id: 2,
        register: 'street2',
        label: messages.CARD_COMMON_ADDRESS_TWO,
        info: messages.CARD_COMMON_OPTIONAL,
    },
    {
        id: 3,
        register: 'city',
        options: {
            required: {
                value: true,
                message: messages.CARD_COMMON_CITY_ERROR,
            },
        },
        label: messages.CARD_COMMON_CITY,
    },
];

export const STRIPE_FORM = [
    {
        id: 0,
        stripeElement: CardNumberElement,
        error: 'cardNumber',
        showIcon: true,
    },
    {
        id: 1,
        stripeElement: CardExpiryElement,
        error: 'cardExpiry',
        showIcon: undefined,
        halfSized: true,
    },
    {
        id: 2,
        stripeElement: CardCvcElement,
        error: 'cardCvc',
        showIcon: undefined,
        halfSized: true,
    },
];
