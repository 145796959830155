export const vanityURLs = [
    '/sanchinarro',
    '/goya',
    '/pozuelo',
    '/diagonal',
    '/callao',
    '/vigo',
    '/independencia',
    '/campo-de-las-naciones',
    '/mendez-alvaro',
    '/el-corte-ingles',
];
