import {
    createContext,
    ReactNode,
    useCallback,
    useContext,
    useMemo,
    useState,
} from 'react';
import { useUtmStorage } from 'contexts/UTMStorage';
import { UpgradeQueryParams } from 'services/pilotIdInfo';
import { Callbacks, doPost } from 'utils/helper';

import { useTranslation } from './useTranslation/useTranslation';
import { ORDER_ERROR, PostOrder, UseOrderType } from './constants';
import useRetailCheck from './useRetailCheck';
import { buildOrderBody, buildUpgradeOrderBody, getRoute } from './utils';

export const OrderContext = createContext<UseOrderType | undefined>(undefined);

interface Props {
    children: ReactNode;
}

export function OrderProvider({ children }: Props) {
    const [submitting, setSubmitting] = useState(false);
    const [userEmail, setUserEmail] = useState('');
    const { utmParams } = useUtmStorage();
    const { currentLang } = useTranslation();
    const { isRetail } = useRetailCheck();

    const postUpgradeOrder = useCallback(
        async (
            upgradeInfo: Partial<UpgradeQueryParams>,
            data: Omit<PostOrder, 'billingInfo'>,
            callbacks: Callbacks<string>
        ) => {
            setSubmitting(true);
            setUserEmail(data.shippingInfo.email);
            if (upgradeInfo.accountIdentifier === 'TEST_16_A') {
                throw new Error(
                    'Test account identifier detected, wont proceed'
                );
            }

            const postBody = buildUpgradeOrderBody(
                upgradeInfo,
                data,
                utmParams
            );

            doPost(
                getRoute(true, currentLang, isRetail),
                postBody,
                callbacks
            ).finally(() => {
                setSubmitting(false);
            });
        },
        [utmParams, currentLang, isRetail]
    );

    const postOrder = useCallback(
        (data: PostOrder, callbacks: Callbacks<string>) => {
            setSubmitting(true);
            setUserEmail(data.shippingInfo.email);

            const postBody = buildOrderBody(data, utmParams);

            doPost(
                getRoute(false, currentLang, isRetail),
                postBody,
                callbacks
            ).finally(() => {
                setSubmitting(false);
            });
        },
        [currentLang, isRetail, utmParams]
    );

    const value = useMemo(
        () => ({
            postOrder,
            postUpgradeOrder,
            userEmail,
            submitting,
        }),
        [postOrder, postUpgradeOrder, submitting, userEmail]
    );

    return (
        <OrderContext.Provider value={value}>{children}</OrderContext.Provider>
    );
}

export const useOrder = () => {
    const ctx = useContext(OrderContext);

    if (!ctx) {
        throw new Error(ORDER_ERROR);
    }
    return ctx;
};
