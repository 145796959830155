import { FAQSectionMessages, FaqTypes } from 'lang/messages/faqSection';

export function FAQS(
    showActivation: boolean,
    isSpanish: boolean,
    faqsType: FaqTypes = FAQSectionMessages
) {
    const faqs = [
        {
            key: 0,
            title: faqsType.ABOUT_SUBSCRIPTION_TITLE,
            content: [
                {
                    key: 0,
                    question: faqsType.ABOUT_SUBSCRIPTION_FAQ0_QUESTION,
                    answer: faqsType.ABOUT_SUBSCRIPTION_FAQ0_ANSWER,
                },
                {
                    key: 1,
                    question: faqsType.ABOUT_SUBSCRIPTION_FAQ1_QUESTION,
                    answer: faqsType.ABOUT_SUBSCRIPTION_FAQ1_ANSWER,
                },
                {
                    key: 2,
                    question: faqsType.ABOUT_SUBSCRIPTION_FAQ2_QUESTION,
                    answer: faqsType.ABOUT_SUBSCRIPTION_FAQ2_ANSWER,
                },
                {
                    key: 3,
                    question: faqsType.ABOUT_SUBSCRIPTION_FAQ3_QUESTION,
                    answer: faqsType.ABOUT_SUBSCRIPTION_FAQ3_ANSWER,
                },
                {
                    key: 4,
                    question: faqsType.ABOUT_SUBSCRIPTION_FAQ4_QUESTION,
                    answer: showActivation
                        ? faqsType.ABOUT_SUBSCRIPTION_FAQ4_ANSWER_ACTIVATION
                        : faqsType.ABOUT_SUBSCRIPTION_FAQ4_ANSWER,
                },
                {
                    key: 5,
                    question: faqsType.ABOUT_SUBSCRIPTION_FAQ5_QUESTION,
                    answer: faqsType.ABOUT_SUBSCRIPTION_FAQ5_ANSWER,
                },
                {
                    key: 6,
                    question: faqsType.ABOUT_SUBSCRIPTION_FAQ6_QUESTION,
                    answer: faqsType.ABOUT_SUBSCRIPTION_FAQ6_ANSWER,
                },
                {
                    key: 7,
                    question: faqsType.ABOUT_SUBSCRIPTION_FAQ7_QUESTION,
                    answer: faqsType.ABOUT_SUBSCRIPTION_FAQ7_ANSWER,
                },
                {
                    key: 8,
                    question: faqsType.ABOUT_SUBSCRIPTION_FAQ8_QUESTION,
                    answer: faqsType.ABOUT_SUBSCRIPTION_FAQ8_ANSWER,
                },
                {
                    key: 9,
                    question: faqsType.ABOUT_SUBSCRIPTION_FAQ9_QUESTION,
                    answer: faqsType.ABOUT_SUBSCRIPTION_FAQ9_ANSWER,
                },
                {
                    key: 10,
                    question: faqsType.ABOUT_SUBSCRIPTION_FAQ10_QUESTION,
                    answer: faqsType.ABOUT_SUBSCRIPTION_FAQ10_ANSWER,
                },
            ],
        },
        {
            key: 1,
            title: faqsType.ABOUT_SERVICE_TITLE,
            content: [
                {
                    key: 0,
                    question: faqsType.ABOUT_SERVICE_FAQ0_QUESTION,
                    answer: faqsType.ABOUT_SERVICE_FAQ0_ANSWER,
                },
                {
                    key: 1,
                    question: faqsType.ABOUT_SERVICE_FAQ1_QUESTION,
                    answer: faqsType.ABOUT_SERVICE_FAQ1_ANSWER,
                },
                {
                    key: 2,
                    question: faqsType.ABOUT_SERVICE_FAQ2_QUESTION,
                    answer: faqsType.ABOUT_SERVICE_FAQ2_ANSWER,
                },
                {
                    key: 3,
                    question: faqsType.ABOUT_SERVICE_FAQ3_QUESTION,
                    answer: faqsType.ABOUT_SERVICE_FAQ3_ANSWER,
                },
                {
                    key: 4,
                    question: faqsType.ABOUT_SERVICE_FAQ4_QUESTION,
                    answer: faqsType.ABOUT_SERVICE_FAQ4_ANSWER,
                },
                {
                    key: 5,
                    question: faqsType.ABOUT_SERVICE_FAQ5_QUESTION,
                    answer: faqsType.ABOUT_SERVICE_FAQ5_ANSWER,
                },
                {
                    key: 6,
                    question: faqsType.ABOUT_SERVICE_FAQ6_QUESTION,
                    answer: faqsType.ABOUT_SERVICE_FAQ6_ANSWER,
                },
                {
                    key: 7,
                    question: faqsType.ABOUT_SERVICE_FAQ7_QUESTION,
                    answer: faqsType.ABOUT_SERVICE_FAQ7_ANSWER,
                },
                {
                    key: 8,
                    question: faqsType.ABOUT_SERVICE_FAQ8_QUESTION,
                    answer: faqsType.ABOUT_SERVICE_FAQ8_ANSWER,
                },
            ],
        },
        isSpanish
            ? {
                  key: 2,
                  title: faqsType.ABOUT_PRINTER_TITLE,
                  content: [
                      {
                          key: 0,
                          question: faqsType.ABOUT_PRINTER_FAQ0_QUESTION,
                          answer: faqsType.ABOUT_PRINTER_FAQ0_ANSWER,
                      },
                      {
                          key: 2,
                          question: showActivation
                              ? faqsType.ABOUT_PRINTER_FAQ2_QUESTION_ACTIVATION
                              : faqsType.ABOUT_PRINTER_FAQ2_QUESTION,
                          answer: showActivation
                              ? faqsType.ABOUT_PRINTER_FAQ2_ANSWER_ACTIVATION
                              : faqsType.ABOUT_PRINTER_FAQ2_ANSWER,
                      },
                      {
                          key: 3,
                          question: faqsType.ABOUT_PRINTER_FAQ3_QUESTION,
                          answer: faqsType.ABOUT_PRINTER_FAQ3_ANSWER,
                      },
                      {
                          key: 4,
                          question: faqsType.ABOUT_PRINTER_FAQ4_QUESTION,
                          answer: faqsType.ABOUT_PRINTER_FAQ4_ANSWER,
                      },
                  ],
              }
            : {
                  key: 2,
                  title: faqsType.ABOUT_PRINTER_TITLE,
                  content: [
                      {
                          key: 0,
                          question: faqsType.ABOUT_PRINTER_FAQ0_QUESTION,
                          answer: faqsType.ABOUT_PRINTER_FAQ0_ANSWER,
                      },
                      {
                          key: 1,
                          question: showActivation
                              ? faqsType.ABOUT_PRINTER_FAQ1_QUESTION_ACTIVATION
                              : faqsType.ABOUT_PRINTER_FAQ1_QUESTION,
                          answer: showActivation
                              ? faqsType.ABOUT_PRINTER_FAQ1_ANSWER_ACTIVATION
                              : faqsType.ABOUT_PRINTER_FAQ1_ANSWER,
                      },
                      {
                          key: 2,
                          question: showActivation
                              ? faqsType.ABOUT_PRINTER_FAQ2_QUESTION_ACTIVATION
                              : faqsType.ABOUT_PRINTER_FAQ2_QUESTION,
                          answer: showActivation
                              ? faqsType.ABOUT_PRINTER_FAQ2_ANSWER_ACTIVATION
                              : faqsType.ABOUT_PRINTER_FAQ2_ANSWER,
                      },
                      {
                          key: 3,
                          question: faqsType.ABOUT_PRINTER_FAQ3_QUESTION,
                          answer: faqsType.ABOUT_PRINTER_FAQ3_ANSWER,
                      },
                      {
                          key: 4,
                          question: faqsType.ABOUT_PRINTER_FAQ4_QUESTION,
                          answer: faqsType.ABOUT_PRINTER_FAQ4_ANSWER,
                      },
                  ],
              },
        {
            key: 3,
            title: faqsType.GETTING_STARTED_TITLE,
            content: [
                {
                    key: 0,
                    question: faqsType.GETTING_STARTED_FAQ0_QUESTION,
                    answer: faqsType.GETTING_STARTED_FAQ0_ANSWER,
                },
                {
                    key: 1,
                    question: showActivation
                        ? faqsType.GETTING_STARTED_FAQ1_QUESTION_ACTIVATION
                        : faqsType.GETTING_STARTED_FAQ1_QUESTION,
                    answer: showActivation
                        ? faqsType.GETTING_STARTED_FAQ1_ANSWER_ACTIVATION
                        : faqsType.GETTING_STARTED_FAQ1_ANSWER,
                },
                {
                    key: 2,
                    question: showActivation
                        ? faqsType.GETTING_STARTED_FAQ2_QUESTION_ACTIVATION
                        : faqsType.GETTING_STARTED_FAQ2_QUESTION,
                    answer: showActivation
                        ? faqsType.GETTING_STARTED_FAQ2_ANSWER_ACTIVATION
                        : faqsType.GETTING_STARTED_FAQ2_ANSWER,
                },
                {
                    key: 3,
                    question: showActivation
                        ? faqsType.GETTING_STARTED_FAQ3_QUESTION_ACTIVATION
                        : faqsType.GETTING_STARTED_FAQ3_QUESTION,
                    answer: showActivation
                        ? faqsType.GETTING_STARTED_FAQ3_ANSWER_ACTIVATION
                        : faqsType.GETTING_STARTED_FAQ3_ANSWER,
                },
                {
                    key: 4,
                    question: showActivation
                        ? faqsType.GETTING_STARTED_FAQ4_QUESTION_ACTIVATION
                        : faqsType.GETTING_STARTED_FAQ4_QUESTION,
                    answer: showActivation
                        ? faqsType.GETTING_STARTED_FAQ4_ANSWER_ACTIVATION
                        : faqsType.GETTING_STARTED_FAQ4_ANSWER,
                },
                {
                    key: 5,
                    question: showActivation
                        ? faqsType.GETTING_STARTED_FAQ5_QUESTION_ACTIVATION
                        : faqsType.GETTING_STARTED_FAQ5_QUESTION,
                    answer: showActivation
                        ? faqsType.GETTING_STARTED_FAQ5_ANSWER_ACTIVATION
                        : faqsType.GETTING_STARTED_FAQ5_ANSWER,
                },
                {
                    key: 6,
                    question: showActivation
                        ? faqsType.GETTING_STARTED_FAQ6_QUESTION_ACTIVATION
                        : faqsType.GETTING_STARTED_FAQ6_QUESTION,
                    answer: showActivation
                        ? faqsType.GETTING_STARTED_FAQ6_ANSWER_ACTIVATION
                        : faqsType.GETTING_STARTED_FAQ6_ANSWER,
                },
                {
                    key: 7,
                    question: showActivation
                        ? faqsType.GETTING_STARTED_FAQ7_QUESTION
                        : faqsType.GETTING_STARTED_FAQ7_QUESTION,
                    answer: showActivation
                        ? faqsType.GETTING_STARTED_FAQ7_ANSWER
                        : faqsType.GETTING_STARTED_FAQ7_ANSWER,
                },
            ],
        },
    ];

    if (showActivation) {
        faqs[3].content.push(
            {
                key: 8,
                question: faqsType.GETTING_STARTED_FAQ8_QUESTION_ACTIVATION,
                answer: faqsType.GETTING_STARTED_FAQ8_ANSWER_ACTIVATION,
            },
            {
                key: 9,
                question: faqsType.GETTING_STARTED_FAQ9_QUESTION_ACTIVATION,
                answer: faqsType.GETTING_STARTED_FAQ9_ANSWER_ACTIVATION,
            }
        );
    }

    return faqs;
}
