import { forwardRef, ReactNode, useRef } from 'react';
import { useTrackLink } from 'hooks';

import styles from './Accordeon.module.scss';

interface Props {
    id: string;
    title: string;
    children: ReactNode;
}

const Accordeon = forwardRef<HTMLInputElement, Props>(function Accordeon(
    { id, title, children },
    ref
) {
    const contentRef = useRef<HTMLDivElement>(null);
    const trackLink = useTrackLink();

    const handleClick = () => {
        contentRef.current?.scrollTo({ left: 0 });
        trackLink('Toggle "Compare ways to print" accordeon', 'checkout');

        setTimeout(() => {
            window.scrollTo({
                top: contentRef.current?.offsetTop,
                behavior: 'smooth',
            });
        }, 200);
    };

    return (
        <section className={styles.container}>
            <div className={styles.tab}>
                <input
                    type="checkbox"
                    onClick={handleClick}
                    id={id}
                    className={styles.input}
                    ref={ref}
                />
                <label className={styles.tabLabel} htmlFor={id}>
                    {title}
                </label>
                <div ref={contentRef} className={styles.tabContent}>
                    {children}
                </div>
            </div>
        </section>
    );
});

export default Accordeon;
