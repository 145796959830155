import { forwardRef } from 'react';
import { useDataProvider } from 'contexts/DataProvider';
import { NewInkPlan, Printer } from 'contexts/PrinterChoice/constants';
import { useFormat } from 'hooks';
import {
    OrderYourPlanMessages,
    orderYourPlanValues,
} from 'lang/messages/orderYourPlan';
import { CommonRef } from 'types/globals';

import { printerImage } from './utils';
import Container from '../Container/Container';

import styles from './OrderYourPlan.module.scss';

interface Props {
    printer?: Printer;
    inkPlan?: NewInkPlan;
}

function OrderYourPlan(
    { printer = undefined, inkPlan = undefined }: Props,
    ref: CommonRef
) {
    const { TITLE, SUBTITLE, ACTIVATION_SUBTITLE, PLAN } = useFormat(
        OrderYourPlanMessages,
        orderYourPlanValues({ pages: inkPlan?.pages })
    );
    const { showActivation } = useDataProvider();

    const subTitleId = showActivation ? ACTIVATION_SUBTITLE : SUBTITLE;

    return (
        <Container mainImage={printerImage(printer?.productName)} ref={ref}>
            <h2 className={styles.title} title="order-your-plan">
                {TITLE}
            </h2>
            <p className={styles.subtitle} title="main-subtitle">
                {subTitleId}
            </p>

            <div className={styles.summary}>
                <div className={styles.topSection}>
                    <h2
                        className={styles.planType}
                        title={`printer-${printer?.productName}`}
                    >
                        {printer?.productName}
                    </h2>
                    <h3 className={styles.printerModel}>{printer?.name}</h3>
                </div>
                <p className={styles.pagesPerMonth}>{PLAN}</p>
            </div>
        </Container>
    );
}

export default forwardRef(OrderYourPlan);
