import { Token } from '@stripe/stripe-js';
import { Nullable } from 'types/globals';

interface OobeCardInfo {
    exp_month: string;
    exp_year: string;
    last4: string;
    name: string;
}

interface PayPal {
    email: string;
    env: string;
    locale: string;
}

export type BillingInfo = {
    paymentType?: string;
    ccNameOnCard: string;
    street: string;
    street2?: string;
    city: string;
    state: string;
    zip: string;
    useShippingAddress: boolean;
    oobeCardInfo?: OobeCardInfo;
    token?: Token;
    paypal?: PayPal;
};

export type ShippingInfo = {
    firstName: string;
    lastName: string;
    company?: string;
    address1: string;
    address2?: string;
    city: string;
    state: string;
    zip: string;
    phone: string;
    email: string;
    recieveMobileAlerts: boolean;
    printerSerialNumber?: string;
};

export const mockShipping: ShippingInfo = {
    firstName: 'first name',
    lastName: 'last name',
    company: 'company',
    address1: 'addre 1',
    address2: 'stre add 2',
    city: 'city',
    state: 'AZ',
    zip: '12345',
    phone: '12345678',
    email: 'newguns@soad.com',
    recieveMobileAlerts: false,
};

export const mockBilling: BillingInfo = {
    ccNameOnCard: 'Name',
    street: 'asdaw',
    street2: 'asdwa',
    city: 'sdaw',
    state: 'FL',
    zip: '12456',
    useShippingAddress: true,
    token: {
        id: 'tok_1N5VJ6D9ipaeSbbduYmwiIF9',
        object: 'token',
        card: {
            id: 'card_1N5VJ6D9ipaeSbbdko5HcdTm',
            object: 'card',
            address_city: 'sdaw',
            address_country: 'US',
            address_line1: 'asdaw',
            address_line1_check: 'unchecked',
            address_line2: 'asdwa',
            address_state: 'FL',
            address_zip: '12456',
            address_zip_check: 'unchecked',
            brand: 'MasterCard',
            country: 'AR',
            cvc_check: 'unchecked',
            dynamic_last4: null,
            exp_month: 3,
            exp_year: 2024,
            funding: 'prepaid',
            last4: '6377',
            name: 'Name',
            tokenization_method: null,
            metadata: {},
        },
        client_ip: '186.141.225.5',
        created: 1683557096,
        livemode: true,
        type: 'card',
        used: false,
    },
};

export interface IUserInfo {
    shipping: Nullable<ShippingInfo>;
    billing: Nullable<BillingInfo>;
    shippingLoading: boolean;
    billingLoading: boolean;
    changeInfo: (
        whatToChange: 'shipping' | 'billing',
        info: Nullable<Partial<ShippingInfo>> | Nullable<Partial<BillingInfo>>
    ) => void;
}

export const INITIAL_STATE: IUserInfo = {
    shipping: null,
    billing: null,
    shippingLoading: false,
    billingLoading: false,
    changeInfo: () => {},
};

export interface BillingResponse {
    currency: string;
    customerId: number;
    orderId: string;
    ipAddress: string;
    customField: null;
    merchantName: string;
    paymentMethods: string[];
    paymentType: string;
    credentialState: null;
    newPaymentSwitcherStrings: boolean;
    pgsIframeUrl: string;
    creditCard: CreditCard;
    payPal: PayPal;
}

export interface CreditCard {
    cardTypeKey: string;
    cardNumber: string;
    displayExpirationDate: string;
    cardExpirationDate: string;
    iframeUrl: string;
}

export interface ShippingResponse {
    tenantClassification: string;
    supportMultiShipping: boolean;
    source: string;
    id: string;
    street1: string;
    street2: string;
    city: string;
    state: string;
    fullState: string;
    zipCode: string;
    countryCode: string;
    firstName: string;
    lastName: string;
    fullName: string;
    company: string;
    phoneNumber1: string;
    optedIn: boolean;
    isDefaultAddress: boolean;
    allowPoBox: boolean;
    rplState: string;
    rplRequestId: string;
    rplLastUpdated: string;
    supportedServices: string[];
}
