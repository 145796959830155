import carbonNeutralLogo from 'assets/carbon_neutral_logo.webp';
import classNames from 'classnames';
import { Paths } from 'components/RoutedContent/paths';
import { TrackLocation, TrackType } from 'constants/analytics';
import { commonValues } from 'constants/formatValues';
import { useFormat, useTranslation } from 'hooks';
import { CommonMessages } from 'lang/messages/common';
import { EnviromentMessages } from 'lang/messages/enviroment';

import RedirectButton from '../RedirectButton/RedirectButton';

import styles from './PrintResponsibly.module.scss';

function PrintResponsibly() {
    const { isSpain } = useTranslation();
    const { TITLE, CLIMATE, IMG_ALT } = useFormat(EnviromentMessages);
    const { GET_STARTED_FROM } = useFormat(
        CommonMessages,
        commonValues(isSpain())
    );

    return (
        <section className={styles.container}>
            <h1 className={styles.title}>{TITLE}</h1>

            <figure
                className={classNames(styles.infoContainer, {
                    [styles.carbonNeutralBorder]: isSpain(),
                })}
            >
                <p className={styles.content}>{CLIMATE}</p>
                <img
                    className={styles.image}
                    src={carbonNeutralLogo}
                    alt={IMG_ALT}
                />
            </figure>
            <RedirectButton
                action={TrackType.CTA}
                from={TrackLocation.Enviroment}
                redirectTo={Paths.PrinterSelection}
            >
                {GET_STARTED_FROM}
            </RedirectButton>
        </section>
    );
}
export default PrintResponsibly;
