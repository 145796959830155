import { useEffect } from 'react';
import InstantInkLogo from 'assets/instantink_logo_white.svg';
import { Button, LoadingScreen } from 'components';
import { Paths } from 'components/RoutedContent/paths';
import { TrackLocation } from 'constants/analytics';
import { commonValues } from 'constants/formatValues';
import { useFormat, useRedirect, useTrackPage } from 'hooks';
import useUpgradeFetch from 'hooks/useUpgradeFetch';
import { CommonMessages } from 'lang/messages/common';
import { UpgradeLandingMessages } from 'lang/messages/upgradeLanding';
import Footer from 'screens/LandingPage/components/Footer/Footer';

import ReducingWaste from './assets/reducingWaste.svg';
import AlreadyRedeemed from './components/AlreadyRedeemed';
import NotEligible from './components/NotEligible';
import Debug from '../Debug/Debug';

import styles from './styles.module.scss';

function LandingUpgrade() {
    const trackPage = useTrackPage();

    const {
        error,
        data,
        isLoading,
        elegibilityCheckError,
        elegibilityCheckData,
        elegibilityCheckLoading,
    } = useUpgradeFetch();

    const { CONTINUE } = useFormat(CommonMessages, commonValues());

    const { push } = useRedirect();

    const {
        SLIDE_ONE_TITLE,
        SLIDE_ONE_BODY,
        SLIDE_TWO_TITLE,
        SLIDE_TWO_BODY_NO_PRICE_DROP,
        SLIDE_TWO_BODY_PRICE_DROP,
    } = useFormat(UpgradeLandingMessages, {
        [UpgradeLandingMessages.SLIDE_TWO_BODY_PRICE_DROP]: {
            price: `$${data?.isReducePrice ? data.price?.toFixed(2) : 'x.xx'}`,
        },
    });

    const handleRedirect = () => {
        push(Paths.PrinterSelection);
    };

    useEffect(() => {
        trackPage(TrackLocation.UpgradeLanding);
    }, [trackPage]);

    const render = () => {
        if (isLoading || elegibilityCheckLoading) {
            return <LoadingScreen />;
        }

        if (elegibilityCheckData?.hasUpgraded) {
            return <AlreadyRedeemed />;
        }

        if (
            error ||
            elegibilityCheckError ||
            !elegibilityCheckData?.isEligible
        ) {
            return <NotEligible />;
        }

        return (
            <>
                <section className={styles.hero}>
                    <img
                        src={InstantInkLogo}
                        alt="Instant Ink Logo"
                        className={styles.instantInkLogo}
                    />
                    <div className={styles.content}>
                        <h1>{SLIDE_ONE_TITLE}</h1>
                        <p>{SLIDE_ONE_BODY}</p>
                        <Button
                            onClick={handleRedirect}
                            className={styles.upgradeButton}
                        >
                            {CONTINUE}
                        </Button>
                    </div>
                </section>
                <section className={styles.notReady}>
                    <div className={styles.leftContainer}>
                        <h2>{SLIDE_TWO_TITLE}</h2>
                        {data?.isReducePrice
                            ? SLIDE_TWO_BODY_PRICE_DROP
                            : SLIDE_TWO_BODY_NO_PRICE_DROP}
                    </div>
                    <div>
                        <img
                            src={ReducingWaste}
                            alt="Reducing Waste"
                            className={styles.stamp}
                        />
                    </div>
                </section>
                <Footer />
            </>
        );
    };

    return (
        <>
            <Debug />

            {render()}
        </>
    );
}
export default LandingUpgrade;
