import { useEffect } from 'react';
import { useTransition } from '@react-spring/web';

import { getTransition } from './utils';
import { useHeight } from '../useHeight/useHeight';

function useExpandingAnimation(trigger: boolean) {
    const { ref: heightRef, height: currentHeight } = useHeight({
        on: trigger,
    });

    const [transitions, api] = useTransition(trigger, () =>
        getTransition(currentHeight)
    );

    useEffect(() => {
        api.start({
            height: trigger ? `${currentHeight + 10}px` : '0px',
            opacity: trigger ? 1 : 0,
        });
    }, [currentHeight, trigger]);

    return { heightRef, transitions };
}
export default useExpandingAnimation;
