import { ReactNode } from 'react';
import { AccordionItemInterface } from '@veneer/core';

export const processFAQs: (
    content: { key: number; question: string; answer: string }[],
    formatMessage: (id: string) => ReactNode,
    expandFAQs: boolean
) => AccordionItemInterface[] = (
    content,
    formatMessage,
    expandFAQs = false
) => {
    const prefix = 'faq';

    if (content.length <= 0) throw new Error('There are no keys in the FAQs');
    if (prefix.length === 0) throw new Error('A prefix must be present');

    return content.map((faqKey) => ({
        content: formatMessage(faqKey.answer),
        expanded: expandFAQs,
        header: {
            centralArea: formatMessage(faqKey.question),
        },
        id: faqKey.key.toString(),
    }));
};

export const toggleCollapseOnFAQ = (
    prevState: AccordionItemInterface[],
    id: number,
    expanded: boolean
) => {
    if (prevState.length <= 0) throw new Error('Previous state cant be empty');
    if (id < 0) throw new Error('ID cant be a negative number');
    if (Number.isNaN(id)) throw new Error('ID must be a number');

    return prevState.map((item, index) => {
        if (index === id)
            return {
                ...item,
                expanded,
            };

        return item;
    });
};
