import { useFormat } from 'hooks';
import {
    PurchaseCompleteMessages,
    purchaseCompleteValues,
} from 'lang/messages/purchaseComplete';

import icon from './assets/icon.svg';

import styles from './RegisterMessageBox.module.scss';

function RegisterMessageBox() {
    const { PLEASE_CALL_TEXT } = useFormat(
        PurchaseCompleteMessages,
        purchaseCompleteValues({})
    );

    return (
        <div className={styles.contactContainer}>
            <img src={icon} className={styles.icon} alt="" />
            <p className={styles.contactBody}>{PLEASE_CALL_TEXT}</p>
        </div>
    );
}

export default RegisterMessageBox;
