import { AxiosError, AxiosResponse } from 'axios';

import { camelToSnakeCase } from './caseTransformers';

export type BaseRequest<T, V> = (params?: T) => Promise<AxiosResponse<V>>;

type SuccessResponse<V> = {
    status: 'success';
    data: V;
};

type ErrorResponse = {
    status: 'error';
    error: string;
};

export type BaseResponse<V> = Promise<SuccessResponse<V> | ErrorResponse>;

export const requestHandler =
    <T, V>(request: BaseRequest<T, V>) =>
    async (params?: T): BaseResponse<V> => {
        try {
            const parsedParams = {} as T;

            if (params) {
                Object.keys(params).forEach((key) => {
                    parsedParams[camelToSnakeCase(key) as keyof T] =
                        params[key as keyof T];
                });
            }

            const response = await request(parsedParams);
            return { status: 'success', data: response.data };
        } catch (e) {
            if (e instanceof AxiosError)
                return {
                    status: 'error',
                    error: e.response?.data.message || e.message,
                };
            if (e instanceof Error)
                return { status: 'error', error: e.message };

            return { status: 'error', error: JSON.stringify(e) };
        }
    };
