import { LOCALES } from 'lang/locales';

export enum FAQSectionMessages {
    TITLE = 'faqSection.title',
    TITLE_LONG = 'faqSection.title.long',

    ABOUT_SUBSCRIPTION_TITLE = 'faqSection.aboutSubscription.title',
    ABOUT_SUBSCRIPTION_FAQ0_QUESTION = 'faqSection.aboutSubscription.faq0.question',
    ABOUT_SUBSCRIPTION_FAQ0_ANSWER = 'faqSection.aboutSubscription.faq0.answer',
    ABOUT_SUBSCRIPTION_FAQ1_QUESTION = 'faqSection.aboutSubscription.faq1.question',
    ABOUT_SUBSCRIPTION_FAQ1_ANSWER = 'faqSection.aboutSubscription.faq1.answer',
    ABOUT_SUBSCRIPTION_FAQ2_QUESTION = 'faqSection.aboutSubscription.faq2.question',
    ABOUT_SUBSCRIPTION_FAQ2_ANSWER = 'faqSection.aboutSubscription.faq2.answer',
    ABOUT_SUBSCRIPTION_FAQ3_QUESTION = 'faqSection.aboutSubscription.faq3.question',
    ABOUT_SUBSCRIPTION_FAQ3_ANSWER = 'faqSection.aboutSubscription.faq3.answer',
    ABOUT_SUBSCRIPTION_FAQ4_QUESTION = 'faqSection.aboutSubscription.faq4.question',
    ABOUT_SUBSCRIPTION_FAQ4_ANSWER = 'faqSection.aboutSubscription.faq4.answer',
    ABOUT_SUBSCRIPTION_FAQ4_ANSWER_ACTIVATION = 'faqSection.aboutSubscription.faq4.answer.activation',
    ABOUT_SUBSCRIPTION_FAQ5_QUESTION = 'faqSection.aboutSubscription.faq5.question',
    ABOUT_SUBSCRIPTION_FAQ5_ANSWER = 'faqSection.aboutSubscription.faq5.answer',
    ABOUT_SUBSCRIPTION_FAQ6_QUESTION = 'faqSection.aboutSubscription.faq6.question',
    ABOUT_SUBSCRIPTION_FAQ6_ANSWER = 'faqSection.aboutSubscription.faq6.answer',
    ABOUT_SUBSCRIPTION_FAQ7_QUESTION = 'faqSection.aboutSubscription.faq7.question',
    ABOUT_SUBSCRIPTION_FAQ7_ANSWER = 'faqSection.aboutSubscription.faq7.answer',
    ABOUT_SUBSCRIPTION_FAQ8_QUESTION = 'faqSection.aboutSubscription.faq8.question',
    ABOUT_SUBSCRIPTION_FAQ8_ANSWER = 'faqSection.aboutSubscription.faq8.answer',
    ABOUT_SUBSCRIPTION_FAQ9_QUESTION = 'faqSection.aboutSubscription.faq9.question',
    ABOUT_SUBSCRIPTION_FAQ9_ANSWER = 'faqSection.aboutSubscription.faq9.answer',
    ABOUT_SUBSCRIPTION_FAQ10_QUESTION = 'faqSection.aboutSubscription.faq10.question',
    ABOUT_SUBSCRIPTION_FAQ10_ANSWER = 'faqSection.aboutSubscription.faq10.answer',

    ABOUT_SERVICE_TITLE = 'faqSection.aboutService.title',
    ABOUT_SERVICE_FAQ0_QUESTION = 'faqSection.aboutService.faq0.question',
    ABOUT_SERVICE_FAQ0_ANSWER = 'faqSection.aboutService.faq0.answer',
    ABOUT_SERVICE_FAQ1_QUESTION = 'faqSection.aboutService.faq1.question',
    ABOUT_SERVICE_FAQ1_ANSWER = 'faqSection.aboutService.faq1.answer',
    ABOUT_SERVICE_FAQ2_QUESTION = 'faqSection.aboutService.faq2.question',
    ABOUT_SERVICE_FAQ2_ANSWER = 'faqSection.aboutService.faq2.answer',
    ABOUT_SERVICE_FAQ3_QUESTION = 'faqSection.aboutService.faq3.question',
    ABOUT_SERVICE_FAQ3_ANSWER = 'faqSection.aboutService.faq3.answer',
    ABOUT_SERVICE_FAQ4_QUESTION = 'faqSection.aboutService.faq4.question',
    ABOUT_SERVICE_FAQ4_ANSWER = 'faqSection.aboutService.faq4.answer',
    ABOUT_SERVICE_FAQ5_QUESTION = 'faqSection.aboutService.faq5.question',
    ABOUT_SERVICE_FAQ5_ANSWER = 'faqSection.aboutService.faq5.answer',
    ABOUT_SERVICE_FAQ6_QUESTION = 'faqSection.aboutService.faq6.question',
    ABOUT_SERVICE_FAQ6_ANSWER = 'faqSection.aboutService.faq6.answer',
    ABOUT_SERVICE_FAQ7_QUESTION = 'faqSection.aboutService.faq7.question',
    ABOUT_SERVICE_FAQ7_ANSWER = 'faqSection.aboutService.faq7.answer',
    ABOUT_SERVICE_FAQ8_QUESTION = 'faqSection.aboutService.faq8.question',
    ABOUT_SERVICE_FAQ8_ANSWER = 'faqSection.aboutService.faq8.answer',

    ABOUT_PRINTER_TITLE = 'faqSection.aboutPrinter.title',
    ABOUT_PRINTER_FAQ0_QUESTION = 'faqSection.aboutPrinter.faq0.question',
    ABOUT_PRINTER_FAQ0_ANSWER = 'faqSection.aboutPrinter.faq0.answer',
    ABOUT_PRINTER_FAQ1_QUESTION = 'faqSection.aboutPrinter.faq1.question',
    ABOUT_PRINTER_FAQ1_QUESTION_ACTIVATION = 'faqSection.aboutPrinter.faq1.question.activation',
    ABOUT_PRINTER_FAQ1_ANSWER = 'faqSection.aboutPrinter.faq1.answer',
    ABOUT_PRINTER_FAQ1_ANSWER_ACTIVATION = 'faqSection.aboutPrinter.faq1.answer.activation',
    ABOUT_PRINTER_FAQ2_QUESTION = 'faqSection.aboutPrinter.faq2.question',
    ABOUT_PRINTER_FAQ2_QUESTION_ACTIVATION = 'faqSection.aboutPrinter.faq2.question.activation',
    ABOUT_PRINTER_FAQ2_ANSWER = 'faqSection.aboutPrinter.faq2.answer',
    ABOUT_PRINTER_FAQ2_ANSWER_ACTIVATION = 'faqSection.aboutPrinter.faq2.answer.activation',
    ABOUT_PRINTER_FAQ3_QUESTION = 'faqSection.aboutPrinter.faq3.question',
    ABOUT_PRINTER_FAQ3_ANSWER = 'faqSection.aboutPrinter.faq3.answer',
    ABOUT_PRINTER_FAQ4_QUESTION = 'faqSection.aboutPrinter.faq4.question',
    ABOUT_PRINTER_FAQ4_ANSWER = 'faqSection.aboutPrinter.faq4.answer',

    GETTING_STARTED_TITLE = 'faqSection.gettingStarted.title',
    GETTING_STARTED_FAQ0_QUESTION = 'faqSection.gettingStarted.faq0.question',
    GETTING_STARTED_FAQ0_ANSWER = 'faqSection.gettingStarted.faq0.answer',

    GETTING_STARTED_FAQ1_QUESTION = 'faqSection.gettingStarted.faq1.question',
    GETTING_STARTED_FAQ1_QUESTION_ACTIVATION = 'faqSection.gettingStarted.faq1.question.activation',
    GETTING_STARTED_FAQ1_ANSWER = 'faqSection.gettingStarted.faq1.answer',
    GETTING_STARTED_FAQ1_ANSWER_ACTIVATION = 'faqSection.gettingStarted.faq1.answer.activation',

    GETTING_STARTED_FAQ2_QUESTION = 'faqSection.gettingStarted.faq2.question',
    GETTING_STARTED_FAQ2_QUESTION_ACTIVATION = 'faqSection.gettingStarted.faq2.question.activation',
    GETTING_STARTED_FAQ2_ANSWER = 'faqSection.gettingStarted.faq2.answer',
    GETTING_STARTED_FAQ2_ANSWER_ACTIVATION = 'faqSection.gettingStarted.faq2.answer.activation',

    GETTING_STARTED_FAQ3_QUESTION = 'faqSection.gettingStarted.faq3.question',
    GETTING_STARTED_FAQ3_QUESTION_ACTIVATION = 'faqSection.gettingStarted.faq3.question.activation',
    GETTING_STARTED_FAQ3_ANSWER = 'faqSection.gettingStarted.faq3.answer',
    GETTING_STARTED_FAQ3_ANSWER_ACTIVATION = 'faqSection.gettingStarted.faq3.answer.activation',

    GETTING_STARTED_FAQ4_QUESTION = 'faqSection.gettingStarted.faq4.question',
    GETTING_STARTED_FAQ4_QUESTION_ACTIVATION = 'faqSection.gettingStarted.faq4.question.activation',
    GETTING_STARTED_FAQ4_ANSWER = 'faqSection.gettingStarted.faq4.answer',
    GETTING_STARTED_FAQ4_ANSWER_ACTIVATION = 'faqSection.gettingStarted.faq4.answer.activation',

    GETTING_STARTED_FAQ5_QUESTION = 'faqSection.gettingStarted.faq5.question',
    GETTING_STARTED_FAQ5_QUESTION_ACTIVATION = 'faqSection.gettingStarted.faq5.question.activation',
    GETTING_STARTED_FAQ5_ANSWER = 'faqSection.gettingStarted.faq5.answer',
    GETTING_STARTED_FAQ5_ANSWER_ACTIVATION = 'faqSection.gettingStarted.faq5.answer.activation',

    GETTING_STARTED_FAQ6_QUESTION = 'faqSection.gettingStarted.faq6.question',
    GETTING_STARTED_FAQ6_QUESTION_ACTIVATION = 'faqSection.gettingStarted.faq6.question.activation',
    GETTING_STARTED_FAQ6_ANSWER = 'faqSection.gettingStarted.faq6.answer',
    GETTING_STARTED_FAQ6_ANSWER_ACTIVATION = 'faqSection.gettingStarted.faq6.answer.activation',

    GETTING_STARTED_FAQ7_QUESTION = 'faqSection.gettingStarted.faq7.question',
    GETTING_STARTED_FAQ7_QUESTION_ACTIVATION = 'faqSection.gettingStarted.faq7.question.activation',
    GETTING_STARTED_FAQ7_ANSWER = 'faqSection.gettingStarted.faq7.answer',
    GETTING_STARTED_FAQ7_ANSWER_ACTIVATION = 'faqSection.gettingStarted.faq7.answer.activation',

    GETTING_STARTED_FAQ8_QUESTION_ACTIVATION = 'faqSection.gettingStarted.faq8.question.activation',
    GETTING_STARTED_FAQ8_ANSWER_ACTIVATION = 'faqSection.gettingStarted.faq8.answer.activation',

    GETTING_STARTED_FAQ9_QUESTION_ACTIVATION = 'faqSection.gettingStarted.faq9.question.activation',
    GETTING_STARTED_FAQ9_ANSWER_ACTIVATION = 'faqSection.gettingStarted.faq9.answer.activation',
}

export enum FAQSectionMessagesB {
    TITLE = FAQSectionMessages.TITLE,
    TITLE_LONG = FAQSectionMessages.TITLE_LONG,

    ABOUT_SUBSCRIPTION_TITLE = FAQSectionMessages.ABOUT_SUBSCRIPTION_TITLE,
    ABOUT_SUBSCRIPTION_FAQ0_QUESTION = FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ0_QUESTION,
    ABOUT_SUBSCRIPTION_FAQ0_ANSWER = 'faqSection.aboutSubscription.faq0.answer_b',
    ABOUT_SUBSCRIPTION_FAQ1_QUESTION = FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ1_QUESTION,
    ABOUT_SUBSCRIPTION_FAQ1_ANSWER = FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ1_ANSWER,
    ABOUT_SUBSCRIPTION_FAQ2_QUESTION = FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ2_QUESTION,
    ABOUT_SUBSCRIPTION_FAQ2_ANSWER = 'faqSection.aboutSubscription.faq2.answer_b',
    ABOUT_SUBSCRIPTION_FAQ3_QUESTION = FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ3_QUESTION,
    ABOUT_SUBSCRIPTION_FAQ3_ANSWER = FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ3_ANSWER,
    ABOUT_SUBSCRIPTION_FAQ4_QUESTION = FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ4_QUESTION,
    ABOUT_SUBSCRIPTION_FAQ4_ANSWER = FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ4_ANSWER,
    ABOUT_SUBSCRIPTION_FAQ4_ANSWER_ACTIVATION = FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ4_ANSWER_ACTIVATION,
    ABOUT_SUBSCRIPTION_FAQ5_QUESTION = FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ5_QUESTION,
    ABOUT_SUBSCRIPTION_FAQ5_ANSWER = FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ5_ANSWER,
    ABOUT_SUBSCRIPTION_FAQ6_QUESTION = FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ6_QUESTION,
    ABOUT_SUBSCRIPTION_FAQ6_ANSWER = FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ6_ANSWER,
    ABOUT_SUBSCRIPTION_FAQ7_QUESTION = FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ7_QUESTION,
    ABOUT_SUBSCRIPTION_FAQ7_ANSWER = FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ7_ANSWER,
    ABOUT_SUBSCRIPTION_FAQ8_QUESTION = FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ8_QUESTION,
    ABOUT_SUBSCRIPTION_FAQ8_ANSWER = FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ8_ANSWER,
    ABOUT_SUBSCRIPTION_FAQ9_QUESTION = FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ9_QUESTION,
    ABOUT_SUBSCRIPTION_FAQ9_ANSWER = FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ9_ANSWER,
    ABOUT_SUBSCRIPTION_FAQ10_QUESTION = FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ10_QUESTION,
    ABOUT_SUBSCRIPTION_FAQ10_ANSWER = FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ10_ANSWER,

    ABOUT_SERVICE_TITLE = FAQSectionMessages.ABOUT_SERVICE_TITLE,
    ABOUT_SERVICE_FAQ0_QUESTION = FAQSectionMessages.ABOUT_SERVICE_FAQ0_QUESTION,
    ABOUT_SERVICE_FAQ0_ANSWER = 'faqSection.aboutService.faq0.answer_b',
    ABOUT_SERVICE_FAQ1_QUESTION = FAQSectionMessages.ABOUT_SERVICE_FAQ1_QUESTION,
    ABOUT_SERVICE_FAQ1_ANSWER = 'faqSection.aboutService.faq1.answer_b',
    ABOUT_SERVICE_FAQ2_QUESTION = FAQSectionMessages.ABOUT_SERVICE_FAQ2_QUESTION,
    ABOUT_SERVICE_FAQ2_ANSWER = FAQSectionMessages.ABOUT_SERVICE_FAQ2_ANSWER,
    ABOUT_SERVICE_FAQ3_QUESTION = FAQSectionMessages.ABOUT_SERVICE_FAQ3_QUESTION,
    ABOUT_SERVICE_FAQ3_ANSWER = FAQSectionMessages.ABOUT_SERVICE_FAQ3_ANSWER,
    ABOUT_SERVICE_FAQ4_QUESTION = FAQSectionMessages.ABOUT_SERVICE_FAQ4_QUESTION,
    ABOUT_SERVICE_FAQ4_ANSWER = FAQSectionMessages.ABOUT_SERVICE_FAQ4_ANSWER,
    ABOUT_SERVICE_FAQ5_QUESTION = FAQSectionMessages.ABOUT_SERVICE_FAQ5_QUESTION,
    ABOUT_SERVICE_FAQ5_ANSWER = FAQSectionMessages.ABOUT_SERVICE_FAQ5_ANSWER,
    ABOUT_SERVICE_FAQ6_QUESTION = FAQSectionMessages.ABOUT_SERVICE_FAQ6_QUESTION,
    ABOUT_SERVICE_FAQ6_ANSWER = 'faqSection.aboutService.faq6.answer_b',
    ABOUT_SERVICE_FAQ7_QUESTION = 'faqSection.aboutService.faq7.question_b',
    ABOUT_SERVICE_FAQ7_ANSWER = 'faqSection.aboutService.faq7.answer_b',
    ABOUT_SERVICE_FAQ8_QUESTION = 'faqSection.aboutService.faq8.question_b',
    ABOUT_SERVICE_FAQ8_ANSWER = 'faqSection.aboutService.faq8.answer_b',

    ABOUT_PRINTER_TITLE = FAQSectionMessages.ABOUT_PRINTER_TITLE,
    ABOUT_PRINTER_FAQ0_QUESTION = FAQSectionMessages.ABOUT_PRINTER_FAQ0_QUESTION,
    ABOUT_PRINTER_FAQ0_ANSWER = 'faqSection.aboutPrinter.faq0.answer_b',
    ABOUT_PRINTER_FAQ1_QUESTION = FAQSectionMessages.ABOUT_PRINTER_FAQ1_QUESTION,
    ABOUT_PRINTER_FAQ1_QUESTION_ACTIVATION = FAQSectionMessages.ABOUT_PRINTER_FAQ1_QUESTION_ACTIVATION,
    ABOUT_PRINTER_FAQ1_ANSWER = FAQSectionMessages.ABOUT_PRINTER_FAQ1_ANSWER,
    ABOUT_PRINTER_FAQ1_ANSWER_ACTIVATION = FAQSectionMessages.ABOUT_PRINTER_FAQ1_ANSWER_ACTIVATION,
    ABOUT_PRINTER_FAQ2_QUESTION = FAQSectionMessages.ABOUT_PRINTER_FAQ2_QUESTION,
    ABOUT_PRINTER_FAQ2_QUESTION_ACTIVATION = FAQSectionMessages.ABOUT_PRINTER_FAQ2_QUESTION_ACTIVATION,
    ABOUT_PRINTER_FAQ2_ANSWER = FAQSectionMessages.ABOUT_PRINTER_FAQ2_ANSWER,
    ABOUT_PRINTER_FAQ2_ANSWER_ACTIVATION = FAQSectionMessages.ABOUT_PRINTER_FAQ2_ANSWER_ACTIVATION,
    ABOUT_PRINTER_FAQ3_QUESTION = FAQSectionMessages.ABOUT_PRINTER_FAQ3_QUESTION,
    ABOUT_PRINTER_FAQ3_ANSWER = FAQSectionMessages.ABOUT_PRINTER_FAQ3_ANSWER,
    ABOUT_PRINTER_FAQ4_QUESTION = FAQSectionMessages.ABOUT_PRINTER_FAQ4_QUESTION,
    ABOUT_PRINTER_FAQ4_ANSWER = FAQSectionMessages.ABOUT_PRINTER_FAQ4_ANSWER,

    GETTING_STARTED_TITLE = FAQSectionMessages.GETTING_STARTED_TITLE,
    GETTING_STARTED_FAQ0_QUESTION = FAQSectionMessages.GETTING_STARTED_FAQ0_QUESTION,
    GETTING_STARTED_FAQ0_ANSWER = 'faqSection.gettingStarted.faq0.answer_b',
    GETTING_STARTED_FAQ1_QUESTION = FAQSectionMessages.GETTING_STARTED_FAQ1_QUESTION,
    GETTING_STARTED_FAQ1_QUESTION_ACTIVATION = FAQSectionMessages.GETTING_STARTED_FAQ1_QUESTION_ACTIVATION,
    GETTING_STARTED_FAQ1_ANSWER = FAQSectionMessages.GETTING_STARTED_FAQ1_ANSWER,
    GETTING_STARTED_FAQ1_ANSWER_ACTIVATION = FAQSectionMessages.GETTING_STARTED_FAQ1_ANSWER_ACTIVATION,
    GETTING_STARTED_FAQ2_QUESTION = FAQSectionMessages.GETTING_STARTED_FAQ2_QUESTION,
    GETTING_STARTED_FAQ2_QUESTION_ACTIVATION = FAQSectionMessages.GETTING_STARTED_FAQ2_QUESTION_ACTIVATION,
    GETTING_STARTED_FAQ2_ANSWER = FAQSectionMessages.GETTING_STARTED_FAQ2_ANSWER,
    GETTING_STARTED_FAQ2_ANSWER_ACTIVATION = FAQSectionMessages.GETTING_STARTED_FAQ2_ANSWER_ACTIVATION,
    GETTING_STARTED_FAQ3_QUESTION = FAQSectionMessages.GETTING_STARTED_FAQ3_QUESTION,
    GETTING_STARTED_FAQ3_QUESTION_ACTIVATION = FAQSectionMessages.GETTING_STARTED_FAQ3_QUESTION_ACTIVATION,
    GETTING_STARTED_FAQ3_ANSWER = FAQSectionMessages.GETTING_STARTED_FAQ3_ANSWER,
    GETTING_STARTED_FAQ3_ANSWER_ACTIVATION = FAQSectionMessages.GETTING_STARTED_FAQ3_ANSWER_ACTIVATION,
    GETTING_STARTED_FAQ4_QUESTION = FAQSectionMessages.GETTING_STARTED_FAQ4_QUESTION,
    GETTING_STARTED_FAQ4_QUESTION_ACTIVATION = FAQSectionMessages.GETTING_STARTED_FAQ4_QUESTION_ACTIVATION,
    GETTING_STARTED_FAQ4_ANSWER = FAQSectionMessages.GETTING_STARTED_FAQ4_ANSWER,
    GETTING_STARTED_FAQ4_ANSWER_ACTIVATION = FAQSectionMessages.GETTING_STARTED_FAQ4_ANSWER_ACTIVATION,
    GETTING_STARTED_FAQ5_QUESTION = FAQSectionMessages.GETTING_STARTED_FAQ5_QUESTION,
    GETTING_STARTED_FAQ5_QUESTION_ACTIVATION = FAQSectionMessages.GETTING_STARTED_FAQ5_QUESTION_ACTIVATION,
    GETTING_STARTED_FAQ5_ANSWER = FAQSectionMessages.GETTING_STARTED_FAQ5_ANSWER,
    GETTING_STARTED_FAQ5_ANSWER_ACTIVATION = FAQSectionMessages.GETTING_STARTED_FAQ5_ANSWER_ACTIVATION,
    GETTING_STARTED_FAQ6_QUESTION = FAQSectionMessages.GETTING_STARTED_FAQ6_QUESTION,
    GETTING_STARTED_FAQ6_QUESTION_ACTIVATION = FAQSectionMessages.GETTING_STARTED_FAQ6_QUESTION_ACTIVATION,
    GETTING_STARTED_FAQ6_ANSWER = 'faqSection.gettingStarted.faq6.answer_b',
    GETTING_STARTED_FAQ6_ANSWER_ACTIVATION = FAQSectionMessages.GETTING_STARTED_FAQ6_ANSWER_ACTIVATION,
    GETTING_STARTED_FAQ7_QUESTION = FAQSectionMessages.GETTING_STARTED_FAQ7_QUESTION,
    GETTING_STARTED_FAQ7_QUESTION_ACTIVATION = FAQSectionMessages.GETTING_STARTED_FAQ7_QUESTION_ACTIVATION,
    GETTING_STARTED_FAQ7_ANSWER = FAQSectionMessages.GETTING_STARTED_FAQ7_ANSWER,
    GETTING_STARTED_FAQ7_ANSWER_ACTIVATION = FAQSectionMessages.GETTING_STARTED_FAQ7_ANSWER_ACTIVATION,
    GETTING_STARTED_FAQ8_QUESTION_ACTIVATION = FAQSectionMessages.GETTING_STARTED_FAQ8_QUESTION_ACTIVATION,
    GETTING_STARTED_FAQ8_ANSWER_ACTIVATION = FAQSectionMessages.GETTING_STARTED_FAQ8_ANSWER_ACTIVATION,
    GETTING_STARTED_FAQ9_QUESTION_ACTIVATION = FAQSectionMessages.GETTING_STARTED_FAQ9_QUESTION_ACTIVATION,
    GETTING_STARTED_FAQ9_ANSWER_ACTIVATION = FAQSectionMessages.GETTING_STARTED_FAQ9_ANSWER_ACTIVATION,
}

export enum FAQSectionMessagesC {
    TITLE = FAQSectionMessages.TITLE,
    TITLE_LONG = FAQSectionMessages.TITLE_LONG,

    ABOUT_SUBSCRIPTION_TITLE = FAQSectionMessages.ABOUT_SUBSCRIPTION_TITLE,
    ABOUT_SUBSCRIPTION_FAQ0_QUESTION = FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ0_QUESTION,
    ABOUT_SUBSCRIPTION_FAQ0_ANSWER = 'faqSection.aboutSubscription.faq0.answer_b',
    ABOUT_SUBSCRIPTION_FAQ1_QUESTION = FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ1_QUESTION,
    ABOUT_SUBSCRIPTION_FAQ1_ANSWER = FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ1_ANSWER,
    ABOUT_SUBSCRIPTION_FAQ2_QUESTION = FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ2_QUESTION,
    ABOUT_SUBSCRIPTION_FAQ2_ANSWER = 'faqSection.aboutSubscription.faq2.answer_c',
    ABOUT_SUBSCRIPTION_FAQ3_QUESTION = FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ3_QUESTION,
    ABOUT_SUBSCRIPTION_FAQ3_ANSWER = FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ3_ANSWER,
    ABOUT_SUBSCRIPTION_FAQ4_QUESTION = FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ4_QUESTION,
    ABOUT_SUBSCRIPTION_FAQ4_ANSWER = FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ4_ANSWER,
    ABOUT_SUBSCRIPTION_FAQ4_ANSWER_ACTIVATION = FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ4_ANSWER_ACTIVATION,
    ABOUT_SUBSCRIPTION_FAQ5_QUESTION = FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ5_QUESTION,
    ABOUT_SUBSCRIPTION_FAQ5_ANSWER = FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ5_ANSWER,
    ABOUT_SUBSCRIPTION_FAQ6_QUESTION = FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ6_QUESTION,
    ABOUT_SUBSCRIPTION_FAQ6_ANSWER = FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ6_ANSWER,
    ABOUT_SUBSCRIPTION_FAQ7_QUESTION = FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ7_QUESTION,
    ABOUT_SUBSCRIPTION_FAQ7_ANSWER = FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ7_ANSWER,
    ABOUT_SUBSCRIPTION_FAQ8_QUESTION = FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ8_QUESTION,
    ABOUT_SUBSCRIPTION_FAQ8_ANSWER = FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ8_ANSWER,
    ABOUT_SUBSCRIPTION_FAQ9_QUESTION = FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ9_QUESTION,
    ABOUT_SUBSCRIPTION_FAQ9_ANSWER = FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ9_ANSWER,
    ABOUT_SUBSCRIPTION_FAQ10_QUESTION = FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ10_QUESTION,
    ABOUT_SUBSCRIPTION_FAQ10_ANSWER = FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ10_ANSWER,

    ABOUT_SERVICE_TITLE = FAQSectionMessages.ABOUT_SERVICE_TITLE,
    ABOUT_SERVICE_FAQ0_QUESTION = FAQSectionMessages.ABOUT_SERVICE_FAQ0_QUESTION,
    ABOUT_SERVICE_FAQ0_ANSWER = 'faqSection.aboutService.faq0.answer_c',
    ABOUT_SERVICE_FAQ1_QUESTION = FAQSectionMessages.ABOUT_SERVICE_FAQ1_QUESTION,
    ABOUT_SERVICE_FAQ1_ANSWER = 'faqSection.aboutService.faq1.answer_c',
    ABOUT_SERVICE_FAQ2_QUESTION = FAQSectionMessages.ABOUT_SERVICE_FAQ2_QUESTION,
    ABOUT_SERVICE_FAQ2_ANSWER = FAQSectionMessages.ABOUT_SERVICE_FAQ2_ANSWER,
    ABOUT_SERVICE_FAQ3_QUESTION = FAQSectionMessages.ABOUT_SERVICE_FAQ3_QUESTION,
    ABOUT_SERVICE_FAQ3_ANSWER = FAQSectionMessages.ABOUT_SERVICE_FAQ3_ANSWER,
    ABOUT_SERVICE_FAQ4_QUESTION = FAQSectionMessages.ABOUT_SERVICE_FAQ4_QUESTION,
    ABOUT_SERVICE_FAQ4_ANSWER = FAQSectionMessages.ABOUT_SERVICE_FAQ4_ANSWER,
    ABOUT_SERVICE_FAQ5_QUESTION = FAQSectionMessages.ABOUT_SERVICE_FAQ5_QUESTION,
    ABOUT_SERVICE_FAQ5_ANSWER = FAQSectionMessages.ABOUT_SERVICE_FAQ5_ANSWER,
    ABOUT_SERVICE_FAQ6_QUESTION = FAQSectionMessages.ABOUT_SERVICE_FAQ6_QUESTION,
    ABOUT_SERVICE_FAQ6_ANSWER = 'faqSection.aboutService.faq6.answer_c',
    ABOUT_SERVICE_FAQ7_QUESTION = 'faqSection.aboutService.faq7.question_c',
    ABOUT_SERVICE_FAQ7_ANSWER = 'faqSection.aboutService.faq7.answer_c',
    ABOUT_SERVICE_FAQ8_QUESTION = 'faqSection.aboutService.faq8.question_c',
    ABOUT_SERVICE_FAQ8_ANSWER = 'faqSection.aboutService.faq8.answer_c',

    ABOUT_PRINTER_TITLE = FAQSectionMessages.ABOUT_PRINTER_TITLE,
    ABOUT_PRINTER_FAQ0_QUESTION = FAQSectionMessages.ABOUT_PRINTER_FAQ0_QUESTION,
    ABOUT_PRINTER_FAQ0_ANSWER = 'faqSection.aboutPrinter.faq0.answer_c',
    ABOUT_PRINTER_FAQ1_QUESTION = FAQSectionMessages.ABOUT_PRINTER_FAQ1_QUESTION,
    ABOUT_PRINTER_FAQ1_QUESTION_ACTIVATION = FAQSectionMessages.ABOUT_PRINTER_FAQ1_QUESTION_ACTIVATION,
    ABOUT_PRINTER_FAQ1_ANSWER = FAQSectionMessages.ABOUT_PRINTER_FAQ1_ANSWER,
    ABOUT_PRINTER_FAQ1_ANSWER_ACTIVATION = FAQSectionMessages.ABOUT_PRINTER_FAQ1_ANSWER_ACTIVATION,
    ABOUT_PRINTER_FAQ2_QUESTION = FAQSectionMessages.ABOUT_PRINTER_FAQ2_QUESTION,
    ABOUT_PRINTER_FAQ2_QUESTION_ACTIVATION = FAQSectionMessages.ABOUT_PRINTER_FAQ2_QUESTION_ACTIVATION,
    ABOUT_PRINTER_FAQ2_ANSWER = FAQSectionMessages.ABOUT_PRINTER_FAQ2_ANSWER,
    ABOUT_PRINTER_FAQ2_ANSWER_ACTIVATION = FAQSectionMessages.ABOUT_PRINTER_FAQ2_ANSWER_ACTIVATION,
    ABOUT_PRINTER_FAQ3_QUESTION = FAQSectionMessages.ABOUT_PRINTER_FAQ3_QUESTION,
    ABOUT_PRINTER_FAQ3_ANSWER = FAQSectionMessages.ABOUT_PRINTER_FAQ3_ANSWER,
    ABOUT_PRINTER_FAQ4_QUESTION = FAQSectionMessages.ABOUT_PRINTER_FAQ4_QUESTION,
    ABOUT_PRINTER_FAQ4_ANSWER = FAQSectionMessages.ABOUT_PRINTER_FAQ4_ANSWER,

    GETTING_STARTED_TITLE = FAQSectionMessages.GETTING_STARTED_TITLE,
    GETTING_STARTED_FAQ0_QUESTION = FAQSectionMessages.GETTING_STARTED_FAQ0_QUESTION,
    GETTING_STARTED_FAQ0_ANSWER = 'faqSection.gettingStarted.faq0.answer_c',
    GETTING_STARTED_FAQ1_QUESTION = FAQSectionMessages.GETTING_STARTED_FAQ1_QUESTION,
    GETTING_STARTED_FAQ1_QUESTION_ACTIVATION = FAQSectionMessages.GETTING_STARTED_FAQ1_QUESTION_ACTIVATION,
    GETTING_STARTED_FAQ1_ANSWER = FAQSectionMessages.GETTING_STARTED_FAQ1_ANSWER,
    GETTING_STARTED_FAQ1_ANSWER_ACTIVATION = FAQSectionMessages.GETTING_STARTED_FAQ1_ANSWER_ACTIVATION,
    GETTING_STARTED_FAQ2_QUESTION = FAQSectionMessages.GETTING_STARTED_FAQ2_QUESTION,
    GETTING_STARTED_FAQ2_QUESTION_ACTIVATION = FAQSectionMessages.GETTING_STARTED_FAQ2_QUESTION_ACTIVATION,
    GETTING_STARTED_FAQ2_ANSWER = FAQSectionMessages.GETTING_STARTED_FAQ2_ANSWER,
    GETTING_STARTED_FAQ2_ANSWER_ACTIVATION = FAQSectionMessages.GETTING_STARTED_FAQ2_ANSWER_ACTIVATION,
    GETTING_STARTED_FAQ3_QUESTION = FAQSectionMessages.GETTING_STARTED_FAQ3_QUESTION,
    GETTING_STARTED_FAQ3_QUESTION_ACTIVATION = FAQSectionMessages.GETTING_STARTED_FAQ3_QUESTION_ACTIVATION,
    GETTING_STARTED_FAQ3_ANSWER = FAQSectionMessages.GETTING_STARTED_FAQ3_ANSWER,
    GETTING_STARTED_FAQ3_ANSWER_ACTIVATION = FAQSectionMessages.GETTING_STARTED_FAQ3_ANSWER_ACTIVATION,
    GETTING_STARTED_FAQ4_QUESTION = FAQSectionMessages.GETTING_STARTED_FAQ4_QUESTION,
    GETTING_STARTED_FAQ4_QUESTION_ACTIVATION = FAQSectionMessages.GETTING_STARTED_FAQ4_QUESTION_ACTIVATION,
    GETTING_STARTED_FAQ4_ANSWER = FAQSectionMessages.GETTING_STARTED_FAQ4_ANSWER,
    GETTING_STARTED_FAQ4_ANSWER_ACTIVATION = FAQSectionMessages.GETTING_STARTED_FAQ4_ANSWER_ACTIVATION,
    GETTING_STARTED_FAQ5_QUESTION = FAQSectionMessages.GETTING_STARTED_FAQ5_QUESTION,
    GETTING_STARTED_FAQ5_QUESTION_ACTIVATION = FAQSectionMessages.GETTING_STARTED_FAQ5_QUESTION_ACTIVATION,
    GETTING_STARTED_FAQ5_ANSWER = FAQSectionMessages.GETTING_STARTED_FAQ5_ANSWER,
    GETTING_STARTED_FAQ5_ANSWER_ACTIVATION = FAQSectionMessages.GETTING_STARTED_FAQ5_ANSWER_ACTIVATION,
    GETTING_STARTED_FAQ6_QUESTION = FAQSectionMessages.GETTING_STARTED_FAQ6_QUESTION,
    GETTING_STARTED_FAQ6_QUESTION_ACTIVATION = FAQSectionMessages.GETTING_STARTED_FAQ6_QUESTION_ACTIVATION,
    GETTING_STARTED_FAQ6_ANSWER = 'faqSection.gettingStarted.faq6.answer_c',
    GETTING_STARTED_FAQ6_ANSWER_ACTIVATION = FAQSectionMessages.GETTING_STARTED_FAQ6_ANSWER_ACTIVATION,
    GETTING_STARTED_FAQ7_QUESTION = FAQSectionMessages.GETTING_STARTED_FAQ7_QUESTION,
    GETTING_STARTED_FAQ7_QUESTION_ACTIVATION = FAQSectionMessages.GETTING_STARTED_FAQ7_QUESTION_ACTIVATION,
    GETTING_STARTED_FAQ7_ANSWER = FAQSectionMessages.GETTING_STARTED_FAQ7_ANSWER,
    GETTING_STARTED_FAQ7_ANSWER_ACTIVATION = FAQSectionMessages.GETTING_STARTED_FAQ7_ANSWER_ACTIVATION,
    GETTING_STARTED_FAQ8_QUESTION_ACTIVATION = FAQSectionMessages.GETTING_STARTED_FAQ8_QUESTION_ACTIVATION,
    GETTING_STARTED_FAQ8_ANSWER_ACTIVATION = FAQSectionMessages.GETTING_STARTED_FAQ8_ANSWER_ACTIVATION,
    GETTING_STARTED_FAQ9_QUESTION_ACTIVATION = FAQSectionMessages.GETTING_STARTED_FAQ9_QUESTION_ACTIVATION,
    GETTING_STARTED_FAQ9_ANSWER_ACTIVATION = FAQSectionMessages.GETTING_STARTED_FAQ9_ANSWER_ACTIVATION,
}

export enum FAQSectionMessagesD {
    TITLE = FAQSectionMessages.TITLE,
    TITLE_LONG = FAQSectionMessages.TITLE_LONG,

    ABOUT_SUBSCRIPTION_TITLE = FAQSectionMessages.ABOUT_SUBSCRIPTION_TITLE,
    ABOUT_SUBSCRIPTION_FAQ0_QUESTION = FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ0_QUESTION,
    ABOUT_SUBSCRIPTION_FAQ0_ANSWER = 'faqSection.aboutSubscription.faq0.answer_b',
    ABOUT_SUBSCRIPTION_FAQ1_QUESTION = FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ1_QUESTION,
    ABOUT_SUBSCRIPTION_FAQ1_ANSWER = FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ1_ANSWER,
    ABOUT_SUBSCRIPTION_FAQ2_QUESTION = FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ2_QUESTION,
    ABOUT_SUBSCRIPTION_FAQ2_ANSWER = 'faqSection.aboutSubscription.faq2.answer_d',
    ABOUT_SUBSCRIPTION_FAQ3_QUESTION = FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ3_QUESTION,
    ABOUT_SUBSCRIPTION_FAQ3_ANSWER = FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ3_ANSWER,
    ABOUT_SUBSCRIPTION_FAQ4_QUESTION = FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ4_QUESTION,
    ABOUT_SUBSCRIPTION_FAQ4_ANSWER = FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ4_ANSWER,
    ABOUT_SUBSCRIPTION_FAQ4_ANSWER_ACTIVATION = FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ4_ANSWER_ACTIVATION,
    ABOUT_SUBSCRIPTION_FAQ5_QUESTION = FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ5_QUESTION,
    ABOUT_SUBSCRIPTION_FAQ5_ANSWER = FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ5_ANSWER,
    ABOUT_SUBSCRIPTION_FAQ6_QUESTION = FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ6_QUESTION,
    ABOUT_SUBSCRIPTION_FAQ6_ANSWER = FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ6_ANSWER,
    ABOUT_SUBSCRIPTION_FAQ7_QUESTION = FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ7_QUESTION,
    ABOUT_SUBSCRIPTION_FAQ7_ANSWER = FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ7_ANSWER,
    ABOUT_SUBSCRIPTION_FAQ8_QUESTION = FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ8_QUESTION,
    ABOUT_SUBSCRIPTION_FAQ8_ANSWER = FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ8_ANSWER,
    ABOUT_SUBSCRIPTION_FAQ9_QUESTION = FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ9_QUESTION,
    ABOUT_SUBSCRIPTION_FAQ9_ANSWER = FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ9_ANSWER,
    ABOUT_SUBSCRIPTION_FAQ10_QUESTION = FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ10_QUESTION,
    ABOUT_SUBSCRIPTION_FAQ10_ANSWER = FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ10_ANSWER,

    ABOUT_SERVICE_TITLE = FAQSectionMessages.ABOUT_SERVICE_TITLE,
    ABOUT_SERVICE_FAQ0_QUESTION = FAQSectionMessages.ABOUT_SERVICE_FAQ0_QUESTION,
    ABOUT_SERVICE_FAQ0_ANSWER = 'faqSection.aboutService.faq0.answer_c',
    ABOUT_SERVICE_FAQ1_QUESTION = FAQSectionMessages.ABOUT_SERVICE_FAQ1_QUESTION,
    ABOUT_SERVICE_FAQ1_ANSWER = 'faqSection.aboutService.faq1.answer_c',
    ABOUT_SERVICE_FAQ2_QUESTION = FAQSectionMessages.ABOUT_SERVICE_FAQ2_QUESTION,
    ABOUT_SERVICE_FAQ2_ANSWER = FAQSectionMessages.ABOUT_SERVICE_FAQ2_ANSWER,
    ABOUT_SERVICE_FAQ3_QUESTION = FAQSectionMessages.ABOUT_SERVICE_FAQ3_QUESTION,
    ABOUT_SERVICE_FAQ3_ANSWER = FAQSectionMessages.ABOUT_SERVICE_FAQ3_ANSWER,
    ABOUT_SERVICE_FAQ4_QUESTION = FAQSectionMessages.ABOUT_SERVICE_FAQ4_QUESTION,
    ABOUT_SERVICE_FAQ4_ANSWER = FAQSectionMessages.ABOUT_SERVICE_FAQ4_ANSWER,
    ABOUT_SERVICE_FAQ5_QUESTION = FAQSectionMessages.ABOUT_SERVICE_FAQ5_QUESTION,
    ABOUT_SERVICE_FAQ5_ANSWER = FAQSectionMessages.ABOUT_SERVICE_FAQ5_ANSWER,
    ABOUT_SERVICE_FAQ6_QUESTION = FAQSectionMessages.ABOUT_SERVICE_FAQ6_QUESTION,
    ABOUT_SERVICE_FAQ6_ANSWER = 'faqSection.aboutService.faq6.answer_c',
    ABOUT_SERVICE_FAQ7_QUESTION = 'faqSection.aboutService.faq7.question_c',
    ABOUT_SERVICE_FAQ7_ANSWER = 'faqSection.aboutService.faq7.answer_c',
    ABOUT_SERVICE_FAQ8_QUESTION = 'faqSection.aboutService.faq8.question_c',
    ABOUT_SERVICE_FAQ8_ANSWER = 'faqSection.aboutService.faq8.answer_c',

    ABOUT_PRINTER_TITLE = FAQSectionMessages.ABOUT_PRINTER_TITLE,
    ABOUT_PRINTER_FAQ0_QUESTION = FAQSectionMessages.ABOUT_PRINTER_FAQ0_QUESTION,
    ABOUT_PRINTER_FAQ0_ANSWER = 'faqSection.aboutPrinter.faq0.answer_d',
    ABOUT_PRINTER_FAQ1_QUESTION = FAQSectionMessages.ABOUT_PRINTER_FAQ1_QUESTION,
    ABOUT_PRINTER_FAQ1_QUESTION_ACTIVATION = FAQSectionMessages.ABOUT_PRINTER_FAQ1_QUESTION_ACTIVATION,
    ABOUT_PRINTER_FAQ1_ANSWER = FAQSectionMessages.ABOUT_PRINTER_FAQ1_ANSWER,
    ABOUT_PRINTER_FAQ1_ANSWER_ACTIVATION = FAQSectionMessages.ABOUT_PRINTER_FAQ1_ANSWER_ACTIVATION,
    ABOUT_PRINTER_FAQ2_QUESTION = FAQSectionMessages.ABOUT_PRINTER_FAQ2_QUESTION,
    ABOUT_PRINTER_FAQ2_QUESTION_ACTIVATION = FAQSectionMessages.ABOUT_PRINTER_FAQ2_QUESTION_ACTIVATION,
    ABOUT_PRINTER_FAQ2_ANSWER = FAQSectionMessages.ABOUT_PRINTER_FAQ2_ANSWER,
    ABOUT_PRINTER_FAQ2_ANSWER_ACTIVATION = FAQSectionMessages.ABOUT_PRINTER_FAQ2_ANSWER_ACTIVATION,
    ABOUT_PRINTER_FAQ3_QUESTION = FAQSectionMessages.ABOUT_PRINTER_FAQ3_QUESTION,
    ABOUT_PRINTER_FAQ3_ANSWER = FAQSectionMessages.ABOUT_PRINTER_FAQ3_ANSWER,
    ABOUT_PRINTER_FAQ4_QUESTION = FAQSectionMessages.ABOUT_PRINTER_FAQ4_QUESTION,
    ABOUT_PRINTER_FAQ4_ANSWER = FAQSectionMessages.ABOUT_PRINTER_FAQ4_ANSWER,

    GETTING_STARTED_TITLE = FAQSectionMessages.GETTING_STARTED_TITLE,
    GETTING_STARTED_FAQ0_QUESTION = FAQSectionMessages.GETTING_STARTED_FAQ0_QUESTION,
    GETTING_STARTED_FAQ0_ANSWER = 'faqSection.gettingStarted.faq0.answer_d',
    GETTING_STARTED_FAQ1_QUESTION = FAQSectionMessages.GETTING_STARTED_FAQ1_QUESTION,
    GETTING_STARTED_FAQ1_QUESTION_ACTIVATION = FAQSectionMessages.GETTING_STARTED_FAQ1_QUESTION_ACTIVATION,
    GETTING_STARTED_FAQ1_ANSWER = FAQSectionMessages.GETTING_STARTED_FAQ1_ANSWER,
    GETTING_STARTED_FAQ1_ANSWER_ACTIVATION = FAQSectionMessages.GETTING_STARTED_FAQ1_ANSWER_ACTIVATION,
    GETTING_STARTED_FAQ2_QUESTION = FAQSectionMessages.GETTING_STARTED_FAQ2_QUESTION,
    GETTING_STARTED_FAQ2_QUESTION_ACTIVATION = FAQSectionMessages.GETTING_STARTED_FAQ2_QUESTION_ACTIVATION,
    GETTING_STARTED_FAQ2_ANSWER = FAQSectionMessages.GETTING_STARTED_FAQ2_ANSWER,
    GETTING_STARTED_FAQ2_ANSWER_ACTIVATION = FAQSectionMessages.GETTING_STARTED_FAQ2_ANSWER_ACTIVATION,
    GETTING_STARTED_FAQ3_QUESTION = FAQSectionMessages.GETTING_STARTED_FAQ3_QUESTION,
    GETTING_STARTED_FAQ3_QUESTION_ACTIVATION = FAQSectionMessages.GETTING_STARTED_FAQ3_QUESTION_ACTIVATION,
    GETTING_STARTED_FAQ3_ANSWER = FAQSectionMessages.GETTING_STARTED_FAQ3_ANSWER,
    GETTING_STARTED_FAQ3_ANSWER_ACTIVATION = FAQSectionMessages.GETTING_STARTED_FAQ3_ANSWER_ACTIVATION,
    GETTING_STARTED_FAQ4_QUESTION = FAQSectionMessages.GETTING_STARTED_FAQ4_QUESTION,
    GETTING_STARTED_FAQ4_QUESTION_ACTIVATION = FAQSectionMessages.GETTING_STARTED_FAQ4_QUESTION_ACTIVATION,
    GETTING_STARTED_FAQ4_ANSWER = FAQSectionMessages.GETTING_STARTED_FAQ4_ANSWER,
    GETTING_STARTED_FAQ4_ANSWER_ACTIVATION = FAQSectionMessages.GETTING_STARTED_FAQ4_ANSWER_ACTIVATION,
    GETTING_STARTED_FAQ5_QUESTION = FAQSectionMessages.GETTING_STARTED_FAQ5_QUESTION,
    GETTING_STARTED_FAQ5_QUESTION_ACTIVATION = FAQSectionMessages.GETTING_STARTED_FAQ5_QUESTION_ACTIVATION,
    GETTING_STARTED_FAQ5_ANSWER = FAQSectionMessages.GETTING_STARTED_FAQ5_ANSWER,
    GETTING_STARTED_FAQ5_ANSWER_ACTIVATION = FAQSectionMessages.GETTING_STARTED_FAQ5_ANSWER_ACTIVATION,
    GETTING_STARTED_FAQ6_QUESTION = FAQSectionMessages.GETTING_STARTED_FAQ6_QUESTION,
    GETTING_STARTED_FAQ6_QUESTION_ACTIVATION = FAQSectionMessages.GETTING_STARTED_FAQ6_QUESTION_ACTIVATION,
    GETTING_STARTED_FAQ6_ANSWER = 'faqSection.gettingStarted.faq6.answer_d',
    GETTING_STARTED_FAQ6_ANSWER_ACTIVATION = FAQSectionMessages.GETTING_STARTED_FAQ6_ANSWER_ACTIVATION,
    GETTING_STARTED_FAQ7_QUESTION = FAQSectionMessages.GETTING_STARTED_FAQ7_QUESTION,
    GETTING_STARTED_FAQ7_QUESTION_ACTIVATION = FAQSectionMessages.GETTING_STARTED_FAQ7_QUESTION_ACTIVATION,
    GETTING_STARTED_FAQ7_ANSWER = FAQSectionMessages.GETTING_STARTED_FAQ7_ANSWER,
    GETTING_STARTED_FAQ7_ANSWER_ACTIVATION = FAQSectionMessages.GETTING_STARTED_FAQ7_ANSWER_ACTIVATION,
    GETTING_STARTED_FAQ8_QUESTION_ACTIVATION = FAQSectionMessages.GETTING_STARTED_FAQ8_QUESTION_ACTIVATION,
    GETTING_STARTED_FAQ8_ANSWER_ACTIVATION = FAQSectionMessages.GETTING_STARTED_FAQ8_ANSWER_ACTIVATION,
    GETTING_STARTED_FAQ9_QUESTION_ACTIVATION = FAQSectionMessages.GETTING_STARTED_FAQ9_QUESTION_ACTIVATION,
    GETTING_STARTED_FAQ9_ANSWER_ACTIVATION = FAQSectionMessages.GETTING_STARTED_FAQ9_ANSWER_ACTIVATION,
}

export type FaqTypes =
    | typeof FAQSectionMessages
    | typeof FAQSectionMessagesB
    | typeof FAQSectionMessagesC
    | typeof FAQSectionMessagesD;

const ABOUT_SUBSCRIPTION_FAQ2_ANSWER =
    '<ul><li>A new printer of your choice: HP OfficeJet Pro, HP Envy or the HP Envy Inspire</li><li>Instant Ink cartridge delivery service</li><li>Full warranty coverage</li><li>24/7 hardware support</li><li>Printer refresh eligibility every two years</li><li>Carbon-neutral usage certified printing</li></ul>';
const ABOUT_SERVICE_FAQ0_ANSWER =
    "<p>Instant Ink printing plans are based on pages, not cartridges. You choose a printing plan based on the number of pages you would like to print monthly. Your monthly plan fee enables you to use the subscription cartridges to print your plan pages and includes ink delivery and recycling.</p> <p>Color, black and white, and photo prints all cost the same. You can roll over unused pages (up to three times your plan pages), or if you print more, you'll automatically purchase sets of 10-15 pages for $1.00 (pages per set varies by plan).</p> <p>We send you HP Instant Ink cartridges when you first sign up for the service. Replacement cartridges are sent when your printer tells HP you need more ink, not by the month.</p> <p>Your plan is flexible and can be changed at any time to accommodate your printing needs.</p>";
const ABOUT_SERVICE_FAQ1_ANSWER =
    '<p>HP Instant Ink Platinum customers can choose from a range of print plans, from light printing with a 10-page allowance per month, to business printing with a 700-page allowance.</p>';
const ABOUT_SERVICE_FAQ6_ANSWER =
    '<p>Yes. One of the advantages of Instant Ink is that you can print full color photos, color documents, or high-quality black and white pages, all for the same cost. </p><p>Since your monthly charge is based on printer model and pages printed, not the amount of ink you use, you never have to worry about printing in black and white to avoid using your color ink cartridges.</p>';
const ABOUT_SERVICE_FAQ7_QUESTION = 'How do I recycle ink cartridges?';
const ABOUT_SERVICE_FAQ7_ANSWER =
    '<p>As part of the service, HP will send you postage-paid recycling envelopes (included with select ink shipments) at no additional cost.</p> <p>Used HP Instant Ink cartridges must be returned in the postage-paid recycling envelopes provided by HP. Please completely fill your cartridge envelope before mailing it in. Returned cartridges will be recycled by HP Planet Partners.</p>';
const ABOUT_SERVICE_FAQ8_QUESTION = 'Will I get cartridges every month?';
const ABOUT_SERVICE_FAQ8_ANSWER =
    "<p>No, cartridges are not sent by the month. You will only receive cartridges when your printer tells us you need them, based on your ink usage.</p> <p>We send you HP Instant Ink cartridges when you first sign up for the service, and additional replacement cartridges are sent as needed, not monthly.</p> <p>HP Instant Ink cartridges have very high capacity, so most customers don't require frequent shipments. You must keep your printer connected to the internet and your shipping address and payment method up to date to avoid service interruption. An active subscription is required for Instant Ink cartridges to print.</p>";
const ABOUT_PRINTER_FAQ0_ANSWER =
    '<p>Yes. When you enroll in HP Instant Ink Platinum, you can choose from three HP printer models. The model you choose, along with the printing plan you select, will determine your monthly subscription fee.</p>';
const GETTING_STARTED_FAQ0_ANSWER =
    '<p>You can enroll online at <a>platinum.hpinstantink.com</a>. To enroll you will select a printer and a printing plan, and enter your credit card and shipping details. You’ll pay nothing up front. We’ll ship you your printer, and once you receive it, you’ll complete the enrollment process when you set up your printer.</p>';
const GETTING_STARTED_FAQ6_ANSWER =
    '<p>If you cancel your subscription within the first year after completing enrollment in Platinum or transferring Platinum service to your printer refresh, you must pay a cancelation fee equal to the list price of the printer. If you cancel your subscription within the second year, the cancelation fee is half the list price of the printer. If you cancel your subscription more than two years after completing enrollment or transferring service to your printer refresh, there is no cancelation fee.</p>';

export const faqSectionMessages = {
    [LOCALES.ENGLISH]: {
        [FAQSectionMessages.TITLE]: 'FAQs',
        [FAQSectionMessages.TITLE_LONG]: 'Frequently Asked Questions',

        [FAQSectionMessages.ABOUT_SUBSCRIPTION_TITLE]: 'About the subscription',

        [FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ0_QUESTION]:
            'What is an HP Instant Ink Platinum subscription?',
        [FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ0_ANSWER]:
            '<p>HP Instant Ink Platinum is a printer subscription program with an all-inclusive monthly payment. This payment includes the printer, 24/7 hardware support, next-business-day replacement warranty, and CarbonNeutral® usage certification. The subscription also includes a new printer every two years.</p>',
        [FAQSectionMessagesB.ABOUT_SUBSCRIPTION_FAQ0_ANSWER]:
            '<p>HP Instant Ink Platinum is a printer subscription program with an all-inclusive monthly payment. This payment includes the printer, 24/7 hardware support, next-business-day replacement warranty, and CarbonNeutral® usage certification. The subscription also includes a new printer every two years.</p>',

        [FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ1_QUESTION]:
            'What is the difference between HP Instant Ink Platinum and regular HP Instant Ink service?',
        [FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ1_ANSWER]:
            '<p>When you enroll in regular Instant Ink service, you must own an eligible printer, and the service provides ink delivered before you run out. With HP Instant Ink Platinum, you don’t have to own a printer or worry about maintenance or breakdowns. Your subscription includes the printer as well as a warranty, support, and eligibility for printer refresh every two years.</p>',

        [FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ2_QUESTION]:
            'What is included with an HP Instant Ink Platinum subscription?',
        [FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ2_ANSWER]:
            ABOUT_SUBSCRIPTION_FAQ2_ANSWER,
        [FAQSectionMessagesB.ABOUT_SUBSCRIPTION_FAQ2_ANSWER]:
            ABOUT_SUBSCRIPTION_FAQ2_ANSWER,
        [FAQSectionMessagesC.ABOUT_SUBSCRIPTION_FAQ2_ANSWER]:
            ABOUT_SUBSCRIPTION_FAQ2_ANSWER,
        [FAQSectionMessagesD.ABOUT_SUBSCRIPTION_FAQ2_ANSWER]:
            ABOUT_SUBSCRIPTION_FAQ2_ANSWER,

        [FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ3_QUESTION]:
            'How does the warranty work?',
        [FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ3_ANSWER]:
            '<p>As long as your HP Instant Ink Platinum subscription remains active, your printer is covered under warranty. You have access to HP customer support 24/7 for hardware issues. HP technicians use remote tools for diagnosis and repair, and if the problem cannot be resolved, the technician sends you a next-business-day replacement.</p>',

        [FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ4_QUESTION]:
            'How does the printer refresh feature work?',
        [FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ4_ANSWER]:
            '<p>As an HP Instant Ink Platinum customer, you are eligible for a printer refresh every two years. There is no additional charge for your printer refresh or shipping. We’ll notify you when you’re eligible and provide you with a choice of HP’s latest models. Once you’re eligible, simply refresh when it’s most convenient for you.</p>',
        [FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ4_ANSWER_ACTIVATION]:
            '<p>As an active HP Instant Ink Platinum customer, you are eligible for a printer refresh every two years. You’ll pay only a $39 printer activation fee; there is no other charge for your printer refresh or shipping. We’ll notify you when you’re eligible and provide you with a choice of HP’s latest models. Once you’re eligible, simply refresh when it’s most convenient for you.</p>',

        [FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ5_QUESTION]:
            'What is CarbonNeutral® usage certified printing?',
        [FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ5_ANSWER]:
            '<p>In accordance with <a>www.carbonneutral.com/the-carbonneutral-protocol|The CarbonNeutral® Protocol</a> the certification covers electricity use of your printer, and life cycle emissions of your cartridge (production, transportation, packaging, and end of life management). HP Instant Ink Platinum received CarbonNeutral® usage certification on November 1, 2021. HP calculates the total usage carbon footprint with information unique to your printer and purchases carbon credits that adhere to the International Carbon Reduction and Offset Alliance (ICROA) standards to offset those calculated emissions.¹⁴</p>',

        [FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ6_QUESTION]:
            'Where can I sign up for HP Instant Ink Platinum?',
        [FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ6_ANSWER]:
            '<p>You can enroll online at <a>platinum.hpinstantink.com</a>. To enroll, you select a printer and a printing plan, and enter your credit card and shipping details. There are no up-front costs. Your card will be charged a monthly fee beginning at the end of your first billing cycle.</p>',

        [FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ7_QUESTION]:
            'Who can subscribe to HP Instant Ink Platinum?',
        [FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ7_ANSWER]:
            '<p>HP Instant Ink Platinum is for individuals, families, and businesses. It is not necessary to already own an eligible HP printer or have an existing subscription to Instant Ink. Current HP Instant Ink subscribers can easily upgrade their subscription to Platinum and receive a new printer, a next-business-day-replacement warranty, 24/7 hardware support, and automatic eligibility for a printer refresh every two years.</p>',

        [FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ8_QUESTION]:
            'I already have an HP printer. Can I enroll in the HP Instant Ink Platinum service?',
        [FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ8_ANSWER]:
            '<p>Yes. However, you will get a new printer shipped to you upon signing up for a Platinum subscription. Currently it is not possible to enroll in Platinum a printer you purchased outside of Platinum.</p>',

        [FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ9_QUESTION]:
            'Can I enroll in HP Instant Ink Platinum if I live outside the United States?',
        [FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ9_ANSWER]:
            '<p>Currently, the service is only available in the continental United States.</p>',

        [FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ10_QUESTION]:
            'What happens if I move to a new address?',
        [FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ10_ANSWER]:
            '<p>You would notify us of your address change, and we will ensure your ink and printer deliveries are sent to your new address. If you move to an address outside the continental United States, unfortunately your subscription will be cancelled, and you may be charged an early cancellation fee.</p>',

        [FAQSectionMessages.ABOUT_SERVICE_TITLE]: 'About the ink service',

        [FAQSectionMessages.ABOUT_SERVICE_FAQ0_QUESTION]:
            'How do printing plans work?',
        [FAQSectionMessages.ABOUT_SERVICE_FAQ0_ANSWER]:
            ABOUT_SERVICE_FAQ0_ANSWER,
        [FAQSectionMessagesB.ABOUT_SERVICE_FAQ0_ANSWER]:
            ABOUT_SERVICE_FAQ0_ANSWER,
        [FAQSectionMessagesC.ABOUT_SERVICE_FAQ0_ANSWER]:
            ABOUT_SERVICE_FAQ0_ANSWER,

        [FAQSectionMessages.ABOUT_SERVICE_FAQ1_QUESTION]:
            'What kind of printing plans are available?',
        [FAQSectionMessages.ABOUT_SERVICE_FAQ1_ANSWER]:
            ABOUT_SERVICE_FAQ1_ANSWER,
        [FAQSectionMessagesB.ABOUT_SERVICE_FAQ1_ANSWER]:
            ABOUT_SERVICE_FAQ1_ANSWER,
        [FAQSectionMessagesC.ABOUT_SERVICE_FAQ1_ANSWER]:
            ABOUT_SERVICE_FAQ1_ANSWER,

        [FAQSectionMessages.ABOUT_SERVICE_FAQ2_QUESTION]:
            'What if my printing exceeds my monthly allowance?',
        [FAQSectionMessages.ABOUT_SERVICE_FAQ2_ANSWER]:
            '<p>If you print more than the allowance for your printing plan, you can use rollover pages to cover the difference, or purchase additional page blocks.</p>',

        [FAQSectionMessages.ABOUT_SERVICE_FAQ3_QUESTION]:
            'How do rollover pages and additional pages work?',
        [FAQSectionMessages.ABOUT_SERVICE_FAQ3_ANSWER]:
            '<p>If you do not use all your plan pages in a month, the unused pages will be kept in your account as rollover pages. You can continue to roll over up to three times the number of pages in your monthly plan (for example, you can roll over up to 150 pages if you are enrolled in a 50-page plan).</p> <p>If you have printed all the pages in your plan and you have used all your rollover pages, you can still easily print additional pages. Additional pages are available in sets of 10-15 pages, depending on your plan, for only $1.00 per set.</p> <p>As long as your printer is connected to the internet, we will send you an email to remind you when you are near printing all the pages in your plan. You can easily change your monthly plan by logging in at hpinstantink.com/users/sign-in and clicking Change Plan. Since you can choose to make a plan upgrade effective immediately, you can choose to upgrade your plan rather than purchasing additional pages.</p>',

        [FAQSectionMessages.ABOUT_SERVICE_FAQ4_QUESTION]:
            'Can I change my printing plan?',
        [FAQSectionMessages.ABOUT_SERVICE_FAQ4_ANSWER]:
            '<p>Yes. You can change your printing plan anytime by logging in at hpinstantink.com/users/sign-in and clicking Change Plan. Since you can choose to make a plan upgrade effective immediately, you can choose to upgrade your plan rather than purchasing additional pages.</p>',

        [FAQSectionMessages.ABOUT_SERVICE_FAQ5_QUESTION]:
            'What if my printing needs change from month to month?',
        [FAQSectionMessages.ABOUT_SERVICE_FAQ5_ANSWER]:
            "<p>Instant Ink plans are flexible. Unused pages roll over, up to three times your monthly plan amount. If you print more, you'll automatically purchase sets of 10-15 additional pages for $1.00 (pages per set varies by plan).</p> <p>From your account page at hpinstantink.com/users/sign-in, you can upgrade your plan in the current billing cycle or in the next billing cycle. Downgrades are effective in the next billing cycle.</p> <p>For more details, see the HP Instant Ink Terms of Service.</p>",

        [FAQSectionMessages.ABOUT_SERVICE_FAQ6_QUESTION]:
            'Is the cost the same for photos, color, and black and white pages?',
        [FAQSectionMessages.ABOUT_SERVICE_FAQ6_ANSWER]:
            ABOUT_SERVICE_FAQ6_ANSWER,
        [FAQSectionMessagesB.ABOUT_SERVICE_FAQ6_ANSWER]:
            ABOUT_SERVICE_FAQ6_ANSWER,
        [FAQSectionMessagesC.ABOUT_SERVICE_FAQ6_ANSWER]:
            ABOUT_SERVICE_FAQ6_ANSWER,

        [FAQSectionMessages.ABOUT_SERVICE_FAQ7_QUESTION]:
            ABOUT_SERVICE_FAQ7_QUESTION,
        [FAQSectionMessagesB.ABOUT_SERVICE_FAQ7_QUESTION]:
            ABOUT_SERVICE_FAQ7_QUESTION,
        [FAQSectionMessagesC.ABOUT_SERVICE_FAQ7_QUESTION]:
            ABOUT_SERVICE_FAQ7_QUESTION,
        [FAQSectionMessages.ABOUT_SERVICE_FAQ7_ANSWER]:
            ABOUT_SERVICE_FAQ7_ANSWER,
        [FAQSectionMessagesB.ABOUT_SERVICE_FAQ7_ANSWER]:
            ABOUT_SERVICE_FAQ7_ANSWER,
        [FAQSectionMessagesC.ABOUT_SERVICE_FAQ7_ANSWER]:
            ABOUT_SERVICE_FAQ7_ANSWER,

        [FAQSectionMessages.ABOUT_SERVICE_FAQ8_QUESTION]:
            ABOUT_SERVICE_FAQ8_QUESTION,
        [FAQSectionMessagesB.ABOUT_SERVICE_FAQ8_QUESTION]:
            ABOUT_SERVICE_FAQ8_QUESTION,
        [FAQSectionMessagesC.ABOUT_SERVICE_FAQ8_QUESTION]:
            ABOUT_SERVICE_FAQ8_QUESTION,

        [FAQSectionMessages.ABOUT_SERVICE_FAQ8_ANSWER]:
            ABOUT_SERVICE_FAQ8_ANSWER,
        [FAQSectionMessagesB.ABOUT_SERVICE_FAQ8_ANSWER]:
            ABOUT_SERVICE_FAQ8_ANSWER,
        [FAQSectionMessagesC.ABOUT_SERVICE_FAQ8_ANSWER]:
            ABOUT_SERVICE_FAQ8_ANSWER,

        [FAQSectionMessages.ABOUT_PRINTER_TITLE]: 'About the printer',

        [FAQSectionMessages.ABOUT_PRINTER_FAQ0_QUESTION]:
            'Can I choose the printer I get with my subscription?',
        [FAQSectionMessages.ABOUT_PRINTER_FAQ0_ANSWER]:
            ABOUT_PRINTER_FAQ0_ANSWER,
        [FAQSectionMessagesB.ABOUT_PRINTER_FAQ0_ANSWER]:
            ABOUT_PRINTER_FAQ0_ANSWER,
        [FAQSectionMessagesC.ABOUT_PRINTER_FAQ0_ANSWER]:
            ABOUT_PRINTER_FAQ0_ANSWER,
        [FAQSectionMessagesD.ABOUT_PRINTER_FAQ0_ANSWER]:
            ABOUT_PRINTER_FAQ0_ANSWER,

        [FAQSectionMessages.ABOUT_PRINTER_FAQ1_QUESTION]:
            'Can I keep the printer if I cancel the service?',
        [FAQSectionMessages.ABOUT_PRINTER_FAQ1_QUESTION_ACTIVATION]:
            'Do I have to purchase an HP printer to start a subscription?',
        [FAQSectionMessages.ABOUT_PRINTER_FAQ1_ANSWER]:
            '<p>If you decide to cancel your subscription for any reason, you may elect to keep your printer, or return it to HP. An early termination fee may apply.</p>',
        [FAQSectionMessages.ABOUT_PRINTER_FAQ1_ANSWER_ACTIVATION]:
            '<p>No. The cost of the printer is included in the service’s all-inclusive monthly fee. In addition to the monthly fee, the service requires a one-time $39 printer activation fee to receive and activate a subscription printer.</p>',

        [FAQSectionMessages.ABOUT_PRINTER_FAQ2_QUESTION]:
            'Can I purchase my HP printer?',
        [FAQSectionMessages.ABOUT_PRINTER_FAQ2_QUESTION_ACTIVATION]:
            'What do I do with my subscription printer when I cancel or opt for a printer refresh?',
        [FAQSectionMessages.ABOUT_PRINTER_FAQ2_ANSWER]:
            '<p>A printer purchase is not required to obtain the service, and there are no up-front costs. Your monthly subscription fee includes the cost of the printer as well as the included services.</p> <p>After two years, if you elect to refresh your printer, you will receive a new printer from HP and may choose to keep your current printer as well at no charge, or return it to HP.</p> <p>If you decide to cancel your service for any reason, you may elect to keep your printer, or return it to HP. If you cancel your subscription within one year of receiving and enrolling your printer or printer refresh, the early cancelation fee is the list price of the printer. If you cancel between one and two years, the cancelation fee is half the list price of the printer. If you cancel more than two years after receiving and enrolling a printer or printer refresh, there is no cancelation fee.</p>',
        [FAQSectionMessages.ABOUT_PRINTER_FAQ2_ANSWER_ACTIVATION]:
            '<p>After two years, when you elect to refresh your printer, you will receive a new printer from HP and return your old printer to HP, using pre-paid shipping label provided by HP.</p> <p>If you decide to cancel your service for any reason, you must return your subscription printer to HP using the pre-paid shipping label provided by HP. There are no cancelation fees.</p>',

        [FAQSectionMessages.ABOUT_PRINTER_FAQ3_QUESTION]:
            'What happens if I have trouble with my printer?',
        [FAQSectionMessages.ABOUT_PRINTER_FAQ3_ANSWER]:
            '<p>The HP customer support team is available to you for hardware support 24/7. As long as your Platinum subscription remains active, your printer is covered under warranty. HP customer support technicians use remote tools for diagnosis and repair, and if a hardware problem cannot be resolved, the technician will initiate shipment to you of a next-business-day replacement. HP will pay for shipping the replacement to you, as well as return shipping on the printer being replaced.</p>',

        [FAQSectionMessages.ABOUT_PRINTER_FAQ4_QUESTION]:
            'What happens if the printer gets lost, stolen, or damaged?',
        [FAQSectionMessages.ABOUT_PRINTER_FAQ4_ANSWER]:
            '<p>While HP provides a warranty to cover defects for as long as your HP Instant Ink subscription remains active, you are responsible should the printer be lost, stolen, or unusable due to damage while in your possession. In this case, you should call HP Instant Ink Platinum Customer Support and assume the cost of the printer replacement and shipping.</p>',

        [FAQSectionMessages.GETTING_STARTED_TITLE]: 'Getting started',

        [FAQSectionMessages.GETTING_STARTED_FAQ0_QUESTION]:
            'How do I start my HP Instant Ink Platinum subscription?',

        [FAQSectionMessages.GETTING_STARTED_FAQ0_ANSWER]:
            GETTING_STARTED_FAQ0_ANSWER,
        [FAQSectionMessagesB.GETTING_STARTED_FAQ0_ANSWER]:
            GETTING_STARTED_FAQ0_ANSWER,
        [FAQSectionMessagesC.GETTING_STARTED_FAQ0_ANSWER]:
            GETTING_STARTED_FAQ0_ANSWER,
        [FAQSectionMessagesD.GETTING_STARTED_FAQ0_ANSWER]:
            GETTING_STARTED_FAQ0_ANSWER,

        [FAQSectionMessages.GETTING_STARTED_FAQ1_QUESTION]:
            'How does the 30-day risk-free trial work?',
        [FAQSectionMessages.GETTING_STARTED_FAQ1_QUESTION_ACTIVATION]:
            'What do I need to sign up?',
        [FAQSectionMessages.GETTING_STARTED_FAQ1_ANSWER]:
            '<p>If you cancel your subscription within 30 days of signup and return the printer to HP within 30 days of canceling, you will not be charged anything.</p>',
        [FAQSectionMessages.GETTING_STARTED_FAQ1_ANSWER_ACTIVATION]:
            '<p>You must have a valid email address and credit card. You’ll also need to provide your name and address so we can ship you a new printer. You’ll need to keep that address up to date at all times for ink shipments.</p> <p>Once you receive the printer you selected, you will complete your enrollment in HP Instant Ink Platinum service during printer set-up. Failure to complete enrollment within 30 days of initiating enrollment in Platinum will result in your credit card being charged the list price of the printer. You will need to keep your printer powered on and continuously connected to the internet via Wi-Fi.</p>',

        [FAQSectionMessages.GETTING_STARTED_FAQ2_QUESTION]:
            'How can I see my usage and billing?',
        [FAQSectionMessages.GETTING_STARTED_FAQ2_QUESTION_ACTIVATION]:
            'Does it cost anything to sign up?',
        [FAQSectionMessages.GETTING_STARTED_FAQ2_ANSWER]:
            "<p>Log in to your account at hpinstantink.com/users/sign-in. Here you can see how many pages you've printed, available rollover pages, estimated next bill, and much more. You can update your payment method, change your address or your plan, and even chat with a support agent.</p>",
        [FAQSectionMessages.GETTING_STARTED_FAQ2_ANSWER_ACTIVATION]:
            '<p>You’ll pay only a one-time $39 printer activation fee when you sign.  The costs of the printer, ink, support, and other services are included in the all-inclusive monthly fee.</p>',

        [FAQSectionMessages.GETTING_STARTED_FAQ3_QUESTION]:
            'When does billing start?',
        [FAQSectionMessages.GETTING_STARTED_FAQ3_QUESTION_ACTIVATION]:
            'Are there any fees owed if I decide to cancel the service?',
        [FAQSectionMessages.GETTING_STARTED_FAQ3_ANSWER]:
            '<p>Your HP Instant Ink Platinum service starts the day you enroll. The first billing cycle will end about 30 days after you complete enrollment. If you are an existing customer, your first bill for Platinum will be after your first full billing cycle with the Platinum service.</p> <p>If you are already an HP Instant Ink customer and are upgrading to HP Instant Ink Platinum service, your current billing cycle will continue, and your new monthly fee will reflect your Platinum combined monthly fee for printer, ink, and other Platinum services.</p>',
        [FAQSectionMessages.GETTING_STARTED_FAQ3_ANSWER_ACTIVATION]:
            '<p>There is no fee to cancel the service, as long as you return the printer to HP in an operable and undamaged condition within 30 days of the end of your current billing cycle.  Failure to return the printer will result in you being charged an equipment fee of $160 (Envy), $200 (Envy Inspire), or $290 (OfficeJet Pro) if you cancel in the first year or $80 (Envy), $100 (Envy Inspire), or $145 (OfficeJet Pro) if you cancel after the first year.</p>',

        [FAQSectionMessages.GETTING_STARTED_FAQ4_QUESTION]:
            'How does the ongoing billing work?',
        [FAQSectionMessages.GETTING_STARTED_FAQ4_QUESTION_ACTIVATION]:
            'How does the 30-day risk-free trial work?',
        [FAQSectionMessages.GETTING_STARTED_FAQ4_ANSWER]:
            '<p>You will be charged each month at the end of the billing cycle. Since your monthly plan fee is the same every month, we will not send you a bill prior to charging your monthly fee, unless you print additional pages.</p> <p>When you print additional pages during your billing cycle, we will send you an email at the end of your billing cycle that lists your monthly fee and the charges for any additional sets of pages you printed. Remember to keep your printer connected to the internet, so you can get notifications about your HP Instant Ink account. We will send you an automatic renewal notification on an annual basis giving you the option to cancel your subscription, noting any early cancelation fee.</p>',
        [FAQSectionMessages.GETTING_STARTED_FAQ4_ANSWER_ACTIVATION]:
            '<p>If you cancel your subscription within 30 days of signup and return the printer to HP in an operational and undamaged condition within 30 days of canceling, HP will refund your $39 printer activation fee, and you will not be charged anything.</p>',

        [FAQSectionMessages.GETTING_STARTED_FAQ5_QUESTION]:
            'How do I cancel HP Instant Ink Platinum?',
        [FAQSectionMessages.GETTING_STARTED_FAQ5_QUESTION_ACTIVATION]:
            'How can I see my usage and billing?',
        [FAQSectionMessages.GETTING_STARTED_FAQ5_ANSWER]:
            '<p>You can change or cancel the service any time by clicking <a>instantink.hpconnected.com/es/es/l/v2|here</a> to cancel online or by calling HP Instant Ink Platinum Customer Care at 1-888-447-0148.</p> <p>Platinum subscriptions come with a 30-day no-obligation trial period. If you cancel your subscription within 30 days of signup and return the printer to HP within ten days of canceling, you will not be charged.</p> <p>After that, if you cancel your subscription within the first year after completing enrollment in Platinum or transferring Platinum service to your printer refresh, you must pay a cancelation fee of $160 for Envy, $200 for Envy Inspire, or $270 for OfficeJet Pro. If you cancel your subscription within the second year, the cancelation fee is $80 for Envy, $100 for Envy Inspire, or $135 for OfficeJet Pro. If you cancel your subscription more than two years after completing enrollment or transferring service to your refresh, there is no cancelation fee.  If you cancel your service, you have the option to keep the printer or return the printer to HP.</p> <p>If you do not cancel your service, your service will renew automatically each month.</p>',
        [FAQSectionMessages.GETTING_STARTED_FAQ5_ANSWER_ACTIVATION]:
            "<p>Log in to your account at <a>hpinstantink.com/users/sign-in</a>. Here you can see how many pages you've printed, available rollover pages, estimated next bill, and much more. You can update your payment method, change your address or your plan, and even chat with a support agent.</p>",

        [FAQSectionMessages.GETTING_STARTED_FAQ6_QUESTION]:
            'Are there any fees associated with canceling my subscription?',
        [FAQSectionMessages.GETTING_STARTED_FAQ6_QUESTION_ACTIVATION]:
            'When does billing start?',
        [FAQSectionMessages.GETTING_STARTED_FAQ6_ANSWER]:
            GETTING_STARTED_FAQ6_ANSWER,
        [FAQSectionMessagesB.GETTING_STARTED_FAQ6_ANSWER]:
            GETTING_STARTED_FAQ6_ANSWER,
        [FAQSectionMessagesC.GETTING_STARTED_FAQ6_ANSWER]:
            GETTING_STARTED_FAQ6_ANSWER,
        [FAQSectionMessagesD.GETTING_STARTED_FAQ6_ANSWER]:
            GETTING_STARTED_FAQ6_ANSWER,
        [FAQSectionMessages.GETTING_STARTED_FAQ6_ANSWER_ACTIVATION]:
            '<p>Your HP Instant Ink Platinum service starts the day you enroll. The first billing cycle will end about 30 days after you complete enrollment. If you are an existing customer, your first bill for Platinum will be after your first full billing cycle with the Platinum service.</p> <p>If you are already an HP Instant Ink customer and are upgrading to HP Instant Ink Platinum service, your current billing cycle will continue, and your new monthly fee will reflect your Platinum combined monthly fee for printer, ink, and other Platinum services.</p>',

        [FAQSectionMessages.GETTING_STARTED_FAQ7_QUESTION]:
            'If I cancel my subscription, can I keep the printer?',
        [FAQSectionMessages.GETTING_STARTED_FAQ7_QUESTION_ACTIVATION]:
            'How does the ongoing billing work?',
        [FAQSectionMessages.GETTING_STARTED_FAQ7_ANSWER]:
            'Yes. If you elect to cancel your service, you have the option to keep the printer, or you may return the printer to HP.',
        [FAQSectionMessages.GETTING_STARTED_FAQ7_ANSWER_ACTIVATION]:
            '<p>You will be charged each month at the end of the billing cycle. Since your monthly plan fee is the same every month, we will not send you a bill prior to charging your monthly fee, unless you print additional pages.</p> <p>When you print additional pages during your billing cycle, we will send you an email at the end of your billing cycle that lists your monthly fee and the charges for any additional sets of pages you printed. Remember to keep your printer connected to the internet, so you can get notifications about your HP Instant Ink account. We will send you an automatic renewal notification on an annual basis giving you the option to cancel your subscription, noting any early cancelation fee.</p>',

        [FAQSectionMessages.GETTING_STARTED_FAQ8_QUESTION_ACTIVATION]:
            'How do I cancel HP Instant Ink Platinum?',
        [FAQSectionMessages.GETTING_STARTED_FAQ8_ANSWER_ACTIVATION]:
            '<p>You can change or cancel the service any time by clicking <a>instantink.hpconnected.com/es/es/l/v2|here</a> to cancel online or by calling HP Instant Ink Platinum Customer Care at 1-888-447-0148.</p> <p>Platinum subscriptions come with a 30-day no-obligation trial period. If you cancel your subscription within 30 days of signup and return the printer to HP within ten days of canceling, you will not be charged.</p> <p>After that, if you cancel your subscription within the first year after completing enrollment in Platinum or transferring Platinum service to your printer refresh, you must pay a cancelation fee of $160 for Envy, $200 for Envy Inspire, or $270 for OfficeJet Pro. If you cancel your subscription within the second year, the cancelation fee is $80 for Envy, $100 for Envy Inspire, or $135 for OfficeJet Pro. If you cancel your subscription more than two years after completing enrollment or transferring service to your refresh, there is no cancelation fee.  If you cancel your service, you have the option to keep the printer or return the printer to HP.</p> <p>If you do not cancel your service, your service will renew automatically each month.</p>',
        [FAQSectionMessages.GETTING_STARTED_FAQ9_QUESTION_ACTIVATION]:
            'If I cancel my subscription, can I keep the printer?',
        [FAQSectionMessages.GETTING_STARTED_FAQ9_ANSWER_ACTIVATION]:
            '<p>Yes. If you wish to keep the printer, you can pay an equipment fee of up to $160 (Envy), $200 (Envy Inspire) or $270 (OfficeJet Pro), depending on how long after enrollment you cancel. </p><p>If you return the printer to HP within 30 days of the end of the billing cycle in which you cancel, there is no fee to cancel the service.</p>',
    },
    [LOCALES.SPANISH]: {
        [FAQSectionMessages.TITLE]: 'Preguntas frecuentes',
        [FAQSectionMessages.TITLE_LONG]: 'Preguntas frecuentes',

        [FAQSectionMessages.ABOUT_SUBSCRIPTION_TITLE]:
            'Acerca de la suscripción',

        [FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ0_QUESTION]:
            '¿Qué es el servicio de suscripción HP Instant Ink Platinum?',
        [FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ0_ANSWER]:
            '<p>HP Instant Ink Platinum es un programa de suscripción de pago mensual a un servicio de impresión con todo incluido. Este pago incluye la impresora, el envío de cartuchos de forma automática solo cuando los necesites, un servicio de atención al cliente personalizado, garantía ilimitada de la impresora mientras esté activa la suscripción y la certificación de uso CarbonNeutral®. La suscripción también incluye la opción de renovar la impresora después de tres años.</p>',
        [FAQSectionMessagesB.ABOUT_SUBSCRIPTION_FAQ0_ANSWER]:
            '<p>HP Instant Ink Platinum es un programa de suscripción de pago mensual a un servicio de impresión con todo incluido. Este pago incluye la impresora, el envío de tinta de forma automática solo cuando los necesites, un servicio de atención al cliente personalizado, garantía ilimitada de la impresora mientras esté activa la suscripción y la certificación de uso CarbonNeutral®. La suscripción también incluye la opción de renovar la impresora después de tres años.</p>',

        [FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ1_QUESTION]:
            '¿Cuál es la diferencia entre el servicio de suscripción de impresión de HP y el servicio estándar de HP Instant Ink?',
        [FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ1_ANSWER]:
            '<p>Cuando te registras en el servicio estándar de HP Instant Ink, debes poseer una impresora que cumpla los requisitos, y el servicio te proporciona tinta antes de que se agote. Con HP Instant Ink Platinum, no tienes que poseer una impresora ni preocuparte por el mantenimiento o las averías. Tu suscripción incluye la impresora, así como una garantía ilimitada mientras esté activa la suscripción, atención al cliente personalizada y la posibilidad de renovar la impresora al cabo de tres años.</p>',

        [FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ2_QUESTION]:
            '¿Qué incluye el servicio de suscripción de impresión de HP?',
        [FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ2_ANSWER]: `<ul>
                <li>Una nueva impresora de tu elección: HP OfficeJet Pro, HP Envy o HP Envy Inspire</li>
                <li>Servicio de entrega de cartuchos HP Instant Ink</li>
                <li>Garantía ilimitada mientras mantengas activa tu suscripción</li>
                <li>Atención al cliente personalizada de lunes a domingo de 9h a 18h</li>
                <li>Posibilidad de renovar la impresora al cabo de tres años</li>
                <li>Impresión certificada por CarbonNeutral®</li>
            </ul>`,
        [FAQSectionMessagesB.ABOUT_SUBSCRIPTION_FAQ2_ANSWER]: `<ul>
            <li>Una nueva impresora de tu elección: HP Smart Tank, HP OfficeJet Pro, HP Envy o HP Envy Inspire</li>
            <li>Servicio de entrega de tinta HP Instant Ink</li>
            <li>Garantía ilimitada mientras mantengas activa tu suscripción</li>
            <li>Atención al cliente personalizada de lunes a domingo de 9h a 18h</li>
            <li>Posibilidad de renovar la impresora al cabo de tres años</li>
            <li>Impresión certificada por CarbonNeutral®</li>
        </ul>`,
        [FAQSectionMessagesC.ABOUT_SUBSCRIPTION_FAQ2_ANSWER]: `<ul>
            <li>Una nueva impresora de tu elección: HP Smart Tank, HP Envy o HP Envy Inspire</li>
            <li>Servicio de entrega de tinta HP Instant Ink</li>
            <li>Garantía ilimitada mientras mantengas activa tu suscripción</li>
            <li>Atención al cliente personalizada de lunes a domingo de 9h a 18h</li>
            <li>Posibilidad de renovar la impresora al cabo de tres años</li>
            <li>Impresión certificada por CarbonNeutral®</li>
        </ul>`,
        [FAQSectionMessagesD.ABOUT_SUBSCRIPTION_FAQ2_ANSWER]: `<ul>
            <li>Una nueva impresora de tu elección: HP Smart Tank 7605, HP Smart Tank 5105, HP OfficeJet Pro, HP Envy o HP Envy Inspire</li>
            <li>Servicio de entrega de tinta HP Instant Ink</li>
            <li>Garantía ilimitada mientras mantengas activa tu suscripción</li>
            <li>Atención al cliente personalizada de lunes a domingo de 9h a 18h</li>
            <li>Posibilidad de renovar la impresora al cabo de tres años</li>
            <li>Impresión certificada por CarbonNeutral®</li>
        </ul>`,

        [FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ3_QUESTION]:
            '¿Cómo funciona la garantía?',
        [FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ3_ANSWER]:
            '<p>Mientras tu suscripción a HP Instant Ink Platinum permanezca activa, tu impresora estará cubierta por la garantía. Tienes acceso al servicio de atención al cliente personalizado de HP para cualquier problema de hardware. Los técnicos de HP utilizan herramientas remotas para el diagnóstico y la reparación, y si el problema no se puede resolver, el técnico valorará la necesidad de enviar una nueva impresora de sustitución al siguiente día laborable.</p>',

        [FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ4_QUESTION]:
            '¿Cómo funciona la opción de renovación de la impresora?',
        [FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ4_ANSWER]:
            '<p>Como cliente de HP Instant Ink Platinum podrás renovar tu impresora al cabo de tres años. No hay ningún cargo adicional por la renovación de tu impresora ni por el envío de la misma. Te notificaremos cuando cumplas los requisitos y te ofreceremos una selección de los últimos modelos de HP. Una vez que reúnas los requisitos, sólo tienes que renovarla cuando más te convenga.</p>',
        [FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ4_ANSWER_ACTIVATION]: ' ',

        [FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ5_QUESTION]:
            '¿Qué es la impresión certificada CarbonNeutral?',
        [FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ5_ANSWER]:
            '<p>De acuerdo con el <a>www.carbonneutral.com/the-carbonneutral-protocol|protocolo CarbonNeutral®</a>, la certificación cubre el uso de electricidad de tu impresora y las emisiones del ciclo de vida de su cartucho (producción, transporte, embalaje y gestión al final de la vida útil). HP Instant Ink Platinum recibió la certificación de uso CarbonNeutral® el 1 de Noviembre de 2021. HP calcula la huella de carbono total del uso con información exclusiva de tu impresora y compra créditos de carbono que se adhieren a los estándares de la Alianza Internacional de Reducción y Compensación de Carbono (ICROA) para compensar esas emisiones calculadas.</p>',

        [FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ6_QUESTION]:
            '¿Dónde puedo registrarme al servicio de suscripción de impresión de HP?',
        [FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ6_ANSWER]:
            '<p>Puedes registrarte en <a>platinum.hpinstantink.com/es/es/</a>. Para suscribirte selecciona una impresora y un plan de impresión, e introduce los datos de tu tarjeta de crédito y de envío. No hay costes iniciales. Se te cobrará una tarifa mensual a partir del final de tu primer ciclo de facturación. </p>',

        [FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ7_QUESTION]:
            '¿Quién puede registrarse al servicio de suscripción de impresión de HP?',
        [FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ7_ANSWER]:
            '<p>HP Instant Ink Platinum es para particulares, familias y pequeñas empresas. No es necesario ser propietario de una impresora HP o tener una suscripción previa a Instant Ink. Los suscriptores actuales de HP Instant Ink pueden actualizar fácilmente su suscripción a Platinum y recibir una nueva impresora, una garantía ilimitada mientras mantengan una suscripción activa, atención al cliente personalizada y la posibilidad de renovar la impresora al cabo de tres años.</p>',

        [FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ8_QUESTION]:
            'Ya tengo una impresora HP. ¿Puedo registrarme en el servicio de suscripción de impresión de HP?',
        [FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ8_ANSWER]:
            '<p>Sí. Sin embargo, se te enviará una impresora nueva al suscribirte a Platinum. Actualmente no es posible registrarse con una impresora que se haya adquirido fuera de Platinum.</p>',

        [FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ9_QUESTION]:
            '¿Puedo registrarme al servicio de suscripción de impresión de HP si vivo fuera de España peninsular?',
        [FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ9_ANSWER]:
            '<p>Actualmente, el servicio sólo está disponible en España peninsular e Islas Baleares. Actualmente no realizamos envíos a las Islas Canarias, Ceuta o Melilla.</p>',

        [FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ10_QUESTION]:
            '¿Qué ocurre si cambio de domicilio?',
        [FAQSectionMessages.ABOUT_SUBSCRIPTION_FAQ10_ANSWER]:
            '<p>Al notificarnos de tu cambio de dirección nos aseguraremos de que las entregas de tinta e impresoras se envíen a tu nueva dirección. Si te mudas fuera de España peninsular o de las Islas Baleares, lamentablemente se cancelará tu suscripción y se cobrará un cargo por cancelación anticipada si esto ocurre durante los primeros 6 meses de suscripción.</p>',

        // About service

        [FAQSectionMessages.ABOUT_SERVICE_TITLE]:
            'Acerca del servicio de tinta',

        [FAQSectionMessages.ABOUT_SERVICE_FAQ0_QUESTION]:
            '¿Cómo funcionan los planes de impresión?',
        [FAQSectionMessages.ABOUT_SERVICE_FAQ0_ANSWER]: `
            <p>
                Los planes de impresión de HP Instant Ink se basan en páginas, no en cartuchos. Tu eliges un plan de impresión basado en el número de páginas que deseas imprimir mensualmente. Tu plan mensual te permite utilizar los cartuchos de la suscripción para imprimir las páginas de tu plan e incluye la entrega y el reciclaje de la tinta.
            </p>
            
            <p>
                Las impresiones en color, blanco y negro y fotográficas cuestan lo mismo. Puedes renovar las páginas no utilizadas (hasta tres veces la cantidad de páginas de tu plan) o, si imprimes más, adquirirás automáticamente juegos de 10-15 páginas por 1€ (las páginas por juego varían según el plan).
            </p>
            
            <p>
                Te enviamos cartuchos HP Instant Ink cuando te suscribes por primera vez al servicio. Los cartuchos de repuesto se envían cuando tu impresora indica a HP que necesita más tinta, no mensualmente.
            </p>
            
            <p>
                Tu plan es flexible y puede cambiarse en cualquier momento para adaptarse a tus necesidades de impresión.
            </p>
            `,
        [FAQSectionMessagesB.ABOUT_SERVICE_FAQ0_ANSWER]: `
            <p>
                Los planes de impresión de HP Instant Ink se basan en páginas. Tu eliges un plan de impresión basado en el número de páginas que deseas imprimir mensualmente. Tu plan mensual te permite imprimir las páginas de tu plan e incluye la entrega y el reciclaje de la tinta.
            </p>
            
            <p>
                Las impresiones en color, blanco y negro y fotográficas cuestan lo mismo. Puedes renovar las páginas no utilizadas (hasta tres veces la cantidad de páginas de tu plan) o, si imprimes más, adquirirás automáticamente juegos de 10-15 páginas por 1€ (las páginas por juego varían según el plan).
            </p>
            
            <p>
                Te enviamos HP Instant Ink cuando te suscribes por primera vez al servicio. La tinta de repuesto se envían cuando tu impresora indica a HP que necesita más tinta, no mensualmente.
            </p>
            
            <p>
                Tu plan es flexible y puede cambiarse en cualquier momento para adaptarse a tus necesidades de impresión.
            </p>
            `,
        [FAQSectionMessagesC.ABOUT_SERVICE_FAQ0_ANSWER]: `
            <p>
                Los planes de impresión de HP Instant Ink se basan en páginas. Tu eliges un plan de impresión basado en el número de páginas que deseas imprimir mensualmente. Tu plan mensual te permite imprimir las páginas de tu plan e incluye la entrega y el reciclaje de la tinta.
            </p>
            
            <p>
                Las impresiones en color, blanco y negro y fotográficas cuestan lo mismo. Puedes renovar las páginas no utilizadas (hasta tres veces la cantidad de páginas de tu plan) o, si imprimes más, adquirirás automáticamente juegos de 10-15 páginas por 1€ (las páginas por juego varían según el plan).
            </p>
            
            <p>
                Te enviamos HP Instant Ink cuando te suscribes por primera vez al servicio. La tinta de repuesto se envían cuando tu impresora indica a HP que necesita más tinta, no mensualmente.
            </p>
            
            <p>
                Tu plan es flexible y puede cambiarse en cualquier momento para adaptarse a tus necesidades de impresión.
            </p>
        `,
        [FAQSectionMessages.ABOUT_SERVICE_FAQ1_QUESTION]:
            '¿Qué planes de impresión hay disponibles?',
        [FAQSectionMessages.ABOUT_SERVICE_FAQ1_ANSWER]:
            '<p>Los clientes de HP Instant Ink Platinum pueden elegir entre una serie de planes de impresión, desde la impresión esporádica con una asignación de 10 páginas al mes, hasta la impresión profesional con una asignación de 700 páginas mensuales.</p>',
        [FAQSectionMessagesB.ABOUT_SERVICE_FAQ1_ANSWER]:
            '<p>Los clientes de HP Instant Ink Platinum pueden elegir entre una serie de planes de impresión, desde la impresión esporádica con una asignación de 10 páginas al mes, hasta la impresión profesional plus con una asignación de 1500 páginas mensuales.</p>',
        [FAQSectionMessagesC.ABOUT_SERVICE_FAQ1_ANSWER]:
            '<p>Los clientes de HP Instant Ink Platinum pueden elegir entre una serie de planes de impresión, desde la impresión esporádica con una asignación de 10 páginas al mes, hasta la impresión profesional plus con una asignación de 1500 páginas mensuales.</p>',
        [FAQSectionMessages.ABOUT_SERVICE_FAQ2_QUESTION]:
            '¿Qué pasa si mi impresión supera mi asignación mensual?',
        [FAQSectionMessages.ABOUT_SERVICE_FAQ2_ANSWER]:
            '<p>Si imprimes más de lo permitido por tu plan de impresión, puedes utilizar las páginas acumuladas de meses anteriores para cubrir la diferencia o comprar juegos de páginas adicionales.</p>',

        [FAQSectionMessages.ABOUT_SERVICE_FAQ3_QUESTION]:
            '¿Cómo funcionan las páginas acumuladas y las páginas adicionales?',
        [FAQSectionMessages.ABOUT_SERVICE_FAQ3_ANSWER]: `
        <p>
            Si no utilizas todas las páginas de tu plan en un mes, las páginas no utilizadas se guardarán en tu cuenta como páginas acumuladas. Puedes acumular hasta tres veces el número de páginas de tu plan mensual (por ejemplo, puedes acumular hasta 150 páginas si estás suscrito en un plan de 50 páginas).
        </p>
        <p>
            Si ya has impreso todas las páginas de tu plan y has utilizado todas las páginas acumuladas, puedes imprimir fácilmente páginas adicionales. Las páginas adicionales están disponibles en juegos de 10-15 páginas, dependiendo de tu plan, por sólo 1€ por juego.
        </p>
        <p>
            Siempre que tu impresora esté conectada a Internet, te enviaremos un correo electrónico para recordarte cuándo estás a punto de imprimir todas las páginas de tu plan. Puedes cambiar fácilmente tu plan mensual iniciando sesión en <a>hpinstantink.com/signin</a> y haciendo click en Cambiar plan. Como puedes elegir una mejora en tu plan y hacerla efectiva inmediatamente, también puedes elegir actualizar tu plan en lugar de comprar páginas adicionales.
        </p>`,

        [FAQSectionMessages.ABOUT_SERVICE_FAQ4_QUESTION]:
            '¿Puedo modificar mi plan de impresión?',
        [FAQSectionMessages.ABOUT_SERVICE_FAQ4_ANSWER]:
            '<p>Sí. Puedes cambiar tu plan de impresión en cualquier momento iniciando sesión en <a>hpinstantink.com/signin</a> y haciendo clic en Cambiar plan. Como puedes elegir una mejora en tu plan y hacerla efectiva inmediatamente, también puedes elegir actualizar tu plan en lugar de comprar páginas adicionales.</p>',

        [FAQSectionMessages.ABOUT_SERVICE_FAQ5_QUESTION]:
            '¿Qué pasa si mis necesidades de impresión varían de mes a mes?',
        [FAQSectionMessages.ABOUT_SERVICE_FAQ5_ANSWER]: `
            <p>
                Los planes de HP Instant Ink son flexibles. Las páginas se acumulan hasta tres veces el importe mensual de tu plan. Si imprimes más, comprarás automáticamente juegos de 10-15 páginas adicionales por 1€ (las páginas por juego varían según el plan).
            </p>
            <p>
                Desde tu cuenta en <a>hpinstantink.com/signin</a> puedes actualizar tu plan en el ciclo de facturación actual o en el siguiente. Las reducciones de plan entran en vigor en el siguiente ciclo de facturación.
            </p>
            <p>
                Para obtener más información, consulta las <a>instantink.hpconnected.com/es/es/terms|Condiciones de servicio de HP Instant Ink.</a>
            </p>`,

        [FAQSectionMessages.ABOUT_SERVICE_FAQ6_QUESTION]:
            '¿El coste es el mismo para las fotos, el color y las páginas en blanco y negro?',
        [FAQSectionMessages.ABOUT_SERVICE_FAQ6_ANSWER]: `
            <p>
                Sí. Una de las ventajas de HP Instant Ink es que puedes imprimir fotos a color, documentos a color o páginas de alta calidad en blanco y negro, todo por el mismo precio.
            </p>
            <p>
                Dado que tu cargo mensual se basa en el modelo de impresora y en las páginas impresas, no en la cantidad de tinta que utilices, nunca tendrás que preocuparte por imprimir en blanco y negro para evitar utilizar tus cartuchos de tinta de color.
            </p>`,
        [FAQSectionMessagesB.ABOUT_SERVICE_FAQ6_ANSWER]: `
            <p>
                Sí. Una de las ventajas de HP Instant Ink es que puedes imprimir fotos a color, documentos a color o páginas de alta calidad en blanco y negro, todo por el mismo precio.
            </p>
            <p>
                Dado que tu cargo mensual se basa en el modelo de impresora y en las páginas impresas, no en la cantidad de tinta que utilices, nunca tendrás que preocuparte por imprimir en blanco y negro para evitar utilizar tu tinta de color.
            </p>`,
        [FAQSectionMessagesC.ABOUT_SERVICE_FAQ6_ANSWER]: `
            <p>
                Sí. Una de las ventajas de HP Instant Ink es que puedes imprimir fotos a color, documentos a color o páginas de alta calidad en blanco y negro, todo por el mismo precio.
            </p>
            <p>
                Dado que tu cargo mensual se basa en el modelo de impresora y en las páginas impresas, no en la cantidad de tinta que utilices, nunca tendrás que preocuparte por imprimir en blanco y negro para evitar utilizar tu tinta de color.
            </p>`,
        [FAQSectionMessages.ABOUT_SERVICE_FAQ7_QUESTION]:
            '¿Cómo puedo reciclar mis cartuchos de tinta?',
        [FAQSectionMessagesB.ABOUT_SERVICE_FAQ7_QUESTION]:
            '¿Cómo puedo reciclar mis cartuchos y botellas de tinta?',
        [FAQSectionMessagesC.ABOUT_SERVICE_FAQ7_QUESTION]:
            '¿Cómo puedo reciclar mis cartuchos y botellas de tinta?',

        [FAQSectionMessages.ABOUT_SERVICE_FAQ7_ANSWER]: `
            <p>
                Como parte del servicio, HP te enviará sobres de reciclaje con franqueo prepagado (incluidos con determinados envíos de tinta) sin coste adicional.
            </p>
            <p>
                Los cartuchos usados de HP Instant Ink deben devolverse en los sobres de reciclaje con franqueo prepagado proporcionados por HP a través del servicio de correo ordinario. Por favor rellena completamente el sobre antes de enviarlo. Los cartuchos devueltos serán reciclados por HP Planet Partners.
            </p>`,
        [FAQSectionMessagesB.ABOUT_SERVICE_FAQ7_ANSWER]: `
            <p>
                Como parte del servicio, HP te enviará sobres de reciclaje con franqueo prepagado (incluidos con determinados envíos de tinta) sin coste adicional.
            </p>
            <p>
                Los cartuchos usados de HP Instant Ink deben devolverse en los sobres de reciclaje con franqueo prepagado proporcionados por HP a través del servicio de correo ordinario. Por favor rellena completamente el sobre antes de enviarlo. Los cartuchos devueltos serán reciclados por HP Planet Partners.
            </p>
            Puede reciclar sus botellas de tinta a través de: <a>hp.com/hprecycle</a>.
            `,
        [FAQSectionMessagesC.ABOUT_SERVICE_FAQ7_ANSWER]: `
            <p>
                Como parte del servicio, HP te enviará sobres de reciclaje con franqueo prepagado (incluidos con determinados envíos de tinta) sin coste adicional.
            </p>
            <p>
                Los cartuchos usados de HP Instant Ink deben devolverse en los sobres de reciclaje con franqueo prepagado proporcionados por HP a través del servicio de correo ordinario. Por favor rellena completamente el sobre antes de enviarlo. Los cartuchos devueltos serán reciclados por HP Planet Partners.
            </p>
            Puede reciclar sus botellas de tinta a través de: <a>hp.com/hprecycle</a>.
        `,

        [FAQSectionMessages.ABOUT_SERVICE_FAQ8_QUESTION]:
            '¿Recibiré cartuchos todos los meses?',
        [FAQSectionMessagesB.ABOUT_SERVICE_FAQ8_QUESTION]:
            '¿Recibiré tinta todos los meses?',
        [FAQSectionMessagesC.ABOUT_SERVICE_FAQ8_QUESTION]:
            '¿Recibiré tinta todos los meses?',
        [FAQSectionMessages.ABOUT_SERVICE_FAQ8_ANSWER]: `
            <p>
                No, los cartuchos no se envían por meses. Sólo recibirás cartuchos cuando tu impresora nos indique que los necesita en función de tu consumo de tinta.
            </p>
            <p>
                Te enviamos cartuchos HP Instant Ink cuando te suscribas por primera vez al servicio, y los cartuchos de repuesto adicionales se envían según sea necesario, no mensualmente.
            </p>
            <p>
                Los cartuchos HP Instant Ink tienen una capacidad muy alta, por lo que la mayoría de los clientes no necesitan envíos frecuentes. Debes mantener tu impresora conectada a Internet y tu dirección de envío y forma de pago actualizadas para evitar la interrupción del servicio. Se requiere una suscripción activa para que los cartuchos Instant Ink impriman.
            </p>`,

        [FAQSectionMessagesB.ABOUT_SERVICE_FAQ8_ANSWER]: `
            <p>
                No, la tinta no se envían mensualmente. Sólo recibirás tinta cuando tu impresora nos indique que necesita en función de tu consumo de tinta.
            </p>
            <p>
                Te enviamos tinta HP Instant Ink cuando te suscribas por primera vez al servicio, y la tinta de repuesto adicionales se envían según sea necesario, no mensualmente.
            </p>
            <p>
                La tinta HP Instant Ink tienen una capacidad muy alta, por lo que la mayoría de los clientes no necesitan envíos frecuentes. Debes mantener tu impresora conectada a Internet y tu dirección de envío y forma de pago actualizadas para evitar la interrupción del servicio. Se requiere una suscripción activa para que los cartuchos y las botellas Instant Ink impriman.
            </p>`,
        [FAQSectionMessagesC.ABOUT_SERVICE_FAQ8_ANSWER]: `
            <p>
                No, la tinta no se envían mensualmente. Sólo recibirás tinta cuando tu impresora nos indique que necesita en función de tu consumo de tinta.
            </p>
            <p>
                Te enviamos tinta HP Instant Ink cuando te suscribas por primera vez al servicio, y la tinta de repuesto adicionales se envían según sea necesario, no mensualmente.
            </p>
            <p>
                La tinta HP Instant Ink tienen una capacidad muy alta, por lo que la mayoría de los clientes no necesitan envíos frecuentes. Debes mantener tu impresora conectada a Internet y tu dirección de envío y forma de pago actualizadas para evitar la interrupción del servicio. Se requiere una suscripción activa para que los cartuchos y las botellas Instant Ink impriman.
            </p>`,

        [FAQSectionMessages.ABOUT_PRINTER_TITLE]: 'Acerca de la impresora',

        [FAQSectionMessages.ABOUT_PRINTER_FAQ0_QUESTION]:
            '¿Puedo elegir la impresora que recibo con mi suscripción?',
        [FAQSectionMessages.ABOUT_PRINTER_FAQ0_ANSWER]:
            '<p>Sí. Cuando te suscribes HP Instant Ink Platinum, podrás elegir entre tres modelos de impresoras HP. El modelo que elijas, junto con el plan de impresión que selecciones, determinará tu cuota de suscripción mensual.</p>',
        [FAQSectionMessagesB.ABOUT_PRINTER_FAQ0_ANSWER]:
            '<p>Sí. Cuando te suscribes HP Instant Ink Platinum, podrás elegir entre cuatro modelos de impresoras HP. El modelo que elijas, junto con el plan de impresión que selecciones, determinará tu cuota de suscripción mensual.</p>',
        [FAQSectionMessagesC.ABOUT_PRINTER_FAQ0_ANSWER]:
            '<p>Sí. Cuando te suscribes HP Instant Ink Platinum, podrás elegir entre tres modelos de impresoras HP. El modelo que elijas, junto con el plan de impresión que selecciones, determinará tu cuota de suscripción mensual.</p>',
        [FAQSectionMessagesD.ABOUT_PRINTER_FAQ0_ANSWER]:
            '<p>Sí. Cuando te suscribes HP Instant Ink Platinum, podrás elegir entre cinco modelos de impresoras HP. El modelo que elijas, junto con el plan de impresión que selecciones, determinará tu cuota de suscripción mensual.</p>',

        [FAQSectionMessages.ABOUT_PRINTER_FAQ1_QUESTION]: ' ',
        [FAQSectionMessages.ABOUT_PRINTER_FAQ1_QUESTION_ACTIVATION]: ' ',
        [FAQSectionMessages.ABOUT_PRINTER_FAQ1_ANSWER]: ' ',
        [FAQSectionMessages.ABOUT_PRINTER_FAQ1_ANSWER_ACTIVATION]: ' ',

        [FAQSectionMessages.ABOUT_PRINTER_FAQ2_QUESTION]:
            '¿Puedo comprar mi impresora HP?',
        [FAQSectionMessages.ABOUT_PRINTER_FAQ2_ANSWER]: `
            <p>
            No es necesario adquirir una impresora por separado para obtener el servicio, la cuota mensual de suscripción incluye el coste de la impresora y de los servicios incluidos.
            </p>
            <p>
            No existe la opción de comprar la impresora Platinum directamente a HP.
            </p>`,
        [FAQSectionMessages.ABOUT_PRINTER_FAQ2_QUESTION_ACTIVATION]: ' ',
        [FAQSectionMessages.ABOUT_PRINTER_FAQ2_ANSWER_ACTIVATION]: ' ',

        [FAQSectionMessages.ABOUT_PRINTER_FAQ3_QUESTION]:
            '¿Qué ocurre si tengo problemas con la impresora?',
        [FAQSectionMessages.ABOUT_PRINTER_FAQ3_ANSWER]:
            '<p>El equipo de atención al cliente personalizado de HP está a tu disposición para ofrecerte asistencia de hardware. Mientras tu suscripción Platinum permanezca activa, tu impresora estará cubierta por la garantía. Los técnicos de atención al cliente de HP utilizan herramientas remotas para el diagnóstico y la reparación y, si no se puede resolver un problema de hardware, el técnico iniciará el envío de una sustituta para el siguiente día laborable. HP se hará cargo de los gastos de envío de la sustitución, así como de los gastos de devolución de la impresora sustituida.</p>',

        [FAQSectionMessages.ABOUT_PRINTER_FAQ4_QUESTION]:
            '¿Qué ocurre si pierdo, me roban o se estropea la impresora?',
        [FAQSectionMessages.ABOUT_PRINTER_FAQ4_ANSWER]:
            '<p>Aunque HP ofrece una garantía que cubre los defectos de fábrica durante el tiempo que permanezca activa tu suscripción, tú eres responsable en caso de pérdida, robo o inutilización de la impresora debido a daños mientras esté en tu posesión. En este caso, deberás llamar al servicio de atención al cliente de HP Instant Ink Platinum y asumir el coste de la sustitución de la impresora y del envío.</p>',

        [FAQSectionMessages.GETTING_STARTED_TITLE]: 'Primeros Pasos',

        [FAQSectionMessages.GETTING_STARTED_FAQ0_QUESTION]:
            '¿Cómo comienzo mi suscripción al servicio de suscripción de impresión de HP?',

        [FAQSectionMessages.GETTING_STARTED_FAQ0_ANSWER]: `
            <p>
                Puedes registrarte en línea en <a>platinum.hpinstantink.com/es/es/</a>. Para registrarte, deberás seleccionar una impresora y un plan de impresión, e introducir los datos de tu tarjeta de crédito y de envío. No hay costes iniciales. Te enviaremos tu impresora y, una vez que la recibas, deberás completar el proceso de registro llamando a nuestro equipo de soporte del servicio de suscripción de impresión de HP Instant Ink Platinum.
            </p>
            <p>
                Una vez recibida tu impresora, debes realizar el registro a través del servicio de asistencia en un plazo máximo de 30 días para evitar un cargo por no registro, al superar los 30 días de prueba, sin cancelar o registrar completamente la impresora. Este cargo de no registro es de: 90€ para Envy, 130€ para Envy Inspire o 250€ para OfficeJet Pro.
            </p>`,

        [FAQSectionMessagesB.GETTING_STARTED_FAQ0_ANSWER]: `
            <p>
                Puedes registrarte en línea en <a>platinum.hpinstantink.com/es/es/</a>. Para registrarte, deberás seleccionar una impresora y un plan de impresión, e introducir los datos de tu tarjeta de crédito y de envío. No hay costes iniciales. Te enviaremos tu impresora y, una vez que la recibas, deberás completar el proceso de registro llamando a nuestro equipo de soporte del servicio de suscripción de impresión de HP Instant Ink Platinum.
            </p>
            <p>
                Una vez recibida tu impresora, debes realizar el registro a través del servicio de asistencia en un plazo máximo de 30 días para evitar un cargo por no registro, al superar los 30 días de prueba, sin cancelar o registrar completamente la impresora. Este cargo de no registro es de: 90€ para Envy, 130€ para Envy Inspire, 250€ para OfficeJet Pro, o 400€ para Smart Tank.
            </p>`,
        [FAQSectionMessagesC.GETTING_STARTED_FAQ0_ANSWER]: `
            <p>
                Puedes registrarte en línea en <a>platinum.hpinstantink.com/es/es/</a>. Para registrarte, deberás seleccionar una impresora y un plan de impresión, e introducir los datos de tu tarjeta de crédito y de envío. No hay costes iniciales. Te enviaremos tu impresora y, una vez que la recibas, deberás completar el proceso de registro llamando a nuestro equipo de soporte del servicio de suscripción de impresión de HP Instant Ink Platinum.
            </p>
            <p>
                Una vez recibida tu impresora, debes realizar el registro a través del servicio de asistencia en un plazo máximo de 30 días para evitar un cargo por no registro, al superar los 30 días de prueba, sin cancelar o registrar completamente la impresora. Este cargo de no registro es de: 90€ para Envy, 130€ para Envy Inspire, o 400€ para Smart Tank.
            </p>`,
        [FAQSectionMessagesD.GETTING_STARTED_FAQ0_ANSWER]: `
            <p>
                Puedes registrarte en línea en <a>platinum.hpinstantink.com/es/es/</a>. Para registrarte, deberás seleccionar una impresora y un plan de impresión, e introducir los datos de tu tarjeta de crédito y de envío. No hay costes iniciales. Te enviaremos tu impresora y, una vez que la recibas, deberás completar el proceso de registro llamando a nuestro equipo de soporte del servicio de suscripción de impresión de HP Instant Ink Platinum.
            </p>
            <p>
                Una vez recibida tu impresora, debes realizar el registro a través del servicio de asistencia en un plazo máximo de 30 días para evitar un cargo por no registro, al superar los 30 días de prueba, sin cancelar o registrar completamente la impresora. Este cargo de no registro es de: 90€ para Envy, 130€ para Envy Inspire, 250€ para OfficeJet Pro, 260€ para Smart Tank 5105, o 400€ para Smart Tank 7605.
            </p>`,

        [FAQSectionMessages.GETTING_STARTED_FAQ1_QUESTION]:
            '¿Cómo funciona la prueba sin riesgo de 30 días?',
        [FAQSectionMessages.GETTING_STARTED_FAQ1_ANSWER]:
            '<p>Prueba el servicio sin compromiso durante 30 días. Si cancelas tu suscripción en un plazo de 30 días a partir de la fecha de registro y devuelves la impresora a HP en un plazo de 10 días a partir de la cancelación, no se te cobrará ninguna cuota mensual. Se aplica una tarifa de cancelación de 50€ en caso de cancelar el servicio durante los próximos 6 meses. El plan se renueva automáticamente cada mes.</p>',
        [FAQSectionMessages.GETTING_STARTED_FAQ1_QUESTION_ACTIVATION]: ' ',
        [FAQSectionMessages.GETTING_STARTED_FAQ1_ANSWER_ACTIVATION]: ' ',

        [FAQSectionMessages.GETTING_STARTED_FAQ2_QUESTION]:
            '¿Cómo puedo ver mi consumo y facturación?',
        [FAQSectionMessages.GETTING_STARTED_FAQ2_ANSWER]:
            '<p>Accede a tu cuenta en <a>hpinstantink.com/signin</a>. Aquí podrás ver cuántas páginas has impreso, las páginas acumuladas disponibles, la estimación de tu próxima factura y mucho más. Puedes actualizar tu método de pago, cambiar tu dirección o tu plan, e incluso chatear con un experto de nuestro equipo de asistencia.</p>',
        [FAQSectionMessages.GETTING_STARTED_FAQ2_QUESTION_ACTIVATION]: ' ',
        [FAQSectionMessages.GETTING_STARTED_FAQ2_ANSWER_ACTIVATION]: ' ',

        [FAQSectionMessages.GETTING_STARTED_FAQ3_QUESTION]:
            '¿Cuándo empieza la facturación?',
        [FAQSectionMessages.GETTING_STARTED_FAQ3_ANSWER]:
            '<p>Tu servicio HP Instant Ink Platinum comienza oficialmente el día de tu registro. El primer ciclo de facturación finalizará unos 30 días después de completar el registro. Pruébalo sin compromiso durante 30 días. Se aplica una tarifa de cancelación en caso de cancelar el servicio durante los próximos 6 meses. El plan se renueva automáticamente cada mes.</p><p>Si ya eres cliente de HP Instant Ink y te estás actualizando al servicio HP Instant Ink Platinum, tu ciclo de facturación actual continuará y tu nueva cuota mensual reflejará tu cuota mensual combinada Platinum para impresora, tinta y otros servicios Platinum.</p>',
        [FAQSectionMessages.GETTING_STARTED_FAQ3_QUESTION_ACTIVATION]: ' ',
        [FAQSectionMessages.GETTING_STARTED_FAQ3_ANSWER_ACTIVATION]: ' ',

        [FAQSectionMessages.GETTING_STARTED_FAQ4_QUESTION]:
            '¿Cómo funciona la facturación continua?',
        [FAQSectionMessages.GETTING_STARTED_FAQ4_ANSWER]:
            '<p>Se te cobrará cada mes al final del ciclo de facturación. Como la cuota mensual de tu plan es la misma todos los meses, no te enviaremos una factura antes de cobrar la cuota mensual a menos que imprimas páginas adicionales.</p><p>Cuando imprimas páginas adicionales durante tu ciclo de facturación, te enviaremos un correo electrónico al final de tu ciclo de facturación listando tu cuota mensual y los cargos por los juegos de páginas adicionales que hayas impreso. Recuerda mantener tu impresora conectada a Internet para recibir notificaciones sobre tu cuenta de HP Instant Ink.</p>',
        [FAQSectionMessages.GETTING_STARTED_FAQ4_QUESTION_ACTIVATION]: ' ',
        [FAQSectionMessages.GETTING_STARTED_FAQ4_ANSWER_ACTIVATION]: ' ',

        [FAQSectionMessages.GETTING_STARTED_FAQ5_QUESTION]:
            '¿Cómo cancelo el servicio de suscripción de impresión de HP?',
        [FAQSectionMessages.GETTING_STARTED_FAQ5_ANSWER]:
            '<p>Puedes cambiar o cancelar el servicio en cualquier momento haciendo clic <a>hpinstantink.com/signin/es-es|aquí</a> para cancelarlo en línea o llamando al servicio de atención al cliente personalizado del servicio  de HP Instant Ink Platinum. Si cancelas el servicio pasados los 30 dias de prueba y antes de los primeros 6 meses se aplica una tarifa de cancelación de 50 €.</p>',
        [FAQSectionMessages.GETTING_STARTED_FAQ5_QUESTION_ACTIVATION]: ' ',
        [FAQSectionMessages.GETTING_STARTED_FAQ5_ANSWER_ACTIVATION]: ' ',

        [FAQSectionMessages.GETTING_STARTED_FAQ6_QUESTION]:
            '¿Hay algún coste asociado a la cancelación de mi suscripción?',
        [FAQSectionMessages.GETTING_STARTED_FAQ6_ANSWER]: `
            <p>
                Puedes cancelar en cualquier momento. No hay gastos de cancelación salvo si cancelas el servicio durante los primeros 6 meses y después de haber superado los 30 días de prueba, solo en ese caso se aplica una tarifa de cancelación de 50€. 
            </p>
            <p>
                Debes devolver la impresora a HP. Si no devuelves la impresora a HP en un plazo de 10 días a partir de la cancelación, se te cobrará una cuota por no devolución. Durante el primer año el cargo por la no devolución de la impresora es de 81€ para Envy, 117€ para Envy Inspire y 225€ para OfficeJet Pro, y disminuirá con cada año que estés suscrito; consulte los términos y condiciones para conocer las tarifas detalladas. Puedes solicitar una etiqueta de envío llamando al soporte de HP Instant Ink Platinum.
            </p>`,
        [FAQSectionMessagesB.GETTING_STARTED_FAQ6_ANSWER]: `
            <p>
                Puedes cancelar en cualquier momento. No hay gastos de cancelación salvo si cancelas el servicio durante los primeros 6 meses y después de haber superado los 30 días de prueba, solo en ese caso se aplica una tarifa de cancelación de 50€. 
            </p>
            <p>
                Debes devolver la impresora a HP. Si no devuelves la impresora a HP en un plazo de 10 días a partir de la cancelación, se te cobrará una cuota por no devolución. Durante el primer año el cargo por la no devolución de la impresora es de 81€ para Envy, 117€ para Envy Inspire, 225€ para OfficeJet Pro, y 360€ para Smart Tank, y disminuirá con cada año que estés suscrito; consulte los términos y condiciones para conocer las tarifas detalladas. Puedes solicitar una etiqueta de envío llamando al soporte de HP Instant Ink Platinum.
            </p>`,
        [FAQSectionMessagesC.GETTING_STARTED_FAQ6_ANSWER]: `
        <p>
            Puedes cancelar en cualquier momento. No hay gastos de cancelación salvo si cancelas el servicio durante los primeros 6 meses y después de haber superado los 30 días de prueba, solo en ese caso se aplica una tarifa de cancelación de 50€. 
        </p>
        <p>
            Debes devolver la impresora a HP. Si no devuelves la impresora a HP en un plazo de 10 días a partir de la cancelación, se te cobrará una cuota por no devolución. Durante el primer año el cargo por la no devolución de la impresora es de 81€ para Envy, 117€ para Envy Inspire, y 360€ para Smart Tank, y disminuirá con cada año que estés suscrito; consulte los términos y condiciones para conocer las tarifas detalladas. Puedes solicitar una etiqueta de envío llamando al soporte de HP Instant Ink Platinum.
        </p>`,
        [FAQSectionMessagesD.GETTING_STARTED_FAQ6_ANSWER]: `
        <p>
            Puedes cancelar en cualquier momento. No hay gastos de cancelación salvo si cancelas el servicio durante los primeros 6 meses y después de haber superado los 30 días de prueba, solo en ese caso se aplica una tarifa de cancelación de 50€. 
        </p>
        <p>
            Debes devolver la impresora a HP. Si no devuelves la impresora a HP en un plazo de 10 días a partir de la cancelación, se te cobrará una cuota por no devolución. Durante el primer año el cargo por la no devolución de la impresora es de 81€ para Envy, 117€ para Envy Inspire, 225€ para OfficeJet Pro, 260€ para Smart Tank 5105, y 360€ para Smart Tank 7605, y disminuirá con cada año que estés suscrito; consulte los términos y condiciones para conocer las tarifas detalladas. Puedes solicitar una etiqueta de envío llamando al soporte de HP Instant Ink Platinum.
        </p>`,
        [FAQSectionMessages.GETTING_STARTED_FAQ6_QUESTION_ACTIVATION]: ' ',
        [FAQSectionMessages.GETTING_STARTED_FAQ6_ANSWER_ACTIVATION]: ' ',

        [FAQSectionMessages.GETTING_STARTED_FAQ7_QUESTION]:
            'Si cancelo mi suscripción, ¿puedo quedarme con la impresora?',
        [FAQSectionMessages.GETTING_STARTED_FAQ7_ANSWER]:
            'No, debes devolver la impresora a HP en el momento de la cancelación.',

        [FAQSectionMessages.GETTING_STARTED_FAQ7_QUESTION_ACTIVATION]: ' ',
        [FAQSectionMessages.GETTING_STARTED_FAQ7_ANSWER_ACTIVATION]: ' ',
        [FAQSectionMessages.GETTING_STARTED_FAQ8_QUESTION_ACTIVATION]: ' ',
        [FAQSectionMessages.GETTING_STARTED_FAQ8_ANSWER_ACTIVATION]: ' ',
        [FAQSectionMessages.GETTING_STARTED_FAQ9_QUESTION_ACTIVATION]: ' ',
        [FAQSectionMessages.GETTING_STARTED_FAQ9_ANSWER_ACTIVATION]: ' ',
    },
};
