import { LOCALES } from 'lang/locales';

export enum EnviromentMessages {
    TITLE = 'enviroment.title',
    CLIMATE = 'enviroment.climate',
    IMG_ALT = 'enviroment.imgAlt',
}

export const enviromentMessages = {
    [LOCALES.ENGLISH]: {
        [EnviromentMessages.TITLE]: 'Print responsibly',
        [EnviromentMessages.CLIMATE]:
            'Your HP Instant Ink Platinum service carries a\nCarbonNeutral usage certification³ and\nincludes responsible ink cartridge and printer\nrecycling.  We even provide pre-paid cartridge-\nreturn envelopes and pre-paid printer shipping\nlabels to make recycling easy.',
        [EnviromentMessages.IMG_ALT]: 'Enviroment',
    },
    [LOCALES.SPANISH]: {
        [EnviromentMessages.TITLE]: 'Imprime de forma\nresponsable',
        [EnviromentMessages.CLIMATE]:
            'El servicio HP Instant Ink Platinum cuenta\ncon la certificación de uso CarbonNeutral³\ne incluye el reciclaje responsable de\ncartuchos de tinta e impresoras³. Incluso,\nproporcionamos sobres prepagados para\ndevolver los cartuchos y etiquetas de envío\nprepagadas para facilitar el reciclaje.',
        [EnviromentMessages.IMG_ALT]: 'Clima',
    },
};
