import billingIcon from 'assets/checkout_billing_icon.svg';
import planIcon from 'assets/checkout_plan_icon.svg';
import shippingIcon from 'assets/checkout_shipping_icon.svg';
import { ModalType } from 'constants/modals';
import { useCheckoutModals, usePrinterChoice, useUserInfo } from 'contexts';
import { AvailablePrinters } from 'contexts/PrinterChoice/constants';
import { useFormat } from 'hooks';
import {
    CheckoutMessages,
    checkoutMessagesValues,
} from 'lang/messages/checkout';
import { CommonMessages, commonMessagesValues } from 'lang/messages/common';

import { BillingCard, Card, PlanCard, ShippingCard } from './components';
import { TEXTS } from './constants';
import { useCheckoutInfo } from './hooks';

import styles from './CardContainer.module.scss';

interface Props {
    isUpgrade?: boolean;
}

function CardContainer({ isUpgrade = false }: Props) {
    const { data, isRetail, onViewPlans } = useCheckoutInfo();
    const { shipping, billing } = useUserInfo();
    const { toggleModals } = useCheckoutModals();
    const { resetUserChoice } = usePrinterChoice();

    const checkoutText = useFormat(
        CheckoutMessages,
        checkoutMessagesValues({})
    );
    const { EDIT } = useFormat(CommonMessages, commonMessagesValues({}));

    const billingText = isUpgrade
        ? TEXTS(checkoutText, EDIT).billingUpgrade
        : TEXTS(checkoutText, EDIT).billing;

    const checkForSayanPrinter = () => {
        if (
            data.plan?.id === AvailablePrinters.LEBI ||
            data.plan?.id === AvailablePrinters.HighVolume
        ) {
            resetUserChoice(data.plan?.id);
            toggleModals(ModalType.SayanNotAvailable);
            return;
        }

        toggleModals(ModalType.Shipping);
    };

    return (
        <main className={styles.container}>
            <Card
                icon={<img src={planIcon} alt="" />}
                text={TEXTS(checkoutText, EDIT).plan}
                linkOnClick={onViewPlans}
                hideLink={isUpgrade}
                isChecked
                textAlignment="left"
            >
                <PlanCard data={data} isUpgrade={isUpgrade} />
            </Card>

            <Card
                icon={<img src={shippingIcon} alt="" />}
                text={TEXTS(checkoutText, EDIT, isRetail, isUpgrade).shipping}
                buttonOnClick={checkForSayanPrinter}
                linkOnClick={() => toggleModals(ModalType.Shipping)}
                isChecked={!!shipping}
            >
                {shipping ? <ShippingCard /> : null}
            </Card>

            <Card
                icon={<img src={billingIcon} alt="" />}
                text={billingText}
                hideButton={isUpgrade}
                buttonOnClick={() => toggleModals(ModalType.Billing)}
                linkOnClick={() => toggleModals(ModalType.Billing)}
                disableButton={!shipping}
                isChecked={!!billing}
            >
                {billing && !isUpgrade ? <BillingCard /> : null}
            </Card>
        </main>
    );
}

export default CardContainer;
