import classNames from 'classnames';

import styles from './Block.module.scss';

interface Props {
    item: { id: number; title: string; body: string; image: string };
}

function Block({ item: { id, title, body, image } }: Props) {
    return (
        <div
            key={id}
            className={classNames(styles.container, {
                [styles.first]: id === 0,
                [styles.second]: id === 1,
                [styles.third]: id === 2,
            })}
            style={{ backgroundImage: `url(${image})` }}
        >
            <div className={styles.textWrapper}>
                <h3>{title}</h3>
                <p>{body}</p>
            </div>
        </div>
    );
}
export default Block;
