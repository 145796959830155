import { IntlConfig } from 'react-intl';
import { LOCALES } from 'lang/locales';

export const i18nConfig: IntlConfig = {
    onWarn: () => {},
    locale: LOCALES.ENGLISH,
    defaultLocale: LOCALES.ENGLISH,
    defaultRichTextElements: {
        ol: (chunks) => <ol>{chunks}</ol>,
        ul: (chunks) => <ul>{chunks}</ul>,
        li: (chunks) => {
            const chunkStr = chunks?.join('');
            const parts = chunkStr.split('|');
            const value = parts[1];
            return value ? (
                <li value={value}>{parts[0]}</li>
            ) : (
                <li>{chunks}</li>
            );
        },
        a: (chunks) => {
            const chunkStr = chunks?.join('');
            const parts = chunkStr.split('|');
            const aHref = parts[0];
            return (
                <a
                    rel="noreferrer"
                    target="_blank"
                    href={
                        aHref.startsWith('/')
                            ? aHref.toLocaleLowerCase()
                            : `https://${aHref.toLocaleLowerCase()}`
                    }
                >
                    {parts[1] || parts[0]}
                </a>
            );
        },
        b: (chunks) => <b>{chunks}</b>,
        p: (chunks) => <p>{chunks}</p>,
        u: (chunks) => (
            <span style={{ textDecoration: 'underline' }}>{chunks}</span>
        ),
        h2: (chunks) => <h2>{chunks}</h2>,
        h3: (chunks) => <h3>{chunks}</h3>,
        h4: (chunks) => <h4>{chunks}</h4>,
        h5: (chunks) => <h5>{chunks}</h5>,
        span: (chunks) => <span>{chunks}</span>,
        sup: (chunks) => <sup>{chunks}</sup>,
        br: () => <br />,
        empty: () => <br />,
    },
};
