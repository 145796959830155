import styles from './ColumnHeader.module.scss';

interface Props {
    title: string;
}

function ColumnHeader({ title }: Props) {
    return (
        <>
            <h6 className={styles.sectionTitle}>{title}</h6>
            <hr />
        </>
    );
}
export default ColumnHeader;
