import PrinterImg from 'assets/printer.png';
import { Button, NavigationBar } from 'components';
import { ButtonVariants } from 'components/CustomButton/constants';
import { Footer } from 'screens/LandingPage/components';

import styles from './SpainSunset.module.scss';

function SpainSunset() {
    const handleDiscoverMore = () => {
        window.location.href =
            'https://instantink.hpconnected.com/es/es/l/v2?utm_source=Platinum&utm_medium=Sunset&utm_campaign=Oct2024';
    };

    return (
        <div className={styles.container}>
            <NavigationBar />
            <header className={styles.header}>
                <h1>Gracias por tu interés en Platinum</h1>
                <p>En estos momentos no aceptamos nuevas suscripciones</p>
                <img src={PrinterImg} alt="Printer" />
            </header>

            <main className={styles.main}>
                <h2>
                    Despreocúpate de los cartuchos para siempre y ahorra hasta
                    un 70% en tinta con el servicio HP Instant Ink
                </h2>
                <p>
                    Regístrate en nuestro servicio HP Instant Ink y recibe
                    automáticamente los cartuchos para tu impresora antes de que
                    se agoten
                </p>
                <Button
                    variant={ButtonVariants.square}
                    onClick={handleDiscoverMore}
                >
                    Descubre más
                </Button>
            </main>

            <Footer />

            {/* <footer className={styles.footer}>
                <ul>
                    <li>
                        <button type="button" onClick={redirectToTerms}>
                            Terminos y Condiciones Platinum
                        </button>
                    </li>
                    {brkpoint !== 'xs' && <li>|</li>}
                    <li>
                        <button type="button" onClick={handleGoToDashboard}>
                            Panel de control Platinum
                        </button>
                    </li>
                </ul>
            </footer> */}
        </div>
    );
}
export default SpainSunset;
