import { useFormat } from 'hooks';
import {
    FooterMessages,
    footerMessagesValues,
} from 'lang/messages/homeFooterBar';

import styles from './styles.module.scss';

interface Props {
    id: number;
    link: string;
    textId: keyof typeof FooterMessages;
}

function FooterLink({ link, textId, id }: Props) {
    const footerText = useFormat(FooterMessages, footerMessagesValues({}));
    return (
        <>
            {id > 0 && <span data-testid="separator"> | </span>}
            <a
                href={link}
                target="_blank"
                rel="noreferrer"
                className={styles.link}
            >
                {footerText[textId]}
            </a>
        </>
    );
}
export default FooterLink;
