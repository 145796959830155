import { useEffect } from 'react';
import { useLocation } from 'react-router-dom-v5-compat';
import { useQuery } from 'hooks';

import { QueryProps } from './constants';

function ScrollToTop() {
    const { pathname } = useLocation();
    const { printer } = useQuery<QueryProps>();

    useEffect(() => {
        if (!printer && !pathname.includes('terms')) {
            // Wait 20ms until page is present and loaded, then scroll

            setTimeout(() => {
                window.scrollTo({ top: 0 });
            }, 20);
        }
    }, [pathname, printer]);

    return null;
}

export default ScrollToTop;
