import { useFormat, useTranslation } from 'hooks';
import {
    FooterMessages,
    footerMessagesValues,
} from 'lang/messages/homeFooterBar';

import FooterLink from './components/FooterLink';
import { LINKS } from './constants';

import styles from './Footer.module.scss';

const currentYear = String(new Date().getFullYear());

function Footer() {
    const footerText = useFormat(
        FooterMessages,
        footerMessagesValues({ year: currentYear })
    );
    const { isSpain: isSpanish } = useTranslation();

    return (
        <footer className={styles.container}>
            <div className={styles.linkContainer}>
                {LINKS(isSpanish()).map(({ id, link, textId }) => (
                    <FooterLink id={id} link={link} textId={textId} key={id} />
                ))}
            </div>
            <p className={styles.copyrightText}>{footerText.COPYRIGHT}</p>
        </footer>
    );
}

export default Footer;
