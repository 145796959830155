import { TrackAction } from 'constants/analytics';
import { useTrackLink } from 'hooks';

function useRedirectOutside() {
    const trackLink = useTrackLink();

    const redirect = (whereTo: string, action: TrackAction) => {
        trackLink(action);
        window.location.href = whereTo;
    };

    return redirect;
}
export default useRedirectOutside;
