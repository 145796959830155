import { LOCALES } from 'lang/locales';

export enum NotFoundMessages {
    TITLE = 'invalidCampaignStore.title',
    SUBTITLE = 'invalidCampaignStore.subtitle',
    BODY = 'invalidCampaignStore.body',
    BUTTON = 'invalidCampaignStore.button',
}

export const notFoundMessages = {
    [LOCALES.ENGLISH]: {
        [NotFoundMessages.TITLE]: "The page you're looking for does not exist",
        [NotFoundMessages.SUBTITLE]: 'Please verify the URL and try again',
        [NotFoundMessages.BODY]:
            'If you want to go back to the main site, please click the button below',
        [NotFoundMessages.BUTTON]: 'Go Home',
    },
    [LOCALES.SPANISH]: {
        [NotFoundMessages.TITLE]: 'La pagina que esta buscando no existe',
        [NotFoundMessages.SUBTITLE]:
            'Por favor, verifique la dirección y vuelva a intentarlo',
        [NotFoundMessages.BODY]:
            'Si desea regresar a la pantalla principal, por favor haga click en el boton debajo',
        [NotFoundMessages.BUTTON]: 'Ir a la pantalla principal',
    },
};
