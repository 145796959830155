import { LOCALES } from 'lang/locales';

export enum RenewalNoticeMessages {
    TITLE = 'renewalNotice.title',
    DESCRIPTION_ONE = 'renewalNotice.descriptionOne',
    DESCRIPTION_ONE_ACTIVATION = 'renewalNotice.descriptionOne.activation',
    DESCRIPTION_TWO = 'renewalNotice.descriptionTwo',
    DESCRIPTION_TWO_ACTIVATION = 'renewalNotice.descriptionTwo.activation',
    DESCRIPTION_THREE = 'renewalNotice.descriptionThree',
    DESCRIPTION_THREE_ACTIVATION = 'renewalNotice.descriptionThree.activation',
    DESCRIPTION_FOUR = 'renewalNotice.descriptionFour',
    DESCRIPTION_FOUR_ACTIVATION = 'renewalNotice.descriptionFour.activation',
    DESCRIPTION_FIVE = 'renewalNotice.descriptionFive',
    DESCRIPTION_FIVE_ACTIVATION = 'renewalNotice.descriptionFive.activation',
}

export const renewalNoticeMessages = {
    [LOCALES.ENGLISH]: {
        [RenewalNoticeMessages.TITLE]: 'Automatic Renewal Notice',
        [RenewalNoticeMessages.DESCRIPTION_ONE]:
            'You will not be billed today. You must complete your enrollment within 30 days to avoid being charged for the printer ($160 for Envy, $200 for Envy Inspire and $270 for OfficeJet Pro).',
        [RenewalNoticeMessages.DESCRIPTION_ONE_ACTIVATION]:
            'Your billing method will be charged the $39 printer activation fee today.',
        [RenewalNoticeMessages.DESCRIPTION_TWO]:
            'Your service will start when you complete your enrollment after receiving your printer. ​',
        [RenewalNoticeMessages.DESCRIPTION_TWO_ACTIVATION]:
            'If you cancel your subscription within 30 days of signup and return the printer to HP within 30 days of canceling, your printer activation fee will be refunded, and you will not be charged anything.',
        [RenewalNoticeMessages.DESCRIPTION_THREE]:
            'Once your service starts, it will automatically renew and continue indefinitely until you cancel. Your payment method on file will be automatically billed monthly at the end of each bill cycle. Your monthly charges will be based on the printer and plan you selected, reflected in the “Your Plan” section above, which includes a fixed charge for the pages included with your plan and a potential overage charge for pages you print in excess of your plan during the month.',
        [RenewalNoticeMessages.DESCRIPTION_THREE_ACTIVATION]:
            'Your service will start when you complete your enrollment after receiving your printer. You must complete your enrollment within 30 days to avoid being charged for the printer ($160 for Envy, $200 for Envy Inspire, and $270 for OfficeJet Pro).',
        [RenewalNoticeMessages.DESCRIPTION_FOUR]:
            'You can change or cancel the service at any time by visiting <a>www.hpinstantink.com</a>, signing in and selecting “Cancel” on your dashboard or by calling 1-888-447-0148. Your service will cancel effective the last day of your current billing cycle. After you cancel, HP will remotely disable the subscription cartridge so you will no longer be able to print with it. There is no fee to cancel the service, as long as you return the printer to HP in an operable and undamaged condition within 30 days of the end of your current billing cycle. Failure to return the printer will result in you being charged an equipment fee of $160 (Envy), $200 (Envy Inspire), or $290 (OfficeJet Pro) if you cancel in the first year or $80 (Envy), $100 (Envy Inspire), or $145 (OfficeJet Pro) if you cancel after the first year. No refunds will be issued for any unused ink or toner.',
        [RenewalNoticeMessages.DESCRIPTION_FOUR_ACTIVATION]:
            'Once your service starts, it will automatically renew and continue indefinitely until you cancel. Your payment method on file will be automatically billed monthly at the end of each bill cycle. Your monthly charges will be based on the printer and plan you selected, reflected in the “Your Plan” section above, which includes a fixed charge for the pages included with your plan and a potential overage charge for pages you print in excess of your plan during the month.',
        [RenewalNoticeMessages.DESCRIPTION_FIVE]:
            'After you cancel, HP will remotely disable the subscription cartridge, so you will no longer be able to print with it. You will then have to return the subscription cartridge to HP. No refunds will be issued for any unused ink or toner. To continue printing after cancellation, you will need to purchase a regular HP cartridge compatible with your printer. An early cancelation fee of up to $160 (Envy), $200 (Envy Inspire) or $270 (OfficeJet Pro) may apply, depending on printer selected and how long after enrollment you cancel.',
        [RenewalNoticeMessages.DESCRIPTION_FIVE_ACTIVATION]:
            'You can change or cancel the service at any time by visiting <a>www.hpinstantink.com</a>, signing in and selecting “Cancel” on your dashboard or by calling 1-888-447-0148. Your service will cancel effective the last day of your current billing cycle. After you cancel, HP will remotely disable the subscription cartridge so you will no longer be able to print with it. There is no fee to cancel the service, as long as you return the printer to HP in an operable and undamaged condition within 30 days of the end of your current billing cycle. Failure to return the printer will result in you being charged an equipment fee of $160 (Envy), $200 (Envy Inspire), or $290 (OfficeJet Pro) if you cancel in the first year or $80 (Envy), $100 (Envy Inspire), or $145 (OfficeJet Pro) if you cancel after the first year. No refunds will be issued for any unused ink or toner.',
    },
    [LOCALES.SPANISH]: {
        [RenewalNoticeMessages.TITLE]: 'Aviso de Renovación Automática',
        [RenewalNoticeMessages.DESCRIPTION_ONE]:
            'Hoy no se le realizará ningún cargo. Debe completar su inscripción en un plazo de 30 días para evitar que se le cobre por la impresora ($160 por Envy, $200 por Envy Inspire y $270 por OfficeJet Pro).',
        [RenewalNoticeMessages.DESCRIPTION_ONE_ACTIVATION]:
            'Hoy se le cobrará la tarifa de activación de la impresora de $39 en su método de pago.',
        [RenewalNoticeMessages.DESCRIPTION_TWO]:
            'Su servicio comenzará cuando complete su inscripción después de recibir su impresora.',
        [RenewalNoticeMessages.DESCRIPTION_TWO_ACTIVATION]:
            'Si cancela su suscripción dentro de los 30 días de la inscripción y devuelve la impresora a HP dentro de los 30 días posteriores a la cancelación, se le reembolsará la tarifa de activación de la impresora y no se le cobrará nada.',

        [RenewalNoticeMessages.DESCRIPTION_THREE]:
            'Una vez que comience su servicio, se renovará automáticamente y continuará indefinidamente hasta que lo cancele. Su método de pago registrado se cargará automáticamente mensualmente al final de cada ciclo de facturación. Los cargos mensuales se basarán en la impresora y el plan que seleccionó, reflejado en la sección "Su Plan" anterior, que incluye un cargo fijo por las páginas incluidas en su plan y un cargo adicional potencial por las páginas que imprima en exceso de su plan durante el mes.',
        [RenewalNoticeMessages.DESCRIPTION_THREE_ACTIVATION]:
            'Su servicio comenzará cuando complete su inscripción después de recibir su impresora. Debe completar su inscripción en un plazo de 30 días para evitar que se le cobre por la impresora ($160 por Envy, $200 por Envy Inspire y $270 por OfficeJet Pro).',
        [RenewalNoticeMessages.DESCRIPTION_FOUR]:
            'Puede cambiar o cancelar el servicio en cualquier momento visitando <a>www.hpinstantink.com</a>, iniciando sesión y seleccionando "Cancelar" en su panel de control o llamando al 1-888-447-0148. Su servicio se cancelará efectivamente el último día de su ciclo de facturación actual. Después de cancelar, HP desactivará remotamente el cartucho de suscripción para que ya no pueda imprimir con él. No hay ninguna tarifa por cancelar el servicio, siempre y cuando devuelva la impresora a HP en condiciones operables e intactas dentro de los 30 días posteriores al final de su ciclo de facturación actual. Si no devuelve la impresora, se le cobrará una tarifa de equipo de $160 (Envy), $200 (Envy Inspire) o $290 (OfficeJet Pro) si cancela durante el primer año, o $80 (Envy), $100 (Envy Inspire) o $145 (OfficeJet Pro) si cancela después del primer año. No se emitirán reembolsos por tinta o tóner no utilizados.',
        [RenewalNoticeMessages.DESCRIPTION_FOUR_ACTIVATION]:
            'Una vez que comience su servicio, se renovará automáticamente y continuará indefinidamente hasta que lo cancele. Su método de pago registrado se cargará automáticamente mensualmente al final de cada ciclo de facturación. Los cargos mensuales se basarán en la impresora y el plan que seleccionó, reflejado en la sección "Su Plan" anterior, que incluye un cargo fijo por las páginas incluidas en su plan y un cargo adicional potencial por las páginas que imprima en exceso de su plan durante el mes.',

        [RenewalNoticeMessages.DESCRIPTION_FIVE]:
            'Después de cancelar, HP desactivará remotamente el cartucho de suscripción, por lo que ya no podrá imprimir con él. Luego deberá devolver el cartucho de suscripción a HP. No se emitirán reembolsos por tinta o tóner no utilizados. Para continuar imprimiendo después de la cancelación, deberá comprar un cartucho HP regular compatible con su impresora. Puede aplicarse una tarifa por cancelación anticipada de hasta $160 (Envy), $200 (Envy Inspire) o $270 (OfficeJet Pro), dependiendo de la impresora seleccionada y el tiempo transcurrido desde la inscripción hasta la cancelación.',
        [RenewalNoticeMessages.DESCRIPTION_FIVE_ACTIVATION]:
            'Puede cambiar o cancelar el servicio en cualquier momento visitando <a>www.hpinstantink.com</a>, iniciando sesión y seleccionando "Cancelar" en su panel de control o llamando al 1-888-447-0148. Su servicio se cancelará efectivamente el último día de su ciclo de facturación actual. Después de cancelar, HP desactivará remotamente el cartucho de suscripción para que ya no pueda imprimir con él. No hay ninguna tarifa por cancelar el servicio, siempre y cuando devuelva la impresora a HP en condiciones operables e intactas dentro de los 30 días posteriores al final de su ciclo de facturación actual. Si no devuelve la impresora, se le cobrará una tarifa de equipo de $160 (Envy), $200 (Envy Inspire) o $290 (OfficeJet Pro) si cancela durante el primer año, o $80 (Envy), $100 (Envy Inspire) o $145 (OfficeJet Pro) si cancela después del primer año. No se emitirán reembolsos por tinta o tóner no utilizados.',
    },
};
