import { ButtonHTMLAttributes } from 'react';
import classNames from 'classnames';

import { ButtonVariants } from './constants';

import styles from './CustomButton.module.scss';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
    variant?: ButtonVariants;
}

function CustomButton({
    onClick,
    variant = ButtonVariants.primary,
    children,
    disabled,
    type,
    className,
    role,
}: Props) {
    const customClassName = classNames(
        {
            [styles.containerPrimary]: variant === ButtonVariants.primary,
            [styles.containerSecondary]: variant === ButtonVariants.secondary,
            [styles.containerSquare]: variant === ButtonVariants.square,
            [styles.containerSquareInverted]:
                variant === ButtonVariants.squareInverted,
        },
        className
    );

    return (
        <button
            type={type === 'submit' ? 'submit' : 'button'}
            disabled={disabled}
            onClick={onClick}
            role={role}
            className={customClassName}
        >
            {children}
        </button>
    );
}

export default CustomButton;
