import { useEffect, useState } from 'react';

import { Breakpoint } from './types';
import { getDeviceConfig } from './utils';

export const useBreakpoint = (): Breakpoint => {
    const [brkPnt, setBrkPnt] = useState(() =>
        getDeviceConfig(window.innerWidth)
    );

    useEffect(() => {
        const calcInnerWidth = () => {
            setBrkPnt(getDeviceConfig(window.innerWidth));
        };

        window.addEventListener('resize', calcInnerWidth);

        return () => window.removeEventListener('resize', calcInnerWidth);
    }, []);

    return brkPnt;
};
