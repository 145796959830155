export const DISCLAIMERS = (messages: Record<string, string>) => [
    {
        id: 0,
        text: messages.SECTION_ONE_TITLE,
    },
    {
        id: 1,
        text: messages.SECTION_ONE_CONTENT,
    },
    {
        id: 2,
        text: messages.SECTION_TWO_TITLE,
    },
    {
        id: 3,
        text: messages.SECTION_TWO_CONTENT,
    },
    {
        id: 4,
        text: messages.SECTION_THREE_TITLE,
    },
    {
        id: 5,
        text: messages.SECTION_THREE_CONTENT,
    },
];
