import { CookieVariation } from 'constants/cookiesEnums';
import { AvailablePrinters } from 'contexts/PrinterChoice/constants';

import { IOutput, PRINTER_INFO } from '../../constants';

export const getFormattedPrinters = (variation: CookieVariation) =>
    PRINTER_INFO.reduce((current, acc, index) => {
        if (
            variation !== CookieVariation.LEBI &&
            acc.id === AvailablePrinters.LEBI
        ) {
            return current;
        }

        if (
            variation === CookieVariation.SayanReplacing &&
            acc.id === AvailablePrinters.Professional
        ) {
            return current;
        }

        // TODO: Check if this is needed because the printers should not
        // dissapear, just be disable
        // if (
        //     variation !== CookieVariation.Control &&
        //     acc.id === AvailablePrinters.HighVolume &&
        //     disabledHighVolume
        // ) {
        //     return current;
        // }

        // if (
        //     variation === CookieVariation.LEBI &&
        //     disabledLEBI &&
        //     acc.id === AvailablePrinters.LEBI
        // ) {
        //     return current;
        // }

        if (
            (variation === CookieVariation.Control || !variation) &&
            acc.id === AvailablePrinters.HighVolume
        ) {
            return current;
        }

        current.push({
            name: acc.printerName,
            img: acc.printerImg,
            id: acc.id,
            index,
        });

        return current;
    }, [] as IOutput[]);

export const getFormattedNames = (spec: string) => {
    let output = '';
    switch (spec) {
        case 'printScanCopy':
            output = 'PRINT_SCAN_COPY';
            break;

        case 'printingSpeed':
            output = 'PRINTING_SPEED';
            break;

        case 'inputCapacity':
            output = 'INPUT_CAPACITY';
            break;

        case 'photoTray':
            output = 'PHOTO_TRAY';
            break;

        case 'touchScreen':
            output = 'TOUCHSCREEN';
            break;

        case 'usb':
            output = 'USB';
            break;

        case 'fax':
            output = 'FAX';
            break;

        default:
            output = 'AUTO_DOCUMENT_FEEDER';
            break;
    }

    return output;
};
