import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Modal } from 'components';
import { useQuery, useRedirect } from 'hooks';

import { AccountButton, ControlPanel, ToggleButton } from './components';
import { Form } from './types';
import { TEST_ACCOUNTS } from '../LandingUpgrade/constants';

import styles from './Debug.module.scss';

function Debug() {
    const [showTestModal, setShowTestModal] = useState(false);

    const {
        control,
        formState: { errors, dirtyFields },
        watch,
        trigger,
    } = useForm<Form>({
        defaultValues: {
            hasPaper: false,
        },
    });

    const { pushWithQuery } = useRedirect();
    const { debugMenu } = useQuery<{ debugMenu: boolean }>();

    const toggleTestModal = () => {
        setShowTestModal((prevState) => !prevState);
    };

    const redirectToTestUser = async (data: (typeof TEST_ACCOUNTS)[0]) => {
        const { hasPaper, paperQuantity, priceCents } = watch();
        const result = await trigger();

        if (!errors.paperQuantity && !errors.priceCents && result) {
            pushWithQuery(
                `/`,
                `?account_identifier=${data.account_identifier}&has_paper=${hasPaper}&pilot_id=${data.pilot_id}&plan_pages=${paperQuantity}&price_cents=${priceCents}&debug_menu=true`
            );
            toggleTestModal();
        }
    };

    return debugMenu ? (
        <div className={styles.container}>
            <Modal show={showTestModal} onClose={toggleTestModal}>
                <div className={styles.testModal}>
                    <ControlPanel
                        control={control}
                        errors={errors}
                        dirty={dirtyFields}
                    />

                    {TEST_ACCOUNTS.map((data) => (
                        <AccountButton
                            data={data}
                            key={data.account_identifier}
                            onRedirect={redirectToTestUser}
                        />
                    ))}
                </div>
            </Modal>

            <ToggleButton toggleTestModal={toggleTestModal} />
        </div>
    ) : null;
}
export default Debug;
