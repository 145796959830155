import { LOCALES } from 'lang/locales';

export enum UpgradePrinterSelectionMessages {
    TITLE = 'upgrade.printerSelection.title',
    BODY_TEXT = 'upgrade.printerSelection.body',

    SUMMARY_YOUR_UPGRADE = 'upgrade.printerSelection.summary.yourUpgrade',
    SUMMARY_CHOOSE_AN_UPGRADE = 'upgrade.printerSelection.summary.chooseAnUpgrade',
    SUMMARY_FOOTNOTE = 'upgrade.printerSelection.summary.footnote',
}

export const upgradePrinterSelectionMessages = {
    [LOCALES.ENGLISH]: {
        [UpgradePrinterSelectionMessages.TITLE]: 'Upgrading your printer',
        [UpgradePrinterSelectionMessages.BODY_TEXT]:
            "<p>When you're ready to upgrade your subscription printer, choose a model from the options below. Your ink and optional paper plans will remain unchanged, but you may adjust them anytime on your account dashboard.</p><p> Once you upgrade your printer, you can still cancel your all-inclusive service anytime. Cancelations within two years of upgrade will be subject to an early cancelation fee.* After setting up your new printer, you may keep your existing printer or recycle it.</p>",
        [UpgradePrinterSelectionMessages.SUMMARY_YOUR_UPGRADE]: 'Your Upgrade',
        [UpgradePrinterSelectionMessages.SUMMARY_CHOOSE_AN_UPGRADE]:
            'Choose a printer upgrade',
        [UpgradePrinterSelectionMessages.SUMMARY_FOOTNOTE]:
            'Comprehensive fee based on your current ink and optional paper plans.<br></br>You can adjust your ink and paper plans anytime on your <a>www.hpinstantink.com|account dashboard.</a>',
    },

    [LOCALES.SPANISH]: {
        [UpgradePrinterSelectionMessages.TITLE]:
            'Actualización de tu impresora',
        [UpgradePrinterSelectionMessages.BODY_TEXT]:
            "<p>Cuando estés listo para actualizar tu impresora por suscripción, elige un modelo de las opciones a continuación. Tus planes de tinta y papel opcionales permanecerán sin cambios, pero puedes ajustarlos en cualquier momento en tu panel de cuenta.</p><p>'Una vez que actualices tu impresora, aún puedes cancelar tu servicio todo incluido en cualquier momento. Las cancelaciones dentro de los dos años posteriores a la actualización estarán sujetas a una tarifa por cancelación anticipada.* Después de configurar tu nueva impresora, puedes conservar tu impresora actual o reciclarla a través de nosotros solicitando una etiqueta de envío prepagada a través del soporte al cliente Platinum.</p>",
        [UpgradePrinterSelectionMessages.SUMMARY_YOUR_UPGRADE]:
            'Tu actualización',
        [UpgradePrinterSelectionMessages.SUMMARY_CHOOSE_AN_UPGRADE]:
            'Elija una actualización',
        [UpgradePrinterSelectionMessages.SUMMARY_FOOTNOTE]:
            'Tarifa integral basada en tus planes actuales de tinta y papel opcionales.<br></br>Puedes ajustar tus planes de tinta y papel en cualquier momento en el <a>www.hpinstantink.com|panel de control.</a>',
    },
};
