import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Nullable } from 'types/globals';

export const usePrinter = ({ styles }: { styles: string }) => {
    const ref = useRef<Nullable<HTMLDivElement>>(null);
    const handlePrint = useReactToPrint({
        content: () => ref.current,
        bodyClass: styles,
    });

    return { ref, handlePrint };
};
