import axios from 'axios';
import { requestHandler } from 'utils/requestHandler';

export interface PilotIdInfo {
    pilotID: number;
    name: string;
    isReducePrice: boolean;
    price: number;
    printerPrices: {
        professional: number;
        versatile: number;
        essential: number;
    };
}

export interface UpgradeElegibilityBody {
    account_identifier: string;
    pilot_id: string;
    has_paper: boolean;
    plan_page: string;
}

export interface UpgradeQueryParams {
    accountIdentifier: string;
    pilotId: number;
    hasPaper: boolean;
    planPages: number;
    planPage: number;
    priceCents: number;
}

export const getPilotIdInfo = requestHandler<
    Partial<UpgradeQueryParams>,
    PilotIdInfo
>((params) => axios.get(`/api/pilot-info/`, { params }));

export const checkUpgradeElegibility = requestHandler<
    Partial<UpgradeElegibilityBody>,
    {
        isEligible: boolean;
        hasUpgraded: boolean;
    }
>((params) =>
    axios.post('/api/upgrade-subscription/printer-upgrade-check', { ...params })
);
