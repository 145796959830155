import { ReactNode, useMemo } from 'react';
import { IntlProvider } from 'react-intl';
import { useTranslation } from 'hooks';
import { LOCALES } from 'lang/locales';
import messages from 'lang/messages';

import { i18nConfig } from './constants';

interface Props {
    children: ReactNode;
}

function IntlConfigurator({ children }: Props) {
    const { currentLang } = useTranslation();

    return useMemo(() => {
        return (
            <IntlProvider
                {...i18nConfig}
                locale={currentLang || LOCALES.ENGLISH}
                messages={messages[currentLang || LOCALES.ENGLISH]}
            >
                {children}
            </IntlProvider>
        );
    }, [children, currentLang]);
}

export default IntlConfigurator;
