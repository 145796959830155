import { MouseEventHandler, ReactNode } from 'react';
import classNames from 'classnames';
import { Button } from 'components';
import { ButtonVariants } from 'components/CustomButton/constants';
import { TrackAction, TrackLocation, TrackType } from 'constants/analytics';
import { useRedirect, useTrackLink } from 'hooks';

interface Props {
    action?: TrackType | TrackAction;
    from?: TrackLocation;
    redirectTo?: string;
    variant?: ButtonVariants;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    children: ReactNode;
    className?: string;
    role?: string;
}

function RedirectButton({
    redirectTo = undefined,
    onClick = undefined,
    className = undefined,
    variant = ButtonVariants.primary,
    from = undefined,
    action = undefined,
    role = 'button',
    children,
}: Props) {
    const trackLink = useTrackLink();
    const { push } = useRedirect();

    const handleOnClick: MouseEventHandler<HTMLButtonElement> = (e) => {
        if (action && from) {
            trackLink(action, from);
        }

        if (onClick) {
            onClick(e);
        }

        if (redirectTo) {
            push(redirectTo);
        }
    };

    return (
        <Button
            className={classNames(className)}
            variant={variant}
            onClick={handleOnClick}
            role={role}
        >
            {children}
        </Button>
    );
}

export default RedirectButton;
