import { LOCALES } from 'lang/locales';

export enum StepsMessages {
    TITLE_ONE = 'steps.titleOne',
    TITLE_TWO = 'steps.titleTwo',
    TITLE_FOUR = 'steps.titleFour',
    DESCRIPTION_ONE = 'steps.descriptionOne',
    DESCRIPTION_TWO = 'steps.descriptionTwo',
    DESCRIPTION_FOUR = 'steps.descriptionFour',
    DESCRIPTION_FOUR_ACTIVATION = 'steps.descriptionFourActivation',
}

export enum StepsMessagesB {
    TITLE_ONE = StepsMessages.TITLE_ONE,
    TITLE_TWO = StepsMessages.TITLE_TWO,
    TITLE_FOUR = StepsMessages.TITLE_FOUR,
    DESCRIPTION_ONE = StepsMessages.DESCRIPTION_ONE,
    DESCRIPTION_TWO = 'steps.descriptionTwo_b',
    DESCRIPTION_FOUR = StepsMessages.DESCRIPTION_FOUR,
    DESCRIPTION_FOUR_ACTIVATION = StepsMessages.DESCRIPTION_FOUR_ACTIVATION,
}

export enum StepsMessagesC {
    TITLE_ONE = StepsMessages.TITLE_ONE,
    TITLE_TWO = StepsMessages.TITLE_TWO,
    TITLE_FOUR = StepsMessages.TITLE_FOUR,
    DESCRIPTION_ONE = StepsMessages.DESCRIPTION_ONE,
    DESCRIPTION_TWO = 'steps.descriptionTwo_b',
    DESCRIPTION_FOUR = StepsMessages.DESCRIPTION_FOUR,
    DESCRIPTION_FOUR_ACTIVATION = StepsMessages.DESCRIPTION_FOUR_ACTIVATION,
}

export const stepsMessages = {
    [LOCALES.ENGLISH]: {
        [StepsMessages.TITLE_ONE]: 'Choose a new printer',
        [StepsMessages.TITLE_TWO]: 'Pick a print plan',
        [StepsMessages.TITLE_FOUR]: 'Start your subscription',
        [StepsMessages.DESCRIPTION_ONE]:
            "Select from HP's latest models -\nas part of your subscription",
        [StepsMessages.DESCRIPTION_TWO]:
            "Let your printer reorder ink when\nyou're low",
        [StepsMessages.DESCRIPTION_FOUR]: 'Enjoy a 30-day risk-free trial',
        [StepsMessagesB.DESCRIPTION_TWO]:
            "Let your printer reorder ink when\nyou're low",
        [StepsMessages.DESCRIPTION_FOUR_ACTIVATION]:
            'You can cancel any time - return the printer and pay no cancelation fee',
    },
    [LOCALES.SPANISH]: {
        [StepsMessages.TITLE_ONE]: 'Elige una nueva\nimpresora',
        [StepsMessages.TITLE_TWO]: 'Selecciona un plan\nde impresión',
        [StepsMessages.TITLE_FOUR]: 'Comienza tu\nsuscripción',
        [StepsMessages.DESCRIPTION_ONE]:
            'Elige entre los últimos modelos de HP como\nparte de tu suscripción.',
        [StepsMessages.DESCRIPTION_TWO]:
            'Recibe tus cartuchos\nautomáticamente en casa solo\ncuando los necesites.',
        [StepsMessages.DESCRIPTION_FOUR]:
            'Puedes cambiar tu plan de\nimpresión en cualquier momento.',
        [StepsMessagesB.DESCRIPTION_TWO]:
            'Recibe tu tinta automáticamente\nen casa solo cuando lo necesites.',
        [StepsMessages.DESCRIPTION_FOUR_ACTIVATION]:
            'Puede cancelar en cualquier momento: devuelva la impresora y no pague ningún cargo por cancelación',
    },
};
