import { LOCALES } from 'lang/locales';

export enum UpgradesMessages {
    TITLE = 'upgrades.title',
    DESCRIPTION = 'upgrades.description',
    DESCRIPTION_ACTIVATION = 'upgrades.descriptionActivation',
    CONTINUE_WITH_UPGRADE = 'upgrades.continueWithUpgrade',
}

export const upgradesMessages = {
    [LOCALES.ENGLISH]: {
        [UpgradesMessages.TITLE]: 'Stay up to date with\nprinter refresh',
        [UpgradesMessages.DESCRIPTION]:
            "With Platinum, you're eligible every two years to\nselect a new printer from HP's latest models. Your\nsubscription includes printer and shipping at no\nadditional charge.",
        [UpgradesMessages.DESCRIPTION_ACTIVATION]:
            "With Platinum, you're eligible every two years to\nselect a new printer from HP's latest models. Your\nsubscription includes printer and shipping at no\nadditional charge. You’ll pay only a $39 activation\ncharge when when you refresh your printer.¹",
        [UpgradesMessages.CONTINUE_WITH_UPGRADE]: 'Continue with Upgrade',
    },
    [LOCALES.SPANISH]: {
        [UpgradesMessages.TITLE]:
            '¡Incluye una impresora HP\ncon la opción de renovarla!',
        [UpgradesMessages.DESCRIPTION]:
            'El servicio HP Instant Ink Platinum incluye una impresora HP con la\nopción de renovarla cada tres años por un nuevo modelo. También\npuedes optar por conservar tu impresora actual.',
        [UpgradesMessages.DESCRIPTION_ACTIVATION]: ' ',
        [UpgradesMessages.CONTINUE_WITH_UPGRADE]:
            'Continuar con la actualizacion',
    },
};
