export const DESCRIPTIONS = (
    isActivation: boolean,
    text: Record<string, string>
) => [
    {
        id: 1,
        text: isActivation
            ? text.DESCRIPTION_ONE_ACTIVATION
            : text.DESCRIPTION_ONE,
    },
    {
        id: 1,
        text: isActivation
            ? text.DESCRIPTION_TWO_ACTIVATION
            : text.DESCRIPTION_TWO,
    },
    {
        id: 1,
        text: isActivation
            ? text.DESCRIPTION_THREE_ACTIVATION
            : text.DESCRIPTION_THREE,
    },
    {
        id: 1,
        text: isActivation
            ? text.DESCRIPTION_FOUR_ACTIVATION
            : text.DESCRIPTION_FOUR,
    },
    {
        id: 1,
        text: isActivation
            ? text.DESCRIPTION_FIVE_ACTIVATION
            : text.DESCRIPTION_FIVE,
    },
];
