import classNames from 'classnames';

import styles from './ShopMethod.module.scss';

interface Props {
    active?: boolean;
    headerImg: string;
    title: string;
    bulletPoints: string[];
}

function ShopMethod({ active = false, headerImg, title, bulletPoints }: Props) {
    return (
        <div className={styles.card}>
            <img src={headerImg} alt={title} className={styles.printerImage} />
            <div
                className={classNames(styles.container, {
                    [styles.active]: active,
                })}
            >
                <h2 className={styles.title}>{title}</h2>
                <hr
                    className={classNames(styles.separator, {
                        [styles.active]: active,
                    })}
                />
                <ul className={styles.list}>
                    {bulletPoints.map((item) => (
                        <li key={item}>{item}</li>
                    ))}
                </ul>
            </div>
        </div>
    );
}
export default ShopMethod;
