import { DataContextType } from 'contexts/DataProvider/constants';
import { NewInkPlan, Printer } from 'contexts/PrinterChoice/constants';
import { BillingInfo, ShippingInfo } from 'contexts/UserInfo/constants';
import { UpgradeQueryParams } from 'services/pilotIdInfo';
import { Nullable } from 'types/globals';
import { Callbacks } from 'utils/helper';

export type Params = {
    country: 'es' | 'us';
    lang: 'es' | 'en';
};

export type PostOrder = {
    dataContext: DataContextType;
    shippingInfo: ShippingInfo;
    billingInfo: BillingInfo;
    planId?: string;
    paper: boolean;
    inkPlanId?: string;
    zone: OrderZones;
};

export type PostOrderBody = {
    zone: OrderZones;
    customerId?: string;
    utmParams?: Partial<Record<string, Nullable<string>>>;
    firstName: string;
    lastName: string;
    companyName?: string;
    email: string;
    mobile: string;
    shippingAddress: {
        street: string;
        street2: string;
        city: string;
        state: string;
        zip: string;
        printerSerialNumber?: string;
    };
    billingAddress: {
        street: string;
        street2: string;
        city: string;
        state: string;
        zip: string;
    };
    printer: string;
    printerSerialNumber?: string;
    stripeToken?: string;
    inkPlan: string;
    paper: boolean;
    ccNameOnCard: string;
};

export interface UpgradeOrderResponse {
    success: true;
}

export type UseOrderType = {
    userEmail?: string;
    postOrder: (data: PostOrder, callbacks: Callbacks<unknown>) => void;
    postUpgradeOrder: (
        upgradeInfo: Partial<UpgradeQueryParams>,
        data: Omit<PostOrder, 'billingInfo'>,
        callbacks: Callbacks<unknown>
    ) => void;
    submitting: boolean;
};

export const UTM_INITIAL_VALUES = {
    utmParams: {
        utmCampaign: null,
        utmSource: null,
        utmMedium: null,
        utmContent: null,
    },
};

export enum OrderZones {
    US = 'US',
    ES = 'ES',
}

export const ORDER_ERROR = 'You are using Order out of context.';

export enum BuyflowStates {
    Shipping = 1,
    Billing,
    Review,
}

export type BuyflowEvent = 'e_addToCart' | 'e_removeFromCart' | 'e_pageView';

export type TrackPageFn = (
    p1: string,
    p2?: string,
    p3?: string,
    p4?: string
) => void;

export type TrackLinkFn = (linkID: string, linkPlacement?: string) => void;

export type TrackBuyflowFn = (
    printer: Printer,
    plan: NewInkPlan,
    action?: BuyflowEvent,
    state?: BuyflowStates
) => void;

export const EMPTY_BILLING_INFO: { billingInfo: BillingInfo } = {
    billingInfo: {
        ccNameOnCard: '',
        city: '',
        state: '',
        street: '',
        useShippingAddress: false,
        zip: '',
    },
};
