import {
    createContext,
    ReactNode,
    useCallback,
    useContext,
    useMemo,
    useState,
} from 'react';
import { TrackAction, TrackLocation } from 'constants/analytics';
import { ModalType } from 'constants/modals';
import { CONTEXT_ERROR } from 'contexts/PrinterChoice/constants';
import { useTrackLink } from 'hooks/useTrack';

export const CheckoutModalsContext = createContext({
    modals: {
        [ModalType.Shipping]: false,
        [ModalType.Billing]: false,
        [ModalType.ARN]: false,
        [ModalType.Questionnare]: false,
        [ModalType.SayanNotAvailable]: false,
    },

    toggleModals: (_modalTotoggle: ModalType) => {},
});

interface Props {
    children: ReactNode;
}

export function CheckoutModalsProvider({ children }: Props) {
    const trackLink = useTrackLink();
    const [modals, setModals] = useState({
        [ModalType.Shipping]: false,
        [ModalType.Billing]: false,
        [ModalType.ARN]: false,
        [ModalType.Questionnare]: false,
        [ModalType.SayanNotAvailable]: false,
    });

    const toggleModals = useCallback(
        (modalToToggle: ModalType) => {
            setModals((prevState) => {
                switch (modalToToggle) {
                    case ModalType.SayanNotAvailable:
                        trackLink(
                            prevState[ModalType.SayanNotAvailable]
                                ? TrackAction.SayanNotAvailableModalClosed
                                : TrackAction.SayanNotAvailableModalOpened,
                            TrackLocation.Checkout
                        );
                        break;

                    case ModalType.Shipping:
                        trackLink(
                            prevState[ModalType.Shipping]
                                ? TrackAction.ShippingModalClosed
                                : TrackAction.ShippingModalOpened,
                            TrackLocation.Checkout
                        );
                        break;

                    case ModalType.Billing:
                        trackLink(
                            prevState[ModalType.Billing]
                                ? TrackAction.BillingModalClosed
                                : TrackAction.BillingModalOpened,
                            TrackLocation.Checkout
                        );
                        break;

                    case ModalType.Questionnare:
                        trackLink(
                            prevState[ModalType.Questionnare]
                                ? TrackAction.SurveyModalClosed
                                : TrackAction.SurveyModalOpened,
                            TrackLocation.Checkout
                        );
                        break;

                    case ModalType.ARN:
                    default:
                        trackLink(
                            prevState[ModalType.ARN]
                                ? TrackAction.ARNModalClosed
                                : TrackAction.ARNModalOpened,
                            TrackLocation.Checkout
                        );
                }

                return {
                    ...prevState,
                    [modalToToggle]: !prevState[modalToToggle],
                };
            });
        },
        [trackLink]
    );

    const value = useMemo(
        () => ({
            modals,
            toggleModals,
        }),
        [modals, toggleModals]
    );

    return (
        <CheckoutModalsContext.Provider value={value}>
            {children}
        </CheckoutModalsContext.Provider>
    );
}

export function useCheckoutModals() {
    const ctx = useContext(CheckoutModalsContext);

    if (!ctx) {
        throw new Error(CONTEXT_ERROR);
    }

    return ctx;
}
