import downloadIcon from 'assets/download_icon.svg';
import classNames from 'classnames';
import { useFormat } from 'hooks';
import { CommonMessages, commonMessagesValues } from 'lang/messages/common';

import styles from './DownloadButton.module.scss';

interface Props {
    onClick?: () => void;
    className?: string;
}

function DownloadButton({ onClick = undefined, className = undefined }: Props) {
    const { DOWNLOAD } = useFormat(CommonMessages, commonMessagesValues({}));

    return (
        <button
            type="button"
            onClick={onClick}
            className={classNames(styles.container, className)}
        >
            <p className={classNames(styles.text, styles.hover)}>{DOWNLOAD}</p>
            <img
                src={downloadIcon}
                className={classNames(styles.icon, styles.hover)}
                alt=""
            />
        </button>
    );
}

export default DownloadButton;
