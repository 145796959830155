import { StripeElementStyle } from '@stripe/stripe-js';

const templateStyle: StripeElementStyle = {
    base: {
        lineHeight: '24px',
        fontSize: '16px',
        fontFamily: 'inherit',
        '::placeholder': {
            fontSize: '15px',
            color: '#585858',
        },
    },
};

const baseStyle: StripeElementStyle = {
    ...templateStyle,
    base: { color: '#212121' },
};

const errorStyle: StripeElementStyle = {
    ...templateStyle,
    base: { color: '#BE1213' },
};

export const stylesOptions = (hasError: boolean) => ({
    style: hasError ? errorStyle : baseStyle,
});
