import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Accordion, AccordionItemInterface } from '@veneer/core';
import { TrackLocation } from 'constants/analytics';
import { useTrackLink } from 'hooks';

import { processFAQs, toggleCollapseOnFAQ } from './utils';

import styles from './FAQItem.module.scss';

interface Props {
    title: string;
    content: { key: number; question: string; answer: string }[];
    hideTitle?: boolean;
    expandAll?: boolean;
}

function FAQItem({
    title,
    content,
    hideTitle = false,
    expandAll = false,
}: Props) {
    const trackLink = useTrackLink();
    const { formatMessage } = useIntl();

    const [items, setItems] = useState<AccordionItemInterface[]>([]);

    const toggleFAQ = useCallback(
        (expanded: boolean) =>
            (
                _event: React.UIEvent<Element, UIEvent>,
                index: number,
                item: AccordionItemInterface
            ) => {
                if (expanded) {
                    trackLink(
                        String(item.header?.centralArea),
                        TrackLocation.FAQ
                    );
                }

                setItems((prevState) =>
                    toggleCollapseOnFAQ(prevState, index, expanded)
                );
            },
        [setItems, trackLink]
    );

    useEffect(() => {
        try {
            setItems(
                processFAQs(
                    content,
                    (id: string) => formatMessage({ id }),
                    expandAll ?? false
                )
            );
        } catch (err) {
            setItems([]);
        }
    }, [content, expandAll, formatMessage]);

    return (
        <div className={styles.root}>
            {!hideTitle && (
                <h5 className={styles.title}>{formatMessage({ id: title })}</h5>
            )}
            <div className={styles.accordionContent}>
                {items.length > 0 ? (
                    <Accordion
                        onCollapse={toggleFAQ(false)}
                        onExpand={toggleFAQ(true)}
                        items={items}
                        behavior="multiExpand"
                    />
                ) : (
                    <p title="error">No items were found for this FAQ</p>
                )}
            </div>
        </div>
    );
}

export default FAQItem;
