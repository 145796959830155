import { LOCALES } from 'lang/locales';

export enum SummaryMessages {
    FREE_SHIPPING = 'summary.freeShipping',
    CHECKOUT = 'summary.checkout',
    UPGRADE = 'summary.upgrade',
    TAX = 'summary.tax',
    ERROR = 'summary.error',
}

export const summaryMessages = {
    [LOCALES.ENGLISH]: {
        [SummaryMessages.FREE_SHIPPING]: 'Shipping included',
        [SummaryMessages.CHECKOUT]: 'Continue to Checkout',
        [SummaryMessages.UPGRADE]: 'Continue to Upgrade',
        [SummaryMessages.TAX]: 'Plus a $20 shipping tax',
        [SummaryMessages.ERROR]:
            'Failed to continue, check printer and plan selection and retry',
    },
    [LOCALES.SPANISH]: {
        [SummaryMessages.FREE_SHIPPING]: 'Envío incluido',
        [SummaryMessages.CHECKOUT]: 'Continuar con la compra',
        [SummaryMessages.UPGRADE]: 'Continuar con la actualización',
        [SummaryMessages.TAX]: 'Cuota mensual con todo incluido',
        [SummaryMessages.ERROR]:
            'Ocurrió un error, verifique su seleccion de impresora y plan. Luego, vuelva a intentarlo',
    },
};
