import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Button, Checkbox, DownloadButton, Modal } from 'components';
import { ModalType } from 'constants/modals';
import { useCheckoutModals, useDataProvider } from 'contexts';
import {
    useFormat,
    useQuery,
    useTrackBuyflowCheckout,
    useTrackLink,
    useVariation,
} from 'hooks';
import { BuyflowStates } from 'hooks/constants';
import useCheckout from 'hooks/useCheckout';
import {
    CheckoutMessages,
    checkoutMessagesValues,
    CheckoutMessageTypes,
} from 'lang/messages/checkout';
import { UpgradeQueryParams } from 'services/pilotIdInfo';
import { Nullable } from 'types/globals';

import Label from './components/Label/Label';

import styles from './AutomaticRenewalNotice.module.scss';

function AutomaticRenewalNotice() {
    const { accountIdentifier } = useQuery<UpgradeQueryParams>();
    const componentToPrint = useRef<Nullable<HTMLDivElement>>(null);
    const trackBuyflow = useTrackBuyflowCheckout();
    const trackLink = useTrackLink();
    const { plan, inkPlan, onConfirm, submitting } = useCheckout();
    const { toggleModals, modals } = useCheckoutModals();
    const [confirmed, setConfirmed] = useState(false);
    const { showActivation } = useDataProvider();
    const { variation } = useVariation();

    const selectCheckoutMessages = useMemo(() => {
        return CheckoutMessageTypes[variation];
    }, [variation]);

    const arnText = useFormat(
        selectCheckoutMessages || CheckoutMessages,
        checkoutMessagesValues({})
    );

    const getTextBody = () => {
        if (showActivation) return arnText.ARN_BODY_ACTIVATION;
        if (accountIdentifier) return arnText.ARN_BODY_UPGRADE;

        return arnText.ARN_BODY;
    };

    const contentToPrint = useCallback(() => componentToPrint.current, []);

    const handlePrint = useReactToPrint({
        content: contentToPrint,
        bodyClass: styles.printer,
    });

    useEffect(() => {
        if (plan && inkPlan) {
            trackBuyflow(plan, inkPlan, 'e_pageView', BuyflowStates.Review);
        }
    }, [trackBuyflow, plan, inkPlan]);

    const close = () => {
        trackLink('Close Subscription', 'buyflow');
        toggleModals(ModalType.ARN);
    };

    return (
        <Modal show={modals[ModalType.ARN]} onClose={close}>
            <div ref={componentToPrint}>
                <header className={styles.header}>
                    <h1 className={styles.title}>{arnText.ARN_TITLE}</h1>

                    <DownloadButton
                        onClick={handlePrint}
                        className={styles.download}
                    />
                </header>

                <div className={styles.textContainer}>{getTextBody()}</div>

                <footer className={styles.footer}>
                    <Checkbox
                        name="billing-confirmation"
                        label={<Label />}
                        checked={confirmed}
                        onChange={(e) => setConfirmed(e.target.checked)}
                    />

                    <Button
                        disabled={!confirmed || submitting}
                        onClick={onConfirm}
                        className={styles.button}
                    >
                        {accountIdentifier
                            ? arnText.ARN_COMPLETE_UPGRADE
                            : arnText.ARN_COMPLETE}
                    </Button>
                </footer>
            </div>
        </Modal>
    );
}

export default AutomaticRenewalNotice;
