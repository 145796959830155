import { Printer } from 'contexts/PrinterChoice/constants';
import { useFormat } from 'hooks';
import { ChooseYourPrinterMessages } from 'lang/messages/chooseYourPrinter';

import PrinterButton from '../PrinterButton';

import styles from './styles.module.scss';

interface Props {
    value?: Printer;
    dispatch: (type: 'printer', choice: Printer) => void;
    toggleComparisonModal: () => void;
    data: Printer[];
}

function PrinterButtonGroup({
    value = undefined,
    data,
    dispatch,
    toggleComparisonModal,
}: Props) {
    const { SUBTITLE, LINK } = useFormat(ChooseYourPrinterMessages);

    return (
        <div className={styles.printerButtonGroup}>
            <fieldset>
                {data.slice(-3).map((printer) => {
                    return (
                        <PrinterButton
                            key={printer.id}
                            checked={value}
                            onChange={dispatch}
                            data={printer}
                        />
                    );
                })}
            </fieldset>
            <div className={styles.linkContainer}>
                <p>{SUBTITLE}</p>
                <button
                    type="button"
                    role="link"
                    className={styles.link}
                    onClick={toggleComparisonModal}
                >
                    {LINK}
                </button>
            </div>
        </div>
    );
}

export default PrinterButtonGroup;
