import { useUserInfo } from 'contexts/UserInfo';
import { useFormat } from 'hooks';
import {
    CheckoutMessages,
    checkoutMessagesValues,
} from 'lang/messages/checkout';

import styles from './BillingCard.module.scss';

function BillingCard() {
    const { billing } = useUserInfo();

    const card = billing?.token?.card;

    const { CARD_BILLING_EXPIRES, CARD_BILLING_LAST_FOUR } = useFormat(
        CheckoutMessages,
        checkoutMessagesValues({
            last4: card?.last4,
            month: String(card?.exp_month),
            year: String(card?.exp_year),
        })
    );

    return (
        <div className={styles.container}>
            <p className={styles.text}>{billing?.ccNameOnCard}</p>
            <p className={styles.text}>{CARD_BILLING_LAST_FOUR}</p>
            <p className={styles.text}>{CARD_BILLING_EXPIRES}</p>
        </div>
    );
}
export default BillingCard;
