import { useCallback, useEffect } from 'react';
import { UseFormReset, UseFormSetValue } from 'react-hook-form';
import { Token } from '@stripe/stripe-js';
import { TrackAction, TrackLocation } from 'constants/analytics';
import { useCheckoutModals, useUserInfo } from 'contexts';
import { useTrackLink } from 'hooks';

import { DEFAULT_VALUES, InputFields } from '../constants';

interface Props {
    reset: UseFormReset<InputFields>;
    setValue: UseFormSetValue<InputFields>;
}

function useFormLogic({ reset, setValue }: Props) {
    const trackLink = useTrackLink();
    const { billing, changeInfo } = useUserInfo();
    const {
        modals: { '1': show },
    } = useCheckoutModals();

    const saveData = (token: Token, data: InputFields) => {
        changeInfo('billing', { ...data, token });
        trackLink(TrackAction.BillingModalSubmitted, TrackLocation.Checkout);
        reset(billing ? data : DEFAULT_VALUES, { keepDirty: true });
    };

    const populateData = useCallback(() => {
        if (billing) {
            reset(DEFAULT_VALUES, { keepDirty: true });
            const keys = Object.keys(billing) as (keyof InputFields)[];

            keys.forEach((item: keyof InputFields) => {
                setValue(item, billing[item], {
                    shouldDirty: true,
                });
            });
        }
    }, [reset, setValue, billing]);

    const resetData = () => {
        if (!billing) {
            reset(DEFAULT_VALUES);
        } else {
            populateData();
        }
    };

    useEffect(() => {
        if (show) {
            populateData();
        }
    }, [populateData, setValue, billing, show]);

    return { resetData, saveData };
}
export default useFormLogic;
