import styles from './IconDescriptor.module.scss';

interface Props {
    icon: string;
    text: string;
}

function IconDescriptor({ icon, text }: Props) {
    return (
        <li className={styles.listItem}>
            <img src={icon} alt={text} />
            <p>{text}</p>
        </li>
    );
}

export default IconDescriptor;
