import EnvyBg from './assets/EnvyBg.png';
import Inspire from './assets/EnvyInspirePrinter.png';
import Envy from './assets/EnvyPrinter.png';
import InspireBg from './assets/InspireBg.png';
import OfficeJetBg from './assets/OfficeJetBg.png';
import OfficeJet from './assets/OfficeJetPrinter.png';

export const getProductImages = (productName?: string) => {
    const imageMapping: {
        [key: string]: { printer: string; background: string };
    } = {
        Essential: { printer: Envy, background: EnvyBg },
        Versatile: { printer: Inspire, background: InspireBg },
        Professional: { printer: OfficeJet, background: OfficeJetBg },
        Esencial: { printer: Envy, background: EnvyBg },
        Versátil: { printer: Inspire, background: InspireBg },
        Profesional: { printer: OfficeJet, background: OfficeJetBg },
    };

    const selectedProduct = productName
        ? imageMapping[productName]
        : imageMapping.Essential;

    return {
        printerImage: selectedProduct.printer,
        backImage: { backgroundImage: `url(${selectedProduct.background})` },
    };
};

export const descriptionPrinter = ({
    email,
    isRetail,
    lang,
}: {
    email?: string;
    isRetail: boolean;
    lang: Record<string, string>;
}) => {
    if (email) {
        return isRetail ? lang.DESCRIPTION_RETAIL : lang.DESCRIPTION;
    }

    return isRetail
        ? lang.DESCRIPTION_RETAIL_WITHOUT_MAIL
        : lang.DESCRIPTION_WITHOUT_MAIL;
};
