import { useFormat } from 'hooks';
import { TermsOfServicesMessages } from 'lang/messages/terms';
import { AddendumSection } from 'screens/TermsAndConditions/types';

import { Links } from './components';

import styles from './ChapterSelection.module.scss';

interface Props {
    sections: AddendumSection;
}

function ChapterSelection({ sections }: Props) {
    const { INDEX } = useFormat(TermsOfServicesMessages);

    return (
        <>
            <p className={styles.title}>{INDEX}</p>
            <ol className={styles.linksContainer}>
                <Links data={sections} />
            </ol>
        </>
    );
}
export default ChapterSelection;
