import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom-v5-compat';
import { Nullable } from 'types/globals';

import { vanityURLs } from './constants';
import { checkCurrentLanguage } from '../utils';

function useTranslation() {
    const { pathname } = useLocation();

    const { currentLanguage, currentCountry } = useMemo(
        () => checkCurrentLanguage(pathname),
        [pathname]
    );

    const [currentLang, setCurrentLang] = useState<Nullable<'es' | 'en'>>(
        currentLanguage as 'es' | 'en'
    );
    const [currentLocation, setCurrentLocation] = useState<
        Nullable<'es' | 'us'>
    >(currentCountry as 'es' | 'us');

    const isSpain = () =>
        vanityURLs.includes(pathname) || currentCountry === 'es';

    const getCurrency = () => {
        if (isSpain()) return '€';
        return '$';
    };

    const printWithCurrency = (price: number | string) => {
        if (isSpain()) {
            return `${price}${getCurrency()}`;
        }

        return `${getCurrency()}${price}`;
    };

    useEffect(() => {
        setCurrentLang(currentLanguage as 'es' | 'en');
        setCurrentLocation(currentCountry as 'es' | 'us');
    }, [currentCountry, currentLanguage]);

    return {
        currentLang,
        currentLocation,
        printWithCurrency,
        currency: getCurrency(),
        isSpain,
    };
}

export { useTranslation };
