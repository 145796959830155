import { config } from '@react-spring/web';

export function getSpringConfiguration(height: number, collapse: boolean) {
    const initialHeight = 525;

    return {
        config: config.molasses,
        from: {
            height: initialHeight,
        },
        to: {
            height: collapse ? initialHeight : height,
        },
    };
}
