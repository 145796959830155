import { useFormat } from 'hooks';
import { PurchaseCompleteMessages } from 'lang/messages/purchaseComplete';

import giftCard from './assets/card.svg';
import { PARSED_USER_EMAIL } from './constants';
import { redirectToSurvey } from './utils';
import Button from '../CustomButton/CustomButton';

import styles from './GiftCard.module.scss';

interface Props {
    hideButton?: boolean;
    customText?: string;
}

function Survey({ hideButton = false, customText = undefined }: Props) {
    const { SURVEY, BUTTON } = useFormat(PurchaseCompleteMessages, {
        [PurchaseCompleteMessages.DESCRIPTION]: PARSED_USER_EMAIL,
        [PurchaseCompleteMessages.DESCRIPTION_RETAIL]: PARSED_USER_EMAIL,
    });

    return (
        <div className={styles.container}>
            <div className={styles.upperSection}>
                <img src={giftCard} alt="Gift Card" />
                <p className={styles.body}>{customText ?? SURVEY}</p>
            </div>
            {!hideButton ? (
                <Button onClick={redirectToSurvey}>{BUTTON}</Button>
            ) : null}
        </div>
    );
}
export default Survey;
