import { LOCALES } from 'lang/locales';

export enum HeroMessages {
    TITLE = 'hero.title',
    SUBTITLE = 'hero.subtitle',
    PRINTER = 'hero.printer',
    DELIVERY = 'hero.delivery',
    WARRANTY = 'hero.warranty',
    TECH_SERVICE = 'hero.upgrade',
    DISCLAIMER_ACTIVATION = 'hero.disclaimerActivation',
    DISCLAIMER_RISK_FREE = 'hero.disclaimerRiskFree',
}
export enum HeroMessagesB {
    TITLE = HeroMessages.TITLE,
    SUBTITLE = HeroMessages.SUBTITLE,
    PRINTER = HeroMessages.PRINTER,
    DELIVERY = 'hero.delivery_b',
    WARRANTY = HeroMessages.WARRANTY,
    TECH_SERVICE = HeroMessages.TECH_SERVICE,
    DISCLAIMER_ACTIVATION = HeroMessages.DISCLAIMER_ACTIVATION,
    DISCLAIMER_RISK_FREE = HeroMessages.DISCLAIMER_RISK_FREE,
}
export enum HeroMessagesC {
    TITLE = HeroMessages.TITLE,
    SUBTITLE = HeroMessages.SUBTITLE,
    PRINTER = HeroMessages.PRINTER,
    DELIVERY = HeroMessagesB.DELIVERY,
    WARRANTY = HeroMessages.WARRANTY,
    TECH_SERVICE = HeroMessages.TECH_SERVICE,
    DISCLAIMER_ACTIVATION = HeroMessages.DISCLAIMER_ACTIVATION,
    DISCLAIMER_RISK_FREE = HeroMessages.DISCLAIMER_RISK_FREE,
}

export const heroMessages = {
    [LOCALES.ENGLISH]: {
        [HeroMessages.TITLE]: 'The All-Inclusive Printer\nSubscription from HP',
        [HeroMessages.SUBTITLE]: 'INSTANT INK PLATINUM',
        [HeroMessages.PRINTER]: 'New HP Printer\nincluded',
        [HeroMessages.DELIVERY]: 'Automatic ink ordering\nbefore you run out',
        [HeroMessagesB.DELIVERY]: 'Automatic ink ordering\nbefore you run out',
        [HeroMessages.TECH_SERVICE]:
            'Eligibility for new printer\nevery two years',
        [HeroMessages.WARRANTY]: 'Next-business-day printer\nreplacements',
        [HeroMessages.DISCLAIMER_ACTIVATION]:
            'Plans start at {price} plus activation charge and automatically renew each month.\nCancel any time and pay no cancelation fees if you return the printer.¹',
        [HeroMessages.DISCLAIMER_RISK_FREE]:
            '30-day risk-free trial. Cancelation fee applies if you cancel\nwithin next 24 months. Plan automatically renews each month.¹',
    },

    [LOCALES.SPANISH]: {
        [HeroMessages.TITLE]:
            'La suscripción de impresión de\nHP con todo lo que necesitas',
        [HeroMessages.SUBTITLE]: 'Servicio de suscripción\nde impresión de HP',
        [HeroMessages.PRINTER]:
            'Incluye una impresora HP y\nrenuévala cada 3 años',
        [HeroMessages.DELIVERY]:
            'Recibe automáticamente\ntus cartuchos en casa solo\ncuando los necesites',
        [HeroMessagesB.DELIVERY]:
            'Recibe automáticamente\ntu tinta en casa solo\ncuando lo necesites',
        [HeroMessages.WARRANTY]:
            'Una garantía que nunca\ncaduca mientras\nmantengas tu suscripción',
        [HeroMessages.TECH_SERVICE]: 'Atención al cliente de\nlunes a domingo',
        [HeroMessages.DISCLAIMER_ACTIVATION]:
            'Los planes comienzan en {price} más el cargo de activación y se renuevan automáticamente cada mes.\nPuedes cancelar en cualquier momento y no pagarás cargos de cancelación si devuelves la impresora.¹',
        [HeroMessages.DISCLAIMER_RISK_FREE]: `Pruébalo sin compromiso durante 30 días. Se aplica una tarifa de\ncancelación en caso de cancelar el servicio durante los próximos\n6 meses. El plan se renueva automáticamente cada mes.¹`,
    },
};

export const heroMessagesValues = ({ price = ' ' }) => ({
    [HeroMessages.DISCLAIMER_ACTIVATION]: {
        price,
    },
    [HeroMessages.DISCLAIMER_RISK_FREE]: {
        price,
    },
});
