import { Fragment, useState } from 'react';
import { animated, useSpring } from '@react-spring/web';
import { ButtonVariants } from 'components/CustomButton/constants';
import { TrackAction, TrackLocation } from 'constants/analytics';
import { useFormat, useHeight } from 'hooks';
import { useDisclaimers } from 'hooks/useDisclaimers/useDisclaimers';
import { CommonMessages, commonMessagesValues } from 'lang/messages/common';
import { disclaimersMessagesValues } from 'lang/messages/disclaimers';

import { DISCLAIMERS } from './constants';
import { getSpringConfiguration } from './utils';
import RedirectButton from '../RedirectButton/RedirectButton';

import styles from './Disclaimers.module.scss';

function Disclaimers() {
    const [collapse, setCollapsed] = useState(true);

    const disclaimerType = useDisclaimers();

    const disclaimersText = useFormat(
        disclaimerType,
        disclaimersMessagesValues({})
    );
    const { VIEW_LESS, VIEW_MORE } = useFormat(
        CommonMessages,
        commonMessagesValues({})
    );
    const { ref: heightRef, height } = useHeight({ on: true });
    const spring = useSpring(getSpringConfiguration(height, collapse));

    const toggleCollapsed = () => {
        setCollapsed((prevState) => !prevState);
    };

    return (
        <section className={styles.container}>
            <h1 className={styles.title}>{disclaimersText.TITLE}</h1>

            <animated.div
                className={styles.disclaimersContainer}
                style={spring}
                data-testid="disclaimers"
            >
                <div data-testid="standardDisclaimers" ref={heightRef}>
                    <ul>
                        {DISCLAIMERS(disclaimersText).map(({ id, text }) => (
                            <Fragment key={id}>{text}</Fragment>
                        ))}
                    </ul>
                    {disclaimersText.FOOTER_SECTION}
                </div>
            </animated.div>

            <RedirectButton
                action={TrackAction.ViewMore}
                from={TrackLocation.Disclaimers}
                onClick={toggleCollapsed}
                className={styles.button}
                variant={ButtonVariants.secondary}
            >
                {collapse ? VIEW_MORE : VIEW_LESS}
            </RedirectButton>
        </section>
    );
}
export default Disclaimers;
