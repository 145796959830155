import { LOCALES } from 'lang/locales';

export enum UpgradePurchaseCompleteMessages {
    TITLE = 'purchaseComplete.upgrade.title',
    BODY = 'purchaseComplete.upgrade.body',
    NEXT_STEPS_TITLE = 'purchaseComplete.upgrade.nextSteps.title',
    NEXT_STEPS_SUBTITLE = 'purchaseComplete.upgrade.nextSteps.subtitle',
    CARD_ONE_TITLE = 'purchaseComplete.upgrade.cardOne.title',
    CARD_ONE_BODY = 'purchaseComplete.upgrade.cardOne.body',
    CARD_TWO_TITLE = 'purchaseComplete.upgrade.cardTwo.title',
    CARD_TWO_BODY = 'purchaseComplete.upgrade.cardTwo.body',
    CARD_THREE_TITLE = 'purchaseComplete.upgrade.cardThree.title',
    CARD_THREE_BODY = 'purchaseComplete.upgrade.cardThree.body',
    CARD_FOUR_TITLE = 'purchaseComplete.upgrade.cardFour.title',
    CARD_FOUR_BODY = 'purchaseComplete.upgrade.cardFour.body',
}

export const upgradePurchaseCompleteMessages = {
    [LOCALES.ENGLISH]: {
        [UpgradePurchaseCompleteMessages.TITLE]:
            'Congratulations! Your\nUpgrade is Complete',
        [UpgradePurchaseCompleteMessages.BODY]:
            "We're getting your new subscription printer ready to\nship to you. You'll receive a confirmation email shortly.",
        [UpgradePurchaseCompleteMessages.NEXT_STEPS_TITLE]:
            'Next steps when you receive your printer',
        [UpgradePurchaseCompleteMessages.NEXT_STEPS_SUBTITLE]:
            "Don't worry, we'll email you this information too.",
        [UpgradePurchaseCompleteMessages.CARD_ONE_TITLE]: 'Open\nSmart App',
        [UpgradePurchaseCompleteMessages.CARD_ONE_BODY]:
            'Open or download the HP\nSmart App & <b>log in using\nthe same credentials</b> as\nyour current Platinum subscription.',
        [UpgradePurchaseCompleteMessages.CARD_TWO_TITLE]:
            'Set up your\nnew printer',
        [UpgradePurchaseCompleteMessages.CARD_TWO_BODY]:
            'Add a new printer and\nfollow the instructions on\nyour Smart App.',
        [UpgradePurchaseCompleteMessages.CARD_THREE_TITLE]:
            'Make sure to\nselect replace',
        [UpgradePurchaseCompleteMessages.CARD_THREE_BODY]:
            'Choose <b>replace a\nprinter</b> to transfer your\nPlatinum subscription to\nyour new printer.',
        [UpgradePurchaseCompleteMessages.CARD_FOUR_TITLE]:
            'Confirm and\ncomplete',
        [UpgradePurchaseCompleteMessages.CARD_FOUR_BODY]:
            'Finish the set up of your\nprinter to complete your\nenrollment and continue\nenjoying Platinum benefits.',
    },
    [LOCALES.SPANISH]: {
        [UpgradePurchaseCompleteMessages.TITLE]: '',
        [UpgradePurchaseCompleteMessages.BODY]: '',
        [UpgradePurchaseCompleteMessages.NEXT_STEPS_TITLE]: '',
        [UpgradePurchaseCompleteMessages.NEXT_STEPS_SUBTITLE]: '',
        [UpgradePurchaseCompleteMessages.CARD_ONE_TITLE]: '',
        [UpgradePurchaseCompleteMessages.CARD_ONE_BODY]: '',
        [UpgradePurchaseCompleteMessages.CARD_TWO_TITLE]: '',
        [UpgradePurchaseCompleteMessages.CARD_TWO_BODY]: '',
        [UpgradePurchaseCompleteMessages.CARD_THREE_TITLE]: '',
        [UpgradePurchaseCompleteMessages.CARD_THREE_BODY]: '',
        [UpgradePurchaseCompleteMessages.CARD_FOUR_TITLE]: '',
        [UpgradePurchaseCompleteMessages.CARD_FOUR_BODY]: '',
    },
};
