import { StepsMessages } from 'lang/messages/steps';

export const STEPS = (messages: Record<keyof typeof StepsMessages, string>) => {
    return [
        {
            step: 1,
            title: messages.TITLE_ONE,
            content: messages.DESCRIPTION_ONE,
        },
        {
            step: 2,
            title: messages.TITLE_TWO,
            content: messages.DESCRIPTION_TWO,
        },
        {
            step: 3,
            title: messages.TITLE_FOUR,
            content: messages.DESCRIPTION_FOUR,
            activationContent: messages.DESCRIPTION_FOUR_ACTIVATION,
        },
    ];
};
