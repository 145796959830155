import { useCallback } from 'react';
import useVariation from 'hooks/useVariation/useVariation';

export const useTrackVariation = () => {
    const { namedVariation } = useVariation();

    return useCallback(() => {
        const layer = {
            event: 'e_ABtest',
            test1name: 'CISS',
            test1variation: namedVariation,
        };

        window.dataLayer.push(layer);
    }, [namedVariation]);
};
