import {
    ZIP_BLACKLIST,
    ZIP_BLACKLIST_SPAIN,
    ZIP_UNAVAILABLE,
} from './constants';

export const baseURLProvider = async (
    apiName: string,
    apiVersion: string,
    baseURL: string
) => `${baseURL}/api/${apiName}/v${apiVersion}`;

export const validateZip = (
    zip: string,
    isSpanish: boolean,
    messages: Record<string, string>,
    isBilling?: boolean
) => {
    if (isBilling) {
        return true;
    }

    if (isSpanish) {
        const zipPrefix = zip.trim().substring(0, 2);

        const zipFound =
            ZIP_UNAVAILABLE.find((e) => e === zipPrefix) ||
            ZIP_BLACKLIST_SPAIN.includes(zip);

        if (zipFound) {
            return messages.CARD_COMMON_ZIP_UNAVAILABLE;
        }

        return true;
    }

    const zipPrefix = zip.trim().substring(0, 3);
    const zipFoundOnBlacklist = ZIP_BLACKLIST.find((e) => e === zipPrefix);

    if (zipFoundOnBlacklist) {
        return messages.CARD_COMMON_ZIP_ERROR_BLACKLIST;
    }

    return true;
};
