import { LOCALES } from 'lang/locales';

export enum PurchaseCompleteMessages {
    IMG = 'purchaseComplete.img',
    TITLE = 'purchaseComplete.title',
    DESCRIPTION = 'purchaseComplete.description',
    DESCRIPTION_WITHOUT_MAIL = 'purchaseComplete.description.withoutMail',
    DESCRIPTION_RETAIL = 'purchaseComplete.description.retail',
    DESCRIPTION_RETAIL_WITHOUT_MAIL = 'purchaseComplete.description.retail.withoutMail',
    SURVEY = 'purchaseComplete.survey',
    PLEASE_CALL_HEADER = 'purchaseComplete.pleaseCall.header',
    PLEASE_CALL_TEXT = 'purchaseComplete.pleaseCall.text',
    BUTTON = 'purchaseComplete.survey.button',
}

export const purchaseCompleteMessages = {
    [LOCALES.ENGLISH]: {
        [PurchaseCompleteMessages.IMG]: 'Printer',
        [PurchaseCompleteMessages.DESCRIPTION]:
            "We're getting your printer ready to ship to you. You will\ncomplete the enrollment process during printer set-\nup. An HP Instant Ink Platinum welcome email will be\nsent to {userEmail}.",
        [PurchaseCompleteMessages.DESCRIPTION_WITHOUT_MAIL]:
            "We're getting your printer ready to ship to you. You will\ncomplete the enrollment process during printer set-\nup. An HP Instant Ink Platinum welcome email will be\nsent to you.",
        [PurchaseCompleteMessages.DESCRIPTION_RETAIL]:
            'You will complete the registration process during the\nprinter setup. A welcome email from HP Instant Ink Platinum\nwill be sent to {userEmail}.',
        [PurchaseCompleteMessages.DESCRIPTION_RETAIL_WITHOUT_MAIL]:
            'You will complete the registration process during the\nprinter setup. A welcome email from HP Instant Ink Platinum\nwill be sent to you.',
        [PurchaseCompleteMessages.TITLE]:
            'Thank you for\nchoosing HP Instant\nInk Platinum Service',
        [PurchaseCompleteMessages.PLEASE_CALL_HEADER]:
            'Please call 1-888-447-0148\nto enroll your printer.',
        [PurchaseCompleteMessages.PLEASE_CALL_TEXT]:
            'Avoid additional fees by enrolling your printer within 30 days.',
        [PurchaseCompleteMessages.SURVEY]:
            'Win a 5€ gift card\ncompleting our 2 minutes\nsurvey',
        [PurchaseCompleteMessages.BUTTON]: 'Complete survey',
    },
    [LOCALES.SPANISH]: {
        [PurchaseCompleteMessages.TITLE]:
            'Gracias por elegir el\nServicio de\nsuscripción de\nimpresión de HP',
        [PurchaseCompleteMessages.IMG]: 'Impresora',
        [PurchaseCompleteMessages.DESCRIPTION]:
            'Estamos preparando tu impresora para enviarla.\nCompletarás el proceso de registro durante la\nconfiguración de la impresora. Te enviaremos un\ncorreo electrónico de bienvenida al HP Instant Ink\nPlatinum a {userEmail}.',
        [PurchaseCompleteMessages.DESCRIPTION_WITHOUT_MAIL]:
            'Estamos preparando tu impresora para enviarla.\nCompletarás el proceso de registro durante la\nconfiguración de la impresora. Te enviaremos un\ncorreo electrónico de bienvenida al HP Instant Ink\nPlatinum',
        [PurchaseCompleteMessages.DESCRIPTION_RETAIL]:
            'Completarás el proceso de inscripción durante la\nconfiguración de la impresora. Un correo electrónico\nde bienvenida de HP Instant Ink Platinum se enviará a\n{userEmail}.',
        [PurchaseCompleteMessages.DESCRIPTION_RETAIL_WITHOUT_MAIL]:
            'Completarás el proceso de inscripción durante la\nconfiguración de la impresora. Se enviará un correo\nelectrónico de bienvenida de HP Instant Ink Platinum.',
        [PurchaseCompleteMessages.PLEASE_CALL_HEADER]:
            'Llama al 1-888-447-0148\npara registrar tu impresora.',
        [PurchaseCompleteMessages.PLEASE_CALL_TEXT]:
            'Llama al <u>930 289 927</u> para registrar tu impresora.\nEvita cargos adicionales registrando tu impresora\ndentro de los 30 días.',
        [PurchaseCompleteMessages.SURVEY]:
            'Gana una tarjeta de regalo de 5€\ncompletando nuestra encuesta de\n2 minutos.',
        [PurchaseCompleteMessages.BUTTON]: 'Completar encuesta',
    },
};

export const purchaseCompleteValues = ({ userEmail = ' ' }) => ({
    [PurchaseCompleteMessages.DESCRIPTION]: { userEmail },
    [PurchaseCompleteMessages.DESCRIPTION_RETAIL]: { userEmail },
});
