import { useFormat } from 'hooks';
import { CommonMessages, commonMessagesValues } from 'lang/messages/common';

import styles from './MostPopularBadge.module.scss';

interface Props {
    testId?: string;
}

function MostPopularBadge({ testId = undefined }: Props) {
    const { MOST_POPULAR } = useFormat(
        CommonMessages,
        commonMessagesValues({})
    );

    return (
        <div className={styles.container} data-testid={testId} role="tooltip">
            <span className={styles.text}>{MOST_POPULAR}</span>
        </div>
    );
}

export default MostPopularBadge;
