import { Paths } from 'components/RoutedContent/paths';
import { TrackLocation, TrackType } from 'constants/analytics';
import { commonValues } from 'constants/formatValues';
import { useFormat, useTranslation, useVariation } from 'hooks';
import { AmountPrintMessages } from 'lang/messages/amountPrint';
import { CommonMessages } from 'lang/messages/common';

import RedirectButton from '../RedirectButton/RedirectButton';

import styles from './TheresAnOption.module.scss';

function TheresAnOption() {
    const { isSpain } = useTranslation();

    const { isControl } = useVariation();
    const { TITLE, TITLE_INK, DESCRIPTION, DESCRIPTION_SAYAN } =
        useFormat(AmountPrintMessages);
    const { GET_STARTED_FROM } = useFormat(
        CommonMessages,
        commonValues(isSpain())
    );

    return (
        <section className={styles.container}>
            <div className={styles.left}>
                <h1 className={styles.title}>
                    {isControl ? TITLE : TITLE_INK}
                </h1>
                <p className={styles.content}>{DESCRIPTION}</p>
                {isControl ? null : (
                    <p className={styles.content}>{DESCRIPTION_SAYAN}</p>
                )}
                <RedirectButton
                    action={TrackType.CTA}
                    from={TrackLocation.RiskFree}
                    redirectTo={Paths.PrinterSelection}
                >
                    {GET_STARTED_FROM}
                </RedirectButton>
            </div>
        </section>
    );
}
export default TheresAnOption;
