import { forwardRef } from 'react';
import { HyperLink } from 'components';
import {
    LOCALSTORAGE_PRINTER,
    Printer,
    UserChoice,
} from 'contexts/PrinterChoice/constants';
import { useFormat, useVariation } from 'hooks';
import useLocalStorage from 'hooks/useLocalStorage';
import { ChooseYourPrinterMessages } from 'lang/messages/chooseYourPrinter';
import { CommonRef } from 'types/globals';

import { useChoosePrinter, useComparisonToggle } from './hooks';
import {
    defaultPrinterMainImage,
    filterUnusedPrinters,
    printerImage,
} from './utils';
import ComparisonTable from '../ComparisonTable/ComparisonTable';
import Container from '../Container/Container';
import PrinterButton from '../PrinterButton/PrinterButton';

interface Props {
    printerData: Printer[];
    userChoice?: Printer;
    isUserSelection?: boolean;
    setUserChoice: (type: 'printer', newPrinter: Printer) => void;
}

function ChoosePrinter(
    {
        printerData,
        userChoice = undefined,
        setUserChoice,
        isUserSelection = false,
    }: Props,
    ref: CommonRef
) {
    const { TITLE, LINK, SUBTITLE } = useFormat(ChooseYourPrinterMessages);

    const { handlePrinterSelection } = useChoosePrinter(setUserChoice);
    const { comparisonModal, toggleComparisonModal } = useComparisonToggle();
    const [localStorage] = useLocalStorage<UserChoice>(LOCALSTORAGE_PRINTER);

    const { variation } = useVariation();

    return (
        <>
            <ComparisonTable
                activePrinter={userChoice?.id || ''}
                printers={printerData}
                show={comparisonModal}
                onClose={toggleComparisonModal}
            />
            <Container
                ref={ref}
                mainImage={
                    userChoice?.image ||
                    defaultPrinterMainImage(
                        variation,
                        localStorage?.disabledPrinterIds
                    )
                }
                title={TITLE}
            >
                {filterUnusedPrinters(printerData, variation).map(
                    (currentPrinter) => (
                        <PrinterButton
                            isSelected={userChoice?.id === currentPrinter.id}
                            isUserChoice={isUserSelection}
                            onClick={() =>
                                handlePrinterSelection(currentPrinter)
                            }
                            key={currentPrinter.id}
                            title={currentPrinter.productName}
                            content={currentPrinter.description}
                            image={printerImage(currentPrinter.id)}
                            subtitle={currentPrinter.name}
                            mostPopular={currentPrinter.mostPopular}
                            disabled={
                                currentPrinter.disabled ||
                                localStorage?.disabledPrinterIds?.includes(
                                    currentPrinter.id
                                )
                            }
                        />
                    )
                )}

                <HyperLink
                    linkText={LINK}
                    text={SUBTITLE}
                    onClick={toggleComparisonModal}
                />
            </Container>
        </>
    );
}

export default forwardRef(ChoosePrinter);
