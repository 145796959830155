import { LOCALES } from 'lang/locales';

export enum OrderYourPlanMessages {
    TITLE = 'orderYourPlan.title',
    SUBTITLE = 'orderYourPlan.subtitle',
    ACTIVATION_SUBTITLE = 'orderYourPlan.activationSubtitle',
    PLAN = 'orderYourPlan.plan',
    PAPER_SUBSCRIPTION = 'orderYourPlan.paperSubscription',
    PAGE_MONTH = 'orderYourPlan.pageMonth',
}

export const orderYourPlanMessages = {
    [LOCALES.ENGLISH]: {
        [OrderYourPlanMessages.TITLE]: 'Order your\nInstant Ink\nPlatinum plan',
        [OrderYourPlanMessages.SUBTITLE]:
            '30 day risk-free trial. Shipping\nincluded. Cancelation fees\napplies in first 24 months.',
        [OrderYourPlanMessages.ACTIVATION_SUBTITLE]:
            '30-day free trial. Shipping\nincluded. No cancelation fees\nor commitment period.',
        [OrderYourPlanMessages.PLAN]: 'Instant Ink: {pages} pages/month',
        [OrderYourPlanMessages.PAPER_SUBSCRIPTION]: 'Paper: {paper}',
        [OrderYourPlanMessages.PAGE_MONTH]: 'pages/month',
    },

    [LOCALES.SPANISH]: {
        [OrderYourPlanMessages.TITLE]:
            'Configura tu\nplan HP Instant\nink Platinum',
        [OrderYourPlanMessages.SUBTITLE]:
            'Pruébalo sin compromiso durante 30\ndías. Se aplica una tarifa de\ncancelación en caso de cancelar el\nservicio durante los próximos 6\nmeses. El plan se renueva\nautomáticamente cada mes.',
        [OrderYourPlanMessages.ACTIVATION_SUBTITLE]: ' ',
        [OrderYourPlanMessages.PLAN]: 'Instant Ink: {pages} páginas/mes',
        [OrderYourPlanMessages.PAPER_SUBSCRIPTION]: 'Papel: {paper}',
        [OrderYourPlanMessages.PAGE_MONTH]: 'páginas/mes',
    },
};

export const orderYourPlanValues = ({ pages = ' ', paper = ' ' }) => ({
    [OrderYourPlanMessages.PLAN]: {
        pages,
    },
    [OrderYourPlanMessages.PAPER_SUBSCRIPTION]: {
        paper,
    },
});
