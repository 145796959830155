import {
    createContext,
    ReactNode,
    useCallback,
    useContext,
    useMemo,
    useState,
} from 'react';
import {
    CONTEXT_ERROR,
    PrinterSelectionResponse,
} from 'contexts/PrinterChoice/constants';
import { Params } from 'screens/Checkout/constants';

import {
    DataContextType,
    DataProviderState,
    INITIAL_CONTEXT,
} from './constants';
import { legacyGetUTMParams, utmParams as getUTMParams } from './utils';

export const DataContext = createContext<DataContextType>(INITIAL_CONTEXT);

interface Props {
    children: ReactNode;
}

function DataProvider({ children }: Props) {
    const {
        showSixMonthFreeBanner,
        showFree2DayShipping,
        show30DayRiskFree,
        showActivation,
    } = legacyGetUTMParams();
    const utmParams = getUTMParams();

    const [state, setState] = useState<DataProviderState>({
        show30DayRiskFree,
        showFree2DayShipping,
        showSixMonthFreeBanner,
        loaded: false,
        loading: true,
        utmParams,
        showActivation,
    });

    const saveData = useCallback(
        (data: PrinterSelectionResponse) => {
            setState((prev) => ({
                ...prev,
                loaded: true,
                loading: false,
                utmParams,
                planData: data,
            }));
        },
        [utmParams]
    );

    const setExistingPlan = useCallback((newItem: string) => {
        setState((prev) => {
            if (!prev.planData) {
                return prev;
            }
            if (!prev?.planData.existingCustomer) {
                return {
                    ...prev,
                    planData: {
                        ...prev.planData,
                        existingCustomer: {
                            id: '-1',
                            inkPlan: newItem,
                            printerAge: 0,
                            printerModel: '',
                        },
                    },
                };
            }

            return {
                ...prev,
                planData: {
                    ...prev.planData,
                    existingCustomer: {
                        ...prev.planData.existingCustomer,
                        inkPlan: newItem,
                    },
                },
            };
        });
    }, []);

    const getCurrentInkPlanAndPlanId = useCallback(
        ({ inkPlanId, planId }: Params) => {
            const inkPlans = state.planData?.newInkPlans || [];
            const inkPlan = inkPlans.find((i) => i.id === inkPlanId);
            const plans = state.planData?.printers || [];
            const plan = plans.find((p) => p.id === planId);

            return { inkPlan: inkPlan ?? null, plan: plan ?? null };
        },
        [state.planData?.newInkPlans, state.planData?.printers]
    );

    const value = useMemo(
        () => ({
            ...state,
            setExistingPlan,
            getCurrentInkPlanAndPlanId,
            saveData,
        }),
        [getCurrentInkPlanAndPlanId, saveData, setExistingPlan, state]
    );

    return (
        <DataContext.Provider value={value}>{children}</DataContext.Provider>
    );
}

export function useDataProvider() {
    const ctx = useContext(DataContext);

    if (!ctx) {
        throw new Error(CONTEXT_ERROR);
    }

    return { ...ctx };
}

export default DataProvider;
