import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { Button, Modal } from 'components';
import { LOCALSTORAGE_PRINTER } from 'contexts/PrinterChoice/constants';
import { useQuery } from 'hooks';
import useLocalStorage from 'hooks/useLocalStorage';

import { buttons } from './constants';
import cookieImg from './cookie-bite-solid.svg';
import { translateVariation } from './utils';

import styles from './CookieDebugMenu.module.scss';

function CookieDebugMenu() {
    const [debugMenuModal, setDebugMenuModal] = useState(false);

    // ABCD Variations, cookie based

    const [cookies, setCookie, removeCookie] = useCookies(['variation']);
    const { debugMenu } = useQuery<{ debugMenu: boolean }>();
    const [, setLocalStorage] = useLocalStorage(LOCALSTORAGE_PRINTER);

    return (
        <>
            <Modal
                show={debugMenuModal}
                onClose={() => {
                    setDebugMenuModal((prev) => !prev);
                }}
            >
                <div className={styles.container}>
                    <h1>
                        Current variant: {translateVariation(cookies.variation)}
                    </h1>
                    <div className={styles.buttonContainer}>
                        {buttons.map((button) => (
                            <Button
                                key={button.id}
                                onClick={() =>
                                    setCookie('variation', button.value)
                                }
                                disabled={cookies.variation === button.value}
                            >
                                {button.name}
                            </Button>
                        ))}
                    </div>

                    <hr />

                    <div className={styles.buttonContainer}>
                        <Button
                            onClick={() => {
                                removeCookie('variation');
                                removeCookie('variation');
                            }}
                            disabled={cookies.variation === undefined}
                        >
                            Assign random variation
                        </Button>
                        <Button
                            onClick={() => {
                                setLocalStorage(undefined);
                                window.location.reload();
                            }}
                        >
                            Remove printerSelection
                        </Button>
                    </div>
                </div>
            </Modal>

            {debugMenu ? (
                <Button
                    className={styles.activationButton}
                    onClick={() => {
                        setDebugMenuModal((prev) => !prev);
                    }}
                >
                    <img src={cookieImg} alt="Cookies" width={30} height={30} />
                </Button>
            ) : null}
        </>
    );
}
export default CookieDebugMenu;
