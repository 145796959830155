import classNames from 'classnames';
import { Paths } from 'components/RoutedContent/paths';
import { TrackLocation, TrackType } from 'constants/analytics';
import { CookieVariation } from 'constants/cookiesEnums';
import { commonValues, heroValues } from 'constants/formatValues';
import { useFormat, useTranslation, useVariation } from 'hooks';
import { CommonMessages } from 'lang/messages/common';
import {
    HeroMessages,
    HeroMessagesB,
    HeroMessagesC,
} from 'lang/messages/heroScreen';
import RedirectButton from 'screens/LandingPage/components/RedirectButton/RedirectButton';

import { ICONS } from '../../constants';
import IconDescriptor from '../IconDescriptor/IconDescriptor';

import styles from './Information.module.scss';

interface Props {
    isMobile?: boolean;
}

function Information({ isMobile = false }: Props) {
    const { isSpain } = useTranslation();
    const { variation } = useVariation();

    const messages = {
        [CookieVariation.Control]: HeroMessages,
        [CookieVariation.PlusSayan]: HeroMessagesB,
        [CookieVariation.SayanReplacing]: HeroMessagesC,
        [CookieVariation.LEBI]: HeroMessagesC, // TODO: Add LEBI hero messages
    };

    const texts = useFormat(
        messages[variation || CookieVariation.Control],
        heroValues
    );

    const { GET_STARTED_FROM } = useFormat(
        CommonMessages,
        commonValues(isSpain())
    );

    return (
        <div
            className={classNames({
                [styles.containerMobile]: isMobile,
                [styles.container]: !isMobile,
            })}
        >
            <hr className={styles.separator} />

            <ul className={styles.list}>
                {ICONS(texts).map(({ text, icon, id }) => (
                    <IconDescriptor text={text} icon={icon} key={id} />
                ))}
            </ul>

            <footer className={styles.footer}>
                <RedirectButton
                    action={TrackType.CTA}
                    from={TrackLocation.Hero}
                    redirectTo={Paths.PrinterSelection}
                >
                    {GET_STARTED_FROM}
                </RedirectButton>
                <p>{texts.DISCLAIMER_RISK_FREE}</p>
            </footer>
        </div>
    );
}

export default Information;
