import classNames from 'classnames';
import { useFormat } from 'hooks';
import { CommonMessages, commonMessagesValues } from 'lang/messages/common';

import styles from './LoadingScreen.module.scss';

interface Props {
    showText?: boolean;
    useContainer?: boolean;
}

function LoadingScreen({ showText = true, useContainer = true }: Props) {
    const { LOADING } = useFormat(CommonMessages, commonMessagesValues({}));

    return (
        <div
            className={classNames({ [styles.container]: useContainer })}
            title="loading-spinner"
        >
            <div
                className={classNames({
                    [styles.spinnerContainer]: useContainer,
                })}
            >
                <div className={styles.ldSpinner}>
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                </div>
                {showText && <h1 className={styles.text}>{LOADING}...</h1>}
            </div>
        </div>
    );
}

export default LoadingScreen;
