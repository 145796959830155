import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom-v5-compat';

function useRetailCheck() {
    const [isRetail, setIsRetail] = useState(false);
    const { pathname } = useLocation();

    const hasRetailOnPath = pathname.includes('/el-corte-ingles');

    const checkForRetail = useMemo(() => hasRetailOnPath, [hasRetailOnPath]);

    useEffect(() => {
        setIsRetail(checkForRetail);
    }, [checkForRetail]);

    return { isRetail, checkForRetail };
}

export default useRetailCheck;
