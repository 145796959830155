export function parseKey(value: string, key: string) {
    let parsedValue: string | number | undefined = value;

    try {
        parsedValue = JSON.parse(value);
    } catch (err) {
        parsedValue = value;
    }

    if (typeof parsedValue === 'string' && parsedValue.length === 0) {
        parsedValue = undefined;
    }

    const parsedKey = key
        .toLowerCase()
        .split('_')
        .reduce((acc, currentValue, index) => {
            if (index === 0) {
                return currentValue;
            }

            const splittedWord: string[] = currentValue.split('');
            const firstLetter = splittedWord.shift() || '';

            return acc + firstLetter.toUpperCase() + splittedWord.join('');
        }, '');

    return { parsedKey, parsedValue };
}
