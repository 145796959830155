import { KeyboardEventHandler } from 'react';

type ICheckForNumber = (
    type: 'number' | 'text' | undefined
) => KeyboardEventHandler<HTMLInputElement>;

export const checkForNumber: ICheckForNumber = (type) => (event) => {
    const regex = /(^\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/;

    if (!event.key.match(regex) && type === 'number') {
        return event.preventDefault();
    }
    return event;
};
