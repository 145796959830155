import AutoDocumentFeederIcon from 'assets/comparison_table_document_feeder_icon.svg';
import FaxIcon from 'assets/comparison_table_fax_icon.svg';
import InputCapacityIcon from 'assets/comparison_table_input_capacity_icon.svg';
import PhotoTrayIcon from 'assets/comparison_table_photo_tray_icon.svg';
import PrintScanCopyIcon from 'assets/comparison_table_print_scan_copy_icon.svg';
import PrintingSpeedIcon from 'assets/comparison_table_printing_speed_icon.svg';
import TouchScreenIcon from 'assets/comparison_table_touch_screen_icon.svg';
import USBIcon from 'assets/comparison_table_usb_icon.svg';
import HpSmartTankImage from 'assets/not_available_sayan_xs.webp';
import HpEnvyImage from 'assets/thumb_essential_front.webp';
import HpSmartTank5105Image from 'assets/thumb_lebi_front.webp';
import HpOfficejetProImage from 'assets/thumb_professional_front.webp';
import HpEnvyInspireImage from 'assets/thumb_versatile_front.webp';

export interface SpecIcon {
    [key: string]: string;
}

export interface PrinterInfo {
    id: string;
    printerId: string;
    printerName: string;
    printerImg: string;
    printScanCopy: boolean;
    printingSpeed: number;
    inputCapacity: number;
    photoTray: boolean;
    touchScreen: boolean;
    usb: boolean;
    fax: boolean;
    autoDocumentFeeder: boolean;
}

export interface IOutput {
    name: string;
    img: string;
    id: string;
    index: number;
}

export const SPEC_ICONS: SpecIcon = {
    printScanCopy: PrintScanCopyIcon,
    printingSpeed: PrintingSpeedIcon,
    inputCapacity: InputCapacityIcon,
    photoTray: PhotoTrayIcon,
    touchScreen: TouchScreenIcon,
    usb: USBIcon,
    fax: FaxIcon,
    autoDocumentFeeder: AutoDocumentFeederIcon,
};

// Printer information for the comparison table, this should be
// coming from the backend so it´s pending to be refactored,
// for now it´s hardcoded.

export const PRINTER_INFO: PrinterInfo[] = [
    {
        id: 'printer5',
        printerId: 'HP_Envy',
        printerName: 'printers.envy',
        printerImg: HpEnvyImage,
        printScanCopy: true,
        printingSpeed: 10,
        inputCapacity: 100,
        photoTray: false,
        touchScreen: false,
        usb: false,
        fax: false,
        autoDocumentFeeder: false,
    },
    {
        id: 'printer4',
        printerId: 'HP_Envy_Inspire',
        printerName: 'printers.inspire',
        printerImg: HpEnvyInspireImage,
        printScanCopy: true,
        printingSpeed: 15,
        inputCapacity: 125,
        photoTray: true,
        touchScreen: true,
        usb: false,
        fax: false,
        autoDocumentFeeder: false,
    },
    {
        id: 'printer3',
        printerId: 'HP_Officejet_PRO',
        printerName: 'printers.pro',
        printerImg: HpOfficejetProImage,
        printScanCopy: true,
        printingSpeed: 22,
        inputCapacity: 250,
        photoTray: false,
        touchScreen: true,
        usb: true,
        fax: true,
        autoDocumentFeeder: true,
    },
    {
        id: 'printer6',
        printerId: 'SmartTank5105',
        printerName: 'printers.smartTank',
        printerImg: HpSmartTank5105Image,
        printScanCopy: true,
        printingSpeed: 12,
        inputCapacity: 100,
        photoTray: false,
        touchScreen: true,
        usb: true,
        fax: true,
        autoDocumentFeeder: true,
    },
    {
        id: 'printer2',
        printerId: 'SmartTank7605',
        printerName: 'printers.smartTank',
        printerImg: HpSmartTankImage,
        printScanCopy: true,
        printingSpeed: 15,
        inputCapacity: 250,
        photoTray: false,
        touchScreen: true,
        usb: true,
        fax: true,
        autoDocumentFeeder: true,
    },
];

export const SPECS = Object.keys(PRINTER_INFO[1]).slice(4);
