import { useIntl } from 'react-intl';
import { AddendumSection } from 'screens/TermsAndConditions/types';

import styles from './TermSection.module.scss';

interface Props {
    sections: AddendumSection;
    activationTerms: boolean;
}

function Terms({ sections, activationTerms }: Props) {
    const { formatMessage } = useIntl();

    return sections.map((currentSection) => (
        <li key={currentSection.id} id={currentSection.id.toString()}>
            <p className={styles.header} id={currentSection.id.toString()}>
                {formatMessage({ id: currentSection.header })}
            </p>
            <div>
                {formatMessage({
                    id:
                        activationTerms && currentSection.activationContent
                            ? currentSection.activationContent
                            : currentSection.content,
                })}
            </div>
        </li>
    ));
}
export default Terms;
