import { LoadingScreen } from 'components';
import { useDataProvider, usePrinterChoice } from 'contexts';
import { EMPTY_STATE } from 'contexts/PrinterChoice/constants';
import { useRequest, useTranslation } from 'hooks';
import { OrderZones } from 'hooks/constants';
import { getPlanData } from 'services/planData';

import CookieDebugMenu from '../CookieDebugMenu/CookieDebugMenu';

interface Props {
    children: JSX.Element;
}

function DataFetcher({ children }: Props) {
    const { saveData: savePrinterChoice, data: printerChoiceData } =
        usePrinterChoice();
    const { saveData: saveProviderData, planData: dataProviderInfo } =
        useDataProvider();

    const { isSpain, currentLang } = useTranslation();

    const { isLoading } = useRequest({
        service: getPlanData,
        params: { zone: isSpain() ? OrderZones.ES : OrderZones.US },
        options: {
            waitForBool:
                Boolean(currentLang) &&
                JSON.stringify(printerChoiceData) ===
                    JSON.stringify(EMPTY_STATE) &&
                JSON.stringify(dataProviderInfo) === undefined,
            transform: (res) => {
                savePrinterChoice(res);
                saveProviderData(res);
            },
        },
    });

    if (isLoading) {
        return <LoadingScreen />;
    }

    return (
        <>
            <CookieDebugMenu />
            {children}
        </>
    );
}

export default DataFetcher;
