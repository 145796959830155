export function doFetch<T>(path: string): Promise<T> {
    return new Promise((cb, cbErr) => {
        fetch(path, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(async (res) => {
                if (res.status < 400) {
                    try {
                        cb((await res.json()) as T);
                    } catch (error) {
                        cbErr(error);
                    }
                } else {
                    cbErr(new Error(`Response: ${res.status}`));
                }
            })
            .catch((error) => {
                cbErr(error);
            });
    });
}

// TODO: Now that this is migrated to ctx/UTMParams, check if any of this is still useful and delete

export function utmParams() {
    const qParams2 = new URLSearchParams(document.location.search);
    const utmParamsIntl: Record<string, string> = {};
    const utmParamKeys = [
        ...(qParams2.keys() as unknown as Array<string>),
    ].filter((k) => k.toLowerCase().startsWith('utm'));
    utmParamKeys.forEach((k) => {
        utmParamsIntl[(k as string).toLowerCase()] = qParams2.get(k) || '';
    });
    return utmParamsIntl;
}

export function legacyGetUTMParams() {
    const qParams = new URLSearchParams(document.location.search);
    const customerId = qParams.get('z') || '';

    const utm = utmParams().utm_campaign?.toUpperCase();
    const showSixMonthFreeBanner = utm === 'FMT';
    const showFree2DayShipping = utm === '2DA';
    const show30DayRiskFree = utm === '30DA';
    const showActivation = utm === 'AC';

    return {
        customerId,
        showSixMonthFreeBanner,
        showFree2DayShipping,
        show30DayRiskFree,
        showActivation,
    };
}
