import { useFormat } from 'hooks';
import {
    HowManyPagesMessages,
    howManyPagesValues,
} from 'lang/messages/howManyPages';
import InformativeModal from 'screens/PrinterSelection/components/InformativeModal/InformativeModal';

import ModalImage from './assets/modalImage.png';

import styles from './Modal.module.scss';

interface Props {
    toggleModal: () => void;
    show: boolean;
}

function Modal({ toggleModal, show }: Props) {
    const { MODAL_TITLE, MODAL_CONTENT } = useFormat(
        HowManyPagesMessages,
        howManyPagesValues({})
    );

    return (
        <InformativeModal image={ModalImage} onClick={toggleModal} show={show}>
            <h1 className={styles.modalTitle}>{MODAL_TITLE}</h1>
            <p className={styles.modalText}>{MODAL_CONTENT}</p>
        </InformativeModal>
    );
}
export default Modal;
