import { CookieVariation } from 'constants/cookiesEnums';
import {
    FAQSectionMessages,
    FAQSectionMessagesB,
    FAQSectionMessagesC,
    FAQSectionMessagesD,
} from 'lang/messages/faqSection';

export const getLandingFAQs = (variation: CookieVariation) => {
    const faqsTypes = {
        [CookieVariation.Control]: FAQSectionMessages,
        [CookieVariation.PlusSayan]: FAQSectionMessagesB,
        [CookieVariation.SayanReplacing]: FAQSectionMessagesC,
        [CookieVariation.LEBI]: FAQSectionMessagesD,
    };

    return [
        {
            id: 0,
            question: faqsTypes[variation].ABOUT_SUBSCRIPTION_FAQ0_QUESTION,
            answer: faqsTypes[variation].ABOUT_SUBSCRIPTION_FAQ0_ANSWER,
        },
        {
            id: 1,
            question: faqsTypes[variation].ABOUT_SUBSCRIPTION_FAQ1_QUESTION,
            answer: faqsTypes[variation].ABOUT_SUBSCRIPTION_FAQ1_ANSWER,
        },
        {
            id: 2,
            question: faqsTypes[variation].ABOUT_SUBSCRIPTION_FAQ2_QUESTION,
            answer: faqsTypes[variation].ABOUT_SUBSCRIPTION_FAQ2_ANSWER,
        },
        {
            id: 3,
            question: faqsTypes[variation].ABOUT_SUBSCRIPTION_FAQ3_QUESTION,
            answer: faqsTypes[variation].ABOUT_SUBSCRIPTION_FAQ3_ANSWER,
        },
        {
            id: 4,
            question: faqsTypes[variation].ABOUT_SUBSCRIPTION_FAQ4_QUESTION,
            answer: faqsTypes[variation].ABOUT_SUBSCRIPTION_FAQ4_ANSWER,
        },
        {
            id: 5,
            question: faqsTypes[variation].GETTING_STARTED_FAQ5_QUESTION,
            activationQuestion:
                faqsTypes[variation].ABOUT_SUBSCRIPTION_FAQ5_QUESTION,
            answer: faqsTypes[variation].GETTING_STARTED_FAQ5_ANSWER,
            activationAnswer:
                faqsTypes[variation].ABOUT_SUBSCRIPTION_FAQ5_ANSWER,
        },
    ];
};
