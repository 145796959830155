import { CookieVariation } from 'constants/cookiesEnums';

import { translateVariation } from './utils';

export const buttons = [
    {
        id: 0,
        name: `${translateVariation(CookieVariation.Control)} (0 - A)`,
        value: CookieVariation.Control,
    },
    {
        id: 1,
        name: `${translateVariation(CookieVariation.PlusSayan)} (1 - B)`,
        value: CookieVariation.PlusSayan,
    },
    {
        id: 2,
        name: `${translateVariation(CookieVariation.SayanReplacing)} (2 - C)`,
        value: CookieVariation.SayanReplacing,
    },
    {
        id: 3,
        name: `${translateVariation(CookieVariation.LEBI)} (3 - D)`,
        value: CookieVariation.LEBI,
    },
];
