import { useCallback } from 'react';
import { NewInkPlan, Printer } from 'contexts/PrinterChoice/constants';

import {
    BuyflowEvent,
    BuyflowStates,
    TrackBuyflowFn,
    TrackLinkFn,
    TrackPageFn,
} from './constants';
import { getPrinterData } from './utils';
import { useDataProvider } from '../contexts/DataProvider';

// Used when user enters a page
export const useTrackPage = (): TrackPageFn => {
    const { loaded, planData } = useDataProvider();
    const userId = planData?.existingCustomer?.id;

    return useCallback(
        (p1: string, p2?: string, p3?: string, p4?: string) => {
            if (!loaded) {
                return;
            }

            window.dataLayer.push({
                event: 'e_pageView',
                userID: userId,
                pageNameL5: p1,
                pageNameL6: p2,
                pageNameL7: p3,
                pageNameL8: p4,
            });
        },
        [loaded, userId]
    );
};

// Used to track user actions
export const useTrackLink = (): TrackLinkFn => {
    const { loaded, planData } = useDataProvider();
    const userId = planData?.existingCustomer?.id;

    return useCallback(
        (linkID: string, linkPlacement?: string) => {
            if (!loaded) {
                return;
            }
            const payload = {
                event: 'e_linkClick',
                userID: userId,
                linkID,
                linkPlacement,
            };

            if (linkPlacement) {
                payload.linkPlacement = linkPlacement;
            }
            window.dataLayer.push(payload);
        },
        [userId, loaded]
    );
};

const getPlanData = (inkPlan: NewInkPlan) => {
    return {
        name: `${inkPlan.pages} page plan`,
        id: inkPlan.pages,
        quantity: 1,
        price: inkPlan.cost,
    };
};

export const useTrackBuyflowAdd = (): TrackBuyflowFn => {
    const { loaded, planData } = useDataProvider();
    const userId = planData?.existingCustomer?.id;

    return useCallback(
        (printer: Printer, plan: NewInkPlan) => {
            if (!loaded) {
                return;
            }
            const payload = {
                event: 'e_addToCart',
                userID: userId,
                ecommerce: {
                    add: {
                        products: [getPrinterData(printer), getPlanData(plan)],
                    },
                },
            };

            window.dataLayer.push(payload);
        },
        [loaded, userId]
    );
};

export const useTrackBuyflowRemove = (): TrackBuyflowFn => {
    const { loaded, planData } = useDataProvider();
    const userId = planData?.existingCustomer?.id;

    return useCallback(
        (printer: Printer, plan: NewInkPlan) => {
            if (!loaded) {
                return;
            }
            const payload = {
                event: 'e_removeFromCart',
                userID: userId,
                ecommerce: {
                    remove: {
                        products: [getPrinterData(printer), getPlanData(plan)],
                    },
                },
            };

            window.dataLayer.push(payload);
        },
        [loaded, userId]
    );
};

export const useTrackBuyflowCheckout = (): TrackBuyflowFn => {
    const { loaded, planData } = useDataProvider();
    const userId = planData?.existingCustomer?.id;

    return useCallback(
        (
            printer: Printer,
            plan: NewInkPlan,
            _evnt?: BuyflowEvent,
            state?: BuyflowStates
        ) => {
            if (!loaded) {
                return;
            }
            const payload = {
                event: 'e_pageView',
                userID: userId,
                ecommerce: {
                    checkout: {
                        actionField: {
                            step: state,
                        },
                        products: [getPrinterData(printer), getPlanData(plan)],
                    },
                },
            };

            window.dataLayer.push(payload);
        },
        [loaded, userId]
    );
};

export const useTrackBuyflowPurchase = (): TrackBuyflowFn => {
    const { loaded, planData } = useDataProvider();
    const userId = planData?.existingCustomer?.id;

    return useCallback(
        (printer: Printer, plan: NewInkPlan) => {
            if (!loaded) {
                return;
            }
            const payload = {
                event: 'e_pageView',
                userID: userId,
                ecommerce: {
                    checkout: {
                        actionField: {
                            affiliation: 'Platinum Instant Ink',
                        },
                        products: [getPrinterData(printer), getPlanData(plan)],
                    },
                },
            };

            window.dataLayer.push(payload);
        },
        [loaded, userId]
    );
};
