import { createContext, ReactNode, useContext, useMemo, useState } from 'react';
import { PilotIdInfo } from 'services/pilotIdInfo';
import { Nullable, StateDispatch } from 'types/globals';

interface Props {
    children: ReactNode;
}

export const UpgradeInfoContext = createContext<{
    data: Nullable<PilotIdInfo>;
    error: Nullable<string>;
    isLoading: boolean;
    setData: StateDispatch<Nullable<PilotIdInfo>>;
    setError: StateDispatch<Nullable<string>>;
    setIsLoading: StateDispatch<boolean>;
}>({
    data: null,
    error: null,
    isLoading: false,
    setIsLoading: () => {},
    setData: () => {},
    setError: () => {},
});

export function UpgradeInfoProvider({ children }: Props) {
    const [data, setData] = useState<Nullable<PilotIdInfo>>(null);
    const [error, setError] = useState<Nullable<string>>(null);
    const [isLoading, setIsLoading] = useState(false);

    const value = useMemo(
        () => ({
            data,
            error,
            isLoading,
            setIsLoading,
            setData,
            setError,
        }),
        [data, error, isLoading]
    );

    return (
        <UpgradeInfoContext.Provider value={value}>
            {children}
        </UpgradeInfoContext.Provider>
    );
}

export function useUpgradeInfo() {
    const ctx = useContext(UpgradeInfoContext);

    if (!ctx) {
        throw new Error('You are using this out of context');
    }

    return ctx;
}
