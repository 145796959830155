import { LOCALES } from 'lang/locales';

export enum ChooseYourPrinterMessages {
    TITLE = 'chooseYourPrinter.title',
    SUBTITLE = 'chooseYourPrinter.subtitle',
    LINK = 'chooseYourPrinter.link',
}

export const chooseYourPrinterMessages = {
    [LOCALES.ENGLISH]: {
        [ChooseYourPrinterMessages.TITLE]: 'Choose your printer',
        [ChooseYourPrinterMessages.SUBTITLE]: "Can't decide?",
        [ChooseYourPrinterMessages.LINK]: 'Compare printers',
    },
    [LOCALES.SPANISH]: {
        [ChooseYourPrinterMessages.TITLE]: 'Elige tu impresora nueva',
        [ChooseYourPrinterMessages.SUBTITLE]: '¿No puedes decidirte?',
        [ChooseYourPrinterMessages.LINK]: 'Compara las impresoras',
    },
};
