import { useEffect, useLayoutEffect, useMemo } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import { DownloadButton, NavigationBar } from 'components';
import { TrackLocation } from 'constants/analytics';
import { CookieVariation } from 'constants/cookiesEnums';
import { useDataProvider } from 'contexts/DataProvider';
import { useTrackPage, useTranslation } from 'hooks';
import {
    FAQSectionMessages,
    FAQSectionMessagesB,
    FAQSectionMessagesC,
    FAQSectionMessagesD,
} from 'lang/messages/faqSection';

import { FAQItem, Header } from './components';
import { FAQS } from './constants';
import { usePrintFAQs } from './hooks';

import styles from './FAQ.module.scss';

// TODO: Refactor for 100% coverage!
const faqsTypes = {
    [CookieVariation.Control]: FAQSectionMessages,
    [CookieVariation.PlusSayan]: FAQSectionMessagesB,
    [CookieVariation.SayanReplacing]: FAQSectionMessagesC,
    [CookieVariation.LEBI]: FAQSectionMessagesD,
};
function FAQ() {
    const trackPage = useTrackPage();
    const { isSpain: isSpanish } = useTranslation();
    const { showActivation } = useDataProvider();
    const [cookies] = useCookies(['variation']);
    const { ref, expandFAQs, handlePrint } = usePrintFAQs({
        bodyClass: styles.printer,
    });

    const newFaqType = useMemo(
        () =>
            faqsTypes[cookies.variation as CookieVariation] ||
            FAQSectionMessages,
        [cookies]
    );

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    });

    useEffect(() => {
        trackPage(TrackLocation.FAQ);
    }, [trackPage]);

    return (
        <>
            <NavigationBar
                aside={<DownloadButton onClick={handlePrint} />}
                variant="black"
            />

            <Container fluid ref={ref}>
                <Row>
                    {!expandFAQs && <Col md={2} lg={4} />}
                    <Col
                        md={expandFAQs ? undefined : 8}
                        lg={expandFAQs ? undefined : 4}
                        className={styles.containerFaqs}
                    >
                        <Header />

                        {FAQS(showActivation, isSpanish(), newFaqType).map(
                            ({ title, content, key }) => (
                                <FAQItem
                                    expandAll={expandFAQs}
                                    key={key}
                                    title={title}
                                    content={content}
                                />
                            )
                        )}
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default FAQ;
