import { forwardRef } from 'react';
import { animated } from '@react-spring/web';
import backArrow from 'assets/chevron_down.svg';
import { Printer } from 'contexts/PrinterChoice/constants';
import { useFormat } from 'hooks';
import { ComparisonTableMessages } from 'lang/messages/comparisonTable';
import { Nullable } from 'types/globals';

import styles from './Specifications.module.scss';

interface Props {
    currentPrinter?: Printer | null;
    handleReturn: (printerToSee: null | undefined | string) => void;
    style: object;
}

const Specifications = forwardRef<Nullable<HTMLDivElement>, Props>(
    function Specifications({ currentPrinter, style, handleReturn }, ref) {
        const { TITLE, SPECIFICATIONS_DATA_SHEET } = useFormat(
            ComparisonTableMessages
        );

        return (
            <animated.div
                style={style}
                className={styles.mainContainer}
                ref={ref}
            >
                <div className={styles.titleContainer}>
                    <button
                        type="button"
                        onClick={() => handleReturn(null)}
                        className={styles.button}
                        aria-label="Go Back"
                    >
                        <img src={backArrow} alt="Go back" />
                    </button>
                    <h1 className={styles.title}>{TITLE}</h1>
                </div>
                <table className={styles.tableContainer}>
                    <tbody>
                        <tr>
                            <td>{SPECIFICATIONS_DATA_SHEET}</td>
                            <td>{currentPrinter?.name}</td>
                        </tr>
                        {currentPrinter?.specifications?.map(
                            (specification) => {
                                return (
                                    <tr key={specification.label}>
                                        <td>{specification.label}</td>
                                        <td>{specification.data}</td>
                                    </tr>
                                );
                            }
                        )}
                    </tbody>
                </table>
            </animated.div>
        );
    }
);

Specifications.defaultProps = {
    currentPrinter: null,
};

export default Specifications;
