import { Paths } from 'components/RoutedContent/paths';
import { TrackLocation, TrackType } from 'constants/analytics';
import { useFormat, useTranslation, useVariation } from 'hooks';
import { CommonMessages, commonMessagesValues } from 'lang/messages/common';
import { SimplePrintMessages } from 'lang/messages/simplePrint';
import { ES_PRICE, US_PRICE } from 'screens/LandingPage/constants';

import { displayImage } from './EverythingYouNeed.utils';
import RedirectButton from '../RedirectButton/RedirectButton';

import styles from './EverythingYouNeed.module.scss';

function EverythingYouNeed() {
    const { isSpain } = useTranslation();
    const { variation } = useVariation();

    const { DESCRIPTION, IMG_ALT, TITLE } = useFormat(SimplePrintMessages);
    const { GET_STARTED_FROM } = useFormat(
        CommonMessages,
        commonMessagesValues({ price: String(isSpain() ? ES_PRICE : US_PRICE) })
    );

    return (
        <section className={styles.container}>
            <div className={styles.left}>
                <img
                    className={styles.image}
                    src={displayImage(variation)}
                    alt={IMG_ALT}
                />
            </div>
            <div className={styles.right}>
                <div className={styles.textContainer}>
                    <h1 className={styles.subtitle}>
                        {DESCRIPTION.toUpperCase()}
                    </h1>
                    <h2 className={styles.title}>{TITLE}</h2>
                    <RedirectButton
                        action={TrackType.CTA}
                        from={TrackLocation.Subscription}
                        redirectTo={Paths.PrinterSelection}
                        className={styles.button}
                    >
                        {GET_STARTED_FROM}
                    </RedirectButton>
                </div>
            </div>
        </section>
    );
}
export default EverythingYouNeed;
