import { forwardRef } from 'react';
import { animated, SpringValues } from '@react-spring/web';
import errorIcon from 'assets/error.svg';
import classNames from 'classnames';
import { CommonNullableRef, Nullable } from 'types/globals';

import styles from './InputErrorMessage.module.scss';

interface Props {
    message?: Nullable<string>;
    style: SpringValues<{
        height: string;
        opacity: number;
        overflow: string;
    }>;
}

function InputErrorMessage(
    { message = undefined, style }: Props,
    ref: CommonNullableRef
) {
    return (
        <animated.div style={style} role="alert">
            <div className={styles.errorContainer}>
                <span className={styles.icon}>
                    <img src={errorIcon} alt="" />
                </span>
                <p className={classNames(styles.errorMessage)} ref={ref}>
                    {message}
                </p>
            </div>
        </animated.div>
    );
}

export default forwardRef(InputErrorMessage);
