import { buildVanityURL, ROUTES } from './constants';
import { Paths } from './paths';

export const checkForSunset = (
    isSunsetEnabled: boolean,
    currentPath: string
) => {
    const splittedPath = currentPath.split('/').filter(Boolean);
    const currentCountry = `${splittedPath[0]}`;
    const allowedPaths = [Paths.Terms, Paths.FAQ, `/upgrade`, Paths.NotFound];
    const isCurrentPathAllowed =
        allowedPaths.includes(
            `/${splittedPath[splittedPath.length - 1]}` as Paths
        ) ||
        allowedPaths.includes(
            `/${splittedPath[splittedPath.length - 2]}` as Paths
        ) ||
        allowedPaths.includes(`/${splittedPath[2]}` as Paths);

    if (
        isSunsetEnabled &&
        !isCurrentPathAllowed &&
        (currentCountry === 'us' || currentCountry === 'es')
    ) {
        return true;
    }

    return false;
};

export const fixPath = (currentPath: string, search?: string) => {
    const validWrongPaths = [
        'es',
        'en',
        'us',
        Paths.Index,
        'el-corte-ingles',
        'upgrade',
    ];

    const newURL = currentPath.split('/').filter(Boolean);
    const badURL = newURL.shift();
    const curatedSearch = search ?? '';

    const isIndex =
        newURL.length === 0 && currentPath === '/' ? '/' : newURL.join('');

    const goToIndex = validWrongPaths.includes(isIndex);

    const isValidURL =
        ROUTES.findIndex(({ path }) => {
            if (path.includes(':')) {
                return path.split('/:')[0] === `/${newURL[0]}`;
            }

            return path === `/${newURL.join('/')}`;
        }) >= 0;

    if (!isValidURL && !goToIndex) {
        return `/us/en${Paths.NotFound}`;
    }

    if (goToIndex) {
        return `/us/en/`;
    }

    switch (badURL) {
        case validWrongPaths[0]:
            return `/es/es/${newURL.join('/')}${curatedSearch}`;

        case validWrongPaths[1]:
        case validWrongPaths[2]:
        case validWrongPaths[3]:
            return `/us/en/${newURL.join('/')}${curatedSearch}`;

        case validWrongPaths[5]:
            return `/us/en/upgrade/${newURL.join('/')}${curatedSearch}`;

        case validWrongPaths[4]:
            return `/es/es/el-corte-ingles/${newURL.join('/')}${curatedSearch}`;

        default:
            return `/us/en${Paths.NotFound}`;
    }
};

export const VALID_VANITY_URLS = [
    {
        id: 0,
        vanity: 'goya',
        output: buildVanityURL(true, 'ECI', 'Att', 1),
    },
    {
        id: 1,
        vanity: 'pozuelo',
        output: buildVanityURL(true, 'ECI', 'Att', 2),
    },
    {
        id: 2,
        vanity: 'diagonal',
        output: buildVanityURL(false, 'ECI', 'Att', 3),
    },
    {
        id: 3,
        vanity: 'callao',
        output: buildVanityURL(false, 'ECI', 'Att', 4),
    },
    {
        id: 4,
        vanity: 'vigo',
        output: buildVanityURL(true, 'ECI', 'Unatt', 5),
    },
    {
        id: 5,
        vanity: 'independencia',
        output: buildVanityURL(true, 'ECI', 'Unatt', 6),
    },
    {
        id: 6,
        vanity: 'campo-de-las-naciones',
        output: buildVanityURL(false, 'ECI', 'Unatt', 7),
    },
    {
        id: 7,
        vanity: 'mendez-alvaro',
        output: buildVanityURL(false, 'ECI', 'Unatt', 8),
    },
    {
        id: 8,
        vanity: 'sanchinarro',
        output: buildVanityURL(false, 'ECI', 'Unatt', 15),
    },
];

export const languageCountryIterator = (
    callback: (country: string, language: string) => void
) => {
    return ['es', 'us'].map((country) =>
        ['es', 'en'].map((language) => callback(country, language))
    );
};
