import { useUserInfo } from 'contexts/UserInfo';
import { useFormat } from 'hooks';
import {
    CheckoutMessages,
    checkoutMessagesValues,
} from 'lang/messages/checkout';

import styles from './ShippingCard.module.scss';

function ShippingCard() {
    const { shipping } = useUserInfo();

    const { CARD_SHIPPING_SERIAL_NUMBER } = useFormat(
        CheckoutMessages,
        checkoutMessagesValues({
            serialNumber: shipping ? shipping.printerSerialNumber : ' ',
        })
    );

    if (!shipping) return null;

    return (
        <div className={styles.container}>
            <p className={styles.text} title="costumerName">
                {shipping.firstName} {shipping.lastName}
            </p>
            {shipping.company && (
                <p title="company" className={styles.text}>
                    {shipping.company}
                </p>
            )}
            <p className={styles.text} title="address1">
                {shipping.address1}
            </p>
            {shipping.address2 && (
                <p title="address2" className={styles.text}>
                    {shipping.address2}
                </p>
            )}
            <p className={styles.text} title="city">
                {shipping.city && `${shipping.city},`} {shipping.state}{' '}
                {shipping.zip}
            </p>
            <br />
            {shipping.printerSerialNumber && (
                <p className={styles.text} title="serialNumber">
                    {CARD_SHIPPING_SERIAL_NUMBER}
                </p>
            )}
        </div>
    );
}

export default ShippingCard;
