import { CookieVariation } from 'constants/cookiesEnums';

export const getRandomNumber: (min: number, max: number) => number = (
    min: number,
    max: number
) => {
    /* 
        0A - Control
        1B - Plus Sayan
        2C - Sayan replacing
        3D - LEBI
    */

    const randomBuffer = new Uint32Array(1);

    window.crypto.getRandomValues(randomBuffer);

    const randomNumber = randomBuffer[0] / (0xffffffff + 1);

    const ceilMin = Math.ceil(min);
    const floorMax = Math.floor(max);
    const result = Math.floor(randomNumber * (floorMax - ceilMin + 1)) + min;

    if (
        result === CookieVariation.SayanReplacing ||
        result === CookieVariation.Control
    ) {
        return getRandomNumber(min, max);
    }

    return result;
};
