import { CookieVariation } from 'constants/cookiesEnums';

export const getNamedVariation = (variation: CookieVariation) => {
    switch (variation) {
        case CookieVariation.PlusSayan:
            return 'Plus_sayan';

        case CookieVariation.SayanReplacing:
            return 'Sayan_replacing';

        case CookieVariation.LEBI:
            return 'LEBI';

        case CookieVariation.Control:
        default:
            return 'Control';
    }
};
