import { Button } from 'components';
import { ButtonVariants } from 'components/CustomButton/constants';
import { useFormat } from 'hooks';
import useCheckout from 'hooks/useCheckout';
import { CommonMessages, commonMessagesValues } from 'lang/messages/common';
import { UpgradesMessages } from 'lang/messages/upgrades';

import styles from './Footer.module.scss';

interface Props {
    isUpgrade?: boolean;
}

function Footer({ isUpgrade = false }: Props) {
    const { CONTINUE, BACK } = useFormat(
        CommonMessages,
        commonMessagesValues({})
    );
    const { CONTINUE_WITH_UPGRADE } = useFormat(UpgradesMessages);
    const { onGoBack, onReview, disableButton, submitting } = useCheckout();

    return (
        <footer className={styles.footer}>
            <Button
                variant={ButtonVariants.secondary}
                disabled={submitting}
                onClick={onGoBack}
                className={styles.button}
            >
                {BACK}
            </Button>
            {!disableButton && (
                <Button
                    disabled={disableButton || submitting}
                    onClick={onReview}
                    className={styles.button}
                >
                    {isUpgrade ? CONTINUE_WITH_UPGRADE : CONTINUE}
                </Button>
            )}
        </footer>
    );
}

export default Footer;
