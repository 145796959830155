import { LOCALES } from 'lang/locales';

export enum HeroVariationAMessages {
    TITLE = 'heroVariationA.title',
    SUBTITLE = 'heroVariationA.subtitle',
    ITEM_A = 'heroVariationA.itemA',
    ITEM_B = 'heroVariationA.itemB',
    ITEM_C = 'heroVariationA.itemC',
    ITEM_D = 'heroVariationA.itemD',
    FOOTER = 'heroVariationA.footer',

    HOW_IT_WORKS_TITLE = 'howItWorks.title',
    HOW_IT_WORKS_ITEM_A_TITLE = 'howItWorks.itemA.title',
    HOW_IT_WORKS_ITEM_A_BODY = 'howItWorks.itemA.body',
    HOW_IT_WORKS_ITEM_B_TITLE = 'howItWorks.itemB.title',
    HOW_IT_WORKS_ITEM_B_BODY = 'howItWorks.itemB.body',
    HOW_IT_WORKS_ITEM_C_TITLE = 'howItWorks.itemC.title',
    HOW_IT_WORKS_ITEM_C_BODY = 'howItWorks.itemC.body',
    HOW_IT_WORKS_ITEM_D_TITLE = 'howItWorks.itemD.title',
    HOW_IT_WORKS_ITEM_D_BODY = 'howItWorks.itemD.body',
}

export const heroVariationAMessages = {
    [LOCALES.ENGLISH]: {
        [HeroVariationAMessages.TITLE]:
            'La suscripción de impresión de\nHP con todo lo que necesitas',
        [HeroVariationAMessages.SUBTITLE]: 'Impresora. Tinta. Soporte.',
        [HeroVariationAMessages.ITEM_A]:
            'Incluye una impresora\nHP y renuévala cada\n3 años',
        [HeroVariationAMessages.ITEM_B]:
            'Recibe automáticamente\ntu tinta en casa solo\ncuando la necesites',
        [HeroVariationAMessages.ITEM_C]:
            'Una garantía que nunca\ncaduca mientras\nmantengas tu suscripción',
        [HeroVariationAMessages.ITEM_D]:
            'Atencion al cliente de\nlunes a domingo',
        [HeroVariationAMessages.FOOTER]:
            'Regístrate por solo 20€, este importe se deducirá automáticamente de las cuotas de tu suscripción mensual. Prueba sin compromiso\ndurante 30 dias. Cancela en cualquier momento sin ningún coste adicional.¹ El plan se renueva automáticamente cada mes.²',
        [HeroVariationAMessages.HOW_IT_WORKS_TITLE]: '¿Cómo funciona?',
        [HeroVariationAMessages.HOW_IT_WORKS_ITEM_A_TITLE]:
            'Elige tu\nnueva\nimpresora',
        [HeroVariationAMessages.HOW_IT_WORKS_ITEM_A_BODY]:
            'Puedes elegir entre varias\nde nuestras impresoras\nmás populares. Está incluida\nen tu cuota mensual.',
        [HeroVariationAMessages.HOW_IT_WORKS_ITEM_B_TITLE]:
            'Selecciona\ntu plan de\nimpresión',
        [HeroVariationAMessages.HOW_IT_WORKS_ITEM_B_BODY]:
            'Elige el plan de Instant Ink\nque mejor se adapte a tus\nnecesidades de impresión.\nPuedes cambiar de plan\ncuando quieras.',
        [HeroVariationAMessages.HOW_IT_WORKS_ITEM_D_TITLE]: 'Fácil\nregistro',
        [HeroVariationAMessages.HOW_IT_WORKS_ITEM_D_BODY]:
            'Introduce tu dirección de\nenvío y tu forma de pago. Se\nte cobrará una tarifa de\nactivación del servicio de\n20€ que se deducirá\nautomáticamente de las\ncuotas de tu suscripción\nmensual.',
        [HeroVariationAMessages.HOW_IT_WORKS_ITEM_C_TITLE]:
            'Cancela en\ncualquier\nmomento',
        [HeroVariationAMessages.HOW_IT_WORKS_ITEM_C_BODY]:
            'Si no estás satisfecho con\ntu suscripción puedes\ndevolver tu impresora sin\nningún coste adicional.\nSi cancelas dentro de los\nprimeros 30 días de prueba\nte devolveremos los 20€ de\nactivación del servicio.',
    },

    [LOCALES.SPANISH]: {
        [HeroVariationAMessages.TITLE]:
            'La suscripción de impresión de\nHP con todo lo que necesitas',
        [HeroVariationAMessages.SUBTITLE]: 'Impresora. Tinta. Soporte.',
        [HeroVariationAMessages.ITEM_A]:
            'Incluye una impresora\nHP y renuévala cada\n3 años',
        [HeroVariationAMessages.ITEM_B]:
            'Recibe automáticamente\ntu tinta en casa solo\ncuando la necesites',
        [HeroVariationAMessages.ITEM_C]:
            'Una garantía que nunca\ncaduca mientras\nmantengas tu suscripción',
        [HeroVariationAMessages.ITEM_D]:
            'Atencion al cliente de\nlunes a domingo',
        [HeroVariationAMessages.FOOTER]:
            'Regístrate por solo 20€, este importe se deducirá automáticamente de las cuotas de tu suscripción mensual. Prueba sin compromiso\ndurante 30 dias. Cancela en cualquier momento sin ningún coste adicional.¹ El plan se renueva automáticamente cada mes.²',
        [HeroVariationAMessages.HOW_IT_WORKS_TITLE]: '¿Cómo funciona?',
        [HeroVariationAMessages.HOW_IT_WORKS_ITEM_A_TITLE]:
            'Elige tu\nnueva\nimpresora',
        [HeroVariationAMessages.HOW_IT_WORKS_ITEM_A_BODY]:
            'Puedes elegir entre varias\nde nuestras impresoras\nmás populares. Está incluida\nen tu cuota mensual.',
        [HeroVariationAMessages.HOW_IT_WORKS_ITEM_B_TITLE]:
            'Selecciona\ntu plan de\nimpresión',
        [HeroVariationAMessages.HOW_IT_WORKS_ITEM_B_BODY]:
            'Elige el plan de Instant Ink\nque mejor se adapte a tus\nnecesidades de impresión.\nPuedes cambiar de plan\ncuando quieras.',
        [HeroVariationAMessages.HOW_IT_WORKS_ITEM_D_TITLE]: 'Fácil\nregistro',
        [HeroVariationAMessages.HOW_IT_WORKS_ITEM_D_BODY]:
            'Introduce tu dirección de\nenvío y tu forma de pago. Se\nte cobrará una tarifa de\nactivación del servicio de\n20€ que se deducirá\nautomáticamente de las\ncuotas de tu suscripción\nmensual.',
        [HeroVariationAMessages.HOW_IT_WORKS_ITEM_C_TITLE]:
            'Cancela en\ncualquier\nmomento',
        [HeroVariationAMessages.HOW_IT_WORKS_ITEM_C_BODY]:
            'Si no estás satisfecho con\ntu suscripción puedes\ndevolver tu impresora sin\nningún coste adicional.\nSi cancelas dentro de los\nprimeros 30 días de prueba\nte devolveremos los 20€ de\nactivación del servicio.',
    },
};
