import { useEffect } from 'react';
import { useParams } from 'react-router-dom-v5-compat';
import check from 'assets/green_check_outlined.svg';
import { GiftCard, NavigationBar } from 'components';
import { TrackLocation } from 'constants/analytics';
import { useDataProvider } from 'contexts/DataProvider';
import { useUserInfo } from 'contexts/UserInfo';
import { useFormat, useTrackPage } from 'hooks';
import { useOrder } from 'hooks/useOrder';
import useRetailCheck from 'hooks/useRetailCheck';
import {
    PurchaseCompleteMessages,
    purchaseCompleteValues,
} from 'lang/messages/purchaseComplete';
import { Params } from 'screens/Checkout/constants';

import SerialNumberDisplay from './components/SerialNumberDisplay/SerialNumberDisplay';
import { RegisterMessageBox } from './components';
import { descriptionPrinter, getProductImages } from './utils';

import styles from './PurchaseComplete.module.scss';

// This page is easily accessible by adding /complete to any checkout URL

function PurchaseComplete() {
    const { userEmail } = useOrder();

    const text = useFormat(
        PurchaseCompleteMessages,
        purchaseCompleteValues({ userEmail })
    );
    const params = useParams<Params & Record<string, string | undefined>>();
    const { getCurrentInkPlanAndPlanId } = useDataProvider();
    const { shipping } = useUserInfo();
    const { isRetail } = useRetailCheck();
    const trackPage = useTrackPage();

    const { plan } = getCurrentInkPlanAndPlanId(params);
    const { backImage, printerImage } = getProductImages(plan?.productName);

    const description = descriptionPrinter({
        email: userEmail,
        isRetail,
        lang: text,
    });

    useEffect(() => {
        trackPage(TrackLocation.PurchaseComplete);
    }, [trackPage]);

    return (
        <main className={styles.mainContainer} style={backImage}>
            <NavigationBar float variant="black" />
            <img
                src={printerImage}
                alt={text.IMG}
                className={styles.printerImage}
            />

            <div className={styles.rightContainer}>
                <div>
                    <img src={check} className={styles.check} alt="" />
                    <h1 className={styles.title}>{text.TITLE}</h1>
                    <p className={styles.body} title="body">
                        {description}
                    </p>

                    {isRetail ? (
                        <SerialNumberDisplay
                            printerName={plan?.name}
                            serialNumber={shipping?.printerSerialNumber}
                        />
                    ) : (
                        <GiftCard />
                    )}
                </div>

                <RegisterMessageBox />
            </div>
        </main>
    );
}

export default PurchaseComplete;
