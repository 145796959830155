import { useState } from 'react';
import {
    CardNumberElement,
    useElements,
    useStripe,
} from '@stripe/react-stripe-js';
import { ChangeEvtHandler } from 'components/StripeInput/StripeInput';
import { useFormat } from 'hooks';
import {
    CheckoutMessages,
    checkoutMessagesValues,
} from 'lang/messages/checkout';

import { InputFields } from '../../constants';

export const useStripeForm = () => {
    const stripe = useStripe();
    const elements = useElements();

    const billingText = useFormat(CheckoutMessages, checkoutMessagesValues({}));

    const [stripeErrors, setStripeErrors] = useState<
        Record<string, string | null>
    >({});

    const [completedStripeForm, setCompletedStripeForm] = useState({
        cardNumber: false,
        cardCvc: false,
        cardExpiry: false,
    });

    const onStripeChange: ChangeEvtHandler = ({
        elementType,
        error,
        complete,
    }) => {
        setCompletedStripeForm((prevState) => ({
            ...prevState,
            [elementType]: complete,
        }));

        setStripeErrors((prevState) => ({
            ...prevState,
            [elementType]: error?.message || null,
        }));
    };

    const reportStripeError = (error?: string) => {
        setStripeErrors((prevState) => ({
            ...prevState,
            cardNumber: error || billingText.CARD_BILLING_FORM_GENERAL_ERROR,
        }));
    };

    async function submitStripeData(data: InputFields) {
        if (!elements || !stripe) {
            reportStripeError('Stripe was not activated');
            return null;
        }

        const cardElement = elements?.getElement(CardNumberElement);

        if (!cardElement) {
            reportStripeError('Stripe did not report any Card element');
            return null;
        }

        const { token, error } = await stripe.createToken(cardElement, {
            ...data,
            address_country: 'US',
        });

        if (error) {
            if (error.type === 'validation_error') {
                reportStripeError();
                return null;
            }

            reportStripeError(error.message);
            return null;
        }

        return token;
    }

    const isStripeInfoValid =
        !stripeErrors.cardNumber &&
        !stripeErrors.cardCvc &&
        !stripeErrors.cardExpiry &&
        completedStripeForm.cardNumber &&
        completedStripeForm.cardCvc &&
        completedStripeForm.cardExpiry;

    return {
        isStripeInfoValid,
        stripe,
        elements,
        stripeErrors,
        onStripeChange,
        submitStripeData,
    };
};
