import { API } from 'constants/api';
import { Printer } from 'contexts/PrinterChoice/constants';
import { UpgradeQueryParams } from 'services/pilotIdInfo';
import { Nullable } from 'types/globals';

import {
    EMPTY_BILLING_INFO,
    OrderZones,
    PostOrder,
    PostOrderBody,
} from './constants';

export const buildOrderBody: (
    data: PostOrder,
    utmParams: Partial<Record<string, Nullable<string>>>
) => PostOrderBody = (data, utmParams) => {
    return {
        zone: data.zone || OrderZones.US,
        customerId: String(data.dataContext?.planData?.existingCustomer?.id),
        utmParams: {
            utm_campaign: String(utmParams.utmCampaign),
            utm_source: String(utmParams.utmSource),
            utm_medium: String(utmParams.utmMedium),
            utm_content: String(utmParams.utmContent),
        },
        firstName: data.shippingInfo?.firstName,
        lastName: data.shippingInfo?.lastName,
        companyName: data.shippingInfo?.company,
        email: data.shippingInfo?.email,
        mobile: String(data.shippingInfo?.phone),
        shippingAddress: {
            street: data.shippingInfo?.address1,
            street2: data.shippingInfo?.address2 || '',
            city: data.shippingInfo?.city,
            state: data.shippingInfo?.state,
            zip: data.shippingInfo?.zip,
        },
        billingAddress: {
            street: data.billingInfo?.street,
            street2: data.billingInfo?.street2 || '',
            city: data.billingInfo?.city,
            state: data.billingInfo?.state,
            zip: data.billingInfo?.zip,
        },
        printer: data.planId || '',
        printerSerialNumber: data.shippingInfo.printerSerialNumber,
        stripeToken: data.billingInfo?.token?.id,
        inkPlan: data.inkPlanId || '',
        paper: data.paper,
        ccNameOnCard:
            data.billingInfo?.token?.card?.name ||
            data.billingInfo?.oobeCardInfo?.name ||
            data.billingInfo.ccNameOnCard,
    };
};

export const buildUpgradeOrderBody = (
    upgradeInfo: Partial<UpgradeQueryParams>,
    data: Omit<PostOrder, 'billingInfo'>,
    utmParams: Partial<Record<string, Nullable<string>>>
) => {
    return {
        account_identifier: upgradeInfo.accountIdentifier,
        pilot_id: String(upgradeInfo.pilotId),
        has_paper: upgradeInfo.hasPaper,
        plan_page: String(upgradeInfo.planPages),
        order: buildOrderBody(
            {
                ...data,
                ...EMPTY_BILLING_INFO,
            },
            utmParams
        ),
    };
};

export const checkCurrentLanguage = (path: string) => {
    const validCountryAndLanguage = {
        country: ['us', 'es'],
        language: ['en', 'es'],
    };
    const splittedURL = path.split('/').filter(Boolean);

    const currentCountry = validCountryAndLanguage.country.includes(
        splittedURL[0]
    )
        ? splittedURL[0]
        : 'us';
    const currentLanguage = validCountryAndLanguage.language.includes(
        splittedURL[1]
    )
        ? splittedURL[1]
        : 'en';

    return { currentCountry, currentLanguage };
};

export const getPrinterData = (printer: Printer) => {
    return {
        name: printer.productName,
        id: printer.productModel,
        category: printer.category,
        quantity: 1,
        price: printer.basePrice,
    };
};

export const getRoute = (
    isUpgrade: boolean,
    currentLang: Nullable<'en' | 'es'>,
    isRetail: boolean
) => {
    if (isUpgrade) {
        return `${API.UPGRADE_ORDER}?zone=${
            (currentLang === 'en' && OrderZones.US) ||
            currentLang?.toUpperCase()
        }${isRetail ? '&retail=true' : ''}`;
    }
    return `${API.ORDER}?zone=${
        (currentLang === 'en' && OrderZones.US) || currentLang?.toUpperCase()
    }${isRetail ? '&retail=true' : ''}`;
};
