import {
    TermsOfServicesMessages,
    TermsOfServicesMessagesB,
    TermsOfServicesMessagesC,
    TermsOfServicesMessagesD,
} from 'lang/messages/terms';

export const SECTIONS = (
    type:
        | typeof TermsOfServicesMessages
        | typeof TermsOfServicesMessagesB
        | typeof TermsOfServicesMessagesC
        | typeof TermsOfServicesMessagesD = TermsOfServicesMessages
) => [
    {
        id: 1,
        header: type.DATA_COLLECTION_HEADER,
        content: type.DATA_COLLECTION_CONTENT,
    },
    {
        id: 2,
        header: type.AGREE_TO_CONTRACT_ELECTRONIC_HEADER,
        content: type.AGREE_TO_CONTRACT_ELECTRONIC_CONTENT,
    },
    {
        id: 3,
        header: type.CHANGES_HEADER,
        content: type.CHANGES_CONTENT,
        activationContent: type.CHANGES_CONTENT_ACTIVATION,
    },
    {
        id: 4,
        header: type.OBLIGATIONS_HEADER,
        content: type.OBLIGATIONS_CONTENT,
    },
    {
        id: 5,
        header: type.SERVICE_REQUIREMENTS_HEADER,
        content: type.SERVICE_REQUIREMENTS_CONTENT,
    },
    {
        id: 6,
        header: type.CHOOSING_PLANS_HEADER,
        content: type.CHOOSING_PLANS_CONTENT,
    },
    {
        id: 7,
        header: type.USING_PRINTER_HEADER,
        content: type.USING_PRINTER_CONTENT,
        activationContent: type.USING_PRINTER_CONTENT_ACTIVATION,
    },
    {
        id: 71,
        header: type.PRINTER_RETURN_HEADER,
        content: type.PRINTER_RETURN_CONTENT,
    },
    {
        id: 8,
        header: type.SUPPORT_SERVICES_HEADER,
        content: type.SUPPORT_SERVICES_CONTENT,
    },
    {
        id: 9,
        header: type.CARBON_NEUTRAL_HEADER,
        content: type.CARBON_NEUTRAL_CONTENT,
    },
    {
        id: 10,
        header: type.PAYMENT_HEADER,
        content: type.PAYMENT_CONTENT,
        activationContent: type.PAYMENT_CONTENT_ACTIVATION,
    },
    {
        id: 11,
        header: type.MANAGING_HEADER,
        content: type.MANAGING_CONTENT,
    },
    {
        id: 12,
        header: type.CANCELLATION_HEADER,
        content: type.CANCELLATION_CONTENT,
        activationContent: type.CANCELLATION_CONTENT_ACTIVATION,
    },
    {
        id: 13,
        header: type.PROMOTIONS_HEADER,
        content: type.PROMOTIONS_CONTENT,
    },
    {
        id: 14,
        header: type.GENERAL_HEADER,
        content: type.GENERAL_CONTENT,
    },
];

export interface Terms {
    title: string;
    subtitle: string;
    topInfo: string;
    index: string;
}

export type Prefix = 'activationterms' | 'terms';
