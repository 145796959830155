import { commonValues } from 'constants/formatValues';
import { useFormat, useQuery } from 'hooks';
import { CommonMessages } from 'lang/messages/common';
import { OrderYourPlanMessages } from 'lang/messages/orderYourPlan';
import { UpgradePrinterSelectionMessages } from 'lang/messages/upgradePrinterSelection';
import { UpgradeQueryParams } from 'services/pilotIdInfo';

import usePrinterSelection from '../../hook';
import CheckoutBanner from '../CheckoutBanner';

import styles from './styles.module.scss';

function PrinterFooter() {
    const { printer, inkPlan, handleRedirect } = usePrinterSelection();

    const { hasPaper } = useQuery<UpgradeQueryParams>();
    const { NO, YES } = useFormat(CommonMessages, commonValues());

    const {
        SUMMARY_CHOOSE_AN_UPGRADE,
        SUMMARY_FOOTNOTE,
        SUMMARY_YOUR_UPGRADE,
    } = useFormat(UpgradePrinterSelectionMessages);

    const { PLAN, PAPER_SUBSCRIPTION } = useFormat(OrderYourPlanMessages, {
        [OrderYourPlanMessages.PLAN]: {
            pages: inkPlan ? inkPlan.pages : 0,
        },
        [OrderYourPlanMessages.PAPER_SUBSCRIPTION]: {
            paper: hasPaper ? YES : NO,
        },
    });

    return (
        <footer className={styles.footer}>
            <div className={styles.summary}>
                <h2>{SUMMARY_YOUR_UPGRADE}</h2>
                <div className={styles.summaryDetails}>
                    <p title="printerProductName">
                        {printer?.productName
                            ? `${printer?.productName}: ${printer.name}`
                            : SUMMARY_CHOOSE_AN_UPGRADE}
                    </p>
                    <p title="plan-selected">{PLAN}</p>
                    <p title="paper-subscription">{PAPER_SUBSCRIPTION}</p>
                </div>

                <p className={styles.footnote}>{SUMMARY_FOOTNOTE}</p>
            </div>

            <CheckoutBanner
                data={printer}
                redirect={handleRedirect}
                disableButton={!inkPlan || hasPaper === undefined}
            />
        </footer>
    );
}
export default PrinterFooter;
