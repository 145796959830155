import { CommonMessages } from 'lang/messages/common';
import { HeroMessages } from 'lang/messages/heroScreen';
import { price } from 'screens/LandingPage/components/SpainHero/constants';
import { ES_PRICE, US_PRICE } from 'screens/LandingPage/constants';

export const commonValues = (isSpain?: boolean) => ({
    [CommonMessages.GET_STARTED_FROM]: {
        price: isSpain ? ES_PRICE : US_PRICE,
    },
});

export const heroValues = {
    [HeroMessages.DISCLAIMER_ACTIVATION]: { price },
    [HeroMessages.DISCLAIMER_RISK_FREE]: { price },
};
