import { LOCALES } from 'lang/locales';

export enum UpgradeLandingMessages {
    COMING_SOON_TITLE = 'upgrade.landing.comingSoon.title',
    COMING_SOON_SUBTITLE = 'upgrade.landing.comingSoon.subtitle',
    COMING_SOON_HELP = 'upgrade.landing.comingSoon.help',

    SLIDE_ONE_TITLE = 'upgrade.landing.slideOne.title',
    SLIDE_ONE_BODY = 'upgrade.landing.slideOne.body',

    SLIDE_TWO_TITLE = 'upgrade.landing.slideTwo.title',
    SLIDE_TWO_BODY_PRICE_DROP = 'upgrade.landing.slideTwo.body.priceDrop',
    SLIDE_TWO_BODY_NO_PRICE_DROP = 'upgrade.landing.slideTwo.body.noPriceDrop',
    SLIDE_TWO_STAMP = 'upgrade.landing.slideTwo.stamp',

    ALREADY_REDEEMED_TITLE = 'upgrade.landing.alreadyRedeemed.title',
    ALREADY_REDEEMED_BODY = 'upgrade.landing.alreadyRedeemed.body',
    ALREADY_REDEEMED_SUPPORT = 'upgrade.landing.alreadyRedeemed.support',

    NOT_ELIGIBLE_TITLE = 'upgrade.landing.notEligible.title',
}

export const upgradeLandingMessages = {
    [LOCALES.ENGLISH]: {
        [UpgradeLandingMessages.SLIDE_ONE_TITLE]: 'Printer Upgrade',
        [UpgradeLandingMessages.SLIDE_ONE_BODY]:
            "Thank you for being a loyal subscriber. One of the benefits of HP Instant Ink\nPlatinum is that you are eligible to receive a new subscription printer any time after\ntwo years. You can also continue using your current subscription printer for as long\nas you like and take your upgrade at some future date. It's your choice.",

        [UpgradeLandingMessages.SLIDE_TWO_TITLE]:
            'Not ready to replace\nyour printer yet?',
        [UpgradeLandingMessages.SLIDE_TWO_BODY_PRICE_DROP]: `<p>No problem. If you want to continue with your existing printer, no\naction is needed today. Your current printer will continue to work\nwith your subscription. Just return here when you're ready.</p><p>Since passing your two-year anniversary, <b>you've been enjoying\na reduced monthly fee of {price}</b> for your all-inclusive print\nsubscription. You'll continue to enjoy a reduced fee until you\nupgrade your printer. When you're ready to upgrade, your\nmonthly fee will reset to the standard fee, which may vary\ndepending on the printer model you select.</p><p>If you'd like to adjust your ink or paper plan, simply visit your\n<a>www.hpinstantink.com|account dashboard</a>.</p>`,
        [UpgradeLandingMessages.SLIDE_TWO_BODY_NO_PRICE_DROP]: `<p>No problem. If you want to continue with your\nexisting printer, no action is needed today. Your\ncurrent printer will continue to work with your\nsubscription. Just return here when you're ready\nto upgrade.</p><p>If you'd like to adjust your ink or paper plan,\nsimply visit your <a>www.hpinstantink.com|account dashboard</a>.</p>`,
        [UpgradeLandingMessages.SLIDE_TWO_STAMP]:
            'Thanks for\nhelping us\nreduce waste.',
        [UpgradeLandingMessages.COMING_SOON_TITLE]: 'Coming Soon',
        [UpgradeLandingMessages.COMING_SOON_SUBTITLE]:
            'Our printer upgrade program is almost ready. Please check back soon.',
        [UpgradeLandingMessages.COMING_SOON_HELP]:
            'If you need help,\ncontact our support team at 1-888-447-0148.',

        [UpgradeLandingMessages.ALREADY_REDEEMED_TITLE]:
            'Our records show that you\nhave already redeemed your\nprinter upgrade.',
        [UpgradeLandingMessages.ALREADY_REDEEMED_BODY]:
            "You're eligible for a new printer any time two\nyears after enrolling or upgrading your printer.",
        [UpgradeLandingMessages.ALREADY_REDEEMED_SUPPORT]:
            'If you believe this is incorrect, please call\n<b>customer support, 888-447-0148</b>.',
        [UpgradeLandingMessages.NOT_ELIGIBLE_TITLE]:
            "We're sorry. You are not eligible for\na printer upgrade.",
    },

    [LOCALES.SPANISH]: {
        [UpgradeLandingMessages.SLIDE_ONE_TITLE]: 'Mejora tu impresora',
        [UpgradeLandingMessages.SLIDE_ONE_BODY]:
            'Gracias por ser un suscriptor leal durante más de 2 años. Ahora tienes la opción de\nactualizar tu impresora en cualquier momento o mantener la impresora que te gusta.\nEs tu elección.',

        [UpgradeLandingMessages.SLIDE_TWO_TITLE]:
            '¿Quieres mantener tu\nimpresora actual?',
        [UpgradeLandingMessages.SLIDE_TWO_BODY_PRICE_DROP]: `<p>No te preocupes, no es necesario que hagas nada en este momento.\nSi cambias de opinión, puedes volver aquí y\nseguir los pasos a continuación.\n\n<b>Tu precio mensual ha bajado a {price} hasta que\nactualices,</b> momento en el cual se reiniciará tu\nsuscripción y se te facturará mensualmente por el precio completo\nde la nueva impresora/plan que elijas.</p>`,
        [UpgradeLandingMessages.SLIDE_TWO_BODY_NO_PRICE_DROP]: `<p>No hay problema. Si quieres continuar con tu\nimpresora existente, no se necesita ninguna acción hoy. Tu\nimpresora actual seguirá funcionando con tu\nsuscripción. Simplemente vuelve aquí cuando estés listo\npara mejorar.\n\nSi te gustaría ajustar tu plan de tinta o papel,\nsimplemente visita el <a>www.hpinstantink.com|panel de tu cuenta</a></p>`,
        [UpgradeLandingMessages.SLIDE_TWO_STAMP]:
            'Gracias por\nayudarnos a\nreducir el desperdicio.',

        [UpgradeLandingMessages.COMING_SOON_TITLE]: 'Coming Soon',
        [UpgradeLandingMessages.COMING_SOON_SUBTITLE]:
            'Our printer upgrade program is almost ready. Please check back soon.',
        [UpgradeLandingMessages.COMING_SOON_HELP]:
            'If you need help,\ncontact our support team at 1-888-447-0148.',
        [UpgradeLandingMessages.ALREADY_REDEEMED_TITLE]:
            "We're sorry. Our records\nshow that you have already\nbeen sent a printer upgrade.",
        [UpgradeLandingMessages.ALREADY_REDEEMED_BODY]:
            "You're eligible for a new printer any time two\nyears after enrolling or upgrading your printer.",
        [UpgradeLandingMessages.ALREADY_REDEEMED_SUPPORT]:
            'If you believe this is incorrect, please call\n<b>customer support, 888-447-0148</b>.',
        [UpgradeLandingMessages.NOT_ELIGIBLE_TITLE]:
            "We're sorry. You are not eligible for\na printer upgrade.",
    },
};
