import { useEffect } from 'react';
import { TrackLocation } from 'constants/analytics';
import { useTrackPage } from 'hooks';

import {
    Disclaimers,
    EverythingYouNeed,
    FAQs,
    Footer,
    PrintResponsibly,
    SimplestWayToPrint,
    SpainHero,
    Steps,
    TheresAnOption,
    UpToDate,
} from './components';

import styles from './LandingPage.module.scss';

function LandingPage() {
    const trackPage = useTrackPage();

    useEffect(() => {
        trackPage(TrackLocation.Home);
    }, [trackPage]);

    return (
        <div className={styles.container}>
            <SpainHero />
            <EverythingYouNeed />
            <TheresAnOption />
            <SimplestWayToPrint />
            <UpToDate />
            <Steps />
            <PrintResponsibly />
            <FAQs />
            <Disclaimers />
            <Footer />
        </div>
    );
}

export default LandingPage;
