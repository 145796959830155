import { useEffect } from 'react';
import { useLocation } from 'react-router-dom-v5-compat';
import { NavigationBar } from 'components';
import { TrackLocation } from 'constants/analytics';
import { useDataProvider } from 'contexts/DataProvider';
import { useFormat, useTrackPage } from 'hooks';
import { RenewalNoticeMessages } from 'lang/messages/renewalNotice';

import { DESCRIPTIONS } from './constants';

import styles from './RenewalNotice.module.scss';

function RenewalNotice() {
    const trackPage = useTrackPage();
    const text = useFormat(RenewalNoticeMessages);
    const { pathname } = useLocation();
    const { showActivation } = useDataProvider();

    const offerTerms = pathname?.startsWith('/offerrenewalnotice');
    const isActivation = showActivation || offerTerms;

    useEffect(() => {
        trackPage(TrackLocation.RenewalNotice);
    }, [trackPage]);

    return (
        <div className={styles.container}>
            <NavigationBar variant="black" />

            <div className={styles.content}>
                <div className={styles.title}>{text.TITLE}</div>

                <div>
                    <ol className={styles.indexLinks}>
                        {DESCRIPTIONS(isActivation, text).map((item) => (
                            <li key={item.id}>{item.text}</li>
                        ))}
                    </ol>
                </div>
            </div>
        </div>
    );
}

export default RenewalNotice;
