import instantInkLogo from 'assets/instantink_logo.svg';
import { LoadingScreen } from 'components';
import { useFormat } from 'hooks';
import useUpgradeFetch from 'hooks/useUpgradeFetch';
import { UpgradePrinterSelectionMessages } from 'lang/messages/upgradePrinterSelection';
import ComparisonTable from 'screens/PrinterSelection/components/ComparisonTable/ComparisonTable';

import printerDisplay from './assets/printer-display.png';
import PrinterButtonGroup from './components/PrinterButtonGroup';
import PrinterFooter from './components/PrinterFooter';
import usePrinterSelection from './hook';

import styles from './styles.module.scss';

function UpgradePrinterSelection() {
    const {
        printer,
        data,
        comparisonModal,
        toggleComparisonModal,
        handleChangePreferences,
        handleGoBack,
    } = usePrinterSelection();

    const { isLoading } = useUpgradeFetch();

    const { BODY_TEXT, TITLE } = useFormat(UpgradePrinterSelectionMessages);

    return isLoading ? (
        <LoadingScreen />
    ) : (
        <>
            <ComparisonTable
                activePrinter={printer?.id || ''}
                printers={data}
                show={comparisonModal}
                onClose={toggleComparisonModal}
            />
            <div className={styles.container}>
                <button
                    onClick={handleGoBack}
                    type="button"
                    aria-label="HP InstantInk Logo"
                    className={styles.navButton}
                >
                    <img src={instantInkLogo} alt="" />
                </button>
                <header className={styles.textContainer}>
                    <h1>{TITLE}</h1>
                    {BODY_TEXT}
                </header>

                <main className={styles.printerContainer}>
                    <img
                        src={printer?.image || printerDisplay}
                        alt="Select your printer"
                        className={styles.printersImage}
                    />

                    <PrinterButtonGroup
                        value={printer}
                        dispatch={handleChangePreferences}
                        data={data}
                        toggleComparisonModal={toggleComparisonModal}
                    />
                </main>

                <PrinterFooter />
            </div>
        </>
    );
}
export default UpgradePrinterSelection;
