import { ToastNotificationType } from '@veneer/core';

export const LOCALSTORAGE_PRINTER = 'printerChoice';
export const CONTEXT_ERROR = 'You are using the context out of context.';

export const ERROR_TOAST: ToastNotificationType = {
    id: Math.random(),
    type: 'negative',
    text: 'There was an error fetching data',
    subtitle: 'Please try again later',
};

export enum AvailablePrinters {
    LEBI = 'printer6',
    Essential = 'printer5',
    Versatile = 'printer4',
    Professional = 'printer3',
    HighVolume = 'printer2',
    NoPrinter = 'no',
}

export enum InkPlans {
    Frequent = 'inkPlan4',
    Light = 'inkPlan1',
    Moderate = 'inkPlan3',
    Occasional = 'inkPlan2',
    Unlimited = 'inkPlan8',
}

export const EMPTY_STATE: PrinterSelectionResponse = {
    stripeApiKey: '',
    gaApiKey: '',
    excludedZipPrefixes: [''],
    oobeFlag: 'false',
    existingCustomer: undefined,
    newInkPlans: [
        {
            id: InkPlans.Frequent,
            pages: '',
            cost: 0,
            title: '',
            basePrices: [],
        },
    ],
    existingInkPlans: [
        {
            id: '',
            pages: '',
            cost: 0,
        },
    ],
    newForExistingInkPlans: [
        {
            id: '',
            pages: '',
            cost: 0,
        },
    ],
    printers: [
        {
            basePrice: 0,
            category: '',
            dataSheetUrl: '',
            description: '',
            disallowedInkPlans: [''],
            id: AvailablePrinters.Essential,
            image: '',
            name: '',
            numReviews: 0,
            popularInkPlan: '',
            preSelectedInkPlan: 0,
            pricePeriod: '',
            productModel: '',
            productName: '',
            shortDescription: '',
            specifications: [
                {
                    label: '',
                    data: '',
                },
            ],
        },
    ],
};

export interface NewInkPlan {
    cost: number;
    id: string;
    pages: string;
    basePrices: BasePrice[];
    title: string;
}

export interface BasePrice {
    printerID: string;
    basePrice: number;
}

export interface Printer {
    basePrice: number;
    category: string;
    dataSheetUrl: string;
    description: string;
    mostPopular?: boolean;
    disallowedInkPlans?: string[];
    id: AvailablePrinters;
    image: string;
    name: string;
    numReviews?: number;
    popularInkPlan: string;
    preSelectedInkPlan: number;
    pricePeriod: string;
    productModel: string;
    productName: string;
    specifications: Specification[];
    shortDescription: string;
    upgradeDescription?: string;
    monthlyFee?: number;
    disabled?: boolean;
}

export interface UserChoice {
    inkPlan?: NewInkPlan;
    isInkPlanSelected?: boolean;
    isPrinterSelected?: boolean;
    disabledPrinterIds?: string[];
    paper?: boolean;
    printer?: Printer;
}

export interface Specification {
    data: string;
    label: string;
}

export const USER_CHOICE_EMPTY: UserChoice = {
    inkPlan: undefined,
    isInkPlanSelected: false,
    isPrinterSelected: false,
    disabledPrinterIds: [],
    paper: false,
    printer: undefined,
};

export interface PrinterSelectionResponse {
    existingCustomer?: { id: string };
    comfeBase?: string;
    tokenBase?: string;
    comfeStack?: string;
    excludedZipPrefixes: string[];
    existingInkPlans: ExistingInkPlan[];
    newForExistingInkPlans: ExistingInkPlan[];
    newInkPlans: NewInkPlan[];
    printers: Printer[];
    oobeFlag: string;
    stripeApiKey: string;
    gaApiKey: string;
}

export interface ExistingInkPlan {
    cost: number;
    id: string;
    pages: string;
}
