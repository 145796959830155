import { Printer } from 'contexts/PrinterChoice/constants';
import MostPopularBadge from 'screens/PrinterSelection/components/MostPopularBadge/MostPopularBadge';

import { imageLookUp } from './utils';

import styles from './styles.module.scss';

interface Props {
    data: Printer;
    checked?: Printer;
    onChange: (type: 'printer', choice: Printer) => void;
}

function PrinterButton({ data, checked = undefined, onChange }: Props) {
    return (
        <label htmlFor={data.name}>
            <input
                type="radio"
                name="printer"
                id={data.name}
                checked={checked ? data.id === checked.id : false}
                className={styles.radioInput}
                onChange={() => onChange('printer', data)}
            />

            <div className={styles.button}>
                <img
                    src={imageLookUp(data.productName || '')}
                    alt={data.name}
                />
                <div className={styles.printerDescription}>
                    <div className={styles.titleContainer}>
                        <p className={styles.title}>{data.productName}</p>
                        <p className={styles.model}>{data.name}</p>
                    </div>
                    <div className={styles.body}>
                        {data.upgradeDescription}{' '}
                        <span title="monthlyFee">
                            Total monthly fee ${data.monthlyFee || 0}/mo
                        </span>{' '}
                        {data.mostPopular && <MostPopularBadge />}
                    </div>
                </div>
            </div>
        </label>
    );
}

export default PrinterButton;
