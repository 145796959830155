import { ReactNode } from 'react';
import greenCheck from 'assets/green_check_filled.svg';
import classNames from 'classnames';
import { Button, LoadingScreen } from 'components';
import { Nullable } from 'types/globals';

import styles from './Card.module.scss';

interface Props {
    icon: ReactNode;
    text: {
        title: string;
        subtitle: string;
        edit: string;
        button: string;
    };
    textAlignment?: 'left' | 'center';
    children: Nullable<ReactNode>;
    isChecked: boolean;
    hideButton?: boolean;
    hideLink?: boolean;
    disableButton?: boolean;
    isLoading?: boolean;
    buttonOnClick?: () => void;
    linkOnClick?: () => void;
}

function Card({
    icon,
    text,
    isChecked,
    children,
    textAlignment = 'center',
    disableButton = false,
    hideButton = false,
    hideLink = false,
    isLoading = false,
    buttonOnClick = undefined,
    linkOnClick = undefined,
}: Props) {
    const mainButton = hideButton ? null : (
        <Button
            disabled={disableButton}
            onClick={buttonOnClick}
            className={styles.button}
        >
            {text.button}
        </Button>
    );
    const content = children ? (
        <>
            <div className={styles.contentBox}>{children}</div>
            {!hideLink ? (
                <button
                    type="button"
                    className={styles.link}
                    disabled={isLoading}
                    onClick={linkOnClick}
                >
                    {text.edit}
                </button>
            ) : (
                <p />
            )}
        </>
    ) : (
        mainButton
    );

    return (
        <div
            className={
                children ? styles.container : styles.containerWithoutContent
            }
        >
            <header className={styles.header}>
                {icon}
                <p> {text.title}</p>
                {isChecked && (
                    <img src={greenCheck} title="green-tick" alt="" />
                )}
            </header>
            <main
                className={classNames(styles.mainContainer, {
                    [styles.textLeft]: textAlignment === 'left',
                    [styles.textCenter]: textAlignment === 'center',
                })}
            >
                <p className={styles.text}>{text.subtitle}</p>
                {isLoading ? (
                    <div className={styles.smallSpinner}>
                        <LoadingScreen useContainer={false} />
                    </div>
                ) : (
                    content
                )}
            </main>
        </div>
    );
}

export default Card;
