import { Button } from 'components';
import { commonValues } from 'constants/formatValues';
import { Printer } from 'contexts/PrinterChoice/constants';
import { useFormat } from 'hooks';
import { CommonMessages } from 'lang/messages/common';
import { SummaryMessages } from 'lang/messages/summary';
import { Nullable } from 'types/globals';

import styles from './styles.module.scss';

interface Props {
    data?: Nullable<Printer>;
    disableButton: boolean;
    redirect: () => void;
}

function CheckoutBanner({ data = null, redirect, disableButton }: Props) {
    const { MONTH_LONG } = useFormat(CommonMessages, commonValues());
    const { FREE_SHIPPING, UPGRADE } = useFormat(SummaryMessages);

    return (
        <div className={styles.checkoutBanner}>
            <div>
                <h3>
                    ${data?.monthlyFee || 0.0}
                    {MONTH_LONG}
                </h3>
                <p>{FREE_SHIPPING}</p>
            </div>
            <div>
                <Button
                    disabled={!data || disableButton}
                    className={styles.customButton}
                    onClick={redirect}
                >
                    {UPGRADE}
                </Button>
            </div>
        </div>
    );
}

export default CheckoutBanner;
