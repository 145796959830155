import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom-v5-compat';
import { ModalType } from 'constants/modals';
import {
    useCheckoutModals,
    useDataProvider,
    usePrinterChoice,
    useUserInfo,
} from 'contexts';
import { AvailablePrinters } from 'contexts/PrinterChoice/constants';
import {
    useFormat,
    useNotification,
    useRedirect,
    useTrackBuyflowAdd,
    useTrackBuyflowCheckout,
    useTrackBuyflowPurchase,
    useTrackBuyflowRemove,
    useTrackLink,
} from 'hooks';
import {
    CheckoutMessages,
    checkoutMessagesValues,
} from 'lang/messages/checkout';
import { Params, PRINTER_SELECTION_PATH } from 'screens/Checkout/constants';
import { UpgradeQueryParams } from 'services/pilotIdInfo';

import { useQuery } from './useQuery/useQuery';
import { useTranslation } from './useTranslation/useTranslation';
import { BuyflowStates, EMPTY_BILLING_INFO, OrderZones } from './constants';
import { useOrder } from './useOrder';

function useCheckout() {
    const { currentLang } = useTranslation();
    const upgradeInfo = useQuery<UpgradeQueryParams>();
    const { planId, inkPlanId } = useParams<
        Params & Record<string, string | undefined>
    >();
    const location = useLocation();
    const { toggleModals } = useCheckoutModals();
    const dataContext = useDataProvider();
    const [submitting, setSubmitting] = useState(false);
    const { push } = useRedirect();
    const { resetUserChoice } = usePrinterChoice();
    const { postOrder, postUpgradeOrder } = useOrder();
    const { notify } = useNotification();
    const {
        shipping: shippingInfo,
        billing: billingInfo,
        changeInfo,
    } = useUserInfo();
    const { CHECKOUT_FAILED_POST } = useFormat(
        CheckoutMessages,
        checkoutMessagesValues({})
    );

    const trackCheckout = useTrackBuyflowCheckout();
    const trackBuyflowAdd = useTrackBuyflowAdd();
    const trackRemove = useTrackBuyflowRemove();
    const trackPurchase = useTrackBuyflowPurchase();
    const trackLink = useTrackLink();

    const { plan, inkPlan } = dataContext.getCurrentInkPlanAndPlanId({
        planId,
        inkPlanId,
    });

    useEffect(() => {
        if (plan && inkPlan) {
            trackBuyflowAdd(plan, inkPlan);
        }
    }, [trackBuyflowAdd, plan, inkPlan]);

    const onGoBack = () => {
        if (plan && inkPlan) {
            trackRemove(plan, inkPlan);
        }
        push(PRINTER_SELECTION_PATH);
    };

    const onConfirm = async () => {
        if (!shippingInfo || !inkPlan || !plan || submitting) {
            setSubmitting(false);
            return;
        }

        if (plan && inkPlan) {
            trackPurchase(plan, inkPlan);
        }

        const body = {
            dataContext,
            shippingInfo,
            billingInfo,
            planId,
            inkPlanId,
            paper: false,
            zone:
                (currentLang === 'en' && OrderZones.US) ||
                (currentLang?.toUpperCase() as OrderZones) ||
                OrderZones.US,
        };

        setSubmitting(true);
        if (upgradeInfo.pilotId && upgradeInfo) {
            postUpgradeOrder(upgradeInfo, body, {
                success: () => {
                    trackLink('Confirm Subscription', 'buyflow');
                    changeInfo('shipping', null);
                    changeInfo('billing', null);
                    push('/complete');
                },

                error: (err) => {
                    notify(err.error ? err.error : CHECKOUT_FAILED_POST);
                },

                finally: () => {
                    setSubmitting(false);
                    toggleModals(ModalType.ARN);
                },
            });
        } else {
            postOrder(
                {
                    ...body,
                    billingInfo:
                        body.billingInfo ?? EMPTY_BILLING_INFO.billingInfo,
                },
                {
                    success: () => {
                        trackLink('Confirm Subscription', 'buyflow');
                        changeInfo('shipping', null);
                        changeInfo('billing', null);
                        push('complete', true);
                    },

                    error: () => {
                        notify(CHECKOUT_FAILED_POST);
                    },

                    finally: () => {
                        setSubmitting(false);
                        toggleModals(ModalType.ARN);
                    },
                }
            );
        }
    };

    const onReview = () => {
        if (!plan || !inkPlan) {
            return;
        }

        if (
            plan?.id === AvailablePrinters.LEBI ||
            plan?.id === AvailablePrinters.HighVolume
        ) {
            resetUserChoice(plan?.id);
            toggleModals(ModalType.SayanNotAvailable);
            return;
        }

        trackCheckout(plan, inkPlan, 'e_pageView', BuyflowStates.Review);
        trackLink('Confirm Checkout', 'buyflow');
        toggleModals(ModalType.ARN);
    };

    return {
        onReview,
        onConfirm,
        onGoBack,
        plan,
        inkPlan,
        submitting,
        disableButton: location.pathname.includes('/upgrade')
            ? !shippingInfo
            : !shippingInfo || !billingInfo,
    };
}

export default useCheckout;
