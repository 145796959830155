import { useIntl } from 'react-intl';

export const useFormat = <
    T extends Record<string, string>,
    R extends Record<string, Record<string, string | number | undefined>>,
>(
    messages: T,
    values?: R
) => {
    const { formatMessage } = useIntl();

    const messagesKeys = Object.keys(messages);
    const output = {} as Record<keyof T, string>;

    messagesKeys.forEach((message: keyof T) => {
        const parsedValue = values ? values[messages[message]] : undefined;

        if (values && parsedValue) {
            output[message] = formatMessage(
                { id: messages[message] },
                parsedValue
            );
        } else {
            output[message] = formatMessage({ id: messages[message] });
        }
    });

    return output;
};
