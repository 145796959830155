import { useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Nullable } from 'types/globals';

function usePrintFAQs({ bodyClass }: { bodyClass: string }) {
    const [expandFAQs, setExpandAllFAQs] = useState(false);

    const ref = useRef<Nullable<HTMLDivElement>>(null);
    const promiseResolveRef = useRef<Nullable<(value?: unknown) => void>>(null);

    const toggleAllFAQS = () => {
        setExpandAllFAQs((prevState) => !prevState);
    };

    useEffect(() => {
        // The SetTimeout is super necessary as the veneer animation
        // takes quite a while and theres no way to disable it
        // before preparing the DOM for printing.

        const timeout = setTimeout(() => {
            if (expandFAQs && promiseResolveRef.current) {
                promiseResolveRef.current();
            }
        }, 1000);

        return () => {
            clearTimeout(timeout);
        };
    }, [expandFAQs]);

    const handlePrint = useReactToPrint({
        content: () => ref.current,
        bodyClass,
        onBeforeGetContent: () => {
            return new Promise((resolve) => {
                promiseResolveRef.current = resolve;
                toggleAllFAQS();
            });
        },
        onAfterPrint: toggleAllFAQS,
    });

    return { ref, expandFAQs, handlePrint };
}

export { usePrintFAQs };
