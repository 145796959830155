import EnvyBk from 'assets/printer_selection_envy_background.webp';
import EnvyBkMob from 'assets/printer_selection_envy_background_mobile.webp';
import InspireBk from 'assets/printer_selection_envy_inspire_background.webp';
import InspireBkMob from 'assets/printer_selection_envy_inspire_background_mobile.webp';
import OfficeJetBk from 'assets/printer_selection_office_jet_background.webp';
import OfficeJetBkMob from 'assets/printer_selection_office_jet_background_mobile.webp';
import { CookieVariation } from 'constants/cookiesEnums';
import { NewInkPlan } from 'contexts/PrinterChoice/constants';

import { highVolumeExclusivePlans, localDisallowedInkPlans } from './constants';

export const roundNumber = (sumToRound: number) => {
    return Math.round(sumToRound * 100) / 100;
};

// Selects the background image based on the printer name both
// spanish and english, desktop and mobile

export const backgroundImageSelector = (
    productName: string | undefined,
    isMob: boolean
) => {
    switch (productName) {
        case 'Essential':
        case 'Esencial':
            return isMob ? EnvyBkMob : EnvyBk;

        case 'Versatile':
        case 'Versátil':
            return isMob ? InspireBkMob : InspireBk;

        case 'Professional':
        case 'Profesional':
            return isMob ? OfficeJetBkMob : OfficeJetBk;

        default:
            return isMob ? EnvyBkMob : EnvyBk;
    }
};

// Filter plans that are not allowed for the selected printer
// this maybe redundant with another function so...
// TODO: check that this function is not redundant

export const filterItems = (
    newInkPlans: NewInkPlan[],
    disallowedInkPlans: string[],
    variation: CookieVariation
) => {
    let data = newInkPlans;

    // If no printer is selected, filter out the highVolume exclusive plans
    // All printer have some kind of disallowed plan, so take that as control

    if (disallowedInkPlans.length === 0) {
        data = newInkPlans.filter(
            (plan) => !highVolumeExclusivePlans.includes(plan.id)
        );
    }

    // Whenever a printer is selected, filter out the disallowed plans
    // both stablished by the printer and some hardcoded ones

    const sanitizedItems = data.filter(
        (plan) =>
            !localDisallowedInkPlans.includes(plan.id) &&
            !disallowedInkPlans.includes(plan.id)
    );

    // Sort the plans by pages
    const sorted = sanitizedItems.sort(
        (a, b) => Number(a.pages) - Number(b.pages)
    );

    // If the user is in the control group, filter out the highVolume exclusive plans
    return variation === CookieVariation.Control || !variation
        ? sorted.filter((plans) => !highVolumeExclusivePlans.includes(plans.id))
        : sorted;
};

// Calculate the total price of the plan based on the plans price
// plus selected printer added price

export const calculateTotalPrice = (
    inkPlan?: NewInkPlan,
    printerId?: string
) => {
    if (!inkPlan?.basePrices) {
        return 0;
    }

    const basePrice = inkPlan?.basePrices.find(
        (price) => price.printerID === printerId
    );

    if (!printerId) return inkPlan?.cost || 0;

    return printerId && inkPlan
        ? roundNumber((inkPlan.cost || 0) + (basePrice?.basePrice || 0))
        : 0;
};
