import {
    ChangeEventHandler,
    forwardRef,
    InputHTMLAttributes,
    ReactNode,
} from 'react';
import { CommonRef } from 'types/globals';

import styles from './CustomCheckbox.module.scss';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    label: string | ReactNode;
    id?: string;
    name: string;
    checked?: boolean;
    onChange: ChangeEventHandler<HTMLInputElement>;
    onBlur?: ChangeEventHandler<HTMLInputElement>;
}

function CustomCheckbox(
    {
        label,
        id = undefined,
        name,
        checked = undefined,
        onChange,
        onBlur = undefined,
        ...rest
    }: Props,
    ref: CommonRef<HTMLInputElement>
) {
    return (
        <div className={styles.wrapper}>
            <input
                type="checkbox"
                name={name}
                id={id}
                onChange={onChange}
                onBlur={onBlur}
                ref={ref}
                {...rest}
                checked={checked}
            />
            <label htmlFor={id} id={id} className={styles.label}>
                {label}
            </label>
        </div>
    );
}

export default forwardRef(CustomCheckbox);
