import { LOCALES } from 'lang/locales';

export enum ComparisonTableMessages {
    TITLE = 'comparisonTable.title',
    VIEW_SPECS = 'comparisonTable.techSpecs',
    SPECIFICATIONS_DATA_SHEET = 'comparisonTable.specifications.dataSheet',
    PRINTER_1 = 'comparisonTable.printer5',
    PRINTER_2 = 'comparisonTable.printer4',
    PRINTER_3 = 'comparisonTable.printer3',
    PRINTER_4 = 'comparisonTable.printer2',
    PRINTER_5 = 'comparisonTable.printer6',
    PRINT_SCAN_COPY = 'comparisonTable.printScanCopy',
    PRINTING_SPEED = 'comparisonTable.printingSpeed',
    INPUT_CAPACITY = 'comparisonTable.inputCapacity',
    PHOTO_TRAY = 'comparisonTable.photoTray',
    TOUCHSCREEN = 'comparisonTable.touchScreen',
    USB = 'comparisonTable.usb',
    FAX = 'comparisonTable.fax',
    AUTO_DOCUMENT_FEEDER = 'comparisonTable.autoDocumentFeeder',
}

export const comparisonTableMessages = {
    [LOCALES.ENGLISH]: {
        [ComparisonTableMessages.TITLE]: 'Comparison Table',
        [ComparisonTableMessages.VIEW_SPECS]: 'See tech specs',
        [ComparisonTableMessages.SPECIFICATIONS_DATA_SHEET]: 'HP Data Sheet',
        [ComparisonTableMessages.PRINTER_1]: 'HP Envy\n ',
        [ComparisonTableMessages.PRINTER_2]: 'HP Envy Inspire',
        [ComparisonTableMessages.PRINTER_3]: 'HP Officejet Pro',
        [ComparisonTableMessages.PRINTER_4]: 'HP Smart Tank 7605',
        [ComparisonTableMessages.PRINTER_5]: 'HP Smart Tank 5105',
        [ComparisonTableMessages.PRINT_SCAN_COPY]: 'Print, scan, copy',
        [ComparisonTableMessages.PRINTING_SPEED]: 'Printing speed, max ppm',
        [ComparisonTableMessages.INPUT_CAPACITY]: 'Input capacity, sheets',
        [ComparisonTableMessages.PHOTO_TRAY]: 'Photo tray',
        [ComparisonTableMessages.TOUCHSCREEN]: 'LCD touch screen',
        [ComparisonTableMessages.USB]: 'USB memory card host',
        [ComparisonTableMessages.FAX]: 'Built-in fax',
        [ComparisonTableMessages.AUTO_DOCUMENT_FEEDER]: 'Auto document feeder',
    },
    [LOCALES.SPANISH]: {
        [ComparisonTableMessages.TITLE]: 'Tabla de comparación',
        [ComparisonTableMessages.VIEW_SPECS]: 'Ver ficha',
        [ComparisonTableMessages.SPECIFICATIONS_DATA_SHEET]: 'Hoja de datos HP',
        [ComparisonTableMessages.PRINTER_1]: 'HP Envy\n ',
        [ComparisonTableMessages.PRINTER_2]: 'HP Envy Inspire',
        [ComparisonTableMessages.PRINTER_3]: 'HP Officejet Pro',
        [ComparisonTableMessages.PRINTER_4]: 'HP Smart Tank 7605',
        [ComparisonTableMessages.PRINTER_5]: 'HP Smart Tank 5105',
        [ComparisonTableMessages.PRINT_SCAN_COPY]:
            'Imprime, escanea, fotocopia',
        [ComparisonTableMessages.PRINTING_SPEED]:
            'Velocidad de impresión (ppm)',
        [ComparisonTableMessages.INPUT_CAPACITY]:
            'Capacidad de entrada, en hojas',
        [ComparisonTableMessages.PHOTO_TRAY]: 'Bandeja de fotos',
        [ComparisonTableMessages.TOUCHSCREEN]: 'Pantalla táctil LCD',
        [ComparisonTableMessages.USB]: 'Host de tarjeta de memoria USB',
        [ComparisonTableMessages.FAX]: 'Fax incorporado',
        [ComparisonTableMessages.AUTO_DOCUMENT_FEEDER]:
            'Alimentador automático de documentos',
    },
};
