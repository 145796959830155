import { Redirect } from 'react-router-dom';
import { CompatRoute, useLocation } from 'react-router-dom-v5-compat';
import { fixPath } from 'components/RoutedContent/utils';

function URLFixer() {
    const { pathname, search } = useLocation();

    return (
        <>
            <CompatRoute
                render={() => <Redirect to={fixPath(pathname, search)} />}
                exact
                path="/*"
            />
            <CompatRoute
                render={() => <Redirect to={fixPath(pathname, search)} />}
                exact
                path="/es/*"
            />
            <CompatRoute
                render={() => <Redirect to={fixPath(pathname, search)} />}
                exact
                path="/en/*"
            />
            <CompatRoute
                render={() => <Redirect to={fixPath(pathname, search)} />}
                exact
                path="/us/*"
            />
            <CompatRoute
                render={() => <Redirect to={fixPath(pathname, search)} />}
                exact
                path="/el-corte-ingles"
            />
            <CompatRoute
                render={() => <Redirect to={fixPath(pathname, search)} />}
                exact
                path="/faq"
            />
            <CompatRoute
                render={() => <Redirect to={fixPath(pathname, search)} />}
                exact
                path="/upgrade"
            />
        </>
    );
}
export default URLFixer;
