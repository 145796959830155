import { useCallback, useEffect } from 'react';

interface Props {
    show: boolean;
    onClose: () => void;
}

function useModalEventListeners({ show, onClose }: Props) {
    const handleCloseWindow = useCallback(
        (e: KeyboardEvent) => {
            if (e.key === 'Escape' && show) {
                onClose();
            }
        },
        [show, onClose]
    );

    useEffect(() => {
        window.addEventListener('keydown', handleCloseWindow);
        if (show) {
            document.body.style.overflow = 'hidden';
            document.body.style.zIndex = '1';
        }

        return () => {
            window.removeEventListener('keydown', handleCloseWindow);
            document.body.style.overflow = 'unset';
            document.body.style.zIndex = '';
        };
    }, [handleCloseWindow, show]);

    return { handleCloseWindow };
}

export default useModalEventListeners;
