import { ReactNode, useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { LoadingScreen } from 'components';
import { useDataProvider } from 'contexts/DataProvider';
import { useTranslation } from 'hooks';

interface Props {
    children: ReactNode;
}

function StripeEnabledApp({ children }: Props) {
    const [stripePromise, setStripePromise] = useState<
        Promise<Stripe | null> | undefined
    >();

    const { planData } = useDataProvider();
    const { isSpain: isSpanish } = useTranslation();

    const stripeApiKey = planData?.stripeApiKey;

    useEffect(() => {
        if (stripePromise || !stripeApiKey) {
            return;
        }

        setStripePromise(loadStripe(stripeApiKey));
    }, [stripePromise, stripeApiKey]);

    if (!stripePromise) {
        return <LoadingScreen />;
    }

    return (
        <Elements
            options={{ locale: isSpanish() ? 'es' : 'en' }}
            stripe={stripePromise}
        >
            {children}
        </Elements>
    );
}

export default StripeEnabledApp;
