import { CookieVariation } from 'constants/cookiesEnums';

export const translateVariation = (current: CookieVariation) => {
    switch (current) {
        case CookieVariation.Control:
            return 'Control';

        case CookieVariation.PlusSayan:
            return 'Plus sayan';

        case CookieVariation.SayanReplacing:
            return 'Sayan replacing';

        case CookieVariation.LEBI:
            return 'Lebi printer';

        default:
            return 'No value set';
    }
};
