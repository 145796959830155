import deliveryIcon from 'assets/hero_delivery.svg';
import printerIcon from 'assets/hero_printer.svg';
import techServiceIcon from 'assets/hero_tech_support.svg';
import warrantyIcon from 'assets/hero_warranty.svg';
import { HeroMessages } from 'lang/messages/heroScreen';

export const price = 20;

export const ICONS = (texts: Record<keyof typeof HeroMessages, string>) => [
    {
        id: 0,
        icon: printerIcon,
        text: texts.PRINTER,
    },
    {
        id: 1,
        icon: deliveryIcon,
        text: texts.DELIVERY,
    },
    {
        id: 2,
        icon: warrantyIcon,
        text: texts.WARRANTY,
    },
    {
        id: 3,
        icon: techServiceIcon,
        text: texts.TECH_SERVICE,
    },
];

export interface ECICodeQuery {
    code: string;
}
