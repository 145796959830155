import { useLocation } from 'react-router-dom-v5-compat';
import { checkForSunset } from 'components/RoutedContent/utils';
import { useTranslation } from 'hooks';
import { SpainSunset } from 'screens';
import Sunset from 'screens/Sunset/Sunset';

function CheckForSunset({ children }: { children: JSX.Element }) {
    const location = useLocation();
    const { isSpain } = useTranslation();

    if (checkForSunset(true, location.pathname)) {
        return isSpain() ? <SpainSunset /> : <Sunset />;
    }

    return children;
}
export default CheckForSunset;
