import { useState } from 'react';
import { TrackAction, TrackLocation } from 'constants/analytics';
import { Printer } from 'contexts/PrinterChoice/constants';
import { useTrackLink } from 'hooks';

export function useChoosePrinter(
    setUserChoice: (type: 'printer', newPrinter: Printer) => void
) {
    const trackLink = useTrackLink();

    const handlePrinterSelection = (newPrinter: Printer) => {
        trackLink(
            `${TrackAction.PrinterSelect}${newPrinter.name}`,
            TrackLocation.PrinterSelection
        );
        setUserChoice('printer', newPrinter);
    };

    return {
        handlePrinterSelection,
    };
}

export function useComparisonToggle() {
    const trackLink = useTrackLink();

    const [comparisonModal, setComparisonModal] = useState(false);

    const toggleComparisonModal = () => {
        setComparisonModal((prevState) => {
            trackLink(
                prevState
                    ? TrackAction.ClosedComparisonTable
                    : TrackAction.OpenedComparisonTable,
                TrackLocation.PrinterSelection
            );
            return !prevState;
        });
    };

    return { comparisonModal, toggleComparisonModal };
}
